import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const Modal = ({ children, open, handleClose }) => {
    return (
        <Container open={open} onClose={handleClose}>
            {children}
        </Container>
    );
};

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
};

export default Modal;
