import styled, { css } from 'styled-components';

export const Container = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    ${({ fixedButtonSubmit, fullWidthButtonSubmit }) =>
        fixedButtonSubmit
            ? ''
            : fullWidthButtonSubmit
            ? css`
                  > div {
                      &:last-of-type {
                          margin-top: 1rem;
                      }
                  }
              `
            : css`
                  > div {
                      &:last-of-type {
                          flex: 1;
                          display: flex;
                          flex-direction: row;
                          justify-content: ${({ deleteButton }) => (deleteButton ? 'space-between' : 'flex-end')};
                          margin-top: 2rem;

                          > button {
                              max-width: 50%;

                              &:first-of-type {
                                  margin-right: 0.5rem;
                              }
                              &:last-of-type {
                                  margin-left: 0.5rem;
                              }
                          }
                      }
                  }
              `}
`;
export const ContainerRight = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;
export const Item = styled.div`
    margin-bottom: 1rem;
`;
