import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { Close } from '@mui/icons-material';
import { getMaxNChars } from 'utils/html';
import { getSystemImageUrl } from 'utils/assets';
import Svg from 'components/atoms/structure/Svg';

const FileCard = ({ file, handleDelete }) => {
    const fileUrl = URL.createObjectURL(file);

    const fileType = file.type.split('/')[0];

    return (
        <Container>
            <aside>
                {
                    {
                        image: <img src={fileUrl} alt={file.name} />,
                        video: <Svg url={getSystemImageUrl('Icons_Publicarvideo.svg')} alt="vídeo" width="35" height="35" />
                    }[fileType]
                }
            </aside>

            <main>
                <span>{getMaxNChars(file.name, 20)}</span>
            </main>

            <footer>
                <button onClick={() => handleDelete()}>
                    <Close />
                </button>
            </footer>
        </Container>
    );
};

FileCard.propTypes = {
    file: PropTypes.object,
    handleDelete: PropTypes.func
};

export default FileCard;
