import ReactQuill from 'react-quill';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;

    > header {
        width: 100%;
        padding: 1rem 0;
    }

    > main {
        width: 100%;
    }

    > footer {
        padding-top: 1rem;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    > * {
        margin-bottom: 0.5rem;
    }
`;

export const Input = styled(ReactQuill)`
    width: 100%;
    border-bottom: 1px solid #e8e8e8;

    .quill {
        border-bottom: 1px solid #e8e8e8;
    }

    .ql-container.ql-snow {
        border: none;
    }
`;
