import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: flex-start;

    > h3 {
        font-size: 1.6rem;
        margin: 0;
        padding: 0;
        color: var(--color-primary);
        font-weight: 700;
    }

    > p {
        font-size: 0.8rem;
        margin: 0;
        padding: 0;
        color: var(--color-gray);
    }
`;
