import React from 'react';
import PropTypes from 'prop-types';
import { Container, QuestionContainer } from './styles';
import { QuizQuestionType } from 'types/components/quiz';
import MultipleQuestion from 'components/molecules/questions/MultipleQuestion';

const Quiz = ({ questions, answers, answerQuestion }) => {
    return (
        <Container>
            <header>
                <h2>Para fixar o conteúdo</h2>
            </header>
            <main>
                {questions &&
                    questions.map((question, index) => (
                        <QuestionContainer key={index}>
                            <MultipleQuestion index={index} question={question} onChoose={answerQuestion} previousAnswer={answers[index]} />
                        </QuestionContainer>
                    ))}
            </main>
        </Container>
    );
};

Quiz.propTypes = {
    questions: PropTypes.arrayOf(PropTypes.shape(QuizQuestionType)).isRequired,
    answers: PropTypes.arrayOf(PropTypes.number),
    answerQuestion: PropTypes.func.isRequired
};

export default Quiz;
