import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    top: 5rem;

    width: calc(100% - 2rem);
    max-width: 600px;
    height: 80vh;

    left: 50%;
    transform: translate(-50%, 0);

    background-color: #fff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    z-index: 20;
    border: 1px solid #ddd;

    > header {
        height: 3.4rem;
        padding: 1rem;
        border-bottom: 1px solid #ddd;

        > h3 {
            font-size: 1.1rem;
        }
    }

    > main {
        height: calc(100% - 8rem);
        padding: 1rem;

        overflow: auto;

        font-size: 0.9rem;
    }

    > footer {
        background-color: #fff;
        padding: 1rem 1rem 1rem 1rem;
        width: 100%;
        bottom: 0;
        left: 0;
        border-top: 1px solid #ddd;
        display: flex;
        height: 80px;
        max-width: 600px;

        > * {
            flex: 1;
            &:first-child {
                margin-right: 0.5rem;
            }

            &:last-child {
                margin-left: 0.5rem;
            }
        }
    }

    @media screen and (min-width: 768px) {
        width: 600px;
    }
`;
