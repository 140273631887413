import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import NumberFormat from 'react-number-format';

const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            isNumericString
            prefix="R$"
            decimalScale={2}
            decimalSeparator=","
            thousandSeparator="."
        />
    );
});

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

const CashInput = ({ id, item, handleChange, value, handleBlur, error }) => {
    const { label, optional, message, typeInput } = item;

    return (
        <Container
            label={`${label}${optional ? '' : '*'}`}
            value={value}
            variant="outlined"
            type={typeInput}
            onChange={(e) => handleChange(e)}
            helperText={error || message}
            error={!!error}
            onBlur={handleBlur}
            id={id}
            name={id}
            InputProps={{
                inputComponent: NumberFormatCustom
            }}
        />
    );
};

CashInput.propTypes = {
    item: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    value: PropTypes.any,
    error: PropTypes.string,
    id: PropTypes.string
};

export default CashInput;
