import useFeedback from 'hooks/useFeedback';
import { useDispatch } from 'react-redux';
import { quizTunnel } from 'services/quizzes';

const useQuiz = () => {
    const dispatch = useDispatch();
    const feedback = useFeedback();

    const close = () => {
        dispatch({ type: 'REMOVE_QUIZ' });
    };

    const add = (quiz) => {
        if (Array.isArray(quiz)) {
            quiz.forEach((q) => {
                feedback.satisfactionSearch(quizTunnel(q));
            });

            return;
        }

        feedback.satisfactionSearch(quizTunnel(quiz));
    };

    return { add, close };
};

export default useQuiz;
