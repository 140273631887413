import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from '../../redux/reducers/alertReducer';

const useAlert = () => {
    const dispatch = useDispatch();

    const alertReducer = useSelector((state) => state.alert);

    const defaultCloseTime = 4500;

    const success = (message, closeTime) => {
        dispatch({ type: alertActions.SET_ALERT_MESSAGE, payload: message });
        dispatch({ type: alertActions.SET_ALERT_VARIANT, payload: 'success' });
        dispatch({ type: alertActions.SET_ALERT_OPEN, payload: true });

        closeTimeout(closeTime ?? defaultCloseTime);
    };
    const warning = (message, closeTime) => {
        dispatch({ type: alertActions.SET_ALERT_MESSAGE, payload: message });
        dispatch({ type: alertActions.SET_ALERT_VARIANT, payload: 'warning' });
        dispatch({ type: alertActions.SET_ALERT_OPEN, payload: true });

        closeTimeout(closeTime ?? defaultCloseTime);
    };
    const error = (message, closeTime) => {
        dispatch({ type: alertActions.SET_ALERT_MESSAGE, payload: message });
        dispatch({ type: alertActions.SET_ALERT_VARIANT, payload: 'error' });
        dispatch({ type: alertActions.SET_ALERT_OPEN, payload: true });

        closeTimeout(closeTime ?? defaultCloseTime);
    };

    const close = () => {
        dispatch({ type: alertActions.SET_ALERT_OPEN, payload: false });
    };

    const closeTimeout = (timeout) =>
        setTimeout(() => {
            dispatch({ type: alertActions.SET_ALERT_OPEN, payload: false });
        }, timeout);

    return {
        alertReducer,
        success,
        error,
        warning,
        close
    };
};
export default useAlert;
