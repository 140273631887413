import { api } from 'services/api';
import { getImageUrl } from 'utils/assets';
import { dateJsToISO } from 'utils/date';
import { postLogError } from '../logErrors/index';

const getEvents = async (studentId) => {
    const response = await api
        .get(`/event_client`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 200) {
        return {
            data: parsedEvents(response.data, studentId),
            status: response.status
        };
    }

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    return {
        data: response.data,
        status: response.status
    };
};

const getStudentEvents = async (studentId) => {
    const response = await api
        .get(`/get_student_events/${studentId}`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 200) {
        return {
            data: parsedEvents(response.data, studentId),
            status: response.status
        };
    }

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    return {
        data: response.data,
        status: response.status
    };
};

const getEventById = async (id, studentId) => {
    const response = await api
        .get(`/event/${id}`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 200) {
        return {
            data: parsedEvent(response.data, studentId),
            status: response.status
        };
    }

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    return {
        data: response.data,
        status: response.status
    };
};

const subscribeEvent = async (eventId, studentId, subscribe) => {
    const response = await api
        .post(`/registered_event`, {
            idEvent: eventId,
            idStudent: studentId.toString(),
            registered: Number(subscribe)
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status !== 200) {
        return {
            data: response.data,
            status: response.status
        };
    }

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    return {
        data: response.data,
        status: response.status
    };
};

const likeEvent = async (eventId, studentId, like) => {
    const response = await api
        .post(`/like_event`, {
            idEvent: eventId,
            idStudent: studentId.toString(),
            like: Number(like)
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const viewEvent = async (eventId) => {
    const response = await api
        .post(`/view_event`, {
            idEvent: eventId
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const seeEvent = async (eventId) => {
    const response = await api
        .post(`/saw_event`, {
            idEvent: eventId
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const getFilterData = async () => {
    const response = await api
        .get(`/get_resource_event`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const parsedEvents = (events, studentId = null) => {
    if (!events) return [];

    let parsedEventsData = [];

    events.forEach((event) => {
        if (event.active || new Date() <= new Date(event.finalDate)) {
            parsedEventsData.push(parsedEvent(event, studentId));
        }
    });

    return parsedEventsData;
};

const parsedEvent = (event, studentId = null) => {
    const speakers = event?.speaker ? event?.speaker.map((speaker) => speaker.name) : null;
    return {
        id: event?.id,
        title: event?.title,
        point: event?.point,
        initialDate: dateJsToISO(event?.initialDate ? event.initialDate : event.initial_date),
        finalDate: dateJsToISO(event?.finalDate ? event.finalDate : event.final_date),
        totalTime: event?.totalTime,
        description: event?.description,
        active: event?.active,
        typeEvent: event?.typeEvent,
        image: event?.file ? (event?.file[0]?.url ? getImageUrl(event?.file[0].url) : '') : '',
        speakers: event?.speaker,
        speaker: event?.speaker
            ? event.speaker.length > 1
                ? speakers.slice(0, -1).join(', ') + ' e ' + speakers.slice(-1)
                : speakers.join(', ')
            : null,
        competences: event?.competence,
        registered: event?.registered?.some((registered) => registered?.id_student === studentId),
        like: event?.liked,
        likes: event?.like,
        registers: event?.registered?.length,
        url: event?.url,
        saw: event?.saw,
        sawed: event?.sawed,
        viewed: event?.viewed,
        isFinished: event?.isFinished,
        publication: event?.publication || false,
        publicationCommentsTotal: event?.publicationCommentsTotal || 0
    };
};

export {
    getEvents,
    getEventById,
    getFilterData,
    subscribeEvent,
    likeEvent,
    viewEvent,
    seeEvent,
    parsedEvents,
    parsedEvent,
    getStudentEvents
};
