import styled from 'styled-components';

export const Container = styled.div`
    background-color: #fff;
    position: fixed;
    width: 90vw;
    min-height: 100vh;
    max-height: 100vh;
    margin: 0;
    margin-left: ${(props) => (props.active ? '0' : '-100vw')};
    transition: var(--transition);
    z-index: 11;
    top: 0;
    left: 0;
    overflow: auto;

    > header {
        background-color: var(--color-background);
        padding: 2rem var(--padding-base) var(--padding-base) var(--padding-base);
        border-bottom: 1px #bbb solid;

        > h2 {
            color: var(--color-primary);
            font-weight: 700;
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
        }
    }

    > main {
    }

    @media screen and (min-width: 768px) {
        width: 600px;
        margin-left: ${(props) => (props.active ? '0' : '-600px')};
    }
`;
