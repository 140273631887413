import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    border: 1px solid #dee2e6;

    height: 5rem;

    > aside {
        display: flex;
        flex: 4;
        margin-right: 0.5rem;

        > img {
            width: auto;
            height: auto;
            max-height: 4rem;
            max-width: 7rem;
            border-radius: 0.5rem;
        }
    }

    > main {
        margin: 0 0.5rem 0 0.5rem;

        display: flex;
        flex: 4;
        max-width: 100%;

        > span {
            font-size: 0.8rem;
        }
    }

    > footer {
        margin-left: 0.5rem;

        display: flex;
        flex: 1;
        max-width: 100%;

        > button {
            background-color: #fff;
            color: #c00;
            border: none;
            -webkit-tap-highlight-color: transparent;
        }
    }
`;
