import { api } from 'services/api';
import { postLogError } from '../logErrors/index';

const getProfileById = async (id) => {
    const response = await api
        .get(`/student/${id}`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 200) {
        return {
            data: parsedProfiles(response.data, id),
            id,
            status: response.status
        };
    }
    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }
    return {
        data: response.data,
        status: response.status
    };
};

const getResourceEditData = async () => {
    const parseResources = (data) => {
        return {
            languages:
                data?.languages?.map((item) => {
                    return {
                        id: item.id,
                        value: item.name
                    };
                }) || [],
            states:
                data?.state?.map((item) => {
                    return {
                        id: item.id,
                        value: item.name
                    };
                }) || [],
            levelAcademicEducation:
                data?.levelAcademicEducation?.map((item) => {
                    return {
                        id: item.id,
                        value: item.description
                    };
                }) || [],
            statusAcademicEducation:
                data?.statusAcademicEducation?.map((item) => {
                    return {
                        id: item.id,
                        value: item.description
                    };
                }) || [],
            participationCourseInPfa: data?.participationCourseInPfa,
            participationEventInPfa: data?.participationEventInPfa
        };
    };

    const response = await api
        .get(`/get_resource_student`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token expirado');
    }

    return {
        data: parseResources(response.data),
        status: response.status
    };
};

const updateProfile = async (data, id) => {
    const response = await api
        .post(`/update_student/${id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const parsedProfiles = (profiles, id) => {
    let parsedProfileData = [];
    parsedProfileData.push(parsedProfile(profiles, id));

    return parsedProfileData;
};

const parsedProfile = (profile) => {
    profile.academicEducation.forEach((element) => {
        element.title = element.institution;
        element.legend = element.name;
        element.footer = element?.final_date
            ? new Date(element.initial_date).getFullYear() + '-' + new Date(element.final_date).getFullYear()
            : new Date(element.initial_date).getFullYear() + '-' + new Date(element.end_forecast).getFullYear();
    });

    profile.courseAndCertificate.forEach((element) => {
        element.title = element.name;
        element.legend = element.institution;
        element.footer = new Date(element.conclusion_date).getFullYear();
    });

    profile.experience.forEach((element) => {
        element.title = element.office;
        element.legend = element.company;
        element.footer =
            new Date(element.initial_date).getFullYear() + (element.final_date ? '-' + new Date(element.final_date).getFullYear() : '');
    });

    profile.language.forEach((element) => {
        element.title = element.language;
    });

    profile.levelExperience = {
        id: profile?.levelExperience?.id,
        name: profile?.levelExperience?.level
    };

    return profile;
};

const getCities = async (idState) => {
    const response = await api
        .get(`/city`, {
            params: {
                idState: idState
            }
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const updateBackground = async (data) => {
    const response = await api
        .post('/update_background', data)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            throw new Error(err);
        });

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

export { getResourceEditData, getProfileById, getCities, updateProfile, updateBackground };
