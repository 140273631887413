import PropTypes from 'prop-types';

const navigationType = {
    title: PropTypes.string,
    icon: PropTypes.node,
    iconSelected: PropTypes.node,
    link: PropTypes.string,
    disabled: PropTypes.bool
};

const absoluteMenuType = {
    title: PropTypes.string,
    icon: PropTypes.node,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
};

export { navigationType, absoluteMenuType };
