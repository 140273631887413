import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { pfaListItemType } from 'types/components/lists/PfaListItem';
import PfaListCard from 'components/molecules/cards/PfaListCard';
import Message from 'components/molecules/alerts/Message';

const PfaList = ({ data, errorMessage }) => {
    if (!data || data.length === 0) {
        return <Message text={errorMessage} />;
    }

    return (
        <Container>
            {data.map((item, index) => (
                <PfaListCard key={index} {...item} />
            ))}
        </Container>
    );
};

PfaList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape(pfaListItemType)),
    errorMessage: PropTypes.string
};

export default PfaList;
