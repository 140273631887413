import React from 'react';
import PropTypes from 'prop-types';
import { Container, Label, SelectStyled, Title } from './styles';
import { MenuItem, FormHelperText } from '@mui/material';
import { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

const DropdownInput = ({ id, handleChange, value, error, handleBlur, item, width }) => {
    const { label, optional, options, onChange } = item;

    useEffect(() => {
        if (!value || !onChange) {
            return;
        }

        onChange(value);
    }, [value]);

    useEffect(() => {
        const optionsIds = options.map((option) => option.id);

        if (!optionsIds.includes(value)) {
            handleChange({
                target: {
                    value: ''
                }
            });
        }
    }, [options]);

    return (
        <>
            <Title>{ReactHtmlParser(item.descriptionLabel)}</Title>
            <Container error={error} width={width || null}>
                <Label>
                    {label}
                    {optional ? '' : '*'}
                </Label>
                <SelectStyled
                    id={id}
                    name={id}
                    onBlur={handleBlur}
                    value={value || ''}
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                    helperText={'d'}
                    error={error}
                >
                    {options.map((option) => (
                        <MenuItem value={option.id} key={option.id}>
                            {option.value}
                        </MenuItem>
                    ))}
                </SelectStyled>
                <FormHelperText>{error}</FormHelperText>
            </Container>
        </>
    );
};

DropdownInput.propTypes = {
    item: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    value: PropTypes.any,
    error: PropTypes.string,
    id: PropTypes.string,
    width: PropTypes.string
};

export default DropdownInput;
