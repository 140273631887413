import styled from 'styled-components';

export const Container = styled.div`
    margin-bottom: 1rem;
`;

export const CertificateContainer = styled.div`
    background: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 16px 0;
    margin-top: 16px;
    padding: 1rem;

    > * {
        margin-bottom: 0.5rem;

        &:last-child {
            margin-bottom: 0;
        }

        h3 {
            text-align: center;
        }

        button {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding: 8px 20px;
            gap: 8px;
            border-radius: 4px;
            margin: 0 auto;
            font-weight: 600;
            font-size: 14px;
        }
    }

    > a {
        display: block;
        text-align: center;
        color: var(--color-primary);
    }

    > button {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 8px 20px;
        gap: 8px;
        border-radius: 4px;
        margin: 0 auto;
        font-weight: 400;
        font-size: 1rem;
    }
`;

export const ErrorMessage = styled.div`
    margin-top: 16px;
`;
