import styled from 'styled-components';

export const Container = styled.div`
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: 1rem;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    ${({ pointer }) => pointer && 'cursor: pointer;'}

    > aside {
        margin-right: 1rem;
        background-color: #e7f5e6;
        border-radius: var(--border-radius);
        padding: 1rem;

        > div {
            background: #e7f5e6;
            border-radius: var(--border-radius);
            width: 4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #888;
        }

        > img {
            border-radius: var(--border-radius);
            width: 2rem;
            height: 2rem;
            object-fit: cover;
            background-color: #eee;
        }
    }

    > main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        > * {
            margin: 0;
            padding: 0;
        }

        > h3 {
            font-size: 1rem;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            font-weight: 700;
        }

        > p {
            font-size: 0.9rem;
            line-height: 1.1rem;
            color: var(--color-black);
        }

        > span {
            color: var(--color-gray);
            font-size: 0.9rem;
        }
    }
`;
