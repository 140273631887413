import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { ParticipantType } from 'types/data/participant';
import ParticipantsLetterList from '../ParticipantsLetterList';
import Message from 'components/molecules/alerts/Message';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
const ParticipantsList = ({ participants, search, loadingMore }) => {
    const idRole = useSelector((state) => state.participants.filterIdRole);

    const participantsKeys = Object.keys(participants).sort((a, b) => a.localeCompare(b));

    const checkHasStudents = () => {
        let hasStudentsAux = false;

        participantsKeys.map((key) => {
            const participantsByKey = participants[key];
            const filteredParticipants = participantsByKey
                .filter((participant) => {
                    return participant.name.toLowerCase().includes(search.toLowerCase());
                })
                .sort((a, b) => a.name.localeCompare(b.name));

            if (filteredParticipants.length > 0) {
                hasStudentsAux = true;
            }
        });

        return hasStudentsAux;
    };

    const hasStudents = checkHasStudents();

    return (
        <Container>
            {participantsKeys.map((key) => {
                const participantsByKey = participants[key];
                const filteredParticipants = participantsByKey
                    .filter((participant) => {
                        return (
                            (participant.name.toLowerCase().includes(search.toLowerCase()) && participant.roleId == idRole) ||
                            (participant.name.toLowerCase().includes(search.toLowerCase()) && idRole === null)
                        );
                    })
                    .sort((a, b) => a.name.localeCompare(b.name));

                return <ParticipantsLetterList key={key} letter={key} participants={filteredParticipants} />;
            })}

            {loadingMore ? (
                <footer>
                    <CircularProgress />
                </footer>
            ) : (
                <>{!hasStudents && <Message text="Nenhum participante encontrado" />}</>
            )}
        </Container>
    );
};

ParticipantsList.propTypes = {
    participants: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape(ParticipantType))).isRequired,
    search: PropTypes.string.isRequired,
    loadingMore: PropTypes.bool.isRequired,
    searching: PropTypes.bool
};

export default ParticipantsList;
