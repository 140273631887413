const getLimitOfPage = () =>
    Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
    );

const verifyEndOfPage = (scrollY) => {
    return window.innerHeight + scrollY + 10 >= document.documentElement.scrollHeight;
};

export { getLimitOfPage, verifyEndOfPage };
