import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { LevelType } from 'types/data/level';
import Progress from 'components/atoms/inputs/Progress';
import Svg from 'components/atoms/structure/Svg';
import { getSystemImageUrl } from 'utils/assets';

const BlockedLevelCard = ({ level }) => {
    const componentId = `level-card-${level.id}`;

    return (
        <Container id={componentId}>
            <header>
                <Svg url={getSystemImageUrl('lock.svg')} width="50" height="50" />
            </header>

            <main>
                <h2>{level.name}</h2>
            </main>

            <footer>
                <Progress progress={level.progress} color="secondary" />
            </footer>
        </Container>
    );
};

BlockedLevelCard.propTypes = {
    level: PropTypes.shape(LevelType)
};

export default BlockedLevelCard;
