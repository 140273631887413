import BlankContainer from 'components/atoms/structure/BlankContainer';
import React from 'react';
import Title from 'components/atoms/texts/Title';
import FormLogin from 'components/organisms/forms/Auth/FormLogin';
import Meta from 'components/templates/Seo/Meta';

const Login = () => {
    return (
        <>
            <Meta title="Login" description="Faça seu login" />
            <Title heading="Bem vindos ao:" title="Profissional do Futuro do Agronegócio" />

            <BlankContainer>
                <FormLogin />
            </BlankContainer>
        </>
    );
};

export default Login;
