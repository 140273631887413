import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    background-color: #fff;
    padding: 1rem;
    margin-bottom: -1rem;

    > h2 {
        font-size: 1.2rem;
        color: var(--color-text);
    }

    > * {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const Section = styled.div`
    width: 100%;
    margin-top: 2rem;

    &:first-of-type {
        margin-top: 0;
    }

    > * {
        margin-bottom: 1rem;
    }

    > header {
        display: flex;
        flex-direction: row;
        align-items: center;

        > img {
            background-color: #ddd;
            border-radius: 50%;
            padding: 0.5rem;
            height: 45px;
            width: 45px;
        }

        > h2 {
            font-size: 1rem;
            letter-spacing: 0.15px;
            font-weight: 600;
            font-size: 1rem;
            line-height: 24px;
            margin-left: 1rem;
            color: var(--color-text);
        }
    }

    > main {
        > * {
            margin-bottom: 1rem;
        }
    }
`;
