import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Controls, Crop, CropperContainer } from './styles';

import Modal from 'components/organisms/layout/modals/Modal';
import Button from 'components/molecules/buttons/Button';
import { getUrlAndImageByCrop } from './utils';

const CropImage = ({ imageSrc, updateImageByCropped, aspect = 1, shape = 'rect' }) => {
    const [croppedArea, setCroppedArea] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    };

    const onSubmit = async () => {
        const data = await getUrlAndImageByCrop(imageSrc, croppedArea);

        updateImageByCropped(data.url, data.image);
    };

    return (
        <Modal open={true} handleClose={() => {}}>
            <Container>
                <header>
                    <h3>Ajuste sua imagem</h3>
                </header>
                <main>
                    <CropperContainer>
                        <Crop
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={aspect}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                            cropShape={shape}
                        />
                    </CropperContainer>
                    <Controls>
                        <input
                            type="range"
                            value={zoom}
                            min={1}
                            max={10}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e) => {
                                setZoom(e.target.value);
                            }}
                            className="zoom-range"
                        />
                    </Controls>
                </main>
                <footer>
                    <Button onClick={() => onSubmit()} width="8rem">
                        Salvar
                    </Button>
                </footer>
            </Container>
        </Modal>
    );
};

CropImage.propTypes = {
    imageSrc: PropTypes.any.isRequired,
    updateImageByCropped: PropTypes.func.isRequired,
    aspect: PropTypes.number,
    shape: PropTypes.oneOf(['rect', 'round'])
};

export default CropImage;
