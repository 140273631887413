import styled from 'styled-components';

export const Container = styled.div`
    grid-area: LA;
    width: 100%;
    padding-right: 30px;
    height: 30rem;
    position: sticky;
    top: calc(var(--navbar-height) + 1rem);
    align-self: start;
`;
