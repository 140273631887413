import styled from 'styled-components';

export const Container = styled.div`
    grid-area: HA;

    width: 100%;
    height: 30rem;

    > header {
        > h3 {
            font-family: 'Myriad Pro';
            font-style: normal;
            font-weight: 700;
            font-size: 1.2rem;
            line-height: 130%;
            color: #373848;
        }
    }
`;

export const Content = styled.div`
    margin-top: 2rem;

    &:first-of-type {
        margin-top: 0;
    }
`;
