import React, { useEffect } from 'react';
import List from '@mui/material/List';
import { ListItemButton, ListItemText, Divider, ListItemIcon } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Container, ListHeader, AddFavorite, BoxIcone } from './styles';
import useFavoriteList from 'hooks/useFavoriteList';
import { useSelector } from 'react-redux';

const FavoriteMenu = () => {
    const favoriteReducer = useSelector((state) => state.favoriteList);
    const favoriteList = useFavoriteList();

    const submit = (id) => {
        window.location.href = `/aluno/favoritos/${id}`;
    };

    useEffect(() => {
        favoriteList.getAll();
    }, []);

    return (
        <Container>
            <List
                disablePadding
                component="nav"
                subheader={
                    <ListHeader>
                        <h1>Seus Favoritos</h1>
                    </ListHeader>
                }
            >
                <Divider />
                <ListItemButton onClick={() => favoriteList.openAddModal()}>
                    <ListItemIcon>
                        <AddFavorite />
                    </ListItemIcon>
                    <ListItemText primary="Crie uma nova lista" />
                </ListItemButton>
                <Divider />
                {favoriteReducer.list.map((value) => (
                    <ListItemButton key={value.id} onClick={() => submit(value.id)}>
                        <ListItemIcon>
                            <BoxIcone>
                                {value.icon ? (
                                    <img src={value.icon.url} alt="imagem icone" width={25} />
                                ) : (
                                    <AutoAwesomeIcon style={{ color: '#87a851' }} />
                                )}
                            </BoxIcone>
                        </ListItemIcon>
                        <ListItemText primary={value.description} />
                    </ListItemButton>
                ))}
            </List>
        </Container>
    );
};

export default FavoriteMenu;
