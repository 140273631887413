import { CircularProgress } from '@mui/material';
import Message from 'components/molecules/alerts/Message';
import Meta from 'components/templates/Seo/Meta';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getProfileById } from 'services/profile';
import MenuLayout from 'components/templates/layouts/Components/MenuLayout';
import EditProfileForm from 'components/organisms/forms/Others/EditProfileForm';
import { getEnterpriseById } from 'services/enterprise';
import EditEnterpriseForm from 'components/organisms/forms/Others/EditEnterpriseForm';

const EditarPerfil = () => {
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState({});
    const auth = useSelector((state) => state.auth);
    const [error, setError] = useState('');

    const getProfile = async () => {
        if (!auth?.user || profile?.id) {
            return;
        }

        const data = await getProfileById(auth.user.studentId).then((response) => response);

        if (data.status !== 200) {
            setError(data.data.error);
            setLoading(false);
        }

        if (data.status === 401) {
            navigate('/login');
            return;
        }

        setProfile(...data.data);
        setLoading(false);
    };

    const getEnterprise = async () => {
        if (!auth?.user || profile?.id) {
            return;
        }

        const data = await getEnterpriseById(auth.user.enterpriseId, true).then((response) => response);

        if (data.status !== 200) {
            setError(data.data.error);
            setLoading(false);
        }

        if (data.status === 401) {
            navigate('/login');
            return;
        }

        setProfile(data.data[0]);
        setLoading(false);
    };

    useEffect(() => {
        if (!auth?.user?.studentId) {
            return;
        }

        if (auth.user?.enterpriseId) {
            getEnterprise();
            return;
        }

        if (auth?.user.studentId) {
            getProfile(auth?.user.studentId);
        }
    }, [auth?.user.studentId, auth]);

    return (
        <MenuLayout title="Editar Perfil" returnUrlDefault="/aluno/perfil">
            <Meta title="Editar Perfil" />

            {loading ? (
                <CircularProgress />
            ) : error || !profile?.id ? (
                <Message text={error} />
            ) : (
                <>{auth?.user?.enterpriseId ? <EditEnterpriseForm profile={profile} /> : <EditProfileForm profile={profile} />}</>
            )}
        </MenuLayout>
    );
};

export default EditarPerfil;
