import { CircularProgress } from '@mui/material';
import Accordion from 'components/organisms/containers/Accordion';
import HelpLayout from 'components/templates/layouts/Pages/HelpLayout';
import Meta from 'components/templates/Seo/Meta';
import useSnackbar from 'hooks/useSnackbar';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getHelp } from 'services/help';
import Button from 'components/molecules/buttons/Button';
import useWindowSize from 'hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';
import HelpHeader from 'components/molecules/headers/HelpHeader';

const Ajuda = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [help, setHelp] = useState([]);
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const windowSize = useWindowSize();

    const getHelpData = async () => {
        await getHelp()
            .then((response) => {
                setHelp(response);
                setLoading(false);
            })
            .catch(() => {
                snackbar('Não foi possível carregar as mensagens de ajuda.', 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: '/aluno',
                returnText: 'Ajuda',
                returnPath: location.pathname
            }
        });
    }, []);

    useEffect(() => {
        getHelpData();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    if (windowSize.mobile) {
        return (
            <>
                <Meta title="Ajuda" />

                <Accordion data={help} />

                <Button
                    color="secondary"
                    onClick={() => {
                        navigate('/aluno/ajuda/duvidas');
                    }}
                    width="100%"
                >
                    Dúvidas e Sugestões
                </Button>
            </>
        );
    }

    return (
        <>
            <Meta title="Ajuda" />
            <HelpLayout>
                <HelpHeader title="Perguntas frequentes" />
                <div>
                    <Accordion data={help} />
                </div>
            </HelpLayout>
        </>
    );
};

export default Ajuda;
