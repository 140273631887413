import { ButtonMenu } from './styles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

const Header = () => {
    const [optionSelected, setOptionSelected] = useState(null);
    const options = [
        { id: null, description: 'Todos' },
        { id: 2, description: 'Estudantes' },
        { id: 4, description: 'Empresas' }
    ];
    const dispatch = useDispatch();

    const handleChangeOption = (newValue) => {
        dispatch({ type: 'FILTER_TYPE_ROLE', payload: newValue });
        dispatch({ type: 'PAGE', payload: 0 });
        setOptionSelected(newValue);
    };
    return (
        <>
            {options.map((value, key) => (
                <ButtonMenu
                    key={key}
                    onClick={() => handleChangeOption(value.id)}
                    style={
                        optionSelected !== value.id
                            ? {
                                  backgroundColor: '#E7F5E6'
                              }
                            : {
                                  borderBottom: '2px solid #6B9D4A'
                              }
                    }
                >
                    {value.description}
                </ButtonMenu>
            ))}
        </>
    );
};

export default Header;
