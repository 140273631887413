import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { useNavigate } from 'react-router-dom';
import useFavoriteList from '../../../../hooks/useFavoriteList';
import SaveCheckbox from '../../../atoms/inputs/SaveCheckbox';
import { useSelector } from 'react-redux';
import { getEventById, likeEvent } from '../../../../services/events';
import FavoriteCheckbox from '../../../atoms/inputs/FavoriteCheckbox';
import useSnackbar from '../../../../hooks/useSnackbar';

const GridEventCard = ({ event }) => {
    const navigate = useNavigate();
    const { REACT_APP_BASE_URL } = process.env;
    const auth = useSelector((state) => state.auth);
    const snackbar = useSnackbar();
    const [isLiked, setIsLiked] = useState(false);
    const [speakers, setSpeakers] = useState([]);

    const formatDescription = (description) => {
        if (typeof description !== 'undefined') {
            description = description.replace(/<\/?[^>]+(>|$)/g, '');

            return description;
        }
        return '';
    };

    const redirectToEvent = (eventId) => {
        navigate(`/aluno/eventos/${eventId}`);
    };

    const handleLikeEvent = (value) => {
        setIsLiked(value);
        likeEvent(event.id, auth?.user?.studentId, value).then(() => {
            snackbar(value ? 'Evento curtido com sucesso' : 'Evento descurtido com sucesso', 'success');
        });
    };
    useEffect(() => {
        setIsLiked(event.like);
        if (event.certificatePdf && !event.formatted) {
            let [, path] = event.certificatePdf.split('/storage/');
            event.certificatePdf = `${REACT_APP_BASE_URL}/get_file/${path}`;
            event.formatted = true;
        }
        getEventById(event.id, auth?.user?.studentId).then((response) => {
            setSpeakers(response.data.speaker);
        });
    }, [event]);

    return (
        <Container>
            <div className={'image'}>
                <img src={event.image} alt={event.title} />
            </div>
            <div className={'title'}>
                <h4>{event.title}</h4>
                <span>
                    <FavoriteCheckbox active={isLiked} setActive={handleLikeEvent} noPaddingLeft />
                </span>
            </div>
            <h6>{speakers}</h6>
            <div className={'description'}>
                <p>{formatDescription(event.description)}</p>
            </div>
            <h6>{event.totalTime} horas no total</h6>
            <button
                onClick={() => {
                    redirectToEvent(event.id);
                }}
            >
                Saiba Mais
            </button>
        </Container>
    );
};
GridEventCard.propTypes = {
    event: PropTypes.any.isRequired
};
export default GridEventCard;
