export const initialState = {
    feedbacks: []
};

const feedbackReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'ADD_FEEDBACK':
            const feedbacks = state.feedbacks.concat(actions.payload);
            return {
                ...state,
                feedbacks
            };

        case 'REMOVE_FEEDBACK':
            state.feedbacks.shift();

            return {
                ...state,
                feedbacks: state.feedbacks
            };

        default:
            return state;
    }
};

export default feedbackReducer;
