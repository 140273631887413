import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Item, ContentIcons, Box, BoxSelect, Content, Text } from './styles';
import TextInput from 'components/atoms/formInputs/TextInput';
import DropdownInput from 'components/atoms/formInputs/DropdownInput';
import TextareaInput from 'components/atoms/formInputs/TextareaInput';
import { Formik } from 'formik';
import { Validator } from 'utils/validators';

import FormList from 'components/atoms/formInputs/FormList';
import DateInput from 'components/atoms/formInputs/DateInput';
import MultiSelectInput from 'components/atoms/formInputs/MultiSelectInput';
import CheckboxInput from 'components/atoms/formInputs/CheckboxInput';
import Button from 'components/molecules/buttons/Button';
import DropzoneInput from 'components/atoms/formInputs/DropzoneInput';
import Dialog from 'components/molecules/alerts/Dialog';
import PasswordInput from 'components/atoms/formInputs/PasswordInput';
import FormLabel from 'components/atoms/formInputs/FormLabel';
import Line from 'components/atoms/structure/Line';
import ButtonInput from 'components/atoms/formInputs/ButtonInput';
import FixedButton from 'components/molecules/buttons/FixedButton';
import useSnackbar from 'hooks/useSnackbar';
import TermsInput from 'components/atoms/formInputs/TermsInput';
import EditorInput from 'components/atoms/formInputs/TextareaInput';
import CashInput from 'components/atoms/formInputs/CashInput';
import { getFavoriteIcons } from 'services/favorite';
import { getModalData } from './utils';

const FormMakerFavorite = ({
    data,
    formSubmit,
    fixedButtonSubmit,
    fullWidthButtonSubmit,
    submitText,
    noConfirm,
    deleteItem,
    itemName,
    create,
    subtitle,
    favoriteRemove
}) => {
    const [favoriteIcons, setFavoriteIcons] = useState('');
    const [initialValues, setInitialValues] = useState(null);
    const [validationSchema, setValidationSchema] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [openRemoveModal, setOpenRemoveModal] = useState(false);
    const [submitValues, setSubmitValues] = useState(null);
    const [iconSelect, setIconSelect] = useState(true);
    const snackbar = useSnackbar();
    const modalData = getModalData(itemName, create ? 'create' : 'update');
    const [submitTry, setSubmitTry] = useState(false);

    const getValidationSchema = () => {
        const schema = {};
        data.map((line) => {
            line.map((item) => {
                schema[item.id] = item?.validation;
            });
        });
        return schema;
    };

    const getAllFavoriteIcons = async () => {
        await getFavoriteIcons()
            .then((response) => {
                setFavoriteIcons(response.data);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar ícones', 'error');
            });
    };

    const getInitialValues = () => {
        const initialValues = {};
        data.map((line) => {
            line.map((item) => {
                if (!item.id) {
                    return;
                }

                if (item.type === 'checkbox' || item.type === 'terms') {
                    initialValues[item.id] = item?.initialValue || false;
                    return;
                }

                if (item.type === 'text') {
                    initialValues[item.id] = item?.initialValue || '';
                    return;
                }

                if (item.type === 'formList') {
                    initialValues[item.id] = item?.initialValue || [];
                    return;
                }

                if (item.type === 'date') {
                    initialValues[item.id] = item?.initialValue || '';
                    return;
                }

                initialValues[item.id] = item?.initialValue || '';
            });
        });

        return initialValues;
    };

    useEffect(() => {
        setInitialValues(getInitialValues());
        setValidationSchema(getValidationSchema());
    }, [data]);

    useEffect(() => {
        getAllFavoriteIcons();
    }, []);

    if (!initialValues) {
        return null;
    }

    return (
        <>
            <Formik
                initialValues={{ ...initialValues }}
                validationSchema={Validator.object().shape(validationSchema)}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        const valuesForm = {
                            description: values.description,
                            idIcon: iconSelect
                        };
                        setSubmitValues(valuesForm);
                        setSubmitting(true);
                        if (noConfirm) {
                            formSubmit(submitValues, setErrors);
                            return;
                        }
                        setOpenModal(true);
                    } catch (error) {
                        console.error(error);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <Container
                        fixedButtonSubmit={fixedButtonSubmit}
                        fullWidthButtonSubmit={fullWidthButtonSubmit}
                        deleteButton={!!deleteItem}
                        onSubmit={(e) => {
                            e.preventDefault();
                            setSubmitTry(true);

                            if (Object.values(errors).length > 0) {
                                snackbar(Object.values(errors)[0], 'error');
                            }

                            handleSubmit();
                        }}
                    >
                        {data.map((line, index) => (
                            <div key={index}>
                                {line.map((item, key) => (
                                    <Item key={key} marginLeft={key > 0}>
                                        {item?.conditionAttr && item?.conditionValue && !item?.conditionValue(values[item.conditionAttr])
                                            ? null
                                            : {
                                                  label: <FormLabel item={item} />,
                                                  line: <Line marginTop="1rem" />,
                                                  text: (
                                                      <TextInput
                                                          handleChange={handleChange}
                                                          handleBlur={handleBlur}
                                                          value={values[item.id]}
                                                          error={touched[item.id] || submitTry ? errors[item.id] : ''}
                                                          id={item.id}
                                                          item={item}
                                                      />
                                                  ),
                                                  dropdown: (
                                                      <DropdownInput
                                                          handleChange={handleChange}
                                                          handleBlur={handleBlur}
                                                          value={values[item.id]}
                                                          error={touched[item.id] || submitTry ? errors[item.id] : ''}
                                                          id={item.id}
                                                          item={item}
                                                      />
                                                  ),
                                                  multiSelect: (
                                                      <MultiSelectInput
                                                          handleChange={handleChange}
                                                          handleBlur={handleBlur}
                                                          value={values[item.id]}
                                                          error={touched[item.id] || submitTry ? errors[item.id] : ''}
                                                          id={item.id}
                                                          item={item}
                                                      />
                                                  ),
                                                  checkbox: (
                                                      <CheckboxInput
                                                          handleBlur={handleBlur}
                                                          value={values[item.id]}
                                                          id={item.id}
                                                          item={item}
                                                      />
                                                  ),
                                                  textarea: (
                                                      <TextareaInput
                                                          handleChange={handleChange}
                                                          handleBlur={handleBlur}
                                                          value={values[item.id]}
                                                          item={item}
                                                          error={touched[item.id] || submitTry ? errors[item.id] : ''}
                                                          id={item.id}
                                                      />
                                                  ),
                                                  editor: (
                                                      <EditorInput
                                                          value={values[item.id]}
                                                          item={item}
                                                          error={touched[item.id] || submitTry ? errors[item.id] : ''}
                                                      />
                                                  ),
                                                  date: (
                                                      <DateInput
                                                          handleBlur={handleBlur}
                                                          value={values[item.id]}
                                                          item={item}
                                                          error={touched[item.id] || submitTry ? errors[item.id] : ''}
                                                          id={item.id}
                                                      />
                                                  ),
                                                  cash: (
                                                      <CashInput
                                                          handleChange={handleChange}
                                                          handleBlur={handleBlur}
                                                          value={values[item.id]}
                                                          error={touched[item.id] || submitTry ? errors[item.id] : ''}
                                                          id={item.id}
                                                          item={item}
                                                      />
                                                  ),
                                                  password: (
                                                      <PasswordInput
                                                          handleChange={handleChange}
                                                          handleBlur={handleBlur}
                                                          value={values[item.id]}
                                                          error={touched[item.id] || submitTry ? errors[item.id] : ''}
                                                          id={item.id}
                                                          item={item}
                                                      />
                                                  ),
                                                  dropfile: <DropzoneInput item={item} values={values} />,
                                                  button: <ButtonInput item={item} />,
                                                  terms: (
                                                      <TermsInput
                                                          handleBlur={handleBlur}
                                                          value={values[item.id]}
                                                          id={item.id}
                                                          item={item}
                                                      />
                                                  ),
                                                  formList: (
                                                      <FormList
                                                          handleChange={handleChange}
                                                          handleBlur={handleBlur}
                                                          errors={errors}
                                                          touched={touched}
                                                          values={values}
                                                          item={item}
                                                          submitTry={submitTry}
                                                      />
                                                  )
                                              }[item.type]}
                                    </Item>
                                ))}
                            </div>
                        ))}

                        <Content>
                            <Text>{subtitle}</Text>
                        </Content>

                        {favoriteIcons && (
                            <ContentIcons>
                                {favoriteIcons.map((value) =>
                                    value.id === iconSelect ? (
                                        <BoxSelect key={value.id} onClick={() => setIconSelect(value.id)}>
                                            <img src={value.url} alt="icone favorito" />
                                        </BoxSelect>
                                    ) : (
                                        <Box key={value.id} onClick={() => setIconSelect(value.id)}>
                                            <img src={value.url} alt="icone favorito" />
                                        </Box>
                                    )
                                )}
                            </ContentIcons>
                        )}

                        {favoriteRemove}

                        {fixedButtonSubmit ? (
                            <FixedButton
                                onClick={() => {
                                    handleSubmit();
                                }}
                                label="Salvar"
                            />
                        ) : (
                            <div>
                                {deleteItem && (
                                    <Button color="error" disabled={isSubmitting} onClick={() => setOpenRemoveModal(true)}>
                                        {submitText ? submitText : 'Excluir'}
                                    </Button>
                                )}
                                <Button type="submit" color="secondary" disabled={isSubmitting} onClick={() => handleSubmit()}>
                                    {submitText ? submitText : 'Salvar'}
                                </Button>
                            </div>
                        )}
                    </Container>
                )}
            </Formik>

            <Dialog
                open={openModal}
                submit={() => formSubmit(submitValues)}
                title={modalData.title}
                text={modalData.text}
                handler={() => setOpenModal(false)}
                submitText="Sim"
                cancelText="Não"
            />

            <Dialog
                open={openRemoveModal}
                submit={() => deleteItem()}
                title="Confirmar exclusão"
                text={`Deseja excluir ${itemName} selecionada?`}
                handler={() => setOpenRemoveModal(false)}
                submitText="Sim"
                cancelText="Não"
            />
        </>
    );
};

FormMakerFavorite.propTypes = {
    data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
    formSubmit: PropTypes.func.isRequired,
    fixedButtonSubmit: PropTypes.bool,
    fullWidthButtonSubmit: PropTypes.bool,
    submitText: PropTypes.string,
    subtitle: PropTypes.any,
    noConfirm: PropTypes.bool,
    itemName: PropTypes.string,
    deleteItem: PropTypes.func,
    create: PropTypes.bool,
    favoriteRemove: PropTypes.any
};

export default FormMakerFavorite;
