import React from 'react';
import { userType } from 'types/data/user';
import PropTypes from 'prop-types';

import { Container, Line, ParticipantsContent, PostForm, UserContent, UserImage } from './styles';
import LevelIcon from 'components/molecules/containers/LevelIcon';
import Button from 'components/molecules/buttons/Button';
import { Link } from 'react-router-dom';
import { getFallbackUrl } from 'utils/general';
import AvatarGroup from 'components/molecules/containers/AvatarGroup';
import { useSelector } from 'react-redux';
import CircleGreenButton from 'components/molecules/buttons/CircleGreenButton';
import { getSystemImageUrl } from 'utils/assets';
import useFeedback from 'hooks/useFeedback';

const UserAside = ({ user, users }) => {
    const auth = useSelector((state) => state.auth);
    const feedback = useFeedback();

    const openPostModal = () => {
        feedback.postForm();
    };

    return (
        <Container>
            <UserContent>
                <header>
                    <UserImage src={user?.image}>{user?.name ? user?.name[0].toUpperCase() : ''}</UserImage>
                    <p>Bem vindo ao PFA</p>
                    <h3>{user?.name}</h3>
                </header>

                {user.level && (
                    <main>
                        <LevelIcon
                            level={{
                                id: user.level.id,
                                name: user.level.level
                            }}
                            small
                        />
                    </main>
                )}

                <footer>
                    <Link to={getFallbackUrl(`/aluno/perfil`)}>
                        <Button size="fat" type="button" onClick={() => {}} width="auto" color="neutral">
                            Perfil
                        </Button>
                    </Link>
                </footer>

                {auth?.user?.enterpriseId && (
                    <PostForm onClick={() => openPostModal()}>
                        <Line />

                        <header>
                            <textarea placeholder="Compartilhe suas ideias:" readOnly />
                        </header>

                        <footer>
                            <CircleGreenButton iconUrl={getSystemImageUrl('Icons_Publicarimage.svg')} onClick={() => {}} />

                            <CircleGreenButton iconUrl={getSystemImageUrl('Icons_Publicarvideo.svg')} onClick={() => {}} />

                            <CircleGreenButton iconUrl={getSystemImageUrl('hashtag.svg')} onClick={() => {}} />
                        </footer>
                    </PostForm>
                )}
            </UserContent>

            <ParticipantsContent>
                <h3>Participantes do PFA</h3>

                <AvatarGroup users={users} />

                <Link to={getFallbackUrl(`/aluno/participantes`)}>
                    <Button size="fat" type="button" onClick={() => {}} width="auto" color="neutral">
                        Comunidade
                    </Button>
                </Link>
            </ParticipantsContent>
        </Container>
    );
};

UserAside.propTypes = {
    user: PropTypes.shape(userType).isRequired,
    users: PropTypes.arrayOf(PropTypes.shape(userType)).isRequired
};

export default UserAside;
