import useVideoPlayer from 'hooks/useVideoPlayer';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Container, Content } from './styles';
import { Pause, PlayArrow, Replay, VolumeOff, VolumeUp } from '@mui/icons-material';

const Video = ({ url }) => {
    const videoElement = useRef(null);
    const { playerState, togglePlay, handleOnTimeUpdate, handleVideoProgress, toggleMute } = useVideoPlayer(videoElement);

    return (
        <Container>
            <Content>
                <video src={url} ref={videoElement} onTimeUpdate={handleOnTimeUpdate}>
                    <source src={url} type="video/mp4" />
                    <track src="" kind="captions" srcLang="pt-br" label="" />
                </video>
                <footer>
                    <button onClick={togglePlay}>
                        {playerState.ended ? <Replay /> : !playerState.isPlaying ? <PlayArrow /> : <Pause />}
                    </button>

                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={playerState.progress || 0}
                        onChange={(e) => handleVideoProgress(e)}
                        onClick={() => {}}
                    />
                    <button onClick={toggleMute}>{!playerState.muted ? <VolumeUp /> : <VolumeOff />}</button>
                </footer>
            </Content>
        </Container>
    );
};

Video.propTypes = {
    url: PropTypes.string.isRequired
};

export default Video;
