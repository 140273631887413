import { getNotificationsTotal } from 'services/notifications';

export const initialState = {
    total: 0
};

export const loadTotalNotifications = async (dispatch) => {
    await getNotificationsTotal()
        .then((res) => {
            dispatch({
                type: 'GET_TOTAL_NOTIFICATIONS',
                payload: {
                    total: res.data
                }
            });
        })
        .catch(() => {});
};

const notificationsReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'GET_TOTAL_NOTIFICATIONS':
            return {
                ...state,
                total: actions.payload.total
            };

        case 'SAW_NOTIFICATION':
            return {
                ...state,
                total: state.total - 1
            };

        default:
            return state;
    }
};

export default notificationsReducer;
