import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > * {
        margin-bottom: 0.5rem;
    }

    > h1 {
        font-size: 1.2rem;
        color: var(--color-black);
        text-align: center;
    }

    > p {
        font-size: 0.9rem;
        color: var(--color-black);
        text-align: center;
    }
`;
