import { api } from 'services/api';
import { getDateDDMMYYYY } from 'utils/date';

const getEnterpriseById = async (id) => {
    const response = await api
        .get(`/enterprise/${id}`)
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 200) {
        return {
            data: parsedProfiles(response.data),
            id,
            status: response.status
        };
    }
    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }
    return {
        data: response.data,
        status: response.status
    };
};

const updateEnterprise = async (data, id) => {
    const response = await api
        .post(`/update_enterprise/${id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const parsedProfiles = (profiles) => {
    let parsedProfileData = [];
    parsedProfileData.push(parsedProfile(profiles));

    return parsedProfileData;
};

const parsedProfile = (profile) => {
    return {
        ...profile,
        size: profile.size_company,
        sector: profile?.sector
            ? {
                  title: 'Setor',
                  legend: profile.sector,
                  footer: '',
                  image: ''
              }
            : null,
        opportunityEnterprise: profile?.opportunityEnterprise.map((item) => {
            return {
                title: item.office,
                legend: `candidate-se até ${getDateDDMMYYYY(item.final_date)}`,
                footer: '',
                image: '',
                id: item.id_publication,
                uri: `/aluno/publicacao/${item.id_publication}`
            };
        })
    };
};

export { getEnterpriseById, updateEnterprise };
