import { api } from 'services/api';
import { dateJsToISO } from 'utils/date';
import { getImageUrl } from 'utils/assets';
import { postLogError } from '../logErrors/index';

const getUsers = async () => {
    const response = await api
        .get(`/student_random`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err;
        });

    if (response.status === 200) {
        return {
            data: {
                students: response.data?.student || [],
                total: response.data?.countStudent || 0
            },
            status: response.status
        };
    }

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    return {
        data: response.data,
        status: response.status
    };
};

const getParticipatingEventsCourses = async (studentId) => {
    const response = await api
        .get(`/student_commitments/${studentId}`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err;
        });

    const events = [];
    const courses = [];

    response.data.forEach((value) => {
        if (value.type === 1) {
            events.push(value);
        } else if (value.type === 2) {
            courses.push(value);
        }
    });

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    return {
        events: parsedEvents(events, studentId),
        courses: parsedCourses(courses)
    };
};

const parsedEvents = (events, studentId = null) => {
    if (!events) return [];

    let parsedEventsData = [];

    events.forEach((event) => {
        if (event.active || new Date() <= new Date(event.finalDate)) {
            parsedEventsData.push(parsedEvent(event, studentId));
        }
    });
    return parsedEventsData;
};

const parsedEvent = (event, studentId = null) => {
    return {
        id: event?.id,
        type: event?.type,
        title: event?.title,
        point: event?.point,
        initialDate: dateJsToISO(event?.initialDate ? event.initialDate : event.initial_date),
        finalDate: dateJsToISO(event?.finalDate ? event.finalDate : event.final_date),
        description: event?.description,
        active: event?.active,
        typeEvent: event?.typeEvent,
        image: event?.file ? (event?.file[0]?.url ? getImageUrl(event?.file[0].url) : '') : '',
        speakers: event?.speaker,
        competences: event?.competence,
        registered: event?.registered?.some((registered) => registered?.id_student === studentId),
        like: event?.like?.some((like) => like?.id_student === studentId),
        likes: event?.like?.length,
        registers: event?.registered?.length,
        url: event?.url
    };
};

const parsedCourses = (courses) => {
    if (!courses) return [];

    let parsedData = [];

    courses.forEach((course) => {
        parsedData.push(parsedCourse(course));
    });

    return parsedData;
};

const parsedCourse = (course) => {
    return {
        id: course.id,
        title: course.name,
        time: course.time || 0,
        description: course.description,
        active: course.active,
        image: course?.file ? (course?.file[0]?.url ? getImageUrl(course.file[0].url) : '') : '',
        speakers: course?.speaker ? course?.speaker : null,
        competences: course?.competence
            ? course?.competence.map((item) => {
                  return {
                      ...item,
                      description: item.desription
                  };
              })
            : course?.competences
            ? course?.competences.map((item) => {
                  return {
                      ...item,
                      description: item.desription
                  };
              })
            : null,
        registered: course?.registered,
        registers: course?.registeres,
        likes: course?.likes,
        liked: course?.liked,
        isFavorite: course.isFavorite,
        classes: course?.class ? course?.class : null,
        progress: course.completeMyStudent,
        learningObject: course?.learningObject ? course?.learningObject : [],
        completeMyStudent: course.completeMyStudent,
        finalDate: course?.final_date ? course?.final_date : null
    };
};

export { getUsers, getParticipatingEventsCourses };
