import { FormControlLabel } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
        font-size: 0.9rem;

        a {
            color: var(--color-secondary);
        }
    }
`;

export const Content = styled(FormControlLabel)`
    width: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.9rem !important;
`;
