import styled from 'styled-components';

export const Container = styled.div`
    margin-bottom: 1rem;

    > p {
        margin-top: 0.4rem;
        color: var(--color-black);
        font-size: 0.9rem;
    }

    > button {
        display: flex;
        padding: 8px 22px;
        margin: 0 auto;
        height: 42px;
        font-size: 16px;
    }
`;

export const ProfileContainer = styled.div`
    text-align: center;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 2px 8px 0px #0000000d;
    margin-top: 10px;
    padding: 1rem;

    > * {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .rpv-core-page-layer {
        box-shadow: none !important;
    }
`;
