import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    position: fixed;
    height: 56px;
    bottom: 0;
    background-color: var(--color-secondary);
    z-index: 10;

    > .MuiBottomNavigation-root {
        margin: 0 auto;
        max-width: var(--max-width);
        background-color: var(--color-secondary);
        display: flex;
        justify-content: space-between;

        > button {
            padding: 0 8px;
            min-width: 60px;
        }

        .MuiSvgIcon-fontSizeMedium {
            font-size: 1.2rem;
        }

        > .MuiBottomNavigationAction-root {
            color: #ddd;
            font-size: 0.5rem;
        }

        > .Mui-selected {
            color: #fff !important;
        }
    }
`;
