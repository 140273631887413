import { useRoutes } from 'react-router-dom';

import MainRoutes from './paths/main';
import AuthenticationRoutes from './paths/auth';
import StudentRoutes from './paths/student';
import config from '../config';

const ThemeRoutes = () => {
    return useRoutes([MainRoutes, AuthenticationRoutes, ...StudentRoutes], config.basename);
};

export default ThemeRoutes;
