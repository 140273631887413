import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: center;
    padding-bottom: 0.5rem;
    padding-top: 1rem;
`;
