import React from 'react';
import PropTypes from 'prop-types';
import { Container, CloseButton, Content } from './styles';
import { NotificationType } from 'types/data/notifications';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const NotificationCard = ({ notification, remove }) => {
    const { id, type, date, text, backgroundColor, borderColor, button, idCourse, idEvent, idLibrary, method, idPublication } =
        notification;

    const navigate = useNavigate();
    const path = window.location.pathname;

    moment.locale('pt-br');
    const dateText = moment(date).format('DD/MM/YYYY');

    const handleClick = async () => {
        if (type === 'course') {
            await remove(id);

            if (method === 'canceled') {
                navigate(`/aluno/cursos${path ? `?returnUrl=${path}` : ''}`);
            }

            navigate(`/aluno/cursos/curso/${idCourse}${path ? `?returnUrl=${path}` : ''}`);

            return;
        }

        if (type === 'event') {
            await remove(id);

            if (method !== 'canceled') {
                navigate(`/aluno/eventos/${idEvent}${path ? `?returnUrl=${path}` : ''}`);
            }

            navigate(`/aluno/eventos${path ? `?returnUrl=${path}` : ''}`);

            return;
        }

        if (type === 'library') {
            await remove(id);

            if (method !== 'cancelled') {
                navigate(`/aluno/biblioteca/conteudo/${idLibrary}${path ? `?returnUrl=${path}` : ''}`);
            }

            return;
        }

        if (type === 'profile') {
            await remove(id);
            navigate(`/aluno/perfil/editar${path ? `?returnUrl=${path}&returnName=Notificações` : ''}`);
            return;
        }

        if (type === 'timeline') {
            await remove(id);
            navigate(`/aluno`);
            return;
        }

        if (type === 'publication') {
            await remove(id);
            navigate(`/aluno/publicacao/${idPublication}${path ? `?returnUrl=${path}` : ''}`);
            return;
        }
    };

    return (
        <Container>
            <CloseButton aria-label="close" onClick={() => remove(id)}>
                <Close fontSize="small" />
            </CloseButton>

            <Content backgroundColor={backgroundColor} borderColor={borderColor} onClick={() => handleClick()}>
                <header>
                    <span>{dateText}</span>
                </header>

                <main>{ReactHtmlParser(text)}</main>
            </Content>
        </Container>
    );
};

NotificationCard.propTypes = {
    notification: PropTypes.shape(NotificationType).isRequired,
    remove: PropTypes.func.isRequired
};

export default NotificationCard;
