import { IconButton } from '@mui/material';
import styled from 'styled-components';
import { Badge } from '@mui/material';

export const Container = styled.div`
    display: flex;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    background-color: #fff;
    height: var(--navbar-height);
    justify-content: center;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.03);
`;

export const Content = styled.div`
    max-width: var(--max-width);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > aside {
        flex: 1;
        padding: 0 var(--padding-base);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > a {
            margin-top: 0.5rem;
        }
    }

    > main {
        flex: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        height: 100%;

        > * {
            margin-left: 2rem;

            &:first-child {
                margin-left: 0;
            }
        }
    }
`;

export const NavigationHeader = styled.div`
    flex: 1;
    padding: 0 var(--padding-base);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > img {
        margin-top: 0.5rem;
    }

    > a {
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        height: 100%;
    }

    > * {
        margin-left: 0.4rem;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-left: 1rem;
        }
    }
`;

export const MenuButton = styled(IconButton)`
    margin-left: -8px;
`;

export const MenuBadge = styled(Badge)`
    > span {
        background-color: #c00;
        font-size: 0.7rem;
    }
`;
