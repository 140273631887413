import PropTypes from 'prop-types';

export const PostType = {
    id: PropTypes.number,
    liked: PropTypes.bool,
    likes: PropTypes.number,
    views: PropTypes.number,
    viewed: PropTypes.bool,
    type: PropTypes.number,
    typeText: PropTypes.string,
    typeAdvertisement: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    company: PropTypes.string,
    salary: PropTypes.number,
    period: PropTypes.string,
    image: PropTypes.string
};
