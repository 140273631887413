import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { Item } from './styles';
import { CheckCircle } from '@mui/icons-material';

const WeekLogAccess = ({ selectedWeek }) => {
    const weeks = [1, 2, 3, 4, 5];

    return (
        <Container>
            {weeks.map((week, index) => (
                <Item key={index} active={selectedWeek > index}>
                    <CheckCircle htmlColor={selectedWeek > index ? '#6B9D4A' : '#E8E8E8'} />
                    <span>Sem:{week}</span>
                </Item>
            ))}
        </Container>
    );
};

WeekLogAccess.propTypes = {
    selectedWeek: PropTypes.number
};

export default WeekLogAccess;
