import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { navigationType } from 'types/components/navigation';
import { useLocation, useNavigate } from 'react-router-dom';

const DesktopNavbarItem = ({ item }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const redirect = () => {
        if (item.disabled) {
            return;
        }

        navigate(item.link);
    };

    return (
        <Container onClick={() => redirect()}>
            <header>
                <span>{item.icon}</span>
                <h3>{item.title}</h3>
            </header>

            {location.pathname === item.link && <footer />}
        </Container>
    );
};

DesktopNavbarItem.propTypes = {
    item: PropTypes.shape(navigationType).isRequired
};

export default DesktopNavbarItem;
