import AddFavoriteDrawer from 'components/molecules/drawers/AddFavoriteDrawer';
import EditFavoriteDrawer from 'components/molecules/drawers/EditFavoriteDrawer';
import EditFavoriteDrawerDesktop from 'components/molecules/drawers/EditFavoriteDrawerDesktop';
import ListFavoriteDrawer from 'components/molecules/drawers/ListFavoriteDrawer';
import RemoveFavoriteDrawer from 'components/molecules/drawers/RemoveFavoriteDrawer';
import AddFavoriteDrawerDesktop from 'components/molecules/drawers/AddFavoriteDrawerDesktop';
import useWindowSize from 'hooks/useWindowSize';
import React from 'react';

const FavoriteDrawers = () => {
    const windowSize = useWindowSize();

    if (windowSize.mobile) {
        return (
            <>
                <AddFavoriteDrawer />
                <EditFavoriteDrawer />
                <RemoveFavoriteDrawer />
                <ListFavoriteDrawer />
            </>
        );
    }
    return (
        <>
            <AddFavoriteDrawerDesktop />
            <RemoveFavoriteDrawer />
            <EditFavoriteDrawerDesktop />
            <ListFavoriteDrawer />
        </>
    );
};

export default FavoriteDrawers;
