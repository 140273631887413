import { Avatar } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
    background-image: url(${({ background }) => background});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 6.2rem;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;

    @media screen and (min-width: 600px) {
        border-radius: 0 0 8px 8px;
    }
`;

export const ImageContainer = styled(Avatar)`
    border-radius: ${({ isCircle }) => (isCircle ? '50%' : '0')};
    border: 4px solid var(--color-background);
    height: 6rem;
    width: 6rem;
    margin-bottom: -1.5rem;
`;

export const EditProfileButton = styled.button`
    background: #ffffff;
    border-radius: 4px;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
`;

export const Toolbar = styled.div`
    position: absolute;
    top: 1rem;
    right: 1rem;

    > * {
        margin-bottom: 0.5rem;
    }
`;
