import { api } from 'services/api';
import { getImageUrl } from 'utils/assets';
import { postLogError } from '../logErrors/index';
import course from '../../components/organisms/containers/Course';

const getParticipatingCourses = async (notCompleted = false) => {
    const response = await api
        .get(`/course_student${notCompleted ? '?not_completed=1' : ''}`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: parsedCourses(response.data),
        status: response.status
    };
};

const getClientCourses = async () => {
    const response = await api
        .get(`/course_client`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 200) {
        return {
            data: parsedCourses(response.data),
            status: response.status
        };
    }

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    return {
        data: response.data,
        status: response.status
    };
};

const getAllCourses = async (page = 1) => {
    const response = await api
        .get(`/get_all_courses`, {
            params: {
                page
            }
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        rawData: parsedCoursesWeb(response.data),
        data: parsedCourses(response.data.data),
        last_page: response.data.last_page,
        status: response.status
    };
};

const getStudentCourseCertificate = async (studentCourseId) => {
    const response = await api
        .get(`/student_course_certificate/${studentCourseId}`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    return {
        data: response.data,
        status: response.status
    };
};

const getCourseById = async (courseId) => {
    const response = await api
        .get(`/course/${courseId}`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 200) {
        return {
            data: parsedCourse(response.data),
            status: response.status
        };
    }

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    return {
        data: response.data,
        status: response.status
    };
};

const getCompletedCourses = async () => {
    const response = await api
        .get(`/course_completed`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: parsedCourses(response.data.data),
        status: response.status
    };
};

const getCoursesByCompetence = async (competenceId, notCompleted = false) => {
    const response = await api
        .get(`/course_client`, {
            params: {
                idCompetence: competenceId,
                notCompleted: notCompleted ? 1 : 0
            }
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: parsedCourses(response.data),
        status: response.status
    };
};

const favoriteCourse = async (courseId, idFavoriteList) => {
    const response = await api
        .post(`/favorite_course`, {
            idCourse: courseId,
            idFavoriteList: Number(idFavoriteList)
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    return {
        data: response.data,
        status: response.status
    };
};

const subscribeCourse = async (courseId, studentId, subscribe) => {
    const response = await api
        .post(`/registered_course`, {
            idCourse: courseId,
            idStudent: studentId,
            registered: Number(subscribe)
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    return {
        data: response.data,
        status: response.status
    };
};

const likeCourse = async (courseId, studentId, like) => {
    const response = await api
        .post(`/like_course`, {
            idCourse: courseId,
            idStudent: studentId.toString(),
            like: Number(like)
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    return {
        data: response.data,
        status: response.status
    };
};

const removeCourseList = async (courseId) => {
    const response = await api
        .post(`/remove_course_list`, {
            idCourse: courseId
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    return {
        data: response.data,
        status: response.status
    };
};

const seeCourse = async (courseId) => {
    const response = await api
        .post(`/saw_course`, {
            idCourse: courseId
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    return {
        data: response.data,
        status: response.status
    };
};
const parsedCoursesWeb = (courses) => {
    Object.keys(courses).forEach(function (key) {
        if (courses[key] && courses[key].data && courses[key].data.length > 0) {
            courses[key].data = courses[key].data.map((course) => parsedCourse(course));
        }
    });
    return courses;
};
const parsedCourses = (courses) => {
    if (!courses) return [];

    let parsedData = [];

    courses.forEach((course) => {
        parsedData.push(parsedCourse(course));
    });

    return parsedData;
};

const parsedCourse = (course) => {
    const speakers = course?.speaker ? course?.speaker.map((speaker) => speaker.name) : null;
    return {
        id: course.id,
        title: course.name,
        time: course.time || 0,
        description: course.description,
        active: course.active,
        image: course?.file ? (course?.file[0]?.url ? getImageUrl(course.file[0].url) : '') : '',
        speakers: course?.speaker ?? null,
        speaker: course?.speaker
            ? course.speaker.length > 1
                ? speakers.slice(0, -1).join(', ') + ' e ' + speakers.slice(-1)
                : speakers.join(', ')
            : null,
        competences: course?.competence
            ? course?.competence.map((item) => {
                  return {
                      ...item,
                      description: item.desription
                  };
              })
            : course?.competences
            ? course?.competences.map((item) => {
                  return {
                      ...item,
                      description: item.desription
                  };
              })
            : null,
        registered: course?.registered,
        registers: course?.registeres,
        likes: course?.likes,
        liked: course?.liked,
        sawed: course?.sawed,
        isFavorite: course?.isFavorite
            ? {
                  description: course?.isFavorite?.descriptionFavoriteList,
                  id: Number(course?.isFavorite?.idFavoriteList)
              }
            : null,
        classes: course?.class ? course?.class : null,
        progress: course.completeMyStudent,
        learningObject: course?.learningObject ? course?.learningObject : [],
        completeMyStudent: course.completeMyStudent,
        finalDate: course?.final_date ? course?.final_date : null,
        studentCourseId: course.student_course_id,
        certificatePdf: course.certificate_pdf || false,
        publication: course.publication || false,
        publicationCommentsTotal: course.publicationCommentsTotal || false
    };
};

export {
    getCourseById,
    getParticipatingCourses,
    getCoursesByCompetence,
    favoriteCourse,
    subscribeCourse,
    likeCourse,
    getClientCourses,
    parsedCourses,
    removeCourseList,
    seeCourse,
    getCompletedCourses,
    getAllCourses,
    getStudentCourseCertificate
};
