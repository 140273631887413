import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import CheckCircleOutlineIcon from '@mui/icons-material/Check';

const CheckListItem = ({ text, component = <CheckCircleOutlineIcon color="secondary" /> }) => {
    return (
        <Container>
            {/* <aside>{component}</aside> */}
            <aside>
                <CheckCircleOutlineIcon color="secondary" />
            </aside>
            <main>
                <h4>{text}</h4>
            </main>
        </Container>
    );
};

CheckListItem.propTypes = {
    text: PropTypes.string.isRequired
};

export default CheckListItem;
