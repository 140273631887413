import React from 'react';
import PropTypes from 'prop-types';
import { Container, Content } from './styles';
import { Search } from '@mui/icons-material';
import { TextField, InputAdornment } from '@mui/material';

const SearchInputIconStart = ({ value, onChange, footer, placeholder }) => {
    return (
        <Container>
            <Content>
                <TextField
                    type="text"
                    value={value}
                    size="small"
                    fullWidth
                    onChange={(e) => onChange(e.target.value)}
                    placeholder={placeholder}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        )
                    }}
                />
            </Content>
            {footer && <footer>{footer}</footer>}
        </Container>
    );
};

SearchInputIconStart.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    footer: PropTypes.string,
    placeholder: PropTypes.string.isRequired
};

export default SearchInputIconStart;
