import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import CompetenceIcon from 'components/molecules/containers/CompetenceIcon';
import { CompetenceType } from 'types/data/competence';

const CompetenceHeader = ({ competence }) => {
    const { icon, description } = competence;

    const goldFilter = 'invert(81%) sepia(55%) saturate(2181%) hue-rotate(334deg) brightness(92%) contrast(89%)';

    return (
        <Container>
            <CompetenceIcon icon={icon} filter={goldFilter} />

            <h1>{description}</h1>
            <p>Aprenda mais com as nossas sugestões de cursos e eventos e recursos.</p>
        </Container>
    );
};

CompetenceHeader.propTypes = {
    competence: PropTypes.shape(CompetenceType).isRequired
};

export default CompetenceHeader;
