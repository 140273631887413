import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const CoursesLayout = ({ children }) => {
    return <Container>{children}</Container>;
};

CoursesLayout.propTypes = {
    children: PropTypes.node.isRequired
};

export default CoursesLayout;
