import Svg from 'components/atoms/structure/Svg';
import AbsoluteMenu from 'components/organisms/menus/AbsoluteMenu';
import useFeedback from 'hooks/useFeedback';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'services/auth';
import { getSystemImageUrl } from 'utils/assets';
import { getFallbackUrl } from 'utils/general';

import { Container, Content } from './styles';

const UserNavigationButton = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const feedback = useFeedback();
    const [openMenu, setOpenMenu] = useState(false);

    const redirect = (path) => {
        navigate(getFallbackUrl(path));
    };

    const redirectLogout = async () => {
        logout();

        await dispatch({ type: 'SET_LOGOUT' });
        navigate('/');

        return;
    };

    const suggestionModal = () => {
        feedback.suggestion();
    };

    const absoluteMenuData = [
        {
            title: 'Perfil',
            icon: <Svg url={getSystemImageUrl('user-menu/user-menu.svg')} width="18" height="18" />,
            onClick: () => redirect('/aluno/perfil')
        },
        {
            title: 'Configurações da conta',
            icon: <Svg url={getSystemImageUrl('user-menu/config.svg')} width="18" height="18" />,
            onClick: () => redirect('/aluno/minha-conta'),
            disabled: false
        },
        // {
        //     title: 'Envie sua sugestão',
        //     icon: <Svg url={getSystemImageUrl('user-menu/chat.svg')} width="18" height="18" />,
        //     onClick: () => suggestionModal()
        // },
        {
            title: 'Sair',
            icon: <Svg url={getSystemImageUrl('user-menu/logout.svg')} width="18" height="18" />,
            onClick: () => redirectLogout()
        }
    ];

    return (
        <Container>
            <Content onClick={() => setOpenMenu(!openMenu)}>
                <Svg url={getSystemImageUrl('Menu.svg')} width="32" height="32" />
                <Svg url={getSystemImageUrl('arrow.svg')} width="8" height="8" />
            </Content>

            {openMenu && <AbsoluteMenu data={absoluteMenuData} handler={() => setOpenMenu(false)} />}
        </Container>
    );
};

export default UserNavigationButton;
