import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    background-color: #fff;
    position: relative;

    > h3 {
        font-size: 1rem;
        color: var(--color-black);
    }
    > p {
        font-size: 0.9rem;
        font-weight: 700;
        color: var(--color-black);
    }
    > span {
        margin-top: 1rem;
        font-size: 0.8rem;
        color: var(--color-gray);
    }
`;

export const Status = styled.div`
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    background-color: ${({ finished }) => (finished ? 'var(--color-secondary)' : 'var(--color-gray)')};
    padding: 0 0.4rem;
    border-radius: var(--border-radius);

    > span {
        color: #fff;
        font-size: 0.8rem;
    }
`;
