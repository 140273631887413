import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const Chip = ({ label, color }) => {
    return <Container background={color} label={label} size="small" />;
};

Chip.propTypes = {
    label: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
};

export default Chip;
