import PropTypes from 'prop-types';
import { ListItemButtonCompetence, ListItemTextStyled, ListStyled, CompetenceGroupList, CompetenceGroupItem } from './styles';

const CompetenceList = ({ competences, onClickCompetence, options = {} }) => {
    return (
        <>
            <ListStyled className={'competencesList'} component="nav" disablePadding>
                <ListItemTextStyled>Competencias</ListItemTextStyled>
                {competences
                    .sort((a, b) => a.description.localeCompare(b.description))
                    .map((competence, index) => (
                        <ListItemButtonCompetence
                            key={index}
                            border={competence.color}
                            onClick={() => onClickCompetence(options.returnFullCompetence ? competence : competence.key)}
                        >
                            {competence.description}
                        </ListItemButtonCompetence>
                    ))}
            </ListStyled>
            {options.showGroups && (
                <CompetenceGroupList>
                    {competences
                        .map((competence) => ({
                            color: competence.color,
                            group: competence.competenceGroup
                        }))
                        .reduce((unique, o) => {
                            if (!unique.some((obj) => obj.group === o.group && obj.color === o.color)) {
                                unique.push(o);
                            }
                            return unique;
                        }, [])
                        .map((group, index) => (
                            <CompetenceGroupItem key={`group-${index}`} color={group.color}>
                                Competência {group.group}
                            </CompetenceGroupItem>
                        ))}
                </CompetenceGroupList>
            )}
        </>
    );
};

CompetenceList.propTypes = {
    competences: PropTypes.array.isRequired,
    onClickCompetence: PropTypes.func.isRequired,
    options: PropTypes.shape({
        returnFullCompetence: PropTypes.bool,
        showGroups: PropTypes.bool
    })
};

CompetenceList.defaultProps = {
    competences: []
};

export default CompetenceList;
