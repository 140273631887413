import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    border-radius: var(--border-radius);
    -webkit-tap-highlight-color: transparent;

    > aside {
        width: 5rem;
        height: 5rem;
        border-radius: var(--border-radius);
        border: 1px solid #c7c7c7;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
    }
`;
