import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    > * {
        margin-bottom: 0.5rem;
    }

    > h3 {
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 130%;
        color: #373848;
    }

    > p {
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        color: #373848;
    }
`;

export const ImageContainer = styled.div`
    width: 100%;
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > aside {
    }

    > main {
        margin-left: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        > * {
            margin-bottom: 0.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;
