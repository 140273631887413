import styled from 'styled-components';

export const Container = styled.div`
    > header {
        font-weight: 600;
        margin-bottom: 2rem;

        > h2 {
            font-size: 22px;
        }
    }

    > main {
        margin-top: 1rem;
    }

    > footer {
        margin-top: 0.5rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        > a {
            font-size: 0.9rem;
            color: var(--color-primary);
            text-decoration: underline;
        }
    }
`;
