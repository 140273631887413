import styled from 'styled-components';
import Carousel from 'nuka-carousel';

export const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    overflow: auto;
    width: 100%;
    padding: 0.5rem 1rem 0.8rem 1rem;
    max-width: 100%;

    > * {
        margin-right: 1rem;
    }

    ::-webkit-scrollbar {
        border-radius: 10px;
        width: 0.4rem;
        height: 0.4rem;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #bbb;
    }

    ::-webkit-scrollbar-thumb:hover {
        border-radius: 10px;
        background: #aaa;
    }

    @media screen and (max-width: 768px) {
        ::-webkit-scrollbar {
            border-radius: 10px;
            width: 0.1rem;
            height: 0.1rem;
        }
    }
`;

export const DesktopContainer = styled.div`
    grid-area: CC;
    width: 100%;
    margin-top: 1rem;

    > header {
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        color: #373848;
    }

    > main {
        width: 100%;
    }
`;

export const CarouselContainer = styled(Carousel)`
    padding: 1rem 0.65rem 0 0.65rem;
    width: 100%;
    height: 100%;
`;

export const CarouselCard = styled.div`
    height: 9rem;
`;
