import PropTypes from 'prop-types';

const userType = {
    email: PropTypes.string,
    id: PropTypes.number,
    roleId: PropTypes.number,
    studentId: PropTypes.number,
    name: PropTypes.string,
    level: PropTypes.object,
    image: PropTypes.string
};

export { userType };
