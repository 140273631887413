import React from 'react';

import { Circle, Container } from './styles';

const NowText = () => {
    return (
        <Container>
            <Circle />
            <span>Acontecendo agora</span>
        </Container>
    );
};

export default NowText;
