import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow: visible;

    > main {
        overflow: visible;
    }
`;

export const Content = styled.div`
    margin-top: 1rem;
`;
