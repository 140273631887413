import React from 'react';
import { Container, Content } from './styles';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import FullWidthContainer from 'components/atoms/structure/FullWidthContainer';

const AuthNavbar = () => {
    const navigate = useNavigate();

    return (
        <FullWidthContainer background="var(--color-background)">
            <Container>
                <Content>
                    <IconButton onClick={() => navigate('/login')}>
                        <ChevronLeft />
                    </IconButton>
                </Content>
            </Container>
        </FullWidthContainer>
    );
};

export default AuthNavbar;
