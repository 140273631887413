import { api } from 'services/api';
import { postLogError } from '../logErrors/index';

const getHelp = async () => {
    const response = await api
        .get(`/help`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return response.data.data;
};
const sendHelp = async (data) => {
    const response = await api
        .post(`/contact_us`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};
const getResource = async (idState) => {
    const response = await api
        .get(`/get_resource_contact_us`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

export { getHelp, sendHelp, getResource };
