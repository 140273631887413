import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import useSnackbar from 'hooks/useSnackbar';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import useQueryParam from 'hooks/useQueryParam';

import SubSection from 'components/molecules/structure/SubSection';
import PdfViewer from 'components/atoms/structure/PdfViewer';
import Message from 'components/molecules/alerts/Message';
import { getCompletedCourses, getStudentCourseCertificate } from 'services/courses';

import Button from 'components/molecules/buttons/Button';

import { Container, CertificateContainer, ErrorMessage } from './styles';

const ProfileCertificates = () => {
    const [loading, setLoading] = useState(true);
    const [concludedCourses, setConcludedCourses] = useState([]);
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const query = useQueryParam();
    const { REACT_APP_BASE_URL } = process.env;

    useEffect(() => {
        getConcludedCoursesData();
    }, []);

    const getConcludedCoursesData = async () => {
        await getCompletedCourses()
            .then((response) => {
                setConcludedCourses(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Não foi possível carregar os cursos.', 'error');
            });
    };

    const downloadPDF = async (course) => {
        const { data } = await getStudentCourseCertificate(course.studentCourseId);
        Object.assign(document.createElement('a'), {
            href: data.pdf,
            download: `${course.title}.pdf`
        }).click();
    };

    const getReturnUrl = () => {
        const returnUrl = query.get('returnUrl');

        if (returnUrl) {
            return returnUrl;
        }

        return '/aluno/perfil';
    };

    useEffect(() => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: getReturnUrl(),
                returnText: 'Perfil',
                returnPath: location.pathname
            }
        });
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    const courseCerts = concludedCourses
        .filter((course) => course.certificatePdf)
        .map((course) => {
            const [, path] = course?.certificatePdf?.split('storage/');
            const certificatePdf = path ? `${REACT_APP_BASE_URL}/get_file/${path}` : false;
            return { ...course, certificatePdf };
        });

    return (
        <SubSection title="Certificados:" borderTop>
            <Container>
                {courseCerts.length == 0 && (
                    <ErrorMessage>
                        <Message html text="Você não concluiu nenhum curso ainda." />
                    </ErrorMessage>
                )}

                {courseCerts.map((course, index) => (
                    <CertificateContainer key={index}>
                        <PdfViewer src={course.certificatePdf} />
                        <a
                            href="javascript:void(0);"
                            onClick={() => navigate(`/aluno/cursos/curso/${course.id}?returnUrl=/aluno/cursos/certificados`)}
                        >
                            <h3>{course.title}</h3>
                        </a>
                        <Button type="button" size="small" width="auto" onClick={() => downloadPDF(course)} color="neutral">
                            Baixar
                        </Button>
                    </CertificateContainer>
                ))}
            </Container>
        </SubSection>
    );
};

ProfileCertificates.propTypes = {
    data: PropTypes.array
};

export default ProfileCertificates;
