import SaveCheckbox from 'components/atoms/inputs/SaveCheckbox';
import Button from 'components/molecules/buttons/Button';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Container, DesktopContainer } from './styles';
import FullWidthContainer from 'components/atoms/structure/FullWidthContainer';
import ClassesMenu from 'components/organisms/menus/ClassesMenu';
import { ClassType } from 'types/data/course';
import useWindowSize from '../../../../hooks/useWindowSize';
import { Chip } from '@mui/material';
import { withStyles } from '@material-ui/core';
import { lighten } from 'polished';
import Progress from '../../../atoms/inputs/Progress';

const Competence = withStyles({
    root: {
        backgroundColor: (props) => lighten(0.4, props.bgColor),
        color: (props) => props.bgColor,
        fontWeight: 600,
        fontSize: '10px'
    }
})(Chip);
const WatchHeader = ({
    title,
    classes,
    favorite,
    handleFavorite,
    progress,
    activeClassNumber,
    maxClassNumber,
    changeClass,
    finished,
    competences
}) => {
    const [classesMenu, setClassesMenu] = useState(false);
    const windowSize = useWindowSize();
    const handleClass = (value) => {
        setClassesMenu(false);
        changeClass(value);
    };

    if (windowSize.mobile) {
        return (
            <FullWidthContainer background="#fff" marginTop="0 !important">
                <Container>
                    <header>
                        <h3>{title}</h3>
                    </header>
                    <footer>
                        <Button size="small" width="auto" color="neutral" onClick={() => setClassesMenu(true)}>
                            Menu das aulas
                        </Button>
                        <SaveCheckbox active={favorite} setActive={handleFavorite} fontSize="1.8" />
                    </footer>
                </Container>

                <ClassesMenu
                    active={classesMenu}
                    handler={() => setClassesMenu(false)}
                    classes={classes}
                    progress={progress}
                    currentClassNumber={activeClassNumber}
                    maxClassNumber={maxClassNumber}
                    changeClass={handleClass}
                    finished={finished}
                />
            </FullWidthContainer>
        );
    }
    return (
        <FullWidthContainer background="#fff" marginTop="0 !important" contentMaxWidth={1200}>
            <DesktopContainer>
                <div>
                    <h3>Curso: {title}</h3>
                    <div className={'competences'}>
                        {competences.slice(0, 2).map((competence) => (
                            <Competence
                                key={`competence-${competence.id}`}
                                size={'small'}
                                bgColor={competence.color}
                                label={competence.description}
                            />
                        ))}
                        {competences.length > 2 && <Competence size={'small'} bgColor={'#717171'} label={`+${competences.length - 2}`} />}
                    </div>
                </div>
                <div>
                    <p>
                        <strong>Seu progresso:</strong>
                    </p>
                    <div className="progress">
                        <Progress progress={progress} color={'primary'} />
                    </div>
                    <div className="certificado"></div>
                </div>
            </DesktopContainer>

            <ClassesMenu
                active={classesMenu}
                handler={() => setClassesMenu(false)}
                classes={classes}
                progress={progress}
                currentClassNumber={activeClassNumber}
                maxClassNumber={maxClassNumber}
                changeClass={handleClass}
                finished={finished}
            />
        </FullWidthContainer>
    );
};

WatchHeader.propTypes = {
    title: PropTypes.string.isRequired,
    classes: PropTypes.arrayOf(PropTypes.shape(ClassType)).isRequired,
    favorite: PropTypes.bool,
    progress: PropTypes.number.isRequired,
    activeClassNumber: PropTypes.number.isRequired,
    maxClassNumber: PropTypes.number.isRequired,
    changeClass: PropTypes.func.isRequired,
    finished: PropTypes.bool.isRequired,
    handleFavorite: PropTypes.func.isRequired,
    competences: PropTypes.array
};

export default WatchHeader;
