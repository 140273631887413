import styled, { css } from 'styled-components';

export const Container = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    padding: 0.3rem 0.6rem;
    width: 100%;
    border-color: 1px #efefef solid;
    background-color: transparent;

    ${({ active }) =>
        active &&
        css`
            background-color: var(--color-secondary);
            color: #fff;
            border-color: var(--color-secondary);
        `}

    opacity: ${(props) => (!props.selectable ? 0.5 : 1)};

    > span {
        font-size: 0.8rem;
        white-space: nowrap;
    }
`;
