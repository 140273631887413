import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { absoluteMenuType } from 'types/components/navigation';
import AbsoluteMenuItem from './AbsoluteMenuItem';

const AbsoluteMenu = ({ data, handler }) => {
    return (
        <Container>
            {data.map((item, index) => (
                <AbsoluteMenuItem key={index} item={item} handler={handler} />
            ))}
        </Container>
    );
};

AbsoluteMenu.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape(absoluteMenuType)).isRequired,
    handler: PropTypes.func.isRequired
};

export default AbsoluteMenu;
