import { CircularProgress } from '@mui/material';
import Message from 'components/molecules/alerts/Message';
import WatchHeader from 'components/molecules/headers/WatchHeader';
import WatchClass from 'components/organisms/containers/WatchClass';
import Meta from 'components/templates/Seo/Meta';
import useFavoriteList from 'hooks/useFavoriteList';
import useFeedback from 'hooks/useFeedback';
import useQueryParam from 'hooks/useQueryParam';
import useSnackbar from 'hooks/useSnackbar';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getCourseById, likeCourse } from 'services/courses';
import { answerQuiz, finishClass } from 'services/courses/watch';

const Watch = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [course, setCourse] = useState({});
    const [isliked, setIsLiked] = useState(false);
    const [likes, setLikes] = useState(0);
    const [favorited, setFavorited] = useState(null);
    const [progress, setProgress] = useState(0);
    const [activeClass, setActiveClass] = useState({});
    const [maxClass, setMaxClass] = useState(0);
    const [lastLesson, setLastLesson] = useState(false);
    const [finished, setFinished] = useState(false);
    const [quizAnswers, setQuizAnswers] = useState([]);

    const dispatch = useDispatch();
    const location = useLocation();
    const auth = useSelector((state) => state.auth);
    const query = useQueryParam();
    const snackbar = useSnackbar();
    const feedback = useFeedback();
    const navigate = useNavigate();
    const favoriteList = useFavoriteList();

    const handleFavorite = async () => {
        favoriteList.openListModal(favorited, setFavorited, id, 'course');
    };

    const handleLike = async (value) => {
        await likeCourse(id, auth?.user?.studentId, value)
            .then(() => {
                setIsLiked(value);
                snackbar(value ? 'Curso curtido com sucesso' : 'Curso descurtido com sucesso', 'success');
            })
            .catch((err) => {
                snackbar(err.error, 'error');
            });
    };

    const changeClass = async (value) => {
        const selectedClass = course.classes.find((classItem) => classItem.number === value);
        setActiveClass(selectedClass);
    };

    const answerQuizCall = async (questionId, answerId, index) => {
        await answerQuiz(questionId, answerId)
            .then(() => {
                const newAnswers = quizAnswers;
                newAnswers[activeClass.id][index] = answerId;
                setQuizAnswers(newAnswers);
            })
            .catch((error) => {
                snackbar(error.message, 'error');
            });
    };

    const getCourseData = useCallback(async () => {
        await getCourseById(id)
            .then((response) => {
                if (!response.data.registered) {
                    snackbar('Você não está inscrito no curso.', 'error');
                    navigate(`/aluno/cursos/curso/${id}`);
                    return;
                }
                setCourse(response.data);
            })
            .catch((error) => {
                setError(error.data.error);

                console.error(error);
                snackbar('Não foi possível carregar os cursos.', 'error');
            });
    }, []);

    const fillQuizAnswers = () => {
        let quizAnswers = {};

        course.classes.map((cls) => {
            quizAnswers[cls.id] = cls?.quiz.flatMap((quiz) => {
                return quiz?.question
                    ? quiz?.question.map((question) => {
                          return question?.answerStudent ? question?.answerStudent.id_answer : null;
                      })
                    : [];
            });
        });

        return quizAnswers;
    };

    const fillData = () => {
        const getActualClassData = () => {
            if (course.classes?.length === 0) {
                setError('Não existem aulas para este curso.');
                return null;
            }

            const currentClass = course.classes.find((classData) => classData.myStudentComplete === false);

            if (!currentClass) {
                return course.classes[0];
            }

            return currentClass;
        };

        if (!course?.id) {
            return;
        }

        const actualClass = getActualClassData();
        const quizAnswers = fillQuizAnswers();

        setQuizAnswers(quizAnswers);
        setFavorited(course.isFavorite);
        setIsLiked(course.liked);
        setLikes(course.likes - course.liked);
        setActiveClass(actualClass);
        setMaxClass(course.completeMyStudent === 100 ? course.classes.length : Number(actualClass?.number) || 0);
        setProgress(course.progress || 56);
        setLoading(false);
    };

    const nextClass = () => {
        if (Number(activeClass.number) === course.classes.length) {
            return;
        }

        const nextClass = course.classes.find((classData) => classData.number === Number(activeClass.number) + 1);

        if (Number(activeClass.number) + 1 > maxClass) {
            setMaxClass(Number(activeClass.number) + 1);
        }

        window.scrollTo(0, 0);
        setActiveClass(nextClass);
    };

    const calculateProgress = () => {
        if (!course?.classes) {
            return;
        }

        let completedClasses = 0;
        let totalClasses = 0;

        course.classes.forEach((classData) => {
            if (classData.myStudentComplete) {
                completedClasses++;
            }

            totalClasses++;
        });

        setProgress(Math.round((completedClasses / totalClasses) * 100));
    };

    const showMessage = (finishedClassIndex) => {
        if (finishedClassIndex + 1 === course.classes.length) {
            const courseWeight = course?.classes?.reduce((acc, curr) => acc + curr.peso, 0);
            feedback.finishCourse(courseWeight);
            return;
        }

        snackbar('Aula concluída com sucesso', 'success');
    };

    const handleFinish = (goToNextClass = false) => {
        setFinished(true);

        if (!activeClass.myStudentComplete) {
            finishClass(activeClass.id, auth?.user?.studentId)
                .then(() => {
                    const finishedClassIndex = course.classes.findIndex((classData) => classData.id === activeClass.id);
                    const finishedClass = course.classes[finishedClassIndex];

                    if (!finishedClass.myStudentComplete) {
                        showMessage(finishedClassIndex);
                    }

                    setFinished(true);
                    setCourse((prevCourse) => ({
                        ...prevCourse,
                        classes: prevCourse.classes.map((classData, index) => {
                            if (index === finishedClassIndex) {
                                return {
                                    ...classData,
                                    myStudentComplete: true
                                };
                            }

                            return classData;
                        })
                    }));
                })
                .then(() => {
                    if (goToNextClass) {
                        nextClass();
                    }
                })
                .catch((err) => console.error(err));
        } else {
            if (goToNextClass) {
                nextClass();
            }
        }
    };

    const getReturnUrl = () => {
        const returnUrl = query.get('returnUrl');

        if (returnUrl) {
            return returnUrl;
        }

        return '/aluno/cursos';
    };

    const setSubNavbar = () => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: getReturnUrl(),
                returnText: 'Cursos',
                returnPath: location.pathname
            }
        });
    };

    useEffect(() => {
        if (loading) {
            fillData();
        }

        calculateProgress();
    }, [course]);

    useEffect(() => {
        setSubNavbar();
        getCourseData();
    }, []);

    useEffect(() => {
        if (course?.classes?.length === 0 || !activeClass?.id) {
            return;
        }

        setFinished(activeClass.myStudentComplete);
        setLastLesson(course?.classes && Number(activeClass.number) === course?.classes?.length);
        window.scrollTo(0, 0);
    }, [activeClass]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error || !course?.id || !course.classes) {
        return <Message text={error || ' Não foi possível carregar os dados do curso.'} />;
    }
    return (
        <>
            <Meta title={`Assistir ${course?.title}`} />

            <WatchHeader
                title={course?.title}
                classes={course?.classes}
                favorite={favorited}
                handleFavorite={handleFavorite}
                progress={progress}
                id={course.id}
                activeClassNumber={Number(activeClass.number)}
                maxClassNumber={maxClass}
                changeClass={changeClass}
                finished={finished}
                competences={course.competences}
            />

            <WatchClass
                watchClass={activeClass}
                liked={isliked}
                setLiked={handleLike}
                likes={likes}
                nextClass={nextClass}
                handleFinish={handleFinish}
                lastLesson={lastLesson}
                setFinished={setFinished}
                finishedCourse={course?.completeMyStudent === 100}
                quizAnswers={quizAnswers[activeClass.id]}
                answerQuiz={answerQuizCall}
                classes={course?.classes}
                activeClassNumber={Number(activeClass.number)}
                maxClassNumber={maxClass}
                changeClass={changeClass}
                progress={progress}
                finished={finished}
            />
        </>
    );
};

export default Watch;
