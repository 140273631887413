import { Formik } from 'formik';
import React, { useState } from 'react';

import { Container } from './styles';
import Button from '../../../../molecules/buttons/Button';
import { authentication, recoverPassword } from '../../../../../services/auth';
import TextButton from '../../../../atoms/texts/TextButton';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Validator } from 'utils/validators';
import InputEmail from 'components/atoms/formInputs/InputEmail';
import InputPassword from 'components/atoms/formInputs/InputPassword';
import useSnackbar from 'hooks/useSnackbar';
import useQuiz from 'hooks/useQuiz';

const FormLogin = () => {
    const [recoveringPassword, setRecoveringPassword] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const quiz = useQuiz();

    const signIn = async (values, { setSubmitting }) => {
        const { email, password } = values;
        const response = await authentication(email, password);

        if (response.status !== 200) {
            snackbar(response.error || 'Erro ao realizar login', 'error');
            setSubmitting(false);
            return response;
        }

        if (!response.data.user.id_student || response.data.user.id_role === 1) {
            snackbar(response.error || 'Usuário não tem acesso a essa plataforma', 'error');
            setSubmitting(false);
            return response;
        }

        if (response.data?.satisfaction) {
            quiz.add(response.data?.satisfaction);
        }

        dispatch({
            type: 'SET_LOGIN',
            payload: {
                id: response.data.user.id,
                name: response.data.user.name,
                studentId: response.data.user.id_student,
                enterpriseId: response.data.user.id_enterprise,
                email: response.data.user.email,
                image: response.data.user.url_image,
                level: response.data.user?.levelExperience
            }
        });

        if (response.data.user.firstAccess) {
            navigate('/aluno/bem-vindo');
            return response;
        }

        navigate('/');
        return response;
    };

    const signUp = () => {
        navigate('/cadastro');
    };

    const forgotPassword = async (email, error) => {
        if (recoveringPassword) {
            snackbar('Recuperação de senha em andamento', 'warning');
            return;
        }

        if (error) {
            snackbar('Insira um e-mail válido para poder recuperar sua senha', 'error');
            return;
        }

        setRecoveringPassword(true);

        await recoverPassword(email).then((response) => {
            setRecoveringPassword(false);

            if (response.status !== 200) {
                snackbar(response.message, 'error');
                return;
            }

            snackbar(response.data.message, 'success');
        });
    };

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={Validator.object().shape({
                email: Validator.string().email('Digite um email válido').max(255).required('Email Obrigatório'),
                password: Validator.string().max(255).required('Senha Obrigatório')
            })}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true);
                    await signIn(values, { setSubmitting });
                } catch (error) {
                    console.error(error);
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <Container noValidate onSubmit={handleSubmit}>
                    <InputEmail
                        id="email"
                        label="Usuário"
                        value={values.email}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        error={Boolean(touched.email && errors.email)}
                        errorText={errors.email}
                    />

                    <InputPassword
                        id="password"
                        label="Senha"
                        value={values.password}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        error={Boolean(touched.password && errors.password)}
                        errorText={errors.password}
                    />

                    {/* <Button type="button" onClick={() => signUp()} disabled={isSubmitting}>
                        Cadastrar
                    </Button> */}

                    <Button color="light" type="submit" onClick={() => handleSubmit()} disabled={isSubmitting}>
                        Entrar
                    </Button>
                    <TextButton text="Esqueci minha senha" onClick={() => forgotPassword(values.email, errors.email)} />
                </Container>
            )}
        </Formik>
    );
};

export default FormLogin;
