import SubSection from 'components/molecules/structure/SubSection';
import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const ProfileAbout = ({ text }) => {
    if (!text) {
        return null;
    }

    return (
        <SubSection title="Sobre:" borderTop>
            <Container>
                <p dangerouslySetInnerHTML={{ __html: text }} />
            </Container>
        </SubSection>
    );
};

ProfileAbout.propTypes = {
    text: PropTypes.string
};

export default ProfileAbout;
