import ModalCard from 'components/organisms/layout/modals/ModalCard';
import React from 'react';
import PropTypes from 'prop-types';
import { FeedbackType } from 'types/components/feedback';
import useFeedback from 'hooks/useFeedback';
import Level1LevelModal from 'components/molecules/levelModals/Level1LevelModal';
import Level2LevelModal from 'components/molecules/levelModals/Level2LevelModal';
import Level3LevelModal from 'components/molecules/levelModals/Level3LevelModal';
import Level4LevelModal from 'components/molecules/levelModals/Level4LevelModal';
import Level5LevelModal from 'components/molecules/levelModals/Level5LevelModal';
import Level1GoldLevelModal from '../../levelModals/Level1GoldLevelModal';
import Level2GoldLevelModal from '../../levelModals/Level2GoldLevelModal';

const ToLevelUpModal = ({ feedback }) => {
    const { level, currentLevel } = feedback;
    const hook = useFeedback();

    const getLevelImageKey = () => {
        const levelKey = {
            '1 estrela - Prata': 1,
            '2 estrelas - Prata': 2,
            '3 estrelas - Prata': 3,
            '4 estrelas - Prata': 4,
            '5 estrelas - Prata': 5,
            '1 estrela - Ouro': 6,
            '2 estrelas - Ouro': 7
        };

        if (!levelKey[level?.name]) {
            return null;
        }

        return levelKey[level?.name];
    };

    return (
        <ModalCard open={true} title="Suba de nível" handleClose={hook.closeFeedback}>
            {
                {
                    1: <Level1LevelModal />,
                    2: <Level2LevelModal />,
                    3: <Level3LevelModal level={level} levelConquered={currentLevel >= 3} />,
                    4: <Level4LevelModal level={level} levelConquered={currentLevel >= 4} />,
                    5: <Level5LevelModal level={level} levelConquered={currentLevel >= 5} />,
                    6: <Level1GoldLevelModal level={level} levelConquered={currentLevel >= 6} />,
                    7: <Level2GoldLevelModal level={level} levelConquered={currentLevel >= 7} />
                }[getLevelImageKey()]
            }
        </ModalCard>
    );
};

ToLevelUpModal.propTypes = {
    feedback: PropTypes.shape(FeedbackType).isRequired
};

export default ToLevelUpModal;
