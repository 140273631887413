import React from 'react';

import FormMaker from 'components/organisms/layout/form/FormMaker';
import { Validator } from 'utils/validators';
// import PropTypes from 'prop-types';
import useSnackbar from 'hooks/useSnackbar';
import Cookies from 'js-cookie';
import { updatePassword } from 'services/myAccount';
import { useNavigate } from 'react-router-dom';

const AccountConfig = () => {
    const snackbar = useSnackbar();
    const navigate = useNavigate();

    const formSubmit = async (values) => {
        const data = new FormData();
        data.append('password', values.password);
        data.append('newPassword', values.newPassword);
        data.append('token', Cookies.get('token'));

        await updatePassword(data)
            .then((resp) => {
                snackbar(resp.data.data.success, 'success');
                navigate('/aluno');
            })
            .catch((e) => {
                snackbar(e.message, 'error');
            });
    };

    const form = [
        [
            {
                text: 'Mudar a Senha:',
                type: 'label'
            }
        ],
        [
            {
                id: 'password',
                type: 'password',
                label: 'Senha atual',
                validation: Validator.string().required('Nova senha é obrigatória')
            }
        ],
        [
            {
                id: 'newPassword',
                label: 'Nova senha',
                type: 'password',
                validation: Validator.string()
                    .oneOf([Validator.ref('newPasswordRepeat'), null], 'Senhas são divergentes')
                    .min(8, 'Senha deve possuir 8 ou mais caracteres')
                    .required('Nova senha é obrigatória')
            }
        ],
        [
            {
                id: 'newPasswordRepeat',
                label: 'Repita a nova senha',
                type: 'password',
                validation: Validator.string()
                    .oneOf([Validator.ref('newPassword'), null], 'Senhas são divergentes')
                    .required('Confirmação de senha é obrigatória')
            }
        ]
        // [
        //     {
        //         type: 'line'
        //     }
        // ],
        // [
        //     {
        //         text: 'Desejo cancelar minha conta:',
        //         type: 'label'
        //     }
        // ],
        // [
        //     {
        //         type: 'button',
        //         label: 'Cancelar conta no aplicativo',
        //         onClick: () => {
        //             alert('Cancelar conta');
        //         }
        //     }
        // ]
    ];

    return <FormMaker data={form} formSubmit={formSubmit} fixedButtonSubmit />;
};

AccountConfig.propTypes = {};

export default AccountConfig;
