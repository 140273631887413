import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Container, Item } from './styles';
import { htmlClean } from 'utils/html';

const EnterpriseContent = ({ overview, age, size, site }) => {
    if (!overview && !age && !size && !site) {
        return null;
    }

    return (
        <Container>
            {overview && (
                <Item>
                    <h3>Visão geral:</h3>
                    <p>{<p>{ReactHtmlParser(htmlClean(overview))}</p>}</p>
                </Item>
            )}

            {age && (
                <Item>
                    <h3>Fundada em:</h3>
                    <p>{new Date(age).getFullYear()}</p>
                </Item>
            )}

            {size && (
                <Item>
                    <h3>Tamanho da empresa:</h3>
                    <p>
                        {size} funcionário{size > 1 && 's'}
                    </p>
                </Item>
            )}

            {site && (
                <Item>
                    <h3>Site:</h3>
                    <a href={site} target="_blank" rel="noreferrer">
                        {site}
                    </a>
                </Item>
            )}
        </Container>
    );
};

EnterpriseContent.propTypes = {
    overview: PropTypes.string,
    site: PropTypes.string,
    size: PropTypes.number,
    age: PropTypes.string
};

export default EnterpriseContent;
