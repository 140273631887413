import { CompetenceBody, CompetenceFooter, CompetenceHeader, Container } from './styles';
import PropTypes from 'prop-types';
import CompetenceKnob from '../../cards/CompetenceKnob';
import React, { useEffect } from 'react';
import CoursesCarousel from '../../carousels/CoursesCarousel';
import { Link } from 'react-router-dom';

const CoursesCompetence = ({ competence, courses, iconOnly = false, cardVariant }) => {
    if (cardVariant === 'concluded') {
        courses = courses.filter((course) => course.competences.some((comp) => comp.description === competence.description));
    }
    return (
        <Container className={'competenceCourses'} id={`competence-${competence.key}`}>
            <CompetenceHeader>
                <CompetenceKnob competence={competence} courseRedirect={false} iconOnly={iconOnly} />
            </CompetenceHeader>
            <CompetenceBody>
                {courses.length > 0 ? (
                    <CoursesCarousel courses={courses} label={null} errorLabel={null} cardVariant={cardVariant} />
                ) : (
                    <div className={'message'}>Você não concluiu nenhum curso nessa competência</div>
                )}
            </CompetenceBody>
            <CompetenceFooter>
                {cardVariant !== 'concluded' && (
                    <Link className={'footer-button'} to={`/aluno/perfil/competencias/${competence.id}`}>
                        Ver Todos
                    </Link>
                )}
            </CompetenceFooter>
        </Container>
    );
};
CoursesCompetence.propTypes = {
    competence: PropTypes.any.isRequired,
    courses: PropTypes.any.isRequired,
    iconOnly: PropTypes.bool,
    cardVariant: PropTypes.string
};
export default CoursesCompetence;
