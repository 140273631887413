import styled from 'styled-components';

export const Container = styled.div`
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    width: 100%;

    > h3 {
        margin-left: 1rem;
        font-weight: 600;
        font-size: 14px;
    }
`;
