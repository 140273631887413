import styled, { css } from 'styled-components';

export const Container = styled.div`
    overflow: visible;
    margin-bottom: 0rem;
    margin-left: -0.2rem;
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    ${({ stars, size }) =>
        stars === 5
            ? css`
                  width: calc(3 * ${size - 8}px);
              `
            : css`
                  width: calc(2 * ${size - 8}px);
              `}

    svg {
        fill: ${(props) => props.color || '#A9B2B1'};
        flex: 0 0 0;
        color: white;
        display: flex;
        width: 100%;
        height: 100%;
        min-height: ${({ size }) => size}px;
        min-width: ${({ size }) => size}px;
        margin: -0.3rem;
    }
`;
