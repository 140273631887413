const htmlClean = (html) => {
    if (!html) return '';

    const cleanText = html
        .replace('style="text-decoration:underline;font-family: Montserrat;"', '')
        .replace(/style="[a-zA-Z0-9:;.s()-,]*"/g, '')
        .replace(/onclick="[a-zA-Z0-9:;.s()-, ]*"/g, '')
        .replace(/height="[a-zA-Z0-9:;.s()-, ]*"/g, '')
        .replace(/width="[a-zA-Z0-9:;.s()-, ]*"/g, '')
        .replace(/<script[sS]*?>[sS]*?<\/script>/g, '')
        .replace(/iframe/g, 'amp-iframe width="1280" height="753" layout="responsive"')
        .replace(/iframe\//g, 'amp-iframe/')
        .replace(/style="font-family:arial,helvetica,sans-serif"/g, '')
        .replace(/style="font-family:arial/g, 'style="font-family:montserrat')
        .replace(/style="background-color:transparent;font-family:arial"/g, '')
        .replace(/\s+longdesc(?:="[^"]*")?/g, '')
        .replace(/\s+copyright(?:="[^"]*")?/g, '')
        .replace(/\s+allowfullscreen(?:="[^"]*")?/g, '')

        .replace(/title="[a-zA-Z0-9:;.s()-, ]*"/g, '')
        .replace(/align="[a-zA-Z0-9:;.s()-, ]*"/g, '')
        .replace(/alt="[a-zA-Z0-9:;.s()-, ]*"/g, '')
        .replace(/classname="[a-zA-Z0-9:;.s()-, ]*"/g, '')
        .replace(/[0-200].jpg/g, '')
        .replace(/rc="[a-zA-Z0-9:;.s()-, ]*"/g, '')
        .replace('<p', '<p class="desc"')
        .replace('re>', '>"');

    return cleanText;
};

const getMaxNChars = (text, n) => {
    if (text.length > n) {
        return text.substring(0, n) + '...';
    }

    return text;
};

export { htmlClean, getMaxNChars };
