import PostTextEditor from 'components/atoms/inputs/PostTextEditor';
import UserHeader from 'components/molecules/headers/UserHeader';
import React from 'react';
import { useSelector } from 'react-redux';

import { Container, Content } from './styles';

const SharePost = () => {
    const auth = useSelector((state) => state.auth);

    if (!auth?.user?.id) {
        return null;
    }

    return (
        <Container>
            <header>
                <UserHeader name={auth?.user?.name} image={auth?.user?.image} />
            </header>
            <main>
                <Content>
                    <PostTextEditor />
                </Content>
            </main>
        </Container>
    );
};

export default SharePost;
