import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import ParticipantListItem from 'components/molecules/listItems/ParticipantListItem';

const ParticipantsLetterList = ({ letter, participants }) => {
    if (!participants || participants.length === 0) return null;

    return (
        <Container>
            <header>
                <h3>{letter}</h3>
            </header>
            <main>
                {participants.map((participant) => (
                    <ParticipantListItem key={participant.id} participant={participant} />
                ))}
            </main>
        </Container>
    );
};

ParticipantsLetterList.propTypes = {
    letter: PropTypes.string.isRequired,
    participants: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ParticipantsLetterList;
