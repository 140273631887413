import ModalCard from 'components/organisms/layout/modals/ModalCard';
import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import useFeedback from 'hooks/useFeedback';
import QuizModalForm from 'components/organisms/forms/Modals/QuizModalForm';

const SatisfactionSearchModal = ({ feedback }) => {
    const hook = useFeedback();

    return (
        <ModalCard open={true} title="Pesquisa Rápida" handleClose={hook.closeFeedback}>
            <Container>
                <QuizModalForm quiz={feedback.quiz} />
            </Container>
        </ModalCard>
    );
};

SatisfactionSearchModal.propTypes = {
    feedback: PropTypes.any.isRequired
};

export default SatisfactionSearchModal;
