import styled from 'styled-components';
import Carousel from 'nuka-carousel';

export const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    overflow: auto;
    width: 100%;
    padding: 0.5rem 1rem 0.8rem 1rem;

    > * {
        margin-right: 0.5rem;
    }

    ::-webkit-scrollbar {
        border-radius: 10px;
        width: 0.4rem;
        height: 0.4rem;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #bbb;
    }

    ::-webkit-scrollbar-thumb:hover {
        border-radius: 10px;
        background: #aaa;
    }

    @media screen and (max-width: 768px) {
        ::-webkit-scrollbar {
            border-radius: 10px;
            width: 0.1rem;
            height: 0.1rem;
        }
    }
`;
export const DesktopContainer = styled.div`
    grid-area: CC;
    width: 100%;

    > header {
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        color: #373848;
    }

    > main {
        width: 100%;
    }
`;

export const Footer = styled.footer`
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    > button {
        background-color: transparent;
        border: none;
        font-weight: 700;
        color: var(--color-primary);
    }
`;
export const GridContainer = styled.div`
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: 256px 256px 256px;
    grid-column-gap: 40px;
    padding: 0.65rem 0 0.65rem 1rem;
`;
export const CarouselContainer = styled(Carousel)`
    padding: 0.65rem 0 0.65rem 1rem;
    width: 100%;
    height: 100%;
`;

export const CarouselCard = styled.div`
    min-height: 381px;
    height: 381px;
`;
