import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    width: 100%;
`;

export const Content = styled.div`
    height: 200px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    outline: 0;
    border: 1px solid #e6e6e6;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    > img {
        width: 100%;
        height: 100%;
        max-width: 10rem;
        max-height: 10rem;
        object-fit: cover;
        border-radius: 50%;
        background-color: #eee;
        background-size: cover;
    }

    > p {
        width: calc(100% - 60px);
        height: calc(100% - 60px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #333;

        > svg {
            color: #e6e6e6;
            width: 35px;
            height: 35px;
            margin-bottom: 8px;
        }
    }
`;

export const Buttons = styled.div`
    position: absolute;
    margin: auto;

    > button {
        margin-right: 0.5rem;

        &:last-of-type {
            margin-right: 0;
        }
    }
`;

export const EditButton = styled(IconButton)`
    background-color: #fff5;
`;
