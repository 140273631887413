import Button from 'components/molecules/buttons/Button';
import React from 'react';
import PropTypes from 'prop-types';

const ButtonInput = ({ item }) => {
    return (
        <Button width="15rem" type="button" size="thin" color="lightGray" onClick={() => item.onClick()}>
            {item.label}
        </Button>
    );
};

ButtonInput.propTypes = {
    item: PropTypes.object.isRequired
};

export default ButtonInput;
