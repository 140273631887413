import Admin from 'pages/Auth/Admin';
import Cadastro from 'pages/Auth/Cadastro';
import RememberPassword from 'pages/Auth/RememberPassword';
import React from 'react';
import BlankLayout from 'components/templates/layouts/Outlet/BlankLayout';
import Login from 'pages/Auth/Login';

const AuthenticationRoutes = {
    path: '/',
    element: <BlankLayout />,
    children: [
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/remember_password',
            element: <RememberPassword />
        },
        {
            path: '/cadastro',
            element: <Cadastro />
        },
        {
            path: '/auth/admin',
            element: <Admin />
        }
    ]
};

export default AuthenticationRoutes;
