import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    > aside {
        margin-right: 0.5rem;
    }

    > main {
        > h4 {
            font-size: 1.4rem;
            color: var(--color-text);
        }

        > p {
            font-size: 14px;
            color: #555;
        }
    }
`;
