import React from 'react';
import BorderTitle from 'components/molecules/headers/BorderTitle';
import Meta from 'components/templates/Seo/Meta';
import ProfileCertificates from 'components/organisms/containers/ProfileCertificates';

const Certificados = () => {
    return (
        <>
            <Meta title="Todos os certificados" description="Aqui você pode ver todos os certificados" />
            <BorderTitle title="Todos os certificados" />
            <ProfileCertificates />
        </>
    );
};

export default Certificados;
