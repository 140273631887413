import ModalFormTemplate from 'components/molecules/structure/ModalFormTemplate';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Form } from './styles';
import { FormControlLabel, Radio } from '@mui/material';

const RadioModalFormInput = ({ item, onSubmit }) => {
    const [value, setValue] = useState();

    const handleSubmit = () => {
        onSubmit(value);
    };

    return (
        <ModalFormTemplate onSubmit={handleSubmit}>
            <Container>
                <h2>{item.label}</h2>

                <Form>
                    {item.options.map((option, index) => (
                        <FormControlLabel
                            checked={value === option.value}
                            onChange={() => setValue(option.value)}
                            key={index}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                        />
                    ))}
                </Form>
            </Container>
        </ModalFormTemplate>
    );
};

RadioModalFormInput.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                label: PropTypes.string,
                value: PropTypes.string
            })
        ),
        validation: PropTypes.any,
        submit: PropTypes.func
    }),
    onSubmit: PropTypes.func
};

export default RadioModalFormInput;
