import React from 'react';
import PropTypes from 'prop-types';

import { Container, Title, Content, Actions, Button } from './styles';

const DialogForm = ({ title, text, submit, handler, open, cancelText, submitText, noButton }) => {
    const onSubmit = () => {
        submit();
        handler();
    };

    return (
        <Container open={open} onClose={handler} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <Title size="small" id="alert-dialog-title">
                {title}
            </Title>

            <Content>{text}</Content>

            {!noButton && (
                <Actions>
                    <Button cancel="true" size="small" onClick={handler}>
                        {cancelText || 'Cancelar'}
                    </Button>
                    <Button size="small" onClick={() => onSubmit()}>
                        {submitText || 'Continuar'}
                    </Button>
                </Actions>
            )}
        </Container>
    );
};

DialogForm.propTypes = {
    title: PropTypes.string.isRequired,
    submit: PropTypes.func,
    text: PropTypes.any,
    handler: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    cancelText: PropTypes.string,
    submitText: PropTypes.string,
    noButton: PropTypes.bool
};

export default DialogForm;
