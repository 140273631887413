import { CloseButton, Container, DownloadButton, Modal } from './styles';
import PropTypes from 'prop-types';
import PdfViewer from '../../../atoms/structure/PdfViewer';
import useCourses from '../../../../hooks/useCourses';
import { getStudentCourseCertificate } from '../../../../services/courses';

const CertificateModal = ({ open, course }) => {
    const { setOpenCertificateModal } = useCourses();
    if (!course) return <></>;
    const downloadPDF = async () => {
        const { data } = await getStudentCourseCertificate(course.studentCourseId);
        Object.assign(document.createElement('a'), {
            href: data.pdf,
            download: `${course.title}.pdf`
        }).click();
    };

    return (
        <Modal open={open} onClose={() => setOpenCertificateModal(false)} fullWidth maxWidth={'md'}>
            <Container>
                <CloseButton onClick={() => setOpenCertificateModal(false)} />
                <h1>{course.title}</h1>
                <h4>{course.finalDate}</h4>
                <div className={'certificate'}>
                    <PdfViewer src={course.certificatePdf} />
                </div>
                <footer>
                    <DownloadButton onClick={downloadPDF}>Baixar Certificado</DownloadButton>
                </footer>
            </Container>
        </Modal>
    );
};
CertificateModal.propTypes = {
    open: PropTypes.bool.isRequired,
    course: PropTypes.any.isRequired
};
export default CertificateModal;
