import styled from 'styled-components';
import { css } from 'styled-components';

export const Container = styled.div`
    background-color: var(--color-background);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    width: 100%;
    padding: 54px var(--padding-base) 70px var(--padding-base);
    max-width: var(--max-width);

    ${(props) =>
        props.hiddenBottomNavbar &&
        css`
            padding-bottom: 16px;
        `}

    > * {
        margin-top: 1rem;
    }
`;
