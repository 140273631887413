import styled from 'styled-components';

export const Container = styled.div`
    margin: 0;

    > h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        color: #464646;
        flex: none;
        order: 0;
        flex-grow: 0;

        > span {
            margin-left: 0.5rem;
            font-weight: 400;
            font-size: 12px;
        }
    }

    > main {
        width: 100%;
        padding: 1rem 0;

        > textarea {
            width: 100%;
            border-radius: var(--border-radius);
            padding: 1rem;
            border: 1.5px solid #a1a1a7;
            min-height: 5rem;
            resize: vertical;

            ::-webkit-scrollbar {
                border-radius: 10px;
                width: 0.4rem;
                height: 0.4rem;
            }

            ::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: #bbb;
            }

            ::-webkit-scrollbar-thumb:hover {
                border-radius: 10px;
                background: #aaa;
            }
        }
    }
`;
