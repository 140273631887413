import React from 'react';
import StarsSquare from '../StarsSquare';
import Svg from '../Svg';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { getStarsByLevel } from 'utils/rules/level';
import { getSystemImageUrl } from 'utils/assets';
import { LevelType } from 'types/data/level';

const BlondeLevel = ({ level, small }) => {
    const widthLeaf = small ? 36 : 48;
    const heightLeaf = small ? 60 : 74;

    return (
        <Container>
            <Svg url={getSystemImageUrl('left-leaf.svg')} width={widthLeaf} height={heightLeaf} />
            <StarsSquare data={getStarsByLevel(level.name, false)} small={small} />
            <Svg url={getSystemImageUrl('right-leaf.svg')} width={widthLeaf} height={heightLeaf} />
        </Container>
    );
};

BlondeLevel.propTypes = {
    level: PropTypes.shape(LevelType),
    small: PropTypes.bool
};

export default BlondeLevel;
