import styled from 'styled-components';

export const Container = styled.div`
    padding-top: 1rem;
    width: 100%;

    > main {
        margin-top: 1rem;
        width: 100%;
    }

    > footer {
        width: 100%;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        > button {
            background-color: transparent;
            border: none;
            font-weight: 700;
            color: var(--color-primary);
        }
    }
`;
