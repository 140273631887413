import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Description, Title, BlankImage, DescriptionText, Certificate, Actions, Spacer, DesktopContainer } from './styles';
import FavoriteCheckbox from 'components/atoms/inputs/FavoriteCheckbox';
import Button from 'components/molecules/buttons/Button';
import SpeakerItem from 'components/molecules/headers/SpeakerItem';
import CheckListItem from 'components/molecules/listItems/CheckListItem';
import Dialog from 'components/molecules/alerts/Dialog';
import { useSelector } from 'react-redux';
import SaveCheckbox from 'components/atoms/inputs/SaveCheckbox';
import Line from 'components/atoms/structure/Line';
import TimerDuration from 'components/atoms/texts/TimerDuration';
import { useNavigate } from 'react-router-dom';
import { CourseType } from 'types/data/course';
import { likeCourse, subscribeCourse, getStudentCourseCertificate } from 'services/courses';
import ReactHtmlParser from 'react-html-parser';
import { htmlClean } from 'utils/html';
import useSnackbar from 'hooks/useSnackbar';
import useFavoriteList from 'hooks/useFavoriteList';
import PdfViewer from 'components/atoms/structure/PdfViewer';
import PublicationCommentBtn from 'components/molecules/buttons/PublicationComment';
import useWindowSize from '../../../../hooks/useWindowSize';
import { Done } from '@mui/icons-material';
import useAlert from '../../../../hooks/useAlert';

const Course = ({ course }) => {
    const windowSize = useWindowSize();
    const alert = useAlert();
    const {
        id,
        image,
        speakers,
        isFavorite,
        liked,
        competences,
        time,
        description,
        registered,
        title,
        publication,
        publicationCommentsTotal
    } = course;

    useEffect(() => {
        if (liked) setIsLiked(true);
        if (registered) setIsRegistered(registered);
    }, [course]);

    const [isFavorited, setIsFavorited] = useState(isFavorite);
    const [isLiked, setIsLiked] = useState(liked);
    const [isRegistered, setIsRegistered] = useState(registered);
    const [onLoad, setOnLoad] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false);
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const snackbar = useSnackbar();

    const favoriteList = useFavoriteList();

    const handleFavorite = async () => {
        favoriteList.openListModal(isFavorited, setIsFavorited, id, 'course');
    };

    const handleLike = async (value) => {
        await likeCourse(id, auth?.user?.studentId, value)
            .then(() => {
                setIsLiked(value);
                snackbar(value ? 'Curso curtido com sucesso' : 'Curso descurtido com sucesso', 'success');
            })
            .catch((err) => {
                snackbar(err.message || 'Erro ao curtir curso', 'error');
            });
    };

    const handleRegister = async () => {
        if (isRegistered) {
            return;
        }

        setOnLoad(true);

        await subscribeCourse(id, auth?.user?.studentId, !isRegistered)
            .then(() => {
                setIsRegistered(!isRegistered);
                if (windowSize.mobile) {
                    snackbar('Inscrição realizada com sucesso!', 'success');
                } else {
                    alert.success('Sua inscrição para o curso foi realizada com sucesso!');
                }
            })
            .catch((err) => {
                if (windowSize.mobile) {
                    snackbar(err.error || 'Erro ao inscrever-se no curso', 'error');
                } else {
                    alert.error('Erro ao inscrever-se no curso');
                }
            })
            .finally(() => {
                setOnLoad(false);
            });
    };

    const redirectToWatch = () => {
        navigate(`/aluno/cursos/assistir/${id}`);
    };

    const getText = () => (isRegistered ? (registered ? 'Assistir' : 'Iniciar curso') : 'Inscreva-se!');

    const downloadPDF = async () => {
        const { data } = await getStudentCourseCertificate(course.studentCourseId);
        Object.assign(document.createElement('a'), {
            href: data.pdf,
            download: `${course.title}.pdf`
        }).click();
    };

    if (windowSize.mobile) {
        return (
            <Container>
                <main>
                    <header>{image ? <img src={image} alt={title} /> : <BlankImage />}</header>
                    <Title>
                        <h1>{title}</h1>

                        <aside>
                            <FavoriteCheckbox active={isLiked} setActive={handleLike} />
                            <SaveCheckbox active={isFavorited} setActive={handleFavorite} />
                        </aside>
                    </Title>

                    <Description>
                        <div>
                            <h3>Professor</h3>
                            {speakers &&
                                speakers.length > 0 &&
                                speakers.map((speaker, index) => <SpeakerItem key={index} speaker={speaker} />)}
                        </div>

                        <DescriptionText>
                            <h3>Descriação</h3>
                            {ReactHtmlParser(htmlClean(description))}
                        </DescriptionText>

                        {typeof time !== 'undefined' && <TimerDuration minutes={time} />}

                        <Line />

                        <div>
                            <h3>Evolua suas competências</h3>
                            {competences && competences.map((item, index) => <CheckListItem text={item.desription} key={index} />)}
                        </div>
                    </Description>

                    {course.certificatePdf && (
                        <Certificate>
                            <h3>Certificado conquistado!</h3>
                            <PdfViewer src={course.certificatePdf} />
                            <Button
                                onClick={() => {
                                    downloadPDF();
                                }}
                                type="button"
                                color="secondary"
                            >
                                Baixar
                            </Button>
                        </Certificate>
                    )}

                    <Actions>
                        <div>
                            {publication && (
                                <PublicationCommentBtn publication={publication} modalOpen={modalOpen} setModalOpen={setModalOpen} />
                            )}
                            <span>{publicationCommentsTotal}</span>
                        </div>
                        <Spacer />
                    </Actions>
                </main>

                <footer>
                    <Button
                        disabled={onLoad}
                        onClick={() => {
                            isRegistered ? redirectToWatch() : setDialogOpen(true);
                        }}
                        type="button"
                    >
                        {getText()}
                    </Button>
                </footer>

                <Dialog
                    open={dialogOpen}
                    submit={() => handleRegister()}
                    title="Deseja se inscrever no curso?"
                    handler={() => setDialogOpen(false)}
                    submitText="Inscrever-se"
                    cancelText="Voltar"
                />
            </Container>
        );
    }
    return (
        <DesktopContainer>
            <main>
                <header>{image ? <img src={image} alt={title} /> : <BlankImage />}</header>
                <Title>
                    <h1>{title}</h1>

                    <aside>
                        <FavoriteCheckbox active={isLiked} color={'#A1A1A7'} setActive={handleLike} />
                        <SaveCheckbox active={isFavorited} color={'#A1A1A7'} setActive={handleFavorite} />
                    </aside>
                </Title>

                <Description>
                    <div>
                        <h3>Professor</h3>
                        {speakers && speakers.length > 0 && speakers.map((speaker, index) => <SpeakerItem key={index} speaker={speaker} />)}
                    </div>

                    <DescriptionText>
                        <h3>Descrição</h3>
                        {ReactHtmlParser(htmlClean(description))}
                    </DescriptionText>

                    <TimerDuration minutes={time ?? 0} />

                    <Line />

                    <div>
                        <h3>Evolua suas competências</h3>
                        {competences &&
                            competences.map((item, index) => (
                                <CheckListItem key={index} text={item.desription} component={<Done style={{ color: '#6B9D4A' }} />} />
                            ))}
                    </div>
                </Description>
                {course.certificatePdf && (
                    <Certificate>
                        <h3>Certificado conquistado!</h3>
                        <PdfViewer src={course.certificatePdf} />
                        <Button
                            onClick={() => {
                                downloadPDF();
                            }}
                            type="button"
                            color="secondary"
                        >
                            Baixar
                        </Button>
                    </Certificate>
                )}
                <Actions>
                    <div>
                        {publication && (
                            <PublicationCommentBtn publication={publication} modalOpen={modalOpen} setModalOpen={setModalOpen} />
                        )}
                        <span>{publicationCommentsTotal}</span>
                    </div>
                    <Spacer />
                </Actions>
            </main>

            <footer>
                <Button
                    disabled={onLoad}
                    onClick={() => {
                        isRegistered ? redirectToWatch() : setDialogOpen(true);
                    }}
                    type="button"
                    color={'secondary'}
                >
                    {getText()}
                </Button>
            </footer>

            <Dialog
                open={dialogOpen}
                submit={() => handleRegister()}
                title="Confirme"
                text="Deseja se inscrever no curso?"
                handler={() => setDialogOpen(false)}
                submitText="Inscrever-se"
                cancelText="Voltar"
            />
        </DesktopContainer>
    );
};

Course.propTypes = {
    course: PropTypes.shape(CourseType).isRequired
};

export default Course;
