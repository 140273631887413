import React from 'react';
import PropTypes from 'prop-types';

import { ContainerFooter } from './styles';
import ProfileHeader from 'components/molecules/headers/ProfileHeader';

const UserDescription = ({ name, city, occupation, state, image, background }) => {
    return (
        <>
            <ProfileHeader image={image} background={background} profile />

            <ContainerFooter>
                <h2>{name}</h2>
                <h3>{occupation || ''}</h3>
                {city && state && (
                    <p>
                        {city} , {state}
                    </p>
                )}
            </ContainerFooter>
        </>
    );
};

UserDescription.propTypes = {
    name: PropTypes.string.isRequired,
    occupation: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    image: PropTypes.string,
    background: PropTypes.string
};

export default UserDescription;
