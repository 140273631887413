import React from 'react';

import { Container, SelectedBox } from './styles';
import PropTypes from 'prop-types';
import { FavoriteType } from 'types/data/favorite';
import { useNavigate } from 'react-router-dom';
import { getFallbackUrl } from 'utils/general';
import { Check } from '@mui/icons-material';

const FavoriteListItem = ({ item, active, selectFavoriteList }) => {
    const { id, description, icon } = item;
    const navigate = useNavigate();

    const submit = () => {
        if (selectFavoriteList) {
            selectFavoriteList(item, active);
            return;
        }

        navigate(getFallbackUrl(`/aluno/favoritos/${id}`));
    };

    return (
        <Container onClick={() => submit()}>
            {active && selectFavoriteList ? (
                <SelectedBox>
                    <Check />
                </SelectedBox>
            ) : (
                <aside>
                    <img src={icon.url} alt="ícone fav" />
                </aside>
            )}
            <main>
                <span>{description}</span>
            </main>
        </Container>
    );
};

FavoriteListItem.propTypes = {
    item: PropTypes.shape(FavoriteType).isRequired,
    active: PropTypes.bool,
    selectFavoriteList: PropTypes.func
};

export default FavoriteListItem;
