import React from 'react';
import Meta from 'components/templates/Seo/Meta';
import AuthLayout from 'components/templates/layouts/Components/AuthLayout';
import FormSignUp from 'components/organisms/forms/Auth/FormSignUp';

const Cadastro = () => {
    return (
        <>
            <Meta title="Cadastre-se no PFA" description="Faça seu cadastro no PFA" />

            <AuthLayout>
                <FormSignUp />
            </AuthLayout>
        </>
    );
};

export default Cadastro;
