import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const PasswordInput = ({ id, item, handleChange, value, handleBlur, error }) => {
    const { label, optional } = item;
    const [showPassword, setShowPassword] = useState(false);

    return (
        <Container
            label={`${label}${optional ? '' : '*'}`}
            value={value || ''}
            variant="outlined"
            onChange={(e) => handleChange(e)}
            type={showPassword ? 'text' : 'password'}
            helperText={error}
            error={!!error}
            onBlur={handleBlur}
            id={id}
            name={id}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                            size="large"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
};

PasswordInput.propTypes = {
    item: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    value: PropTypes.any,
    error: PropTypes.string,
    id: PropTypes.string
};

export default PasswordInput;
