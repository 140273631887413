import PropTypes from 'prop-types';
import React from 'react';

import { Container } from './styles';

const TextButton = ({ text, onClick }) => {
    return <Container onClick={() => onClick()}>{text}</Container>;
};

TextButton.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func
};

export default TextButton;
