import React from 'react';
import PropTypes from 'prop-types';
import { Container, CloseButton } from './styles';
import { getIOS } from 'utils/general';
import { Close } from '@mui/icons-material';

const Drawer = ({ active, close, open, children, title, variant }) => {
    const iOS = getIOS();

    return (
        <Container
            variant={variant}
            anchor="bottom"
            open={active}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            onClose={() => close()}
            onOpen={() => open()}
        >
            <header>
                <h3>{title}</h3>
                <CloseButton aria-label="Fechar Menu" onClick={() => close()} size="large">
                    <Close />
                </CloseButton>
            </header>
            <main>{children}</main>
        </Container>
    );
};

Drawer.propTypes = {
    active: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    open: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    variant: PropTypes.string
};

export default Drawer;
