import React, { useState } from 'react';
import { Container, Content, MenuBadge, NavigationHeader } from './styles';
import Logo from '../../../../../assets/images/logo.png';
import MainSidebar from '../MainSidebar';

import { Link, useNavigate } from 'react-router-dom';
import { getFallbackUrl } from 'utils/general';
import { useSelector } from 'react-redux';
import { desktopNavigationData } from 'constants/components/navigation';
import DesktopNavbarItem from './DesktopNavbarItem';
import { IconButton } from '@mui/material';
import UserNavigationButton from 'components/molecules/buttons/UserNavigationButton';
import Svg from 'components/atoms/structure/Svg';
import { getSystemImageUrl } from 'utils/assets';

const DesktopNavbar = () => {
    const [openSidebar, setOpenSidebar] = useState(false);
    const notifications = useSelector((state) => state.notifications);

    const navigate = useNavigate();

    const redirectToNotifications = () => {
        navigate(getFallbackUrl('/aluno/notificacoes'));
    };

    const redirectToHelp = () => {
        navigate(getFallbackUrl('/aluno/ajuda'));
    };

    return (
        <>
            <Container>
                <Content>
                    <aside>
                        <Link to={getFallbackUrl('/aluno')}>
                            <a href={getFallbackUrl('/aluno')} aria-hidden="true">
                                <img src={Logo} alt="Logo do Rehagro" />
                            </a>
                        </Link>
                    </aside>
                    <main>
                        {desktopNavigationData.map((item, index) => (
                            <DesktopNavbarItem key={index} item={item} />
                        ))}
                    </main>

                    <NavigationHeader>
                        <IconButton aria-label="menu" onClick={() => redirectToNotifications()}>
                            <MenuBadge badgeContent={notifications?.total || 0} color="primary">
                                <Svg url={getSystemImageUrl('not.svg')} width="24" height="24" />
                            </MenuBadge>
                        </IconButton>
                        <IconButton aria-label="menu" onClick={() => redirectToHelp()}>
                            <Svg url={getSystemImageUrl('faq.svg')} width="24" height="24" />
                        </IconButton>
                        <UserNavigationButton />
                    </NavigationHeader>
                </Content>
            </Container>

            <MainSidebar active={openSidebar} handler={() => setOpenSidebar(false)} />
        </>
    );
};

export default DesktopNavbar;
