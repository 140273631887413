import { useState, useEffect } from 'react';

const useVideoPlayer = (videoElement) => {
    const [playerState, setPlayerState] = useState({
        isPlaying: false,
        progress: 0,
        speed: 1,
        muted: false,
        ended: false
    });

    const togglePlay = () => {
        setPlayerState({
            ...playerState,
            isPlaying: !playerState.isPlaying
        });
    };

    const handleOnTimeUpdate = () => {
        if (!videoElement) {
            return;
        }

        const progress = (videoElement.current.currentTime / videoElement.current.duration) * 100;
        setPlayerState({
            ...playerState,
            progress
        });
    };

    const handleVideoProgress = (event) => {
        if (!videoElement) {
            return;
        }

        const manualChange = Number(event.target.value);
        videoElement.current.currentTime = (videoElement.current.duration / 100) * manualChange;
        setPlayerState({
            ...playerState,
            progress: manualChange
        });
    };

    const handleVideoSpeed = (event) => {
        if (!videoElement) {
            return;
        }

        const speed = Number(event.target.value);
        videoElement.current.playbackRate = speed;
        setPlayerState({
            ...playerState,
            speed
        });
    };

    const toggleMute = () => {
        setPlayerState({
            ...playerState,
            muted: !playerState.muted
        });
    };

    useEffect(() => {
        if (!videoElement) {
            return;
        }

        playerState.isPlaying ? videoElement.current.play() : videoElement.current.pause();
    }, [playerState.isPlaying, videoElement]);

    useEffect(() => {
        if (!videoElement) {
            return;
        }

        playerState.muted ? (videoElement.current.muted = true) : (videoElement.current.muted = false);
    }, [playerState.muted, videoElement]);

    useEffect(() => {
        if (playerState.progress === 100) {
            setPlayerState({
                ...playerState,
                isPlaying: false,
                ended: true
            });

            return;
        }

        if (playerState.ended) {
            setPlayerState({
                ...playerState,
                ended: false
            });
        }
    }, [playerState.progress]);

    return {
        playerState,
        togglePlay,
        handleOnTimeUpdate,
        handleVideoProgress,
        handleVideoSpeed,
        toggleMute
    };
};

export default useVideoPlayer;
