import Chip from 'components/atoms/texts/Chip';
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { CompetenceType } from 'types/data/competence';

const Chips = ({ data }) => {
    if (!data) return null;

    return (
        <Container>
            {data.map((item, index) => (
                <Chip key={index} label={item.description} color={item.color || '#DF9B34'} />
            ))}
        </Container>
    );
};

Chips.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape(CompetenceType)).isRequired
};

export default Chips;
