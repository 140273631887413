import { api } from 'services/api';
import { parsedCourses } from 'services/courses';
import { postLogError } from '../logErrors/index';

const getFavoriteList = async () => {
    const response = await api
        .get(`/favorite_list`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data
    };
};

const getFavoriteListById = async (id) => {
    const response = await api
        .get(`/favorite_list/${id}`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    const course = parsedCourses(response.data.studentFavorite.filter((course) => course.id_course).map((course) => course.course));
    const library = response.data.studentFavorite
        .filter((library) => library.id_library)
        .map((item) => {
            return {
                ...item.library,
                isFavorite: item?.library.isFavorite
                    ? {
                          description: item?.library.isFavorite?.descriptionFavoriteList,
                          id: Number(item?.library.isFavorite?.idFavoriteList)
                      }
                    : null
            };
        });

    return {
        data: {
            ...response.data,
            studentFavorite: {
                course,
                library
            }
        },
        status: response.status
    };
};

const createFavoriteList = async (list) => {
    const response = await api
        .post(`/favorite_list`, {
            description: list.description,
            idIcon: list.idIcon
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data
    };
};

const editFavoriteList = async (list) => {
    const response = await api
        .put(`/favorite_list/${list.id}`, {
            description: list.description,
            idIcon: list.idIcon
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data
    };
};

const removeFavoriteList = async (id) => {
    const response = await api
        .delete(`/favorite_list/${id}`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data
    };
};

const getFavoriteIcons = async () => {
    const response = await api
        .get(`/favorite_icons`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data
    };
};

export { getFavoriteList, createFavoriteList, getFavoriteListById, editFavoriteList, removeFavoriteList, getFavoriteIcons };
