import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { useNavigate } from 'react-router-dom';

import { Container } from './styles';
import { Avatar } from '@mui/material';
import { htmlClean } from 'utils/html';
import { getFallbackUrl } from 'utils/general';

const PublicationComments = ({ comments, ellipsis, clickHandle, redirectOnClick }) => {
    const navigate = useNavigate();

    const redirectClickHandle = (comment) => {
        if (typeof clickHandle == 'function') {
            clickHandle({ comment });
            return;
        }

        if (redirectOnClick === false) return;

        const url = comment?.enterprise_id ? `/aluno/empresas/${comment?.enterprise_id}` : `/aluno/participantes/${comment?.student_id}`;

        navigate(getFallbackUrl(url));
    };

    return (
        <Container ellipsis={ellipsis}>
            {comments.map((comment, index) => (
                <div className="comment__item" key={index}>
                    <div className="comment__iteml">
                        <Avatar
                            src={comment.user_image}
                            onClick={() => {
                                redirectClickHandle(comment);
                            }}
                        />
                    </div>
                    <div className="comment__itemr">
                        <div
                            aria-hidden="true"
                            className="comment__item-user"
                            onClick={() => {
                                redirectClickHandle(comment);
                            }}
                        >
                            {comment.user_name}
                        </div>
                        <div className="comment__item-comment">{ReactHtmlParser(htmlClean(comment.comment.replace(/\n/g, '<br />')))}</div>
                    </div>
                </div>
            ))}
        </Container>
    );
};

PublicationComments.propTypes = {
    comments: PropTypes.array.isRequired,
    ellipsis: PropTypes.bool,
    clickHandle: PropTypes.func,
    redirectOnClick: PropTypes.bool
};

PublicationComments.defaultProps = {
    ellipsis: false,
    redirectOnClick: true
};

export default PublicationComments;
