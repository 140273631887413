import Title from 'components/atoms/texts/Title';
import PropTypes from 'prop-types';
import React from 'react';

import { Container } from './styles';
import { IconButton } from '@mui/material';

const ButtonTitle = ({ title, onClick, borderBottom, icon }) => {
    return (
        <Container borderBottom={borderBottom}>
            <Title title={title} />
            <IconButton onClick={() => onClick()}>{icon}</IconButton>
        </Container>
    );
};

ButtonTitle.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    borderBottom: PropTypes.bool,
    icon: PropTypes.element
};

export default ButtonTitle;
