import React, { Component } from 'react';
import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import { Container, Input } from './styles';
import PropTypes from 'prop-types';
import { createPublication } from 'services/publication';
import CircleGreenButton from 'components/molecules/buttons/CircleGreenButton';
import { getSystemImageUrl } from 'utils/assets';
import SnackbarModule from 'components/molecules/alerts/SnackbarModule';
import FilesList from 'components/organisms/lists/FilesList';
import { Validator } from 'utils/validators';
import Button from 'components/molecules/buttons/Button';
import { getHashtags } from 'services/publication/hashtags';
import { Navigate } from 'react-router-dom';

class PostTextEditor extends Component {
    static propTypes = {
        content: PropTypes.string,
        onSuccess: PropTypes.func
    };

    message = (message, type) => {
        this.setState({
            message,
            type
        });
    };

    getHashtagsData = async (text) => {
        const response = await getHashtags(text || '').catch((err) => {
            console.error(err);
        });

        return response;
    };

    getFormData = async () => {
        const hashtagsBase = await this.getHashtagsData();

        this.setState({
            hashtags: hashtagsBase
        });
    };

    searchHashtags = async (searchTerm, renderList) => {
        var matches = [];

        if (searchTerm.length === 0) {
            matches = this.state.hashtags;
        } else {
            await this.getHashtagsData(searchTerm).then((data) => {
                matches = data;
            });
        }

        window.setTimeout(() => {
            renderList(matches, searchTerm);
        }, 250);
    };

    saveImage = (file) => {
        const fileType = file['type'];
        const validImageTypes = ['image/jpeg', 'image/png'];

        if (!validImageTypes.includes(fileType)) {
            this.message('Arquivo inválido, selecione uma imagem tipo JPG ou PNG', 'error');
            return;
        }

        if (file.size > 30000000) {
            this.message('Imagem muito grande, selecione uma image menor que 30MB', 'error');
            return;
        }

        this.setState({
            files: [...this.state.files, file]
        });
    };

    saveVideo = (evt) => {
        const file = evt.target.files[0];
        const maxFileSize = 50; //Megabytes;
        const fileType = file['type'];
        const validVideoTypes = [
            'video/mp4',
            'video/avi',
            'video/mov',
            'video/wmv',
            'video/flv',
            'video/mkv',
            'video/HEIF',
            'video/HEVC',
            'video/heif',
            'video/hevc',
            'video/MOV',
            'video/quicktime'
        ];
        console.log(file);
        if (!validVideoTypes.includes(fileType)) {
            evt.target.value = '';
            this.message('Arquivo inválido, selecione um vídeo tipo MP4 ou MKV', 'error');
            return;
        }

        if (file.size / 1000000 > maxFileSize) {
            evt.target.value = '';
            this.message(`Vídeo muito grande, selecione um vídeo menor que ${maxFileSize}MB`, 'error');
            return;
        }

        this.setState({
            files: [...this.state.files, file]
        });
    };

    removeImage = (index) => {
        this.setState({
            files: this.state.files.filter((file, i) => i !== index)
        });
    };

    validationSchema = Validator.object().shape({
        text: Validator.string().min(1, 'Texto não pode ser vazio').required('Texto não pode ser vazio')
    });

    componentDidMount() {
        this.getFormData();

        this.setState({
            text: this.props.content
        });
    }

    state = {
        text: '',
        message: '',
        type: 'success',
        files: [],
        isSubmitting: false
    };

    modules = {
        toolbar: false,
        mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['#'],
            positioningStrategy: 'fixed',
            renderItem: (data) => {
                if (data.disabled) {
                    return `<div style="height:10px;line-height:10px;font-size:10px;background-color:#ccc;margin:0 -20px;padding:4px">${data.value}</div>`;
                }
                return data.value;
            },
            renderLoading: () => {
                return 'Carregando...';
            },
            source: this.searchHashtags
        }
    };

    handleChange = (value) => {
        this.setState({ text: value });
    };

    handleSubmit = async () => {
        this.setState({
            isSubmitting: true
        });

        try {
            await this.validationSchema.validate(this.state, { abortEarly: true });

            const formData = new FormData();

            formData.append('description', this.state.text);
            formData.append('idType', 1);
            formData.append('competence', JSON.stringify([]));
            formData.append('skill', JSON.stringify([]));
            this.state.files.map((value) => formData.append('file[]', value));

            await createPublication(formData)
                .then(() => {
                    this.message('Publicação criada com sucesso', 'success');

                    setTimeout(() => {
                        if (this?.props?.onSuccess) {
                            this.props.onSuccess();
                            return;
                        }

                        this.setState({
                            shouldRedirect: true
                        });
                    }, 2000);
                })
                .catch(() => {
                    this.message('Erro ao publicar', 'error');
                });

            this.setState({
                isSubmitting: false
            });
        } catch (err) {
            if (err instanceof Validator.ValidationError) {
                this.message(err.message, 'error');
                this.setState({
                    isSubmitting: false
                });
                return;
            }

            this.message('Erro ao publicar', 'error');
        }

        this.setState({
            isSubmitting: false
        });
    };

    render() {
        return (
            <>
                <SnackbarModule text={this.state.message} type={this.state.type} cleanText={() => this.message('', this.state.type)} />

                <Container>
                    <Input
                        value={this.state.text}
                        onChange={this.handleChange}
                        modules={this.modules}
                        ref={(el) => (this.quillRef = el)}
                        placeholder="Compartilhe suas ideias na comunidade PFA"
                    />

                    <aside>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => this.saveImage(e.target.files[0])}
                            hidden
                            ref={(el) => (this.uploadImage = el)}
                        />
                        <CircleGreenButton
                            iconUrl={getSystemImageUrl('Icons_Publicarimage.svg')}
                            onClick={() => this.uploadImage.click()}
                        />

                        <input type="file" accept="video/*" onChange={this.saveVideo} hidden ref={(el) => (this.uploadVideo = el)} />
                        <CircleGreenButton
                            iconUrl={getSystemImageUrl('Icons_Publicarvideo.svg')}
                            onClick={() => this.uploadVideo.click()}
                        />

                        <CircleGreenButton
                            iconUrl={getSystemImageUrl('hashtag.svg')}
                            onClick={() => this.quillRef.getEditor().getModule('mention').openMenu('#')}
                        />
                    </aside>

                    <FilesList files={this.state.files} handleDelete={(index) => this.removeImage(index)} />

                    <footer>
                        <Button
                            type="submit"
                            width="6rem"
                            color="secondary"
                            disabled={this.state.isSubmitting}
                            onClick={() => this.handleSubmit()}
                        >
                            Publicar
                        </Button>
                    </footer>
                </Container>

                {this.state.shouldRedirect && <Navigate to="/aluno" />}
            </>
        );
    }
}

PostTextEditor.defaultProps = {
    content: ''
};

export default PostTextEditor;
