export const initialState = {
    loading: false,
    page: 1,
    items: [],
    tab: 'all'
};

const timelineReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_TIMELINE_LOADING':
            return { ...state, loading: actions.payload.loading };

        case 'SET_TIMELINE_PAGE':
            return { ...state, page: actions.payload.page };

        case 'SET_TIMELINE_ITEMS':
            return { ...state, items: actions.payload.items };

        case 'SET_TIMELINE_ITEM':
            const items = state.items.map((item) => {
                if (item.id != actions.payload.item.id) return { ...item };
                return { ...actions.payload.item };
            });
            return { ...state, items };

        case 'ADD_TIMELINE_ITEMS':
            return {
                ...state,
                items: [...state.items, ...actions.payload.items]
            };

        case 'CHANGE_TAB':
            return {
                ...state,
                tab: actions.payload
            };

        default:
            return state;
    }
};

export default timelineReducer;
