export const initialState = {
    filterIdRole: null,
    page: 0
};

const participantsReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'FILTER_TYPE_ROLE':
            const filterIdRole = actions.payload;

            return {
                ...state,
                filterIdRole
            };
        case 'PAGE':
            const page = actions.payload;

            return {
                ...state,
                page
            };

        default:
            return state;
    }
};

export default participantsReducer;
