import styled from 'styled-components';

// LA - Library Aside
// SH - Search Header
// L - Library

export const Container = styled.div`
    display: grid;
    grid-template-columns: 250px auto auto;
    gap: 1rem;
    width: 100%;
    align-items: start;

    grid-template-areas:
        'LA SH SH'
        'LA L L'
        'LA L L';
`;
