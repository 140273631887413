import styled from 'styled-components';

export const Container = styled.div`
    padding: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: -6rem;

    > header {
        width: 100%;
        height: 100%;
    }

    > footer {
        margin-top: 1rem;
        width: 100%;
    }
`;

export const Item = styled.div`
    width: 100%;
    display: flex;
    align-items: space-between;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    height: 100%;
    overflow: hidden;

    > img {
        width: 100%;
        height: 100%;
        margin-bottom: 0.5rem;
    }

    > h2 {
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        margin-bottom: 0.5rem;
    }

    > p {
        font-weight: 400;
        font-size: 16px;
        text-align: center;
    }
`;
