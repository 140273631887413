import Svg from 'components/atoms/structure/Svg';
import React from 'react';
import { getSystemImageUrl } from 'utils/assets';

import { Container, ImageContainer } from './styles';

const ModalFormFinish = () => {
    return (
        <Container>
            <ImageContainer>
                <Svg url={getSystemImageUrl('happyFace.svg')} width="75" height="68" />
            </ImageContainer>
            <p>
                Muito obrigado pela <br />
                sua participação!
            </p>
        </Container>
    );
};

export default ModalFormFinish;
