import styled from 'styled-components';

// M - Menu
// H - Header
// B - Body

export const Container = styled.div`
    display: grid;
    grid-template-columns: 100% auto auto;
    width: 100%;
    grid-template-areas:
        'M M M'
        'H H H'
        'B B B';
`;
