import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { TimerOutlined } from '@mui/icons-material';

const DiffHoursText = ({ minutes }) => {
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;

    const formatTwoDigits = (number) => {
        return number < 10 ? `0${number}` : number;
    };

    return (
        <Container>
            <aside>
                <TimerOutlined fontSize="small" htmlColor="#E2B02F" />
            </aside>
            <p>
                Inicia em {formatTwoDigits(hours)}:{formatTwoDigits(minutesLeft)} horas
            </p>
        </Container>
    );
};

DiffHoursText.propTypes = {
    minutes: PropTypes.number
};

export default DiffHoursText;
