import React from 'react';
import PropTypes from 'prop-types';
import { CloseButton, Container, Content } from './styles';
import { Close } from '@mui/icons-material';

const ModalCard = ({ children, open, handleClose, title, formLayout }) => {
    return (
        <Container open={open} onClose={handleClose} formLayout={formLayout}>
            <Content formLayout={formLayout}>
                <header>
                    <h3>{title}</h3>
                    <CloseButton formLayout={formLayout} aria-label="Fechar Menu" onClick={handleClose} size="large">
                        <Close />
                    </CloseButton>
                </header>
                <main>{children}</main>
            </Content>
        </Container>
    );
};

ModalCard.propTypes = {
    children: PropTypes.node.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    formLayout: PropTypes.bool
};

export default ModalCard;
