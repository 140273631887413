import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: var(--color-background);
    border-radius: 1rem;
    padding: 1rem;
    min-width: 10rem;

    > * {
        margin-bottom: 0.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const Item = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > span {
        font-weight: 700;
        font-size: 14px;
        line-height: 130%;
        text-align: center;
        margin-top: 0.5rem;
        color: var(--color-dark);
        margin-left: 0.5rem;
    }
`;

export const Link = styled.a`
    text-decoration: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    margin-top: 0.5rem;
    color: var(--color-dark);
    margin-left: 0.5rem;
`;
