import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    > main {
        margin-bottom: 1rem;
    }

    > footer {
        width: 100%;
        border-top: 1px solid #c7c7c7;
        padding-top: 1rem;
        display: flex;
        justify-content: flex-end;
    }
`;
