import { Avatar } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { useNavigate } from 'react-router-dom';
import { getFallbackUrl } from 'utils/general';

const UserSidebar = ({ name, email, image, handler }) => {
    const navigate = useNavigate();

    const redirect = () => {
        handler();
        navigate(getFallbackUrl('/aluno/perfil'));
    };

    return (
        <Container onClick={() => redirect()}>
            <aside>
                <Avatar src={image}>{name[0].toUpperCase()}</Avatar>
            </aside>
            <main>
                <h4>{name}</h4>
                <p>{email}</p>
            </main>
        </Container>
    );
};

UserSidebar.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    image: PropTypes.string,
    handler: PropTypes.func
};

export default UserSidebar;
