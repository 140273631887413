import Meta from 'components/templates/Seo/Meta';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import IframeQuery from 'components/organisms/iframes/IframeQuery';
import useWindowSize from 'hooks/useWindowSize';
import HelpLayout from 'components/templates/layouts/Pages/HelpLayout';
import HelpHeader from 'components/molecules/headers/HelpHeader';
import FormHelp from 'components/organisms/forms/Help';

const Duvidas = () => {
    const dispatch = useDispatch();
    const windowSize = useWindowSize();
    const [options, setOptions] = useState({});

    useEffect(() => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: '/aluno/ajuda',
                returnText: 'Ajuda',
                returnPath: location.pathname
            }
        });
    }, []);
    if (windowSize.mobile) {
        return (
            <>
                <Meta title="Dúvidas e sugestões" />

                <HelpHeader
                    title="Envie suas sugestões, dúvidas, reclamações ou elogios"
                    description="Olá! <p> É um prazer ter a oportunidade de conversar com você. Caso tenha alguma dúvida, reclamação, sugestão ou elogio, ficaremos felizes em te ouvir e ajudar. Sua participação é muito importante para contribuir no crescimento de nossa plataforma, sinta-se a vontade para escrever sua mensagem no campo abaixo."
                />
                <FormHelp />
            </>
        );
    }

    return (
        <>
            <Meta title="Dúvidas e sugestões" />
            <HelpLayout>
                <HelpHeader
                    title="Envie suas sugestões, dúvidas, reclamações ou elogios"
                    description="Olá! <p> É um prazer ter a oportunidade de conversar com você. Caso tenha alguma dúvida, reclamação, sugestão ou elogio, ficaremos felizes em te ouvir e ajudar. Sua participação é muito importante para contribuir no crescimento de nossa plataforma, sinta-se a vontade para escrever sua mensagem no campo abaixo."
                />
                <FormHelp options={options} />
            </HelpLayout>
        </>
    );
};

export default Duvidas;
