import { CircularProgress } from '@mui/material';
import Title from 'components/atoms/texts/Title';
import SearchHeader from 'components/molecules/headers/SearchHeader';
import Library from 'components/organisms/containers/Library';
import LibraryAside from 'components/organisms/containers/LibraryAside';
import LibraryLayout from 'components/templates/layouts/Pages/LibraryLayout';
import Meta from 'components/templates/Seo/Meta';
import useSnackbar from 'hooks/useSnackbar';
import useWindowSize from 'hooks/useWindowSize';
import React, { useEffect, useState } from 'react';
import { getLibraryParameters, getLibraryData } from 'services/library';

const Biblioteca = () => {
    const [loading, setLoading] = useState(true);
    const [competences, setCompetences] = useState([]);
    const [formats, setFormats] = useState([]);

    const snackbar = useSnackbar();
    const windowSize = useWindowSize();
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [searchLoading, setSearchLoading] = useState(false);
    const [learningObjects, setLearningObjects] = useState([]);
    const [courses, setCourses] = useState([]);
    const [events, setEvents] = useState([]);
    const [searchParams, setSearchParams] = useState({ page: 1, qtdPerPage: 10, title: '', idTypeLearningObject: null });

    const orderTypeLearningObjects = (typeArrays) => {
        if (!typeArrays) {
            return [];
        }

        return typeArrays.sort((a) => {
            if (a.description === 'Cursos') return -3;
            if (a.description === 'Eventos') return -2;
            return 0;
        });
    };

    const getListData = async () => {
        await getLibraryParameters()
            .then((response) => {
                setCompetences(response.data.competences);
                setFormats(orderTypeLearningObjects(response.data.typeLearningObject));
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Não foi possível carregar a listagens de conteúdo.', 'error');
            });
    };

    useEffect(() => {
        getListData();
    }, []);

    const searchHeaderChange = async (title = '') => {
        setSearchLoading(true);
        setSearchParams((previous) => ({ ...previous, title }));

        if (searchTimeout) clearInterval(searchTimeout);
        setSearchTimeout(
            setTimeout(async () => {
                if (title === '') {
                    setSearchLoading(false);
                    return setLearningObjects([]);
                }

                const { data } = await getLibraryData(false, false, searchParams.title);
                setLearningObjects(data.learningObjects);
                setCourses(data.courses);
                setEvents(data.events);
                setSearchLoading(false);
            }, 1000)
        );
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (windowSize.mobile) {
        return (
            <>
                <Meta title="Biblioteca" />

                <Title title="Biblioteca" />

                <SearchHeader value={searchParams.title} onChange={searchHeaderChange} />

                <Library
                    search={searchParams.title}
                    competences={competences}
                    formats={formats}
                    loading={searchLoading}
                    learningObjects={learningObjects}
                    courses={courses}
                    events={events}
                />
            </>
        );
    }
    return (
        <>
            <Meta title="Biblioteca" />

            <LibraryLayout>
                <LibraryAside formats={formats} />

                <SearchHeader value={searchParams.title} onChange={searchHeaderChange} />

                <Library
                    search={searchParams.title}
                    competences={competences}
                    formats={formats}
                    loading={searchLoading}
                    learningObjects={learningObjects}
                    courses={courses}
                    events={events}
                />
            </LibraryLayout>
        </>
    );
};

export default Biblioteca;
