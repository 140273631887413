import styled from 'styled-components';

export const Container = styled.div`
    background-color: #e7f5e6;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
