import styled from 'styled-components';

export const Container = styled.div`
    background-color: var(--color-background);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    max-width: var(--max-width);
    position: sticky;
    top: 5rem;
    margin-top: -0.1rem;

    ${({ paddingTop }) => paddingTop && 'padding-top: 0.1rem;'}

    ::-webkit-scrollbar {
        border-radius: 10px;
        width: 0.4rem;
        height: 0.4rem;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #aaa;
    }

    ::-webkit-scrollbar-thumb:hover {
        border-radius: 10px;
        background: #555;
    }

    @media screen and (max-width: 768px) {
        ::-webkit-scrollbar {
            border-radius: 10px;
            width: 0.1rem;
            height: 0.1rem;
        }
    }

    > header {
        padding-left: 1rem;
        padding-top: 0.5rem;
    }

    > main {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        overflow: auto;
        padding: 0.5rem 1rem 0.8rem 1rem;

        ::-webkit-scrollbar {
            border-radius: 10px;
            width: 0.4rem;
            height: 0.4rem;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #aaa;
        }

        ::-webkit-scrollbar-thumb:hover {
            border-radius: 10px;
            background: #555;
        }

        @media screen and (max-width: 768px) {
            ::-webkit-scrollbar {
                border-radius: 10px;
                width: 0.1rem;
                height: 0.1rem;
            }
        }

        > * {
            margin-right: 0.5rem;
        }
    }
`;
