import DialogForm from 'components/molecules/alerts/DialogForm';
import FormMakerFavorite from 'components/organisms/layout/form/FormMakerFavorite';
import useFavoriteList from 'hooks/useFavoriteList';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Validator } from 'utils/validators';
import { getFavoriteIcons } from 'services/favorite';
import { Container } from './styles';
import useSnackbar from 'hooks/useSnackbar';

const AddFavoriteDrawerDesktop = () => {
    const favoriteList = useSelector((state) => state.favoriteList);
    const hook = useFavoriteList();
    const invalidNames = favoriteList.list.map((item) => item.description);
    const [favoriteIcons, setFavoriteIcons] = useState('');
    const snackbar = useSnackbar();

    const submit = async (data) => {
        await hook
            .add(data)
            .then(() => hook.closeAddModal())
            .catch(() => {});
    };

    const form = [
        [
            {
                text: 'Dê um nome a sua lista',
                type: 'label'
            }
        ],
        [
            {
                id: 'description',
                type: 'text',
                label: 'Nome da lista',
                message: 'Máximo 50 caracteres',
                validation: Validator.string()
                    .required('Nome da lista é obrigatório')
                    .max(50, 'Nome da lista deve ter no máximo 50 caracteres')
                    .notOneOf(invalidNames, 'Nome da lista já existe')
            }
        ]
    ];

    const getAllFavoriteIcons = async () => {
        await getFavoriteIcons()
            .then((response) => {
                setFavoriteIcons(response.data.data);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar ícones', 'error');
            });
    };

    useEffect(() => {
        getAllFavoriteIcons();
    }, []);

    return (
        <DialogForm
            handler={() => hook.closeAddModal()}
            open={favoriteList.addModal}
            title="Nova Lista"
            noButton={true}
            text={
                <Container>
                    <FormMakerFavorite
                        data={form}
                        formSubmit={submit}
                        submitText="Criar"
                        noConfirm
                        fullWidthButtonSubmit
                        icons={favoriteIcons}
                        subtitle="Escolha um ícone para sua lista"
                    />
                </Container>
            }
        />
    );
};

export default AddFavoriteDrawerDesktop;
