import React from 'react';
import { Container, Content, MenuButton } from './styles';
import { ArrowBackIosNew, HelpOutline } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { getFallbackUrl } from 'utils/general';

const SubNavbar = ({ title, url }) => {
    const navigate = useNavigate();

    const isHelpPage = window.location.pathname === '/aluno/ajuda';

    const redirectToHelp = () => {
        navigate(getFallbackUrl('/aluno/ajuda'));
    };

    return (
        <Container>
            <Content>
                <Link to={url}>
                    <aside>
                        <MenuButton aria-label="menu">
                            <ArrowBackIosNew color="primary" />
                        </MenuButton>

                        <h2>{title}</h2>
                    </aside>
                </Link>

                {!isHelpPage && (
                    <main>
                        <IconButton aria-label="menu" onClick={() => redirectToHelp()}>
                            <HelpOutline color="primary" />
                        </IconButton>
                    </main>
                )}
            </Content>
        </Container>
    );
};

SubNavbar.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
};

export default SubNavbar;
