import styled, { css } from 'styled-components';
import { DialogContent, DialogContentText } from '@mui/material';

export const Container = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    ${({ fixedButtonSubmit, fullWidthButtonSubmit }) =>
        fixedButtonSubmit
            ? ''
            : fullWidthButtonSubmit
            ? css`
                  > div {
                      &:last-of-type {
                          margin-top: 1rem;
                      }
                  }
              `
            : css`
                  > div {
                      &:last-of-type {
                          flex: 1;
                          display: flex;
                          flex-direction: row;
                          justify-content: ${({ deleteButton }) => (deleteButton ? 'space-between' : 'flex-end')};
                          margin-top: 2rem;

                          > button {
                              max-width: 50%;

                              &:first-of-type {
                                  margin-right: 0.5rem;
                              }
                              &:last-of-type {
                                  margin-left: 0.5rem;
                              }
                          }
                      }
                  }
              `}
`;

export const Item = styled.div`
    margin-bottom: 1rem;
`;

export const ContentIcons = styled(DialogContent)`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px;
`;

export const Box = styled.div`
    width: 70px;
    padding: 10px;
    margin: 10px;
    display: flex;
    justify-content: center;
    background-color: #f4f4f4;
    border-radius: 5px;
    cursor: pointer;
`;

export const BoxSelect = styled.div`
    width: 70px;
    padding: 10px;
    margin: 10px;
    display: flex;
    justify-content: center;
    background-color: #f4f4f4;
    border-radius: 5px;
    cursor: pointer;
    border: 3px solid #379f7a;
`;

export const Text = styled(DialogContentText)`
    font-size: 1rem;
    color: #000000;
`;

export const Content = styled(DialogContent)`
    padding: 0.8rem;
    ::-webkit-scrollbar {
        border-radius: 10px;
        width: 0.4rem;
        height: 0.4rem;
    }
`;
