import SubSection from 'components/molecules/structure/SubSection';
import React from 'react';
import PropTypes from 'prop-types';
import { CarouselContainer, DesktopContainer, GridContainer } from './styles';

import { useNavigate } from 'react-router-dom';
import CarouselArrowButton from '../../buttons/CarouselArrowButton';
import GridEventCard from '../../cards/GridEventCard';

const EventsCarousel = ({ events, label }) => {
    const navigate = useNavigate();
    return (
        <DesktopContainer>
            {events.length <= 3 && (
                <GridContainer>
                    {events.map((event, index) => (
                        <GridEventCard key={`event-${index}`} event={event} />
                    ))}
                </GridContainer>
            )}
            {events.length > 3 && (
                <CarouselContainer
                    cellSpacing={16}
                    dragging={false}
                    swiping={false}
                    renderCenterLeftControls={({ previousSlide }) => (
                        <CarouselArrowButton onClick={previousSlide} type="left" backgroundWhite />
                    )}
                    renderCenterRightControls={({ nextSlide }) => <CarouselArrowButton onClick={nextSlide} type="right" backgroundWhite />}
                    renderBottomCenterControls={null}
                    dragThreshold={0.1}
                    slidesToShow={3}
                    wrapAround
                    enableKeyboardControls={false}
                    scrollMode="remainder"
                >
                    {events.map((event, index) => (
                        <GridEventCard key={`event-${index}`} event={event} />
                    ))}
                </CarouselContainer>
            )}
        </DesktopContainer>
    );
};

EventsCarousel.propTypes = {
    events: PropTypes.arrayOf(PropTypes.object).isRequired,
    removeCompletedEvent: PropTypes.func,
    finishedEvents: PropTypes.bool,
    newEvents: PropTypes.bool,
    label: PropTypes.string,
    errorLabel: PropTypes.string
};

export default EventsCarousel;
