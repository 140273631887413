import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    height: 100%;
`;

export const Content = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 100%;
    filter: invert(16%) sepia(9%) saturate(7289%) hue-rotate(120deg) brightness(99%) contrast(92%);

    > svg,
    img {
        filter: brightness(0) saturate(100%);
    }
`;
