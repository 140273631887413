import styled from 'styled-components';
import { List, ListItemButton } from '@mui/material';

export const Container = styled.div`
    margin-top: 30px;
    width: 100%;
`;
export const ListStyled = styled(List)`
    background-color: white;
    border-radius: 10px;
`;
export const ListItemButtonStyled = styled(ListItemButton)`
    &:hover {
        background-color: lightGray;
    }
`;
