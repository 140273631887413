import { Avatar } from '@mui/material';
import React from 'react';
import { SpeakerType } from 'types/data/speaker';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { getImageUrl } from 'utils/assets';

const SpeakerItem = ({ speaker }) => {
    const { name, description, file } = speaker;

    return (
        <Container>
            <aside>{file[0]?.url ? <Avatar src={getImageUrl(file[0].url)} /> : <Avatar>{name[0].toUpperCase()}</Avatar>}</aside>
            <main>
                <h4>{name}</h4>
                <p>{description}</p>
            </main>
        </Container>
    );
};

SpeakerItem.propTypes = {
    speaker: PropTypes.shape(SpeakerType).isRequired
};

export default SpeakerItem;
