import { api } from 'services/api';

const postLogError = async (error) => {
    api.post('error_log', {
        data: error.response.data.exception || error.response.data.error,
        url: window.location.href,
        description: error.response.data.message || error.message,
        file: error.response.data.file || '404 Not Found',
        line: error.response.data.line || '404 Not Found'
    });
};

export { postLogError };
