import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import Title from 'components/atoms/texts/Title';

const BorderTitle = ({ title }) => {
    return (
        <Container>
            <Title title={title} />
        </Container>
    );
};

BorderTitle.propTypes = {
    title: PropTypes.string.isRequired
};

export default BorderTitle;
