import { CircularProgress } from '@mui/material';
import DescriptionText from 'components/atoms/texts/DescriptionText';
import ListCarousel from 'components/molecules/carousels/ListCarousel';
import BorderTitle from 'components/molecules/headers/BorderTitle';
import CompletedCoursesList from 'components/organisms/lists/CompletedCourseList';
import Meta from 'components/templates/Seo/Meta';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCompetences } from 'services/competences';
import { getCompletedCourses } from 'services/courses';

const FinishedCourses = () => {
    const [courses, setCourses] = useState([]);
    const [competences, setCompetences] = useState([]);
    const [selectedCompetence, setSelectedCompetence] = useState('');
    const [selectableCompetences, setSelectableCompetences] = useState([]);
    const [loading, setLoading] = useState(true);
    const [scrolling, setScrolling] = useState(false);
    const [scrollY, setScrollY] = useState(0);
    const [higherYItem, setHigherYItem] = useState(0);

    const dispatch = useDispatch();

    const getCoursesData = async () => {
        await getCompletedCourses()
            .then((response) => {
                setCourses(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Não foi possível carregar os cursos.', 'error');
            });
    };

    const getCompetencesData = async () => {
        await getCompetences()
            .then((response) => {
                setCompetences(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Não foi possível carregar as competências.', 'error');
            });
    };

    const handleCompetenceChange = (index) => {
        setScrolling(true);
        setSelectedCompetence(index);

        setTimeout(() => {
            setScrolling(false);
        }, 500);
    };

    const getHigherYItem = () => {
        const item = document.querySelector(`#competence-${selectableCompetences[0]}`);

        if (!item) {
            return;
        }

        setHigherYItem(item.getBoundingClientRect().top);
    };

    const verifySelectedCompetence = () => {
        if (selectableCompetences.length === 0) {
            setSelectedCompetence(-1);
            return;
        }

        getHigherYItem();

        if (selectableCompetences.includes(selectedCompetence)) {
            return;
        }

        setSelectedCompetence(selectableCompetences[0]);
    };

    const verifyScroll = () => {
        if (scrolling) {
            return;
        }

        let offsetY = 0;
        let component = null;

        if (scrollY !== 0) {
            if (document.body.scrollHeight - scrollY <= window.innerHeight) {
                setSelectedCompetence(selectableCompetences[selectableCompetences.length - 1]);
                return;
            }
        }

        selectableCompetences.forEach((idCompetence) => {
            component = document.getElementById(`competence-${idCompetence}`);

            if (!component) {
                return;
            }

            offsetY = component.getBoundingClientRect().top;

            if (offsetY < 150 && offsetY > 0) {
                setSelectedCompetence(idCompetence);
                return;
            }

            if (scrollY < higherYItem && selectableCompetences.length > 0) {
                setSelectedCompetence(selectableCompetences[0]);
            }
        });
    };

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        setScrollY(currentScrollY);
    };

    useEffect(() => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: '/aluno/cursos',
                returnText: 'Cursos',
                returnPath: location.pathname
            }
        });
    }, []);

    useEffect(() => {
        getCoursesData();
        getCompetencesData();
    }, []);

    useEffect(() => {
        verifyScroll();
    }, [scrollY]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        verifySelectedCompetence();
    }, [selectableCompetences]);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
            <Meta title="Competência" description="Aqui você pode ver todos os cursos sobre a competência" />

            <BorderTitle title="Cursos Concluídos" />

            <DescriptionText>
                <p>
                    Sua lista de cursos que você finalizou e evoluiu junto com o PFA. Sempre reveja os conteúdos ensinados. A melhoria é
                    contínua :)
                </p>
            </DescriptionText>

            <ListCarousel
                data={competences.map((competence) => ({
                    id: competence.id,
                    text: competence.description
                }))}
                selectedData={selectedCompetence}
                onItemClick={handleCompetenceChange}
                selectableItems={selectableCompetences}
                reference="competence"
                description="Navegue por Competências:"
            />

            <CompletedCoursesList competences={competences} courses={courses} setSelectableCompetences={setSelectableCompetences} />
        </>
    );
};

export default FinishedCourses;
