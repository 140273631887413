import ModalCard from 'components/organisms/layout/modals/ModalCard';
import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import useFeedback from 'hooks/useFeedback';
import { FeedbackType } from 'types/components/feedback';
import { useSelector } from 'react-redux';
import UserHeader from 'components/molecules/headers/UserHeader';

import PostTextEditor from 'components/atoms/inputs/PostTextEditor';

const PostFormModal = () => {
    const hook = useFeedback();
    const auth = useSelector((state) => state.auth);

    return (
        <ModalCard open={true} title="Envie sua sugestão" handleClose={hook.closeFeedback} formLayout>
            <Container>
                <header>
                    <UserHeader name={auth?.user?.name} image={auth?.user?.image} />
                </header>
                <main>
                    <PostTextEditor onSuccess={hook.closeFeedback} />
                </main>
            </Container>
        </ModalCard>
    );
};

PostFormModal.propTypes = {
    feedback: PropTypes.shape(FeedbackType).isRequired
};

export default PostFormModal;
