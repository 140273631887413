import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    > span {
        margin-left: 0.5rem;
        color: #c00;
        font-size: 0.9rem;
    }
`;

export const Circle = styled.div`
    height: 0.6rem;
    width: 0.6rem;
    background-color: #f00;
    border-radius: 50%;
    border: none;

    animation: blinker 0.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;

    @keyframes blinker {
        from {
            background-color: #f00;
        }
        to {
            background-color: #ccc;
        }
    }
`;
