import BlankContainer from 'components/atoms/structure/BlankContainer';
import React from 'react';
import Title from 'components/atoms/texts/Title';
import Meta from 'components/templates/Seo/Meta';
import FormRecoverPassword from 'components/organisms/forms/Auth/FormRecoverPassword';

const RememberPassword = () => {
    return (
        <>
            <Meta title="Recuperar senha" description="Recupere sua senha" />
            <Title title="Cadastre sua nova senha" />

            <BlankContainer>
                <FormRecoverPassword />
            </BlankContainer>
        </>
    );
};

export default RememberPassword;
