import { api } from 'services/api';
import { postLogError } from '../../logErrors/index';

const finishClass = async (classId, studentId) => {
    const response = await api
        .post(`/complete_class`, {
            idClass: classId,
            idStudent: studentId,
            complete: 1
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const finishVideoClass = async (classId) => {
    const response = await api
        .post(`/finished_class/${classId}`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const stopClass = async (classId, studentId, time) => {
    const response = await api
        .post(`/time_stop_class`, {
            idClass: classId,
            idStudent: studentId,
            timeStop: time
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const answerQuiz = async (idQuestion, idAnswer) => {
    const response = await api
        .post(`/student_question`, {
            idQuestion,
            idAnswer
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return response.data;
};

export { finishClass, stopClass, answerQuiz, finishVideoClass };
