import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { Terrain } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getFallbackUrl } from 'utils/general';

const ProfileAboutCard = ({ title, legend, footer, image, uri }) => {
    const navigate = useNavigate();

    const redirect = () => {
        if (!uri) {
            return;
        }

        navigate(getFallbackUrl(uri));
    };
    return (
        <Container pointer={!!uri} onClick={() => redirect()}>
            <aside>
                {image ? (
                    <img src={image} alt={title} />
                ) : (
                    <div>
                        <Terrain fontSize="large" />
                    </div>
                )}
            </aside>
            <main>
                {title && <h3>{title}</h3>}
                {legend && <p>{legend}</p>}
                {footer && <span>{footer}</span>}
            </main>
        </Container>
    );
};

ProfileAboutCard.propTypes = {
    title: PropTypes.string,
    legend: PropTypes.string,
    footer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string,
    uri: PropTypes.string
};

export default ProfileAboutCard;
