import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { months } from 'constants/date';

const DateVertical = ({ dateString }) => {
    const getDay = () => {
        const eventDay = new Date(dateString);

        return `${months[eventDay.getMonth()].slice(0, 3)}.`;
    };

    return (
        <Container>
            <h3>{new Date(dateString).getDate()}</h3>
            <p>{getDay()}</p>
        </Container>
    );
};

DateVertical.propTypes = {
    dateString: PropTypes.string
};

export default DateVertical;
