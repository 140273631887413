const initialState = {
    message: null,
    open: false,
    variant: 'success'
};
export const alertActions = {
    SET_ALERT_MESSAGE: 'SET_ALERT_MESSAGE',
    SET_ALERT_OPEN: 'SET_ALERT_OPEN',
    SET_ALERT_VARIANT: 'SET_ALERT_VARIANT'
};
const alertReducer = (state = initialState, actions) => {
    const { type, payload } = actions;
    switch (type) {
        case alertActions.SET_ALERT_MESSAGE:
            return {
                ...state,
                message: payload
            };
        case alertActions.SET_ALERT_OPEN:
            return {
                ...state,
                open: payload
            };
        case alertActions.SET_ALERT_VARIANT:
            return {
                ...state,
                variant: payload
            };

        default:
            return state;
    }
};

export default alertReducer;
