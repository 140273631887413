import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    top: 100%;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 0;
    width: 224px;
    background: #ffffff;
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.2);
    border-radius: 0px 0px 16px 16px;
`;
