import PropTypes from 'prop-types';

export const LevelType = {
    id: PropTypes.number,
    name: PropTypes.string,
    progress: PropTypes.number,
    level: PropTypes.number,
    blocked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
};

export const LevelUserType = {
    id: PropTypes.number,
    level: PropTypes.string
};
