import { FormControl, InputLabel, Select } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(FormControl)`
    width: ${(props) => (props.width ? `${props.width}` : '100%')};
    > h1 {
        margin-bottom: 1rem;
    }
`;
export const SelectStyled = styled(Select)`
    background-color: #fff;
`;
export const Label = styled(InputLabel)`
    background-color: #fff;
    opacity: 0 - 1;
    padding: 0 0.4rem;
`;
export const Title = styled.p`
    margin-bottom: 1rem;
`;
