import CoursesList from 'components/organisms/lists/CoursesList';
import SmallEventList from 'components/organisms/lists/SmallEventList';
import React from 'react';
import { CourseType } from 'types/data/course';
import { EventType } from 'types/data/event';
import PropTypes from 'prop-types';
import { Container, Content } from './styles';

const HomeAside = ({ eventsShow, eventsTitle, events, coursesShow, coursesTitle, courses }) => {
    return (
        <Container>
            {eventsShow && (
                <Content>
                    <header>
                        <h3>{eventsTitle}</h3>
                    </header>
                    <main>
                        <SmallEventList events={events} emptyMessage="Nenhum evento encontrado" />
                    </main>
                </Content>
            )}
            {coursesShow && (
                <Content>
                    <header>
                        <h3>{coursesTitle}</h3>
                    </header>
                    <main>
                        <CoursesList emptyMessage="Você não está inscrito em nenhum curso" courses={courses} small />
                    </main>
                </Content>
            )}
        </Container>
    );
};

HomeAside.propTypes = {
    eventsShow: PropTypes.bool,
    eventsTitle: PropTypes.string,
    events: PropTypes.arrayOf(PropTypes.shape(EventType)),
    coursesShow: PropTypes.bool,
    coursesTitle: PropTypes.string,
    courses: PropTypes.arrayOf(PropTypes.shape(CourseType))
};

HomeAside.defaultProps = {
    eventsShow: true,
    eventsTitle: 'Eventos inscritos',
    events: [],
    coursesShow: true,
    coursesTitle: 'Cursos inscritos',
    courses: []
};

export default HomeAside;
