import React from 'react';

import Title from 'components/atoms/texts/Title';
import { Container, ListItemButtonStyled, ListStyled } from './styles';
import PropTypes from 'prop-types';
import { ListItemText } from '@mui/material';
import CompetenceList from '../../../molecules/lists/CompetenceList';

const CoursesAside = ({ active, competences, onClickType, onClickCompetence }) => {
    return (
        <Container>
            <Title title="Cursos" />
            <ListStyled component="nav" disablePadding>
                <ListItemButtonStyled className={active === 'todos' && 'active'} onClick={() => onClickType('todos')}>
                    Todos os Cursos
                </ListItemButtonStyled>
                <ListItemButtonStyled className={active === 'andamento' && 'active'} onClick={() => onClickType('andamento')}>
                    Cursos em andamento
                </ListItemButtonStyled>
                <ListItemButtonStyled className={active === 'concluidos' && 'active'} onClick={() => onClickType('concluidos')}>
                    Cursos concluídos
                </ListItemButtonStyled>
            </ListStyled>
            <CompetenceList competences={competences} onClickCompetence={onClickCompetence} />
        </Container>
    );
};

CoursesAside.propTypes = {
    active: PropTypes.string.isRequired,
    competences: PropTypes.array.isRequired,
    onClickType: PropTypes.func.isRequired,
    onClickCompetence: PropTypes.func.isRequired
};

export default CoursesAside;
