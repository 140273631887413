import styled from 'styled-components';

export const Container = styled.div`
    background-color: #fff;
    border-bottom: 1px solid #333;
    width: 100%;
    height: 3.5rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;

    display: flex;
    justify-content: center;
`;

export const Content = styled.div`
    max-width: 600px;
    padding: 1rem 1rem 1rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    > h2 {
        font-size: 1.1rem;
        color: var(--color-primary);
    }

    > button {
        color: var(--color-primary);
        padding: 0;
        margin: 0;
    }
`;
