import TabsHeader from 'components/molecules/headers/TabsHeader';
import React, { useEffect, useState } from 'react';
import FilterTitle from 'components/molecules/headers/FilterTitle';
import { getNextMonths } from 'utils/date';
import { getEvents, getFilterData } from 'services/events';
import { CircularProgress } from '@mui/material';
import EventsList from 'components/organisms/lists/EventsList';
import Meta from 'components/templates/Seo/Meta';
import { useDispatch, useSelector } from 'react-redux';
import ListCarousel from 'components/molecules/carousels/ListCarousel';
import { verifyEndOfPage } from 'utils/scroll';
import useSnackbar from 'hooks/useSnackbar';
import useWindowSize from 'hooks/useWindowSize';
import HomeLayout from 'components/templates/layouts/Pages/HomeLayout';
import HomeAside from 'components/organisms/containers/HomeAside';
import EventFilter from 'components/organisms/containers/EventFilter';
import TimelineCardEvent from 'components/molecules/cards/TimelineCardEvent';
import { months as newMonth } from '../../../constants/date';
import { getParticipatingEventsCourses } from 'services/home';
import Event from 'components/organisms/containers/Event';
import { HeaderTitle, HrContainer } from 'components/molecules/cards/TimelineCardEvent/styles';
import { useNavigate } from 'react-router-dom';
import useQueryParam from '../../../hooks/useQueryParam';
import competence from '../Perfil/Competencias/Competence';

const Eventos = ({ eventCard }) => {
    const [months, setMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(0);
    // const [tab, setTab] = useState('Todos de 2022');
    const [filter, setFilter] = useState({});

    const [events, setEvents] = useState([]);
    const [showedEvents, setShowedEvents] = useState([]);

    const [filterData, setFilterData] = useState({});
    const [selectableMonths, setSelectableMonths] = useState([]);
    const [scrolling, setScrolling] = useState(false);
    const [higherYItem, setHigherYItem] = useState(0);
    const [scrollY, setScrollY] = useState(0);
    const [participatingCourses, setParticipatingCourses] = useState([]);
    const [participatingEvents, setParticipatingEvents] = useState([]);
    const [eventsByMonth, setEventsByMonth] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const snackbar = useSnackbar();
    const auth = useSelector((state) => state.auth);
    const tab = useSelector((state) => state.event.filterTab);
    const queryParams = useQueryParam();

    const [loading, setLoading] = useState(true);
    const windowSize = useWindowSize();
    const setTab = (newValue) => {
        dispatch({ type: 'SET_FILTERTAB', payload: newValue });
    };
    const filterStructure = [
        {
            id: 'eventTypes',
            label: 'Tipo do Evento',
            type: 'options',
            options: filterData?.typeEvents || []
        },
        {
            id: 'competences',
            label: 'Competências',
            type: 'options',
            options: (filterData?.competences || []).sort((a, b) => a.description.localeCompare(b.description))
        }
    ];

    const tabs = [
        {
            value: 'Todos de 2022',
            label: 'Todos os eventos'
        },
        {
            value: 'Inscritos',
            label: 'Eventos Inscritos'
        }
    ];

    const getMonthsData = () => {
        const monthToday = new Date().getMonth();

        const monthsData = getNextMonths(monthToday).map((month, index) => {
            return {
                id: index + monthToday < 12 ? monthToday + index : index + monthToday - 12,
                text: month
            };
        });

        setSelectedMonth(monthToday);
        setMonths(monthsData);
    };

    const getEventsByMonth = () => {
        const eventsByMonth = [];
        let eventMonth;
        let eventYear;
        let key;

        showedEvents.forEach((event) => {
            eventMonth = new Date(event.initialDate).getMonth();
            eventYear = new Date(event.initialDate).getFullYear();
            key = `${eventMonth}-${eventYear}`;
            if (!eventsByMonth[key]) {
                eventsByMonth[key] = [];
            }

            eventsByMonth[key] = [...eventsByMonth[key], event];
        });
        setEventsByMonth(eventsByMonth);
    };

    useEffect(() => {
        getEventsByMonth();
    }, [showedEvents]);

    const getEventsData = async () => {
        if (!auth?.user?.studentId) {
            return;
        }

        await getEvents(auth.user.studentId)
            .then((response) => {
                setEvents(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Não foi possível carregar os eventos.', 'error');
            });
    };
    const handleLike = async (value) => {
        await likeEvent(value.id, auth?.user?.studentId, !value.like)
            .then(() => {
                snackbar(value ? 'Evento curtida com sucesso' : 'Evento descurtida com sucesso', 'success');
            })
            .catch((err) => {
                snackbar(err.message || 'Erro ao curtir evento', 'error');
            });
        getEventsData();
    };

    const getFilterOptions = async () => {
        await getFilterData()
            .then((response) => {
                setFilterData(response.data);
                if (queryParams.has('competence')) {
                    const competence = response.data.competences.find(
                        (competence) => competence.id === parseInt(queryParams.get('competence'))
                    );
                    setFilter({ competences: [competence.description] });
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Não foi possível carregar os filtros.', 'error');
            });
    };

    const getSelectableMonths = () => {
        const eventsByMonth = [];

        showedEvents.forEach((event) => {
            const eventMonth = new Date(event.initialDate).getMonth();

            if (eventsByMonth.includes(eventMonth)) {
                return;
            }

            eventsByMonth.push(eventMonth);
        });

        setSelectableMonths(eventsByMonth);
    };

    const filterEvents = () => {
        const subscribeValidation = (event) => {
            if (tab === 'Inscritos') {
                return event.registered === true;
            }

            return true;
        };

        const filterEventTypesValidation = (event) => {
            if (filter?.eventTypes && filter.eventTypes.length > 0) {
                return filter.eventTypes.includes(event.typeEvent);
            }

            return true;
        };

        const filterCompetencesValidation = (event) => {
            if (filter?.competences && filter.competences.length > 0) {
                return (
                    filter.competences.filter((filterCompetence) =>
                        event.competences.some((eventCompetence) => eventCompetence.desription === filterCompetence)
                    ).length > 0
                );
            }

            return true;
        };

        const eventsFiltered = events.filter(
            (event) => subscribeValidation(event) && filterEventTypesValidation(event) && filterCompetencesValidation(event)
        );
        setShowedEvents(eventsFiltered);
    };

    const verifyScroll = () => {
        if (scrolling) {
            return;
        }

        let offsetY = 0;
        let component = null;

        if (scrollY !== 0) {
            if (verifyEndOfPage(scrollY)) {
                setSelectedMonth(selectableMonths[selectableMonths.length - 1]);
                return;
            }
        }

        selectableMonths.forEach((monthIndex) => {
            component = document.getElementById(`month-${monthIndex}`);

            if (!component) {
                return;
            }

            offsetY = component.getBoundingClientRect().top;

            if (offsetY < 150 && offsetY > 0) {
                setSelectedMonth(monthIndex);
                return;
            }

            if (scrollY < higherYItem && selectableMonths.length > 0) {
                setSelectedMonth(selectableMonths[0]);
            }
        });
    };

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        setScrollY(currentScrollY);
    };

    const getHigherYItem = () => {
        const item = document.querySelector(`#month-${selectableMonths[0]}`);

        if (!item) {
            return;
        }

        setHigherYItem(item.getBoundingClientRect().top);
    };

    const verifySelectedMonth = () => {
        if (selectableMonths.length === 0) {
            setSelectedMonth(-1);
            return;
        }

        getHigherYItem();

        if (selectableMonths.includes(selectedMonth)) {
            return;
        }

        setSelectedMonth(selectableMonths[0]);
    };

    const handleMonthChange = (index) => {
        setScrolling(true);
        setSelectedMonth(index);

        const elemSelector = `div[data-initial-month="${(index + 1).toString().padStart(2, '0')}"]`;
        const elem = document.querySelector(elemSelector);
        elem.scrollIntoView({ behavior: 'smooth', block: 'center' });

        setTimeout(() => {
            setScrolling(false);
        }, 500);
    };

    useEffect(() => {
        getMonthsData();
        getEventsData();
        getFilterOptions();
        getParticipatingEventsCoursesData();
    }, [auth]);

    useEffect(() => {
        filterEvents();
    }, [events, tab, filter]);

    useEffect(() => {
        getSelectableMonths();
    }, [showedEvents]);

    useEffect(() => {
        verifySelectedMonth();
    }, [selectableMonths]);

    useEffect(() => {
        verifyScroll();
    }, [scrollY]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    const getParticipatingEventsCoursesData = async () => {
        if (!auth?.user?.studentId) {
            return;
        }
        await getParticipatingEventsCourses(auth.user.studentId)
            .then((response) => {
                setParticipatingEvents(response.events);
                setParticipatingCourses(response.courses);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar compromissos', 'error');
            });
    };

    const eventsListStyle = () => {
        let style = {
            display: 'flex',
            flexDirection: 'column',
            gap: 24
        };

        if (tab === 'Inscritos') {
            style.gridArea = 'CC';
            style.marginTop = 85;
        } else {
            style.gridArea = 'T';
        }

        return style;
    };

    const eventsAsideStyle = () => {
        if (tab === 'Inscritos') {
            return { display: 'none' };
        }
        return { gridArea: 'HA' };
    };

    const redirectToEvent = async () => {
        const path = window.location.pathname;
        const eventUrli = `/aluno/eventos${path ? `?returnUrl=${path}` : ''}`;

        navigate(eventUrli);
    };

    if (windowSize.mobile) {
        return (
            <>
                <Meta title="Eventos" description="Visualize os eventos que acontecerão." />

                <FilterTitle title="Eventos" filterStructure={filterStructure} filterValue={filter} setFilter={setFilter} />

                <TabsHeader tab={tab} setTab={setTab} tabs={tabs} />

                <ListCarousel
                    data={months}
                    selectedData={selectedMonth}
                    onItemClick={handleMonthChange}
                    selectableItems={selectableMonths}
                    reference="month"
                />

                {loading || events === undefined ? (
                    <CircularProgress />
                ) : (
                    <EventsList
                        events={showedEvents}
                        isFiltered={!!(filter?.eventTypes || filter?.competences)}
                        onlySubscribe={tab === 'Inscritos'}
                    />
                )}
            </>
        );
    }

    return (
        <>
            <Meta title="Eventos" description="Visualize os eventos que acontecerão." />

            <HomeLayout title="Eventos" marginTop="44px">
                <div style={{ gridArea: 'UA' }}>
                    <EventFilter
                        filterStructure={filterStructure}
                        filterValue={filter}
                        setFilter={setFilter}
                        groupCompetences={filterData?.groupCompetence || []}
                        tab={tab}
                        tabs={tabs}
                        setTab={dispatch}
                        redirectToEvent={redirectToEvent}
                    />
                </div>

                <div style={{ gridArea: 'CC' }}>
                    <ListCarousel
                        data={months}
                        selectedData={selectedMonth}
                        onItemClick={handleMonthChange}
                        selectableItems={selectableMonths}
                        reference="month"
                        containerWidth="100%"
                        containerMaxWidth="none"
                        noScroll={true}
                    />
                </div>

                <div style={eventsAsideStyle()}>
                    {/* <EventsList
                        events={participatingEvents}
                        isWeb={true}
                        eventTitle="Próximos eventos a participar"
                        // isFiltered={!!(filter?.eventTypes || filter?.competences)}
                        // onlySubscribe={tab === 'Inscritos'}
                    /> */}
                    <HomeAside eventsTitle="Próximos eventos a participar" events={participatingEvents} coursesShow={false} />
                </div>
                {eventCard ? (
                    <>
                        <HomeAside eventsTitle="Próximos eventos a participar" events={participatingEvents} coursesShow={false} />
                        <Event event={eventCard} />
                    </>
                ) : (
                    <div style={eventsListStyle()}>
                        {loading || (events === undefined && <CircularProgress />)}
                        {!loading &&
                            tab !== 'Inscritos' &&
                            Object.keys(eventsByMonth).map((month) => {
                                return (
                                    <>
                                        <HeaderTitle>{`${newMonth[month.split('-')[0]]} ${month.split('-')[1]}`}</HeaderTitle>
                                        {eventsByMonth[month].map((post) => (
                                            <TimelineCardEvent
                                                key={post.id}
                                                post={post}
                                                headerTitle={`${newMonth[month.split('-')[0]]} ${month.split('-')[1]}`}
                                            />
                                        ))}
                                        <HrContainer>
                                            <hr></hr>
                                        </HrContainer>
                                    </>
                                );
                            })}
                        {showedEvents.length === 0 && <h1>Sem registros à exibir</h1>}
                        {!loading && tab === 'Inscritos' && (
                            <EventsList
                                events={showedEvents}
                                isFiltered={!!(filter?.eventTypes || filter?.competences)}
                                onlySubscribe={tab === 'Inscritos'}
                            />
                        )}
                    </div>
                )}
            </HomeLayout>
        </>
    );
};

export default Eventos;
