import styled from 'styled-components';

export const DesktopContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 48px;

    > aside {
        width: 280px;
        height: auto;
        align-self: flex-start;

        position: sticky !important;
        top: calc(var(--navbar-height) + 1rem);

        background-color: white;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
    }
    > main {
        width: 783px;
    }
`;
export const DownloadSection = styled.div`
    &:not(:first-of-type) {
        margin-top: 10px;
    }
`;
export const Container = styled.div`
    width: 100%;

    > header {
        width: 100%;

        > * {
            margin-bottom: 0.5rem;
        }

        > h3 {
            font-size: 1rem;
            color: var(--color-text);
        }

        > h1 {
            font-size: 1.6rem;
            color: #000;
        }
    }

    > main {
        > * {
            margin-bottom: 1rem;
        }

        font-size: 1rem;
    }

    > footer {
        margin-top: 2rem;

        > * {
            margin-bottom: 0.5rem;
        }

        > header {
            display: flex;
            flex-direction: column;

            > h3 {
                font-size: 1.2rem;
            }

            > aside {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin-top: -0.5rem;

                > * {
                    width: auto;
                }
                > p {
                    align-self: flex-start;
                    margin-top: 1.3rem;
                    font-size: 1rem;
                    margin-left: 0.3rem;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            }
        }

        button {
            margin-top: 1rem;
        }
    }
`;
