import { api } from 'services/api';

const createEnterpriseOpportunity = async (data) => {
    const completeData = {
        ...data,
        idType: 2
    };

    const response = await api
        .post(`/publication`, completeData)
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const getEnterpriseOpportunityById = async (id) => {
    const response = await api
        .get(`/publication/${id}`)
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const updateEnterpriseOpportunity = async (data, id) => {
    const completeData = {
        ...data,
        idType: 2
    };

    const response = await api
        .put(`/publication/${id}`, completeData)
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const deleteEnterpriseOpportunity = async (id) => {
    const response = await api
        .delete(`/publication/${id}`)
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

export { createEnterpriseOpportunity, updateEnterpriseOpportunity, deleteEnterpriseOpportunity, getEnterpriseOpportunityById };
