import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import MenuNavbar from 'components/organisms/layout/navbars/MenuNavbar';
import useQueryParam from 'hooks/useQueryParam';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { logged } from 'services/auth';

const MenuLayout = ({ children, title, returnUrlDefault }) => {
    const query = useQueryParam();
    const router = useSelector((state) => state.router);
    const location = useLocation();
    const dispatch = useDispatch();
    const [returnUrl, setReturnUrl] = useState('');
    const auth = useSelector((state) => state.auth);

    const checkAuth = async () => {
        if (auth?.user?.id) {
            return;
        }

        const loggedData = await logged();

        if (!loggedData) {
            navigate('/login');
            return;
        }

        dispatch({
            type: 'SET_AUTH',
            payload: {
                email: loggedData.email,
                name: loggedData.name,
                id: loggedData.id,
                studentId: loggedData.id_student,
                enterpriseId: loggedData?.id_enterprise,
                image: loggedData.url_image,
                level: loggedData?.levelExperience
            }
        });
    };

    const getReturnUrl = () => {
        const returnUrl = query.get('returnUrl');

        if (returnUrl) {
            return returnUrl;
        }

        return null;
    };

    const checkRouterNavbar = () => {
        if (!router?.returnUrl || !router?.returnText || !router?.returnUrl) {
            setReturnUrl(returnUrlDefault);
            return;
        }

        if (router.returnPath !== location.pathname) {
            setReturnUrl(returnUrlDefault);
            return;
        }

        const returnUrl = getReturnUrl() || router.returnUrl;
        setReturnUrl(returnUrl);
    };

    useEffect(() => {
        checkRouterNavbar();
    }, [router, window.location.pathname]);

    useEffect(() => {
        checkAuth();
    }, []);

    return (
        <Container>
            <MenuNavbar url={returnUrl} title={title} />
            <main>{children}</main>
        </Container>
    );
};

MenuLayout.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    returnUrlDefault: PropTypes.string
};

export default MenuLayout;
