import TabsHeader from 'components/molecules/headers/TabsHeader';
import SubSection from 'components/molecules/structure/SubSection';
import PfaList from 'components/organisms/lists/PfaList';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import moment from 'moment';

const PfaContents = ({ courses, events, participant }) => {
    const [activeTab, setActiveTab] = useState('events');
    const path = window.location.pathname;

    const eventErrorMessage = `${participant ? 'O participante' : 'Você'} não está inscrito em nenhum evento ainda.`;
    const courseErrorMessage = `${participant ? 'O participante' : 'Você'} não está inscrito em nenhum curso ainda.`;

    const tabs = [
        {
            label: 'Eventos',
            value: 'events'
        },
        {
            label: 'Cursos',
            value: 'courses'
        }
    ];

    return (
        <SubSection title="Participação na Comunidade PFA:" borderTop marginTop>
            <Container>
                <TabsHeader tabs={tabs} setTab={setActiveTab} tab={activeTab} />

                {
                    {
                        courses: (
                            <PfaList
                                data={courses?.map((course) => ({
                                    label: 'Curso:',
                                    title: course.title,
                                    legend: course.final_date
                                        ? `Concluído em ${moment(course.final_date).format('DD/MM/YYYY')}`
                                        : `Iniciado em ${moment(course.initial_date).format('DD/MM/YYYY')}`,
                                    href: `/aluno/cursos/curso/${course.id}${path && `?returnUrl=${path}`}`,
                                    status: course?.final_date ? 'Concluído' : 'Em andamento'
                                }))}
                                errorMessage={courseErrorMessage}
                            />
                        ),
                        events: (
                            <PfaList
                                data={events?.map((event) => ({
                                    label: 'Evento:',
                                    title: event.title,
                                    legend: `Data: ${moment(event.initial_date).format('DD/MM/YYYY')}`,
                                    href: `/aluno/eventos/${event.id}${path && `?returnUrl=${path}`}`
                                }))}
                                errorMessage={eventErrorMessage}
                            />
                        )
                    }[activeTab]
                }
            </Container>
        </SubSection>
    );
};

PfaContents.propTypes = {
    courses: PropTypes.array,
    events: PropTypes.array,
    participant: PropTypes.bool
};

export default PfaContents;
