import React from 'react';
import PropTypes from 'prop-types';
import { Container, ContainerDesktop } from './styles';
import { Link } from 'react-router-dom';
import Svg from 'components/atoms/structure/Svg';
import { getSystemImageUrl } from 'utils/assets';
import useWindowSize from 'hooks/useWindowSize';

const LibrarySearchCard = ({ type, item }) => {
    const windowSize = useWindowSize();

    const getUri = () => {
        if (type === 'format') {
            return `/aluno/biblioteca/formato/${item.id}`;
        }

        if (type === 'competence') {
            return `/aluno/biblioteca/competencia/${item.id}`;
        }

        return '/aluno/biblioteca';
    };
    if (windowSize.mobile) {
        if (type === 'format') {
            return (
                <Link to={getUri()}>
                    <Container color="#6B9D4A">
                        <span>{item.value}</span>
                        <aside>
                            {
                                {
                                    1: <Svg url={getSystemImageUrl('Video.svg')} width="42" height="42" />,
                                    2: <Svg url={getSystemImageUrl('Arquivo.svg')} width="42" height="42" />,
                                    3: <Svg url={getSystemImageUrl('Imagem.svg')} width="42" height="42" />,
                                    4: <Svg url={getSystemImageUrl('Curso.svg')} width="42" height="42" />,
                                    5: <Svg url={getSystemImageUrl('Evento.svg')} width="42" height="42" />,
                                    6: <Svg url={getSystemImageUrl('Audio.svg')} width="42" height="42" />,
                                    7: <Svg url={getSystemImageUrl('PDF.svg')} width="42" height="42" />,
                                    8: <Svg url={getSystemImageUrl('Tabela.svg')} width="42" height="42" />,
                                    9: <Svg url={getSystemImageUrl('Arquivo.svg')} width="42" height="42" />
                                }[item?.id]
                            }
                        </aside>
                    </Container>
                </Link>
            );
        }

        return (
            <Link to={getUri()}>
                <Container color={item.color || '#6B9D4A'}>
                    <span>{item.value}</span>
                    <aside>
                        <img src={item.icon} alt={item.value} />
                    </aside>
                </Container>
            </Link>
        );
    }
    if (type === 'format') {
        return (
            <Link to={getUri()}>
                <ContainerDesktop color="#6B9D4A">
                    <span>{item.value}</span>
                    <aside>
                        {
                            {
                                1: <Svg url={getSystemImageUrl('Video.svg')} width="42" height="42" />,
                                2: <Svg url={getSystemImageUrl('Arquivo.svg')} width="42" height="42" />,
                                3: <Svg url={getSystemImageUrl('Imagem.svg')} width="42" height="42" />,
                                4: <Svg url={getSystemImageUrl('Curso.svg')} width="42" height="42" />,
                                5: <Svg url={getSystemImageUrl('Evento.svg')} width="42" height="42" />,
                                6: <Svg url={getSystemImageUrl('Audio.svg')} width="42" height="42" />,
                                7: <Svg url={getSystemImageUrl('PDF.svg')} width="42" height="42" />,
                                8: <Svg url={getSystemImageUrl('Tabela.svg')} width="42" height="42" />,
                                9: <Svg url={getSystemImageUrl('Arquivo.svg')} width="42" height="42" />
                            }[item?.id]
                        }
                    </aside>
                </ContainerDesktop>
            </Link>
        );
    }

    return (
        <Link to={getUri()}>
            <ContainerDesktop color={item.color || '#6B9D4A'}>
                <span>{item.value}</span>
                <aside>
                    <img src={item.icon} alt={item.value} />
                </aside>
            </ContainerDesktop>
        </Link>
    );
};

LibrarySearchCard.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.string,
        icon: PropTypes.string,
        color: PropTypes.string
    }).isRequired,
    type: PropTypes.string.isRequired
};

export default LibrarySearchCard;
