import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    margin-bottom: 2rem;
    position: relative;

    > header {
        > h3 {
            font-weight: 700;
            font-size: 1.2rem;
            color: #373848;
            text-transform: uppercase;
        }

        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;
    }

    > main {
        > * {
            margin-bottom: 1rem;
        }
    }
`;
