import styled from 'styled-components';

export const Container = styled.div`
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
    border-radius: 16px;

    > header {
        padding: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        > aside {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
        }
    }

    > main {
        padding: 1rem;
        padding-top: 0;
        width: 100%;

        > footer {
            margin-top: 0.5rem;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
    }

    .divider {
        border-top: 1px solid #e0e0e0;
    }

    > footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;

        > aside {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 27px;

            .footer-aside__item {
                display: flex;
                align-items: center;
            }

            p {
                font-size: 0.9rem;
                height: 100%;
                display: flex;
                align-items: center;
            }
        }

        > main {
            font-size: 0.9rem;
        }
    }
`;

export const UserRedirect = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    ${({ pointer }) => pointer && 'cursor: pointer;'}

    > h3 {
        margin-left: 1rem;
        font-size: 1rem;
        color: var(--color-text);
    }
`;

export const ImageContainer = styled.div`
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    border-radius: 8px;

    > img {
        background-color: #eee;

        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-position: center;
        background-size: cover;
        object-fit: cover;
        background-color: #eee;
    }
`;

export const DescriptionText = styled.div`
    p {
        margin-top: 1rem;
    }

    font-size: 0.9rem !important;
    line-height: 1.2rem !important;
    color: var(--color-black) !important;
    background-color: transparent !important;
    font-family: 'Myriad Pro', sans-serif !important;

    span {
        font-size: 0.9rem !important;
        color: var(--color-black) !important;
        font-family: 'Myriad Pro', sans-serif !important;
        background-color: transparent !important;
        line-height: 1.2rem !important;
    }

    img {
        width: 100%;
    }
`;
