import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const Options = styled.div`
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background: #fff;
    overflow: hidden;

    > main {
        border: solid 1px red !important;

        > label {
            font-family: 'Myriad Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 130%;
            padding: 16px;
            border-bottom: 1px solid #e0e0e0;
            cursor: pointer;
            display: flex;
            gap: 12px;
        }
    }
`;

export const OptionsItem = styled.div`
    font-family: 'Myriad Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
    display: flex;
    gap: 12px;
    border-left: solid 4px ${(props) => props.borderLeft || 'transparent'};

    &.title {
        font-weight: 600;
        font-size: 16px;
        cursor: default;
    }

    &.selected {
        background: #e7f5e6;
    }

    label {
        padding: 0 !important;
        margin: 0 !important;
        width: 100%;
        gap: 12px;

        * {
            padding: 0 !important;
            margin: 0 !important;
        }
    }
`;

export const BlankItems = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;

    > div {
        display: flex;
        gap: 11px;
        align-items: center;

        > .bullet {
            width: 16px;
            height: 16px;
            border-radius: 16px;
        }
    }
`;
