import Drawer from 'components/organisms/layout/modals/Drawer';
import FavoriteList from 'components/organisms/lists/FavoriteList';
import useFavoriteList from 'hooks/useFavoriteList';
import useSnackbar from 'hooks/useSnackbar';
import React from 'react';
import { useSelector } from 'react-redux';
import { favoriteCourse } from 'services/courses';
import { favoriteLibrary } from 'services/library';

import { Container } from './styles';

const ListFavoriteDrawer = () => {
    const favoriteList = useSelector((state) => state.favoriteList);
    const hook = useFavoriteList();
    const snackbar = useSnackbar();

    const selectedContent = favoriteList.content;
    const selectedItem = favoriteList.selectedItem;

    const showSuccessMessageAndSave = (item, removed) => {
        if (removed) {
            hook.selectItem(null);
            callCallback(null);
            snackbar(`Item removido da lista ${item.description} com sucesso!`, 'success');
            return;
        }

        hook.selectItem(item);
        callCallback(item);

        if (selectedItem && selectedItem?.id !== item.id) {
            snackbar(`Item movido da lista ${selectedItem?.description} para ${item?.description}`, 'success');
            return;
        }

        snackbar(`Item adicionado na lista de ${item.description} com sucesso!`, 'success');
    };

    const callCallback = (newItem) => {
        favoriteList.callback(newItem);
    };

    const selectFavoriteList = async (item, removed) => {
        if (selectedContent.type === 'course') {
            await favoriteCourse(selectedContent.id, item.id)
                .then(() => {
                    hook.selectItem(item);
                    showSuccessMessageAndSave(item, removed);
                })
                .catch(() => {});

            return;
        }

        if (selectedContent.type === 'library') {
            await favoriteLibrary(selectedContent.id, item.id)
                .then(() => {
                    hook.selectItem(item);
                    showSuccessMessageAndSave(item, removed);
                })
                .catch(() => {});

            return;
        }
    };

    return (
        <Drawer title="Favoritar" active={favoriteList.listModal} close={() => hook.closeListModal()} open={() => hook.openListModal()}>
            <Container>
                <FavoriteList selectFavoriteList={selectFavoriteList} />
            </Container>
        </Drawer>
    );
};

export default ListFavoriteDrawer;
