import React from 'react';
import { Alert, Snackbar as MUISnackBar } from '@mui/material';
import { useSelector } from 'react-redux';
import useSnackbar from 'hooks/useSnackbar';

const Snackbar = () => {
    const snackbar = useSelector((state) => state.snackbar);
    const hook = useSnackbar();

    const cleanSnackbar = () => {
        hook('', 'success');
    };

    return (
        <MUISnackBar
            open={snackbar?.text?.length > 0}
            autoHideDuration={4000}
            message={snackbar?.text || 'Carregando...'}
            onClose={() => cleanSnackbar()}
        >
            <Alert onClose={() => cleanSnackbar()} severity={snackbar?.type || 'success'} sx={{ width: '100%' }}>
                <>{snackbar?.text || ''}</>
            </Alert>
        </MUISnackBar>
    );
};

export default Snackbar;
