import React, { useEffect, useState } from 'react';
import { CoursesGrid, DesktopContainer, PageDescription, PageTitle } from './styles';
import PropTypes from 'prop-types';
import auth from '../../../../routes/paths/auth';
import CoursesCompetence from '../../../molecules/containers/CoursesCompetence';
import GridCourseCard from '../../../molecules/cards/GridCourseCard';
import CertificateModal from '../../../molecules/modals/CertificateModal';
import { useSelector } from 'react-redux';
import useCourses from '../../../../hooks/useCourses';

const Courses = ({ currentList, competences, courses, participatingCourses, concludedCourses }) => {
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [certificateOpen, setCertificateOpen] = useState(false);
    const { coursesReducer } = useCourses();

    competences.sort((a, b) => {
        const currentLevelData = Number(b.currentLevel) - Number(a.currentLevel);

        if (currentLevelData === 0) {
            return Number(b.percentNextLevel) - Number(a.percentNextLevel);
        }

        return currentLevelData;
    });
    if (!competences || competences.length === 0 || !courses || courses.length === 0) {
        return null;
    }

    const handleClickCertificate = (course) => {
        setSelectedCourse(course);
        setCertificateOpen(true);
    };
    switch (currentList) {
        case 'todos':
            return (
                <DesktopContainer>
                    <PageTitle>Aprenda com os nossos cursos e avance em sua carreira</PageTitle>
                    {competences?.length > 0 && !auth?.user?.enterpriseId && (
                        <>
                            {competences.map((competence, index) => {
                                const description = competence.description
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '')
                                    .replaceAll(' ', '_')
                                    .toLowerCase();
                                const courseList = courses[description];
                                if (courseList && courseList.data && courseList.data.length > 0) {
                                    return (
                                        <CoursesCompetence key={`competence-${index}`} competence={competence} courses={courseList.data} />
                                    );
                                }
                            })}
                        </>
                    )}
                </DesktopContainer>
            );
        case 'andamento':
            return (
                <DesktopContainer>
                    <PageTitle>Continue avançando no seu aprendizado!</PageTitle>
                    {participatingCourses.length > 0 ? (
                        <CoursesGrid>
                            {participatingCourses.map((course, index) => (
                                <GridCourseCard key={`participatingCourse-${index}`} variant={'participating'} course={course} />
                            ))}
                        </CoursesGrid>
                    ) : (
                        <p className={'message'}>Você não está participando de nenhum curso. Explore as competencias ao lado.</p>
                    )}
                </DesktopContainer>
            );
        case 'concluidos':
            return (
                <>
                    <DesktopContainer>
                        <PageTitle>Cursos que você concluiu!</PageTitle>
                        <PageDescription>
                            Sua lista de cursos que você finalizou e evoluiu junto com o PFA. Sempre reveja os conteúdos ensinados.
                            <br />A melhoria é contínua :)
                        </PageDescription>
                        {!auth?.user?.enterpriseId && (
                            <>
                                {competences.map((competence, index) => {
                                    const description = competence.description
                                        .normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, '')
                                        .replaceAll(' ', '_')
                                        .toLowerCase();
                                    return (
                                        <CoursesCompetence
                                            key={`competence-${index}`}
                                            competence={competence}
                                            courses={concludedCourses}
                                            cardVariant={'concluded'}
                                            iconOnly={true}
                                        />
                                    );
                                })}
                            </>
                        )}
                    </DesktopContainer>
                    <CertificateModal open={coursesReducer.openCertificateModal} course={coursesReducer.selectedCourse} />
                </>
            );
    }
};
Courses.propTypes = {
    currentList: PropTypes.string.isRequired,
    competences: PropTypes.any.isRequired,
    courses: PropTypes.object.isRequired,
    participatingCourses: PropTypes.array.isRequired,
    concludedCourses: PropTypes.array.isRequired
};
export default Courses;
