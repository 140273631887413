export const initialState = {
    filterTab: 'Todos de 2022'
};

const eventReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_FILTERTAB':
            return {
                ...state,
                filterTab: actions.payload
            };

        default:
            return state;
    }
};

export default eventReducer;
