import Svg from 'components/atoms/structure/Svg';
import ModalCard from 'components/organisms/layout/modals/ModalCard';
import React from 'react';
import { getSystemImageUrl } from 'utils/assets';
import PropTypes from 'prop-types';

import { Container, ImageContainer } from './styles';
import useFeedback from 'hooks/useFeedback';
import { FeedbackType } from 'types/components/feedback';

const FinishedLibraryModal = ({ feedback }) => {
    const hook = useFeedback();
    const { points } = feedback;

    return (
        <ModalCard open={true} title="Material Concluído" handleClose={hook.closeFeedback}>
            <Container>
                <h3>Super!</h3>
                <p>Você conseguiu finalizar um material e ganhou:</p>

                <ImageContainer>
                    <Svg url={getSystemImageUrl('Biblioteca=Default.svg')} width="75" height="68" image />
                </ImageContainer>
                {points && <h2>+{points} pontos</h2>}
                <p>
                    para cada competência relacionada. <br /> Continue assim: Sempre avançando!
                </p>
            </Container>
        </ModalCard>
    );
};

FinishedLibraryModal.propTypes = {
    feedback: PropTypes.shape(FeedbackType).isRequired
};

export default FinishedLibraryModal;
