import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    color: var(--color-text);
    margin-bottom: 0.2rem;

    > aside {
        color: #999;
        margin-right: 0.5rem;
    }

    > span {
        font-size: 0.9rem;
    }
`;
