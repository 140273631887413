import Button from 'components/molecules/buttons/Button';
import React, { useEffect, useState } from 'react';
import { getSystemImageUrl } from 'utils/assets';
import Carousel from 'components/molecules/functionalities/Carousel';

import { Container, Item } from './styles';
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const navigate = useNavigate();

    const sections = [
        {
            image: getSystemImageUrl('bemvindo1.svg'),
            title: 'Bem vindo ao PFA!',
            text: 'Aqui você viverá uma jornada descomplicada até o ingresso no mercado de trabalho no agronegócio.'
        },
        {
            image: getSystemImageUrl('bemvindo2.svg'),
            text: 'Faça cursos e participe e eventos para desenvolver competências fundamentais para o mercado agro!'
        },
        {
            image: getSystemImageUrl('bemvindo3.svg'),
            text: 'Para começar, não deixe de assitir a Aula Magna sobre Comunidade Profissional do Futuro do Agronegócio.'
        }
    ];

    useEffect(() => {
        if (slideIndex === sections.length) {
            navigate('/aluno/minha-conta');
        }
    }, [slideIndex]);

    return (
        <Container>
            <header>
                <Carousel slide={slideIndex} draggable={false} cellSpacing={0}>
                    {sections.map((section, index) => (
                        <Item key={index}>
                            <img src={section.image} alt="Bem vindo ao PFA" />
                            {section?.title && <h2>{section?.title}</h2>}

                            <p>{section.text}</p>
                        </Item>
                    ))}
                </Carousel>
            </header>
            <footer>
                <Button
                    color="secondary"
                    onClick={() => {
                        setSlideIndex((prevState) => prevState + 1);
                    }}
                    width="100%"
                >
                    Continuar
                </Button>
            </footer>
        </Container>
    );
};

export default Welcome;
