import React, { useEffect, useState } from 'react';

import FormMaker from 'components/organisms/layout/form/FormMaker';
import { Validator } from 'utils/validators';
import useWindowSize from 'hooks/useWindowSize';
import { getResource, sendHelp } from 'services/help';
import useSnackbar from 'hooks/useSnackbar';
import { useNavigate } from 'react-router-dom';

const HelpForm = () => {
    const [startValue, setStartValue] = useState({});
    const [options, setOptions] = useState({});
    const windowSize = useWindowSize();
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    useEffect(() => {
        getResourceHelp();
    }, []);

    const getResourceHelp = async () => {
        const data = await getResource().then((response) => response);
        setOptions(data.data);
    };
    const formSubmit = async (values) => {
        const data = new FormData();
        data.append('idSubject', values.about);
        data.append('description', values.message);
        values.file && data.append('file', values.file);
        // console.log('valores enviados-> ', values);
        // alert('Integrar com API (Ler os dados sendo enviados pelo console');
        await sendHelp(data)
            .then((resp) => {
                snackbar(resp.data.success, 'success');
                setStartValue(null);
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            })
            .catch((e) => {
                snackbar(e.message, 'error');
            });
    };

    const form = [
        [
            {
                id: 'about',
                label: 'Selecione',
                type: 'dropdown',
                descriptionLabel: '<b>Assunto:</b>',
                initialValue: startValue?.idState,
                validation: Validator.number().required('O assunto é obrigatório'),
                options: options?.subject || []
            }
        ],
        [
            {
                id: 'message',
                label: 'Escreva sua mensagem',
                descriptionLabel: '<b>Mensagem:</b>',
                type: 'textArea',
                validation: Validator.string().min(10, 'A mensagem deve possuir 10 ou mais caracteres').required('Mensagem é obrigatória')
            }
        ],
        [
            {
                id: 'file',
                label: 'Anexar',
                descriptionLabel: '<b>Anexar arquivo</b> (até 300kb)',
                type: 'buttonAttachment'
            }
        ]
    ];

    return (
        <FormMaker
            data={form}
            formSubmit={formSubmit}
            create
            itemName=""
            positionRight={true}
            submitText="Enviar"
            width={windowSize.mobile ? '100%' : '20%'}
        />
    );
};
HelpForm.propTypes = {};

export default HelpForm;
