import React from 'react';
import { Container, Content } from './styles';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import FullWidthContainer from 'components/atoms/structure/FullWidthContainer';

const MenuNavbar = ({ url, title }) => {
    const navigate = useNavigate();

    return (
        <FullWidthContainer background="#fff">
            <Container>
                <Content>
                    <h2>{title}</h2>
                    <IconButton onClick={() => navigate(url)}>
                        <Close color="primary" />
                    </IconButton>
                </Content>
            </Container>
        </FullWidthContainer>
    );
};

MenuNavbar.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
};

export default MenuNavbar;
