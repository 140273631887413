import React, { useEffect, useState } from 'react';

import { Slider, VimeoPlayer, Slides } from './styles';
import PropTypes from 'prop-types';
import axios from 'axios';

const TimelineVimeoContent = ({ videos }) => {
    const [vimeos, setVimeos] = useState([]);
    const handleGetVimeo = () => {
        if (videos && videos.length > 0) {
            videos.map((learningObject) => {
                axios
                    .get(`https://vimeo.com/api/oembed.json?url=${learningObject.url}`)
                    .then((resp) => {
                        setVimeos((vimeoInfo) => [...vimeoInfo, resp.data]);
                    })
                    .catch((error) => {
                        console.log(error.response);
                    });
            });
        }
    };
    useEffect(() => {
        handleGetVimeo();
    }, []);

    if (!vimeos || vimeos.length === 0) {
        return null;
    }
    if (vimeos.length === 1) {
        const vimeo = vimeos[0];
        return <VimeoPlayer dangerouslySetInnerHTML={{ __html: vimeo.html }} />;
    }
    return (
        <Slider>
            <Slides>
                {vimeos.map((vimeo, index) => (
                    <VimeoPlayer key={`vimeo-${index}`} dangerouslySetInnerHTML={{ __html: vimeo.html }} />
                ))}
            </Slides>
        </Slider>
    );
};

TimelineVimeoContent.propTypes = {
    vimeos: PropTypes.array
};

export default TimelineVimeoContent;
