import { api } from 'services/api';
import { parsedCourses } from 'services/courses';
import { parsedEvents } from 'services/events';
import { postLogError } from '../logErrors/index';

const getLibraryParameters = async () => {
    const response = await api
        .get(`/get_resource_library`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    if (response.status !== 200) {
        return {
            data: response.data,
            status: response.status
        };
    }

    return {
        data: response.data,
        status: response.status
    };
};

const getLibraryData = async (filter, id, description = '') => {
    const response = await api
        .get(`/get_library_client`, {
            params: {
                idTypeLearningObject: filter === 'formato' ? id : '',
                idCompetence: filter === 'competencia' ? id : '',
                description
            }
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    const course = parsedCourses(response.data?.course || []);
    const event = parsedEvents(response.data?.event || []);

    const library = response.data?.learningObject?.map((item) => {
        return {
            ...item,
            isFavorite: item?.isFavorite
                ? {
                      description: item?.isFavorite?.descriptionFavoriteList,
                      id: Number(item?.isFavorite?.idFavoriteList)
                  }
                : null
        };
    });

    return {
        data: {
            learningObjects:
                library?.sort((a, b) => {
                    const x = a.library.title.toLowerCase();
                    const y = b.library.title.toLowerCase();
                    return x < y ? -1 : x > y ? 1 : 0;
                }) || [],
            courses:
                course?.sort((a, b) => {
                    const x = a.title.toLowerCase();
                    const y = b.title.toLowerCase();
                    return x < y ? -1 : x > y ? 1 : 0;
                }) || [],
            events:
                event?.sort((a, b) => {
                    const x = a.title.toLowerCase();
                    const y = b.title.toLowerCase();
                    return x < y ? -1 : x > y ? 1 : 0;
                }) || []
        },
        status: response.status
    };
};

const searchLibraryData = async (params = {}) => {
    const response = await api.get('/library', { params });

    return {
        data: response.data,
        status: response.status
    };
};

const getLibraryById = async (id) => {
    const response = await api
        .get(`/library/${id}`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: parseLibrary(response.data),
        status: response.status
    };
};

const likeLibrary = async (idStudent, idLibrary, like) => {
    const response = await api
        .post(`/like_library`, {
            idStudent,
            idLibrary,
            like
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const timeStopLibrary = async (idLibrary, idStudent, timeStop) => {
    const response = await api
        .post(`/time_stop_library`, {
            idLibrary,
            idStudent,
            timeStop
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const favoriteLibrary = async (libraryId, idFavoriteList) => {
    const response = await api
        .post(`/favorite_library`, {
            idLibrary: libraryId,
            idFavoriteList: Number(idFavoriteList)
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    return {
        data: response.data,
        status: response.status
    };
};

const seeLibrary = async (idStudent, idLibrary) => {
    const response = await api
        .post(`/student_library`, {
            idLibrary,
            idUser: idStudent
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const parseLibrary = (library) => {
    return {
        id: library?.id,
        title: library?.title,
        description: library?.description,
        point: library?.point,
        created_at: library?.created_at,
        updated_at: library?.updated_at,
        competences: library?.competence?.map((item) => {
            return {
                id: item?.id,
                description: item?.desription,
                color: item?.color
            };
        }),
        post: library?.post,
        time: library?.time,
        learningObject: library?.learningObject?.length > 0 ? library?.learningObject[0] : null,
        liked: library?.studentLiked,
        likes: library?.libraryLikes,
        timeStop: library?.time_stop,
        isViewed: library?.isView,
        isFavorite: library?.isFavorite
            ? {
                  description: library?.isFavorite?.descriptionFavoriteList,
                  id: Number(library?.isFavorite?.idFavoriteList)
              }
            : null,
        publication: library?.publication,
        publicationCommentsTotal: library?.publicationCommentsTotal || 0
    };
};

export {
    getLibraryParameters,
    getLibraryData,
    searchLibraryData,
    getLibraryById,
    likeLibrary,
    timeStopLibrary,
    seeLibrary,
    favoriteLibrary
};
