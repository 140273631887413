import MobileNavbar from '../../../../organisms/layout/navbars/MobileNavbar';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Content, LoadingContainer } from './styles';
import BottomNavbar from 'components/organisms/layout/navbars/BottomNavbar';
import { logged } from 'services/auth';
import { useDispatch } from 'react-redux';
import SubNavbar from 'components/organisms/layout/navbars/SubNavbar';
import useQueryParam from 'hooks/useQueryParam';
import { loadTotalNotifications } from 'redux/reducers/notificationsReducer';
import Snackbar from 'components/molecules/alerts/Snackbar';
import FavoriteDrawers from 'components/organisms/containers/FavoriteDrawers';
import FeedbackModals from 'components/organisms/containers/FeedbackModals';
import useWindowSize from 'hooks/useWindowSize';
import { CircularProgress } from '@mui/material';
import DesktopNavbar from 'components/organisms/layout/navbars/DesktopNavbar';
import Alert from '../../../../molecules/alerts/Alert';

const DashboardLayout = ({ hiddenBottomNavbar }) => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const router = useSelector((state) => state.router);
    const [subNavbarData, setSubNavbarData] = useState(null);
    const query = useQueryParam();
    const windowSize = useWindowSize();

    const checkAuth = async () => {
        if (auth?.user?.id) {
            return;
        }

        const loggedData = await logged();

        if (!loggedData) {
            navigate('/login');
            return;
        }

        dispatch({
            type: 'SET_AUTH',
            payload: {
                email: loggedData.email,
                name: loggedData.name,
                id: loggedData.id,
                studentId: loggedData?.id_student,
                enterpriseId: loggedData?.id_enterprise,
                image: loggedData.url_image,
                level: loggedData?.levelExperience
            }
        });
    };

    const getReturnUrl = () => {
        const returnUrl = query.get('returnUrl');

        if (returnUrl) {
            return returnUrl;
        }

        return null;
    };

    const getReturnName = () => {
        const returnName = query.get('returnName');

        if (returnName) {
            return returnName;
        }

        return null;
    };

    const checkRouterNavbar = () => {
        if (!router?.returnUrl || !router?.returnText) {
            setSubNavbarData(null);
            return;
        }

        if (router.returnPath !== location.pathname) {
            setSubNavbarData(null);
            return;
        }

        const returnUrl = getReturnUrl() || router.returnUrl;

        setSubNavbarData({
            title: getReturnName() || router.returnText,
            url: returnUrl
        });
    };

    const loadTotalNotificationsCall = async () => {
        loadTotalNotifications(dispatch);

        setInterval(() => {
            loadTotalNotifications(dispatch);
        }, 60000);
    };

    useEffect(() => {
        checkAuth();
        loadTotalNotificationsCall();
    }, []);

    useEffect(() => {
        checkRouterNavbar();
    }, [router, window.location.pathname]);

    if (windowSize.mobile === undefined) {
        return (
            <LoadingContainer>
                <CircularProgress />
            </LoadingContainer>
        );
    }

    if (windowSize.mobile) {
        return (
            <Container>
                {subNavbarData ? <SubNavbar title={subNavbarData.title} url={subNavbarData.url} /> : <MobileNavbar />}
                <Content hiddenBottomNavbar={hiddenBottomNavbar}>
                    <Outlet />
                    <Snackbar />
                    <FavoriteDrawers />
                    <FeedbackModals />
                </Content>
                {hiddenBottomNavbar ? null : <BottomNavbar />}
            </Container>
        );
    }

    return (
        <Container>
            <DesktopNavbar />
            <Content hiddenBottomNavbar={hiddenBottomNavbar}>
                <Outlet />
                <Snackbar />
                <FavoriteDrawers />
                <FeedbackModals />
                <Alert />
            </Content>
        </Container>
    );
};

DashboardLayout.propTypes = {
    hiddenBottomNavbar: PropTypes.bool
};

export default DashboardLayout;
