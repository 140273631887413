import { api } from 'services/api';
import { postLogError } from 'services/logErrors';

const getTypeResponse = (typeId) => {
    const types = ['radio', 'textarea', 'evaluation', 'acknowledgment'];

    return types[typeId - 1];
};

const quizTunnel = (quiz) => {
    const parsedQuiz = [];

    parsedQuiz.push({
        id: quiz.id,
        description: quiz.description,
        type: getTypeResponse(quiz.id_type_response),
        required: quiz.required === 1
    });

    if (quiz.satisfactionSecundary) {
        quiz.satisfactionSecundary.forEach((secondary) => {
            parsedQuiz.push({
                id: secondary.id,
                description: secondary.description,
                type: getTypeResponse(secondary.id_type_response),
                required: quiz.required === 1
            });
        });
    }

    parsedQuiz.push({
        id: 0,
        description: getTypeResponse(4),
        type: getTypeResponse(4),
        required: quiz.required === 1
    });

    return parsedQuiz;
};

const answerSatisfactionQuiz = async (idSatisfaction, answer) => {
    const response = await api
        .post(`/satisfaction_user`, {
            idSatisfaction,
            response: answer
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

export { quizTunnel, answerSatisfactionQuiz };
