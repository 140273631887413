import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import TabsHeader from 'components/molecules/headers/TabsHeader';
import SubSection from 'components/molecules/structure/SubSection';
import { EventType } from 'types/data/event';
import { CourseType } from 'types/data/course';
import CoursesList from 'components/organisms/lists/CoursesList';
import SmallEventList from 'components/organisms/lists/SmallEventList';
import { useNavigate } from 'react-router-dom';

const MyCommitments = ({ events, courses }) => {
    const [tab, setTab] = useState('events');
    const navigate = useNavigate();

    const tabs = [
        {
            label: 'Eventos',
            value: 'events'
        },
        {
            label: 'Cursos',
            value: 'courses'
        }
    ];

    const redirectToAll = () => {
        if (tab === 'events') {
            navigate('/aluno/eventos');
        }

        if (tab === 'courses') {
            navigate('/aluno/cursos');
        }
    };

    return (
        <SubSection title="Seus próximos compromissos:" borderTop>
            <Container>
                <TabsHeader tab={tab} setTab={setTab} tabs={tabs} />
                <main>
                    {
                        {
                            events: <SmallEventList events={events} emptyMessage="Nenhum evento encontrado" />,
                            courses: <CoursesList emptyMessage="Você não está inscrito em nenhum curso" courses={courses} small />
                        }[tab]
                    }
                </main>
                <footer>
                    <button onClick={() => redirectToAll()}>Ver tudo</button>
                </footer>
            </Container>
        </SubSection>
    );
};

MyCommitments.propTypes = {
    events: PropTypes.arrayOf(PropTypes.shape(EventType)),
    courses: PropTypes.arrayOf(PropTypes.shape(CourseType))
};

export default MyCommitments;
