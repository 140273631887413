import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { FormHelperText, InputLabel, OutlinedInput } from '@mui/material';

const InputEmail = ({ id, error, value, handleBlur, handleChange, label, errorText }) => {
    return (
        <Container fullWidth error={error}>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <OutlinedInput
                id={id}
                type="email"
                value={value}
                name={id}
                onBlur={handleBlur}
                onChange={handleChange}
                label={label}
                inputProps={{}}
            />
            {error && (
                <FormHelperText error id={`${id}-error`}>
                    {errorText}
                </FormHelperText>
            )}
        </Container>
    );
};

InputEmail.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    error: PropTypes.bool,
    errorText: PropTypes.string
};

export default InputEmail;
