import { Edit } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import Message from 'components/molecules/alerts/Message';
import CourseCard from 'components/molecules/cards/CourseCard';
import LibraryObjectCard from 'components/molecules/cards/LibraryObjectCard';
import ButtonTitle from 'components/molecules/headers/ButtonTitle';
import Meta from 'components/templates/Seo/Meta';
import useFavoriteList from 'hooks/useFavoriteList';
import useSnackbar from 'hooks/useSnackbar';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getFavoriteListById } from 'services/favorite';
import useWindowSize from 'hooks/useWindowSize';
import LibraryListLayout from 'components/templates/layouts/Pages/LibraryLayout/LibraryListLayout';
import LibraryAside from 'components/organisms/containers/LibraryAside';
import LibraryFavoriteListHeader from 'components/molecules/headers/LibraryFavoriteListHeader';
import { ContainerDesktop } from './styles';

const FavoritosListagem = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const snackbar = useSnackbar();
    const location = useLocation();
    const dispatch = useDispatch();
    const hook = useFavoriteList();
    const reducer = useSelector((state) => state.favoriteList);
    const [courses, setCourses] = useState([]);
    const [libraries, setLibraries] = useState([]);
    const windowSize = useWindowSize();
    const selectedItem = reducer?.selectedItem;

    const getFavoriteListData = async () => {
        await getFavoriteListById(id)
            .then((res) => {
                hook.selectItem({
                    id: res.data.id,
                    description: res.data.description
                });

                setCourses(res.data.studentFavorite.course);
                setLibraries(res.data.studentFavorite.library);
            })
            .catch((err) => {
                snackbar(err.message, 'error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDrawer = () => {
        hook.openEditModal({ id, description: selectedItem?.description });
    };

    const getSubNavigation = () => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: '/aluno/favoritos',
                returnText: 'Favoritos',
                returnPath: location.pathname
            }
        });
    };

    useEffect(() => {
        getSubNavigation();
        getFavoriteListData();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }
    if (windowSize.mobile) {
        return (
            <>
                <Meta title={selectedItem?.description || 'Favoritos'} />

                <ButtonTitle
                    title={selectedItem?.description || 'Favoritos'}
                    onClick={handleDrawer}
                    borderBottom
                    icon={<Edit color="primary" />}
                />

                {courses.length === 0 && libraries.length === 0 ? (
                    <Message text="Nenhum item nessa lista foi encontrado." />
                ) : (
                    <>
                        {courses.length > 0 &&
                            courses?.map((course) => <CourseCard key={course.id} course={course} pathName="Favoritos" />)}
                        {libraries.length > 0 &&
                            libraries?.map((learningObject, index) => <LibraryObjectCard learningObject={learningObject} key={index} />)}
                    </>
                )}
            </>
        );
    }
    return (
        <>
            <Meta title={selectedItem?.description || 'Favoritos'} />
            <LibraryListLayout>
                <LibraryAside />
                <LibraryFavoriteListHeader titleList={selectedItem?.description || 'Favoritos'} />
                <ContainerDesktop>
                    {courses.length === 0 && libraries.length === 0 ? (
                        <Message text="Nenhum item nessa lista foi encontrado." />
                    ) : (
                        <>
                            {courses.length > 0 &&
                                courses?.map((course) => <CourseCard key={course.id} course={course} pathName="Favoritos" />)}
                            {libraries.length > 0 &&
                                libraries?.map((learningObject, index) => (
                                    <LibraryObjectCard learningObject={learningObject} key={index} />
                                ))}
                        </>
                    )}
                </ContainerDesktop>
            </LibraryListLayout>
        </>
    );
};

export default FavoritosListagem;
