import styled from 'styled-components';

export const Container = styled.div`
    border-top: 1px solid #e0e0e0;
    padding: 1rem 0;
    width: 100%;
`;

export const Item = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    > h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 130%;
        color: var(--color-black);
        margin-bottom: 0.5rem;
    }

    > p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: var(--color-black);
    }

    > a {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: var(--color-primary);
    }
`;
