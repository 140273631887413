import React from 'react';
import { ParticipantType } from 'types/data/participant';
import PropTypes from 'prop-types';
import { Container, ContainerDesktop } from './styles';
import Svg from 'components/atoms/structure/Svg';
import { getSystemImageUrl } from 'utils/assets';
import { useNavigate } from 'react-router-dom';
import { getFallbackUrl } from 'utils/general';
import { Avatar } from '@mui/material';
import useWindowSize from 'hooks/useWindowSize';

const ParticipantListItem = ({ participant }) => {
    const { name, image, id, uf, city, roleId } = participant;
    const navigate = useNavigate();
    const windowSize = useWindowSize();

    const redirectToParticipant = () => {
        if (roleId === 2) {
            navigate(getFallbackUrl(`/aluno/participantes/${id}`, 'Participantes'));
        }

        if (roleId === 4) {
            navigate(getFallbackUrl(`/aluno/empresas/${id}`, 'Participantes'));
        }
    };

    const icon = roleId === 2 ? 'user.svg' : 'grupo empresas.svg';

    if (windowSize.mobile) {
        return (
            <Container onClick={() => redirectToParticipant()}>
                <main>
                    <Avatar src={image} alt={name} />
                    <p>{name}</p>
                </main>
                <aside>
                    <Svg url={getSystemImageUrl(icon)} width="20" height="20" />
                </aside>
            </Container>
        );
    }

    return (
        <ContainerDesktop onClick={() => redirectToParticipant()}>
            <main>
                <section>
                    <Avatar src={image} alt={name} />
                    <p>{name}</p>
                </section>
            </main>
            <aside>
                <p>{city ? city + '/' + uf : ''}</p>
                <div>
                    <Svg url={getSystemImageUrl(icon)} width="20" height="20" />
                </div>
            </aside>
        </ContainerDesktop>
    );
};

ParticipantListItem.propTypes = {
    participant: PropTypes.shape(ParticipantType).isRequired
};

export default ParticipantListItem;
