import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { absoluteMenuType } from 'types/components/navigation';

const AbsoluteMenuItem = ({ item, handler }) => {
    const onClick = () => {
        if (item.disabled) {
            return;
        }
        handler();
        item.onClick();
    };

    return (
        <Container onClick={() => onClick()}>
            <span>{item.icon}</span>
            <h3>{item.title}</h3>
        </Container>
    );
};

AbsoluteMenuItem.propTypes = {
    item: PropTypes.shape(absoluteMenuType).isRequired,
    handler: PropTypes.func.isRequired
};

export default AbsoluteMenuItem;
