import styled from 'styled-components';

export const Container = styled.div`
    > p {
        margin-top: 0.4rem;
        color: var(--color-black);
        font-size: 0.9rem;
    }

    margin-bottom: 1rem;
`;
