import CompetenceLevelUpModal from 'components/molecules/modals/CompetenceLevelUpModal';
import SatisfactionSearchModal from 'components/molecules/modals/SatisfactionSearchModal';
import FinishedCourseModal from 'components/molecules/modals/FinishedCourseModal';
import FinishedEventModal from 'components/molecules/modals/FinishedEventModal';
import FinishedLibraryModal from 'components/molecules/modals/FinishedLibraryModal';
import LevelUpModal from 'components/molecules/modals/LevelUpModal';
import ToLevelUpModal from 'components/molecules/modals/ToLevelUpModal';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import SuggestionModal from 'components/molecules/modals/SuggestionModal';
import PostFormModal from 'components/molecules/modals/PostFormModal';

const FeedbackModals = () => {
    const feedbackReducer = useSelector((state) => state.feedback);
    const [feedback, setFeedback] = useState(null);
    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        if (feedbackReducer?.feedbacks.length > 0) {
            setFeedback(feedbackReducer?.feedbacks[0]);
            return;
        }

        setFeedback(null);
    }, [feedbackReducer]);

    if (!feedback) {
        return null;
    }

    return (
        <>
            {auth.user.enterpriseId
                ? {
                      finishCourse: <FinishedCourseModal feedback={feedback} />,
                      finishEvent: <FinishedEventModal feedback={feedback} />,
                      satisfactionSearch: <SatisfactionSearchModal feedback={feedback} />,
                      suggestion: <SuggestionModal feedback={feedback} />,
                      postForm: <PostFormModal />
                  }[feedback?.type]
                : {
                      finishCourse: <FinishedCourseModal feedback={feedback} />,
                      finishEvent: <FinishedEventModal feedback={feedback} />,
                      finishLibrary: <FinishedLibraryModal feedback={feedback} />,
                      levelUp: <LevelUpModal feedback={feedback} />,
                      toLevelUp: <ToLevelUpModal feedback={feedback} />,
                      levelUpCompetences: <CompetenceLevelUpModal feedback={feedback} />,
                      satisfactionSearch: <SatisfactionSearchModal feedback={feedback} />,
                      suggestion: <SuggestionModal feedback={feedback} />
                  }[feedback?.type]}
        </>
    );
};

export default FeedbackModals;
