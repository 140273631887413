import React, { useEffect, useState } from 'react';
import { QuizQuestionType } from 'types/components/quiz';
import PropTypes from 'prop-types';
import { Container, Option, Form } from './styles';
import { Radio, RadioGroup } from '@mui/material';
import Button from 'components/molecules/buttons/Button';
import { CancelRounded, CheckCircle } from '@mui/icons-material';
import QuestionFeedback from 'components/molecules/cards/QuestionFeedback';

const MultipleQuestion = ({ question, onChoose, index, previousAnswer }) => {
    const [answer, setAnswer] = useState(previousAnswer);
    const [answered, setAnswered] = useState(!!previousAnswer);

    const questionNumber = index + 1;
    const selectedOption = answer ? question.answer.find((option) => option.id === answer) : null;

    const answerQuestion = (answer) => {
        onChoose(question.id, answer, index);
        setAnswered(true);
    };

    useEffect(() => {
        if (previousAnswer) {
            setAnswer(previousAnswer);
            setAnswered(true);
            return;
        }

        setAnswer(null);
        setAnswered(false);
    }, [previousAnswer]);

    return (
        <Container>
            <header>
                <h2>Questão {questionNumber}</h2>
                <p>{question.question}</p>
            </header>

            {answered ? (
                <Form>
                    <RadioGroup>
                        {question.answer.map((option, key) => (
                            <Option
                                value={option.id}
                                key={key}
                                control={
                                    question.answer.find((item) => option.id === item.id).correct ? (
                                        <Radio
                                            icon={<CheckCircle htmlColor="#87D04D" />}
                                            checkedIcon={<CheckCircle htmlColor="#87D04D" />}
                                        />
                                    ) : option.id === answer ? (
                                        <Radio
                                            icon={<CancelRounded htmlColor="#DB5252" />}
                                            checkedIcon={<CancelRounded htmlColor="#DB5252" />}
                                        />
                                    ) : (
                                        <Radio />
                                    )
                                }
                                label={option.answer}
                                disabled={option.id !== answer}
                            />
                        ))}
                    </RadioGroup>
                </Form>
            ) : (
                <Form>
                    <RadioGroup>
                        {question.answer.map((option, key) => (
                            <Option
                                value={option.id}
                                key={key}
                                onClick={() => setAnswer(option.id)}
                                control={<Radio />}
                                label={option.answer}
                            />
                        ))}
                    </RadioGroup>
                </Form>
            )}

            {answer !== null && !answered && (
                <footer>
                    <Button type="button" width="10rem" size="thin" color="secondary" onClick={() => answerQuestion(answer)}>
                        Confirmar resposta
                    </Button>
                </footer>
            )}

            {answered && selectedOption && (
                <footer>
                    <QuestionFeedback correct={selectedOption.correct} message={selectedOption.feedback} />
                </footer>
            )}
        </Container>
    );
};

MultipleQuestion.propTypes = {
    question: PropTypes.shape(QuizQuestionType).isRequired,
    onChoose: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    previousAnswer: PropTypes.number
};

export default MultipleQuestion;
