import { CircularProgress } from '@mui/material';
import MyCompetencesCarousel from 'components/molecules/carousels/MyCompetencesCarousel';
import HomeAside from 'components/organisms/containers/HomeAside';
import MyCommitments from 'components/organisms/containers/MyCommitments';
import UserAside from 'components/organisms/containers/UserAside';
import UserInfo from 'components/organisms/containers/UserInfo';
import Timeline from 'components/organisms/lists/Timeline';
import HomeLayout from 'components/templates/layouts/Pages/HomeLayout';
import Meta from 'components/templates/Seo/Meta';
import useFeedback from 'hooks/useFeedback';
import useSnackbar from 'hooks/useSnackbar';
import useWindowSize from 'hooks/useWindowSize';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getStudentCompetences } from 'services/competences';
import { getParticipatingEventsCourses } from 'services/home';
import { getUsers } from 'services/home';
import { studentPassedLevel } from 'services/student';
import { getTimeline } from 'services/timeline';

const Home = () => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});
    const auth = useSelector((state) => state.auth);

    const [competences, setCompetences] = useState([]);
    const [participatingCourses, setParticipatingCourses] = useState([]);
    const [participatingEvents, setParticipatingEvents] = useState([]);
    const [scrollY, setScrollY] = useState(0);
    const [users, setUsers] = useState([]);
    const [postsLastPage, setPostsLastPage] = useState(0);

    const setLoadingPosts = (loading) => {
        dispatch({ type: 'SET_TIMELINE_LOADING', payload: { loading } });
    };

    const feedback = useFeedback();
    const snackbar = useSnackbar();
    const windowSize = useWindowSize();

    const dispatch = useDispatch();
    const timeline = useSelector((state) => state.timeline);

    const getCompetencesData = async () => {
        if (!auth?.user?.studentId) {
            return;
        }

        await getStudentCompetences(auth.user.studentId)
            .then((response) => {
                setCompetences(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar competências', 'error');
            });
    };

    const getParticipatingEventsCoursesData = async () => {
        if (!auth?.user?.studentId) {
            return;
        }
        await getParticipatingEventsCourses(auth.user.studentId)
            .then((response) => {
                setParticipatingEvents(response.events);
                setParticipatingCourses(response.courses);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar compromissos', 'error');
            });
    };

    const getUserData = async () => {
        if (!auth?.user?.studentId) {
            return;
        }

        setUser({
            email: auth.user?.email,
            id: auth.user?.id,
            roleId: auth.user?.roleId,
            studentId: auth.user?.studentId,
            name: auth.user?.name,
            image: auth?.user?.image,
            level: auth?.user?.level
        });

        setLoading(false);
        setLoadingPosts(false);
    };

    const getPostsData = async () => {
        if ((timeline.page === postsLastPage && timeline.page !== 0) || timeline.loading) {
            return;
        }

        setLoadingPosts(true);

        try {
            const { data } = await getTimeline(timeline.page);
            dispatch({ type: 'ADD_TIMELINE_ITEMS', payload: { items: data.data } });

            const currentPage = timeline.page + 1;
            dispatch({ type: 'SET_TIMELINE_PAGE', payload: { page: currentPage } });

            setPostsLastPage(data.lastPage);
        } catch (error) {
            snackbar('Erro ao carregar posts', 'error');
        }

        setLoadingPosts(false);
    };

    const getUsersData = async () => {
        await getUsers()
            .then((response) => {
                setUsers(response.data.students);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar usuários', 'error');
            });
    };

    const getStudentPassedLevel = async () => {
        if (!auth?.user?.studentId) {
            return;
        }

        await studentPassedLevel(auth.user.studentId)
            .then((response) => {
                if (response?.levelExperience && response?.levelExperience?.length > 0) {
                    response?.levelExperience
                        .slice(0)
                        .reverse()
                        .map((level) => {
                            feedback.levelUp({
                                name: level.nameLevel,
                                id: level.idLevel
                            });
                        });
                }

                if (response?.levelCompetence) {
                    feedback.levelUpCompetences(response?.levelCompetence);
                }
            })
            .catch((error) => console.error(error));
    };

    const verifyScroll = () => {
        if (scrollY !== 0 && !timeline.loading) {
            if (document.body.scrollHeight - scrollY - 30 < window.innerHeight) {
                getPostsData();
                return;
            }
        }
    };

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        setScrollY(currentScrollY);
    };

    const checkFinishEvent = () => {
        const localStorageData = localStorage.getItem('watchEventPoints');

        if (!localStorageData) {
            return;
        }

        feedback.finishEvent(localStorageData);
        localStorage.removeItem('watchEventPoints');
    };

    useEffect(() => {
        verifyScroll();
    }, [scrollY]);

    useEffect(() => {
        getCompetencesData();
        getUserData();
        getParticipatingEventsCoursesData();
        getPostsData();
        getUsersData();
        getStudentPassedLevel();
    }, [auth]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        checkFinishEvent();
    }, []);

    if (loading || windowSize.mobile === undefined) {
        return <CircularProgress />;
    }

    if (windowSize.mobile) {
        return (
            <>
                <Meta title="Home" />

                <UserInfo user={user} users={users} />

                {!auth?.user?.enterpriseId && <MyCompetencesCarousel competences={competences} />}

                <MyCommitments courses={participatingCourses} events={participatingEvents} />

                <Timeline posts={timeline.items} lastPage={postsLastPage === timeline.page} />
            </>
        );
    }

    return (
        <>
            <Meta title="Home" />

            <HomeLayout>
                <UserAside user={user} users={users} />

                {!auth?.user?.enterpriseId && <MyCompetencesCarousel competences={competences} title={'Suas Competências:'} />}

                <Timeline posts={timeline.items} lastPage={postsLastPage === timeline.page} />

                <HomeAside courses={participatingCourses} events={participatingEvents} />
            </HomeLayout>
        </>
    );
};

export default Home;
