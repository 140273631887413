import React from 'react';
import Button from '../Button';
import PropTypes from 'prop-types';

import { Container, Content } from './styles';

const FixedButton = ({ label, onClick }) => {
    return (
        <Container>
            <Content>
                <Button type="button" size="thin" color="secondary" onClick={() => onClick()}>
                    {label}
                </Button>
            </Content>
        </Container>
    );
};

FixedButton.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default FixedButton;
