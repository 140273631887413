import React from 'react';
import { DesktopContainer } from './styles';
import ButtonTitle from 'components/molecules/headers/ButtonTitle';
import { Edit } from '@mui/icons-material';
import useFavoriteList from 'hooks/useFavoriteList';
import { useParams } from 'react-router-dom';

const LibraryListHeader = ({ titleList }) => {
    const hook = useFavoriteList();
    const { id } = useParams();

    const handleDrawer = () => {
        hook.openEditModal({ id, description: titleList });
    };

    return (
        <DesktopContainer>
            <ButtonTitle title={'Favoritos'} onClick={handleDrawer} borderBottom icon={<Edit color="primary" />} />
        </DesktopContainer>
    );
};

export default LibraryListHeader;
