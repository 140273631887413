import axios from 'axios';
import Cookies from 'js-cookie';

// const BASE_URL = 'https://pfahomologacaoapi.rehagro.com.br/api/';
// const BASE_URL = 'http://localhost:8000/api/';
const { REACT_APP_BASE_URL } = process.env;
const api = axios.create({
    baseURL: REACT_APP_BASE_URL
});

api.interceptors.request.use(
    (config) => {
        return {
            ...config,
            headers: {
                ...config.headers,
                common: {
                    ...config.headers.common,
                    'Access-Control-Allow-Headers': '*',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': '*',
                    Authorization: `Bearer ${Cookies.get('token')}`,
                    Personification: Cookies.get('personification')
                }
            }
        };
    },
    (error) => Promise.reject(error)
);

export { api };
