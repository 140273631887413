import Svg from 'components/atoms/structure/Svg';
import ModalCard from 'components/organisms/layout/modals/ModalCard';
import useFeedback from 'hooks/useFeedback';
import React from 'react';
import { getSystemImageUrl } from 'utils/assets';
import PropTypes from 'prop-types';

import { Container, ImageContainer } from './styles';
import { FeedbackType } from 'types/components/feedback';
import { useSelector } from 'react-redux';

const FinishedEventModal = ({ feedback }) => {
    const hook = useFeedback();
    const { points } = feedback;

    const auth = useSelector((state) => state.auth);
    const isEnterprise = auth.user?.enterpriseId;

    return (
        <ModalCard open={true} title="Evento" handleClose={hook.closeFeedback}>
            <Container>
                <h3>Parabéns!</h3>
                <p>
                    Você conquistou pontos ao <br />
                    participar do nosso último evento!
                </p>

                <ImageContainer>
                    <Svg url={getSystemImageUrl('icone evento.svg')} width="75" height="68" />
                </ImageContainer>

                {!isEnterprise && points && <h2>+{points} pontos</h2>}
                <p>
                    Continue ligado nos eventos PFA <br />
                    para conhecer mais sobre o mercado Agro!
                </p>
            </Container>
        </ModalCard>
    );
};

FinishedEventModal.propTypes = {
    feedback: PropTypes.shape(FeedbackType).isRequired
};

export default FinishedEventModal;
