import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import FullWidthContainer from '../../../atoms/structure/FullWidthContainer';
import CarouselButton from 'components/molecules/buttons/CarouselButton';

const ListCarousel = ({
    data,
    selectedData,
    onItemClick,
    selectableItems,
    selectableItemsAll,
    description,
    reference,
    noScroll,
    containerWidth,
    containerMaxWidth
}) => {
    const handleItemClick = (value, scroll) => {
        if (scroll && !noScroll) {
            const component = document.querySelector(`#${reference}-${value}`);

            if (!component) {
                return;
            }

            const y = component.getBoundingClientRect().top + window.scrollY - 150;
            window.scrollTo(0, y);
        }

        onItemClick(value);
    };

    return (
        <FullWidthContainer
            sticky
            background="var(--color-background)"
            marginTop={description ? null : '0.1rem'}
            containerWidth={containerWidth}
            containerMaxWidth={containerMaxWidth}
        >
            <Container paddingTop={!description}>
                {description && (
                    <header>
                        <h4>{description}</h4>
                    </header>
                )}
                <main>
                    {data.map((item) => (
                        <CarouselButton
                            key={item.id}
                            value={item.id}
                            text={item.text}
                            active={selectedData === item.id}
                            selectable={selectableItemsAll || selectableItems.includes(item.id)}
                            onClick={(scroll) => handleItemClick(item.id, scroll)}
                        />
                    ))}
                </main>
            </Container>
        </FullWidthContainer>
    );
};

ListCarousel.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            text: PropTypes.string
        })
    ).isRequired,
    selectedData: PropTypes.number.isRequired,
    onItemClick: PropTypes.func.isRequired,
    selectableItems: PropTypes.arrayOf(PropTypes.number).isRequired,
    selectableItemsAll: PropTypes.bool,
    reference: PropTypes.string.isRequired,
    description: PropTypes.string,
    noScroll: PropTypes.bool,
    containerWidth: PropTypes.string,
    containerMaxWidth: PropTypes.string
};

ListCarousel.defaultProps = {
    selectableItemsAll: false
};

export default ListCarousel;
