import Title from 'components/atoms/texts/Title';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Container } from './styles';
import { IconButton } from '@mui/material';
import Filter from '../../../organisms/menus/Filter';

const FilterTitle = ({ title, filterStructure, filterValue, setFilter, borderBottom }) => {
    const [filterOpen, setFilterOpen] = useState(false);

    return (
        <>
            <Container borderBottom={borderBottom}>
                <Title title={title} />
                <IconButton onClick={() => setFilterOpen(true)}>
                    <FilterAltIcon color="primary" />
                </IconButton>
            </Container>
            <Filter
                active={filterOpen}
                structure={filterStructure}
                data={filterValue}
                setFilter={setFilter}
                handler={() => setFilterOpen(false)}
            />
        </>
    );
};

FilterTitle.propTypes = {
    title: PropTypes.string,
    filterStructure: PropTypes.arrayOf(PropTypes.object),
    filterValue: PropTypes.object,
    setFilter: PropTypes.func,
    borderBottom: PropTypes.bool
};

export default FilterTitle;
