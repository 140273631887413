import React from 'react';
import PropTypes from 'prop-types';

import { Container, Tab, Tabs } from './styles';
import { tabType } from 'types/components/tab';

const TabsSectionHeader = ({ tab, setTab, tabs }) => {
    const changeTab = (value) => {
        setTab(value);
    };

    return (
        <Container>
            <Tabs>
                {tabs.map((item, index) => (
                    <Tab key={index} active={item.value === tab} onClick={() => changeTab(item.value)}>
                        {item.label}
                    </Tab>
                ))}
            </Tabs>
        </Container>
    );
};

TabsSectionHeader.propTypes = {
    tab: PropTypes.string.isRequired,
    setTab: PropTypes.func.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape(tabType)).isRequired
};

export default TabsSectionHeader;
