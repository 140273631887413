import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
    padding: 20px 0 24px 0;
    &.competenceCourses:not(:last-of-type) {
        border-bottom: 1px solid #c7c7c7;
    }
`;
export const CompetenceHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;
export const CompetenceBody = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -10px;
`;
export const CompetenceFooter = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

    > .footer-button {
        padding: 4px 20px;

        background: #e2edd0;
        border-radius: 4px;
        border: none;

        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 130%;
        color: #015641;
    }
`;

export const CompetenceLine = styled.div`
    display: block;
    width: 100%;
    height: 0;
    border: 0.5px solid #c7c7c7;
    margin-top: 20px;
    margin-bottom: 10px;
`;
