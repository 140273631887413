import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { CompetenceType } from 'types/data/competence';
import CompetenceItem from 'components/molecules/listItems/CompetenceItem';

const CompetencesList = ({ competences }) => {
    return (
        <Container>
            {competences.map((competence) => (
                <CompetenceItem key={competence.id} data={competence} />
            ))}
        </Container>
    );
};

CompetencesList.propTypes = {
    competences: PropTypes.arrayOf(PropTypes.shape(CompetenceType)).isRequired
};

export default CompetencesList;
