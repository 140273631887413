import { CircularProgress } from '@mui/material';
import BorderTitle from 'components/molecules/headers/BorderTitle';
import CoursesList from 'components/organisms/lists/CoursesList';
import Meta from 'components/templates/Seo/Meta';
import useSnackbar from 'hooks/useSnackbar';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCompetenceById } from 'services/competences';
import { getCoursesByCompetence } from 'services/courses';

const CourseCompetence = () => {
    const { id } = useParams();
    const [courses, setCourses] = useState([]);
    const [competence, setCompetence] = useState({});
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const snackbar = useSnackbar();

    const getCoursesData = async () => {
        await getCoursesByCompetence(id, true)
            .then((response) => {
                setCourses(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Não foi possível carregar os cursos.', 'error');
            });
    };

    const getCompetenceData = async () => {
        await getCompetenceById(id)
            .then((data) => {
                setCompetence(data.data);
                dispatch({
                    type: 'SET_COMPETENCE',
                    payload: {
                        competence: {
                            id: data.data.id,
                            name: data.data.description
                        }
                    }
                });
            })
            .catch((error) => {
                console.error(error);
                snackbar('Não foi possível carregar a competência.', 'error');
            });
    };

    useEffect(() => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: '/aluno/cursos',
                returnText: 'Cursos',
                returnPath: location.pathname
            }
        });
    }, []);

    useEffect(() => {
        getCoursesData();
    }, []);

    useEffect(() => {
        getCompetenceData();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
            <Meta title="Competência" description="Aqui você pode ver todos os cursos sobre a competência" />

            <BorderTitle title={competence?.description || ''} />

            <CoursesList courses={courses} />
        </>
    );
};

export default CourseCompetence;
