import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;

    > header {
        width: 100%;
        padding: 1rem 0;
    }

    > main {
        margin-top: 1rem;
        width: 100%;
    }

    > footer {
        padding-top: 1rem;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    > * {
        margin-bottom: 0.5rem;
    }
`;
