import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 5rem;
    justify-content: flex-start;

    > aside {
        margin-right: 0.4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    > p {
        font-weight: 400;
        font-size: 0.8rem;
        color: #e2b02f;
    }
`;
