import FavoriteRemove from 'components/molecules/containers/FavoriteRemove';
import FormMakerFavorite from 'components/organisms/layout/form/FormMakerFavorite';
import DialogForm from 'components/molecules/alerts/DialogForm';
import useFavoriteList from 'hooks/useFavoriteList';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Validator } from 'utils/validators';
import { getFavoriteIcons } from 'services/favorite';
import { Container } from './styles';
import useSnackbar from 'hooks/useSnackbar';

const EditFavoriteDrawer = () => {
    const favoriteList = useSelector((state) => state.favoriteList);
    const hook = useFavoriteList();
    const [favoriteIcons, setFavoriteIcons] = useState('');
    const snackbar = useSnackbar();
    const selectedItem = favoriteList?.selectedItem;

    const submit = async (data) => {
        await hook
            .edit({ ...selectedItem, ...data })
            .then(() => hook.closeEditModal())
            .catch(() => {});
    };

    const form = [
        [
            {
                text: 'Renomear Lista',
                type: 'label'
            }
        ],
        [
            {
                id: 'description',
                type: 'text',
                label: 'Nome da lista',
                initialValue: selectedItem?.description,
                message: 'Máximo 50 caracteres',
                validation: Validator.string()
                    .required('Nome da lista é obrigatório')
                    .max(50, 'Nome da lista deve ter no máximo 50 caracteres')
            }
        ]
    ];

    const getAllFavoriteIcons = async () => {
        await getFavoriteIcons()
            .then((response) => {
                setFavoriteIcons(response.data.data);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar ícones', 'error');
            });
    };

    useEffect(() => {
        getAllFavoriteIcons();
    }, []);

    return (
        <DialogForm
            handler={() => hook.closeEditModal()}
            open={favoriteList.editModal}
            title="Editar lista"
            noButton={true}
            text={
                <Container>
                    <FormMakerFavorite
                        data={form}
                        formSubmit={submit}
                        submitText="Salvar"
                        noConfirm
                        fullWidthButtonSubmit
                        favoriteRemove={<FavoriteRemove />}
                        icons={favoriteIcons}
                        subtitle="Escolha um ícone para sua lista"
                    />
                </Container>
            }
        />
    );
};

export default EditFavoriteDrawer;
