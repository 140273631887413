import styled from 'styled-components';

export const Container = styled.a`
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    background-color: #fff;
    width: 100%;
    padding: 1rem;
    position: relative;
    display: flex;
    flex-direction: row;

    > aside {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 1rem;

        > img {
            width: 3rem;
            z-index: 1;
            height: auto;
        }
    }

    > main {
        > h2 {
            font-size: 1.1rem;
            color: var(--color-black);
        }

        > p {
            margin-top: 0.4rem;
            color: var(--color-black);
            font-size: 0.8rem;
        }
    }
`;

export const Lines = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 0;
`;
