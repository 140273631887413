import styled from 'styled-components';
import { RadioGroup } from '@mui/material';

export const Container = styled.div`
    margin: 0;

    > h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        color: #464646;
        flex: none;
        order: 0;
        flex-grow: 0;
    }
`;

export const Form = styled(RadioGroup)`
    padding: 2rem 0 1rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
`;
