import React from 'react';
import PropTypes from 'prop-types';
import ProfileHeader from 'components/molecules/headers/ProfileHeader';
import { ContainerFooter } from './styles';

const ParticipantDescription = ({ name, city, occupation, state, image, background }) => {
    return (
        <>
            <ProfileHeader image={image} background={background} profile={false} participant={true} />

            <ContainerFooter>
                <h2>{name}</h2>
                <h3>{occupation || ''}</h3>

                {(city || state) && (
                    <p>
                        {city || ''} , {state}
                    </p>
                )}
            </ContainerFooter>
        </>
    );
};

ParticipantDescription.propTypes = {
    name: PropTypes.string,
    occupation: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    image: PropTypes.string,
    background: PropTypes.string
};

export default ParticipantDescription;
