import { TextField } from '@mui/material';
import styled from 'styled-components';
import { FormControl, InputLabel } from '@mui/material';

export const Container = styled(FormControl)`
    width: 100%;
    > p {
        margin-bottom: 1rem;
    }
`;
export const TextArea = styled(TextField)`
    width: 100%;
    background-color: #fff;
`;
export const Label = styled(InputLabel)`
    background-color: #fff;
    opacity: 0 - 1;
    padding: 0 0.4rem;
`;
