import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Description,
    Title,
    DescriptionText,
    ItemsContainer,
    OpportunityTag,
    ParticipatingOpportunity,
    Actions,
    Spacer
} from './styles';
import FavoriteCheckbox from 'components/atoms/inputs/FavoriteCheckbox';
import Button from 'components/molecules/buttons/Button';
import { PostType } from 'types/data/post';
import ReactHtmlParser from 'react-html-parser';
import { htmlClean } from 'utils/html';
import { likePost } from 'services/timeline';
import IconText from 'components/atoms/texts/IconText';
import { Business, LocationOn, Work, WbTwilight, Check } from '@mui/icons-material';
import useSnackbar from 'hooks/useSnackbar';
import TimelineContent from '../TimelineContent';
import { viewPost, studentOpportunity } from 'services/post';
import Dialog from 'components/molecules/alerts/Dialog';
import { Stack } from '@mui/material';
import { getUTCDate } from 'utils/date';
import PublicationCommentBtn from 'components/molecules/buttons/PublicationComment';

const Post = ({ post, idPublication }) => {
    const {
        files,
        liked,
        local,
        period,
        description,
        title,
        company,
        url,
        viewed,
        vacationInternship,
        finalDate,
        requirement,
        publication,
        publicationCommentsTotal
    } = post;
    const [isLiked, setIsLiked] = useState(liked);
    const [openSaveModal, setOpenSaveModal] = useState(false);
    const [openLimitOpportunityModal, setOpenLimitOpportunityModal] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const snackbar = useSnackbar();

    const handleLike = async (value) => {
        await likePost(idPublication)
            .then(() => {
                setIsLiked(value);
                snackbar(value ? 'Postagem curtida com sucesso' : 'Postagem descurtida com sucesso', 'success');
            })
            .catch((err) => {
                snackbar(err.error || 'Erro ao curtir postagem', 'error');
            });
    };

    const redirect = async () => {
        if (vacationInternship === 1) {
            await studentOpportunity(idPublication).then((response) => {
                if (response.data.error && response.status !== 406) {
                    setOpenLimitOpportunityModal(true);
                    return;
                }

                setOpenSaveModal(true);
            });

            return;
        }

        if (finalDate) {
            const date = new Date(getUTCDate(finalDate));

            if (date < new Date()) {
                snackbar('Oportunidade expirada', 'error');
                return;
            }
        }
        await studentOpportunity(idPublication).then(() => {
            redirectToUrl();
        });
    };

    const redirectToUrl = () => {
        if (!viewed) {
            async () => viewPost(idPublication).catch((err) => console.err(err));
        }

        if (!url) {
            return;
        }

        if (!url.includes('http://') && !url.includes('https://')) {
            setTimeout(() => {
                window.open(`https://${url}`, '_blank');
            });

            return;
        }

        setTimeout(() => {
            window.open(url, '_blank');
        });
    };

    return (
        <>
            <Container>
                <main>
                    <Title>
                        <h1>{title} </h1>
                    </Title>

                    <TimelineContent files={files} />
                    <Stack direction="row" spacing={1}>
                        {vacationInternship === 1 && (
                            <OpportunityTag icon={<WbTwilight style={{ color: '#fff' }} />} label="Estágio de Férias" size="small" />
                        )}
                        {post.isParticipatingOpportunity === 1 && (
                            <ParticipatingOpportunity icon={<Check style={{ color: '#fff' }} />} label="Já inscrito" size="small" />
                        )}
                    </Stack>
                    {(local || period || company) && (
                        <ItemsContainer>
                            {local && <IconText icon={<LocationOn />} text={local} label="Local" />}
                            {period && <IconText icon={<Work />} text={period} label="Período" />}
                            {company && <IconText icon={<Business />} text={company} label="Empresa" />}
                        </ItemsContainer>
                    )}

                    <Description>
                        <DescriptionText>
                            <h2>Descrição</h2>

                            <p>{ReactHtmlParser(htmlClean(description))}</p>
                        </DescriptionText>

                        <DescriptionText>
                            <h2>Requisitos</h2>
                            <p>{ReactHtmlParser(htmlClean(requirement))}</p>
                        </DescriptionText>
                    </Description>

                    <Actions>
                        <div>
                            <FavoriteCheckbox active={isLiked} setActive={handleLike} />
                            <span>{post.likes + isLiked}</span>
                        </div>
                        <div>
                            {publication && (
                                <PublicationCommentBtn publication={publication} modalOpen={modalOpen} setModalOpen={setModalOpen} />
                            )}
                            {publicationCommentsTotal}
                        </div>
                    </Actions>
                </main>

                <footer>
                    <Button onClick={() => redirect()} type="button">
                        Quero me inscrever
                    </Button>
                </footer>
            </Container>

            <Dialog
                open={openSaveModal}
                submit={() => redirectToUrl()}
                title="Candidatar-se"
                text="Nesse semestre você poderá se candidatar em 2 vagas de Estágio de Férias. Tem certeza de que deseja se candidatar a essa Vaga?"
                handler={() => setOpenSaveModal(false)}
                submitText="Sim"
                cancelText="Não"
            />

            <Dialog
                noButton={true}
                open={openLimitOpportunityModal}
                title="Atenção"
                text="Nesse semestre você poderá se candidatar em 2 vagas de Estágio de Férias. Você já se inscreveu em duas oportunidades. Boa sorte! :)"
                handler={() => setOpenLimitOpportunityModal(false)}
            />
        </>
    );
};

Post.propTypes = {
    post: PropTypes.shape(PostType).isRequired,
    idPublication: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default Post;
