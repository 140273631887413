import styled from 'styled-components';
import { Close } from '@mui/icons-material';

export const Container = styled.div`
    position: fixed;
    top: calc(var(--navbar-height) - 1rem);

    width: 100vw;
    height: 56px;
    display: flex;
    justify-content: center;
    padding: 0 16px;

    &.success {
        background-color: #87d04d;
    }

    &.error {
        background-color: #d04d4d;
    }

    &.warning {
        background-color: #ccd04d;
    }

    > div {
        width: 100%;
        max-width: 1160px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

export const CloseButton = styled(Close)`
    cursor: pointer;
    display: inline-block;
`;
