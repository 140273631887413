import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import Button from 'components/molecules/buttons/Button';

const ModalFormTemplate = ({ children, onSubmit }) => {
    return (
        <Container>
            <main>{children}</main>
            <footer>
                <Button size="fat" color="secondary" width="5rem" onClick={() => onSubmit()}>
                    Enviar
                </Button>
            </footer>
        </Container>
    );
};

ModalFormTemplate.propTypes = {
    children: PropTypes.node.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default ModalFormTemplate;
