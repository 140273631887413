import { api } from 'services/api';
import { postLogError } from 'services/logErrors';

const getResourcesPublication = async () => {
    const response = await api.get('/get_resource_publication').catch((err) => {
        postLogError(err);
        throw new Error(err.response);
    });

    return response.data;
};

const createPublication = async (data) => {
    const response = await api.post('/publication', data).catch((err) => {
        postLogError(err);
        throw new Error(err.response);
    });

    return response.data;
};

export { getResourcesPublication, createPublication };
