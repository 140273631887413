import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import FileCard from 'components/molecules/cards/FileCard';

const FilesList = ({ files, handleDelete }) => {
    return (
        <Container>
            {files.map((file, index) => (
                <FileCard key={index} file={file} handleDelete={() => handleDelete(index)} />
            ))}
        </Container>
    );
};

FilesList.propTypes = {
    files: PropTypes.array,
    handleDelete: PropTypes.func
};

export default FilesList;
