import Button from 'components/molecules/buttons/Button';
import Drawer from 'components/organisms/layout/modals/Drawer';
import useFavoriteList from 'hooks/useFavoriteList';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';
import { Container } from './styles';
import Dialog from 'components/molecules/alerts/Dialog';

const RemoveFavoriteDrawer = () => {
    const favoriteList = useSelector((state) => state.favoriteList);
    const hook = useFavoriteList();
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    const selectedItem = favoriteList.selectedItem;

    const submit = async () => {
        await hook
            .remove(selectedItem?.id)
            .then(() => navigate('/aluno/favoritos'))
            .catch(() => {});
    };

    const cancel = () => {
        hook.closeRemoveModal();
    };

    if (windowSize.mobile) {
        return (
            <Drawer
                title="Apagar a lista"
                active={favoriteList.removeModal}
                close={() => hook.closeRemoveModal()}
                open={() => hook.openRemoveModal()}
            >
                <Container>
                    <header>
                        <p>Tem certeza de que deseja apagar toda a lista e o seu conteúdo? Essa ação é irreversível.</p>
                    </header>
                    <footer>
                        <Button type="button" color="light" onClick={() => cancel()} width="6rem">
                            Cancelar
                        </Button>
                        <Button type="button" color="secondary" onClick={() => submit()} width="6rem">
                            Apagar
                        </Button>
                    </footer>
                </Container>
            </Drawer>
        );
    }
    return (
        <Dialog
            open={favoriteList.removeModal}
            handler={() => hook.closeRemoveModal()}
            submit={() => submit()}
            title="Apagar a lista"
            text="Tem certeza de que deseja apagar toda a lista e o seu conteúdo? Essa ação é irreversivel."
            submitText="Apagar"
            cancelText="Voltar"
        />
    );
};

export default RemoveFavoriteDrawer;
