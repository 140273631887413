import styled from 'styled-components';
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

export const ContainerDesktop = styled(DesktopDatePicker)`
    width: 100%;
`;

export const ContainerMobile = styled(MobileDatePicker)`
    width: 100%;
`;

export const Input = styled(TextField)`
    width: 100%;
`;
