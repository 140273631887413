import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    width: 100vw;
    bottom: 0;
    padding: 1rem;
    background-color: #f8f8f8;
    left: 0;
    display: flex;
    justify-content: center;
`;

export const Content = styled.div`
    display: flex;
    width: 100%;
    max-width: 600px;
`;
