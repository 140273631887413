import { CloseButton, Container } from './styles';
import useAlert from '../../../../hooks/useAlert';

const Alert = () => {
    const { alertReducer, close } = useAlert();

    if (!alertReducer.open) return <></>;

    return (
        <Container className={alertReducer.variant}>
            <div>
                {alertReducer.message} <CloseButton onClick={close} />
            </div>
        </Container>
    );
};
export default Alert;
