import styled from 'styled-components';

export const Container = styled.div`
    margin: 0;
    width: 100%;

    > h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        color: #464646;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    > legend {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        margin: 1rem 0;
    }

    > main {
        > * {
            margin-bottom: 1rem;
        }
    }
`;

export const EvaluationContainer = styled.div`
    width: 100%;
    border-radius: var(--border-radius);
`;

export const EvaluationButton = styled.button`
    width: 50px;
    height: 35px;
    background: ${(props) => (props.active ? '#E7F5E6' : '#FFFFFF')};
    border: 1px solid #c7c7c7;

    &:last-of-type {
        border-radius: 0px 4px 4px 0px;
    }

    &:first-of-type {
        border-radius: 4px 0px 0px 4px;
    }
`;
