import { api } from 'services/api';
import { postLogError } from '../logErrors/index';

const studentLibraryComplete = async (idStudent, idLibrary) => {
    const response = await api
        .get(`/student_library`, {
            params: {
                idStudent,
                idLibrary
            }
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const studentPassedLevel = async (idStudent) => {
    const response = await api
        .get(`/student_passed_level/${idStudent}`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return response.data;
};

export { studentLibraryComplete, studentPassedLevel };
