import React from 'react';
import { LevelType } from 'types/data/level';
import PropTypes from 'prop-types';

import { Container } from './styles';
import InfoLogLevel from 'components/molecules/containers/InfoLogLevel';
import { getFallbackUrl } from '../../../../utils/general';

const Level4LevelModal = ({ level, levelConquered }) => {
    const data = [
        {
            active: level?.progress?.wheelOfLife || levelConquered,
            title: 'Roda da vida',
            url: getFallbackUrl('/aluno/biblioteca/conteudo/213', 'Perfil')
        },
        {
            active: level?.progress?.behavioralProfile || levelConquered,
            title: 'Perfil Comportamental',
            url: getFallbackUrl('/aluno/biblioteca/conteudo/212', 'Perfil')
        },
        {
            active: level?.progress?.listOfVirtues || levelConquered,
            title: 'Lista de valores',
            url: getFallbackUrl('/aluno/biblioteca/conteudo/210', 'Perfil')
        }
    ];
    return (
        <Container>
            <p>Desafio: </p>

            <h2>Conclua os testes Autoconhecimento</h2>

            <span>Faça os testes</span>

            <InfoLogLevel data={data} />
        </Container>
    );
};

Level4LevelModal.propTypes = {
    level: PropTypes.shape(LevelType),
    levelConquered: PropTypes.bool
};

export default Level4LevelModal;
