import React from 'react';
import PropTypes from 'prop-types';
import { Container, DesktopContainer } from './styles';
import useWindowSize from 'hooks/useWindowSize';
import ComponentsHeaderLibrary from 'components/atoms/structure/ComponentsHeaderLibrary';
import SearchInput from 'components/atoms/inputs/SearchInput';

const SearchHeader = ({ value, onChange }) => {
    const windowSize = useWindowSize();

    if (windowSize.mobile) {
        return (
            <Container>
                <SearchInput value={value} onChange={onChange} />
            </Container>
        );
    }

    return (
        <DesktopContainer>
            <ComponentsHeaderLibrary value={value} onChange={onChange} />
        </DesktopContainer>
    );
};

SearchHeader.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default SearchHeader;
