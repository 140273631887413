import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import { Buttons, Container, Content, EditButton } from './styles';
import { CameraAlt, Edit, Visibility } from '@mui/icons-material';
import Button from 'components/molecules/buttons/Button';
import { Lightbox } from 'react-modal-image';
import CropImage from '../CropImage';
import useSnackbar from 'hooks/useSnackbar';

const Dropzone = ({ onFileUploaded, label, url }) => {
    const [selectedFileUrl, setSelectedFileUrl] = useState('');
    const [focused, setFocused] = useState(false);
    const [changeImage, setChangeImage] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [crop, setCrop] = useState(false);
    const snackbar = useSnackbar();

    const onDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];

            const fileType = file['type'];
            const validImageTypes = ['image/jpeg', 'image/png'];

            if (!validImageTypes.includes(fileType)) {
                snackbar('Arquivo inválido, selecione uma imagem', 'error');
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.addEventListener('load', () => {
                setImageSrc(reader.result);
            });

            const fileUrl = URL.createObjectURL(file);

            setSelectedFileUrl(fileUrl);
            onFileUploaded(file);
        },
        [onFileUploaded]
    );

    const updateImageByCropped = (imageUrl, imageSrc) => {
        setSelectedFileUrl(imageUrl);
        onFileUploaded(imageSrc);
        setCrop(false);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*'
    });

    const uploadBlob = async () => {
        try {
            const blob = await fetch(url).then((r) => r.blob());

            onFileUploaded(blob);
        } catch (err) {}
    };

    useEffect(() => {
        if (url) {
            setSelectedFileUrl(url);
            uploadBlob();
        }
    }, []);

    useEffect(() => {
        if (!changeImage) {
            return;
        }

        setChangeImage(false);
        setCrop(true);
    }, [selectedFileUrl]);

    useEffect(() => {
        setTimeout(() => {
            setChangeImage(true);
        }, 1000);
    }, []);

    return (
        <Container>
            <Buttons>
                <EditButton {...getRootProps()}>
                    <Edit htmlColor="#fff" />
                </EditButton>
                <EditButton onClick={() => setFocused(!focused)}>
                    <Visibility htmlColor="#fff" />
                </EditButton>
            </Buttons>
            <Content {...getRootProps()}>
                <input {...getInputProps()} accept="image/*" />

                {selectedFileUrl ? (
                    <img src={selectedFileUrl} alt="Point Thumbnail" />
                ) : (
                    <p>
                        <CameraAlt fontSize="large" />
                        <Button color="light" width="auto" size="thin" onClick={() => {}}>
                            {label}
                        </Button>
                    </p>
                )}
            </Content>

            {focused && (
                <Lightbox
                    hideDownload
                    medium={selectedFileUrl}
                    large={selectedFileUrl}
                    alt="Imagem de perfil"
                    onClose={() => setFocused(false)}
                />
            )}

            {crop && imageSrc && <CropImage imageSrc={imageSrc} updateImageByCropped={updateImageByCropped} shape="round" />}
        </Container>
    );
};

Dropzone.propTypes = {
    onFileUploaded: PropTypes.func.isRequired,
    label: PropTypes.object.isRequired,
    url: PropTypes.any
};

export default Dropzone;
