import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    > * {
        margin-bottom: 0.5rem;
    }

    > p {
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        color: #373848;
    }
`;

export const ImageContainer = styled.div`
    width: 100%;
    margin: 1rem 0rem;
`;
