import styled from 'styled-components';
import { Button as MUIButton } from '@mui/material';

export const Container = styled.a`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e8e8e8;
    padding: 1rem 0;
    justify-content: center;
    align-items: center;
    width: 100%;

    > header {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        > h2 {
            font-size: 1rem;
            font-weight: 700;
            color: var(--color-black);
            margin: 0.5rem 0;
        }

        > p {
            font-size: 0.9rem;
        }
    }

    > main {
        width: 100%;
    }

    > main {
        width: 100%;
    }

    &:last-child {
        border-bottom: none;
    }
`;

export const Button = styled(MUIButton)`
    border-radius: var(--border-radius);
    text-transform: none;
    padding: 2px;
    box-shadow: none;
`;
