import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { Checkbox, FormControlLabel } from '@mui/material';

const OptionsForm = ({ title, options, values, setValues }) => {
    const onChange = useCallback(
        (option) => {
            if (values.includes(option)) {
                setValues(values.filter((value) => value !== option));
                return;
            }

            setValues([...values, option]);
        },
        [values]
    );

    return (
        <Container>
            <h3>{title}</h3>

            <main>
                {options?.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        control={<Checkbox color="secondary" checked={values.includes(option)} onChange={() => onChange(option)} />}
                        label={option}
                    />
                ))}
            </main>
        </Container>
    );
};

OptionsForm.propTypes = {
    title: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    values: PropTypes.arrayOf(PropTypes.string),
    setValues: PropTypes.func,
    addOption: PropTypes.func
};

export default OptionsForm;
