import React, { useState } from 'react';
import { Container, Content, MenuBadge, MenuButton } from './styles';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../../../../assets/images/logo.png';
import MainSidebar from '../MainSidebar';
import { HelpOutline, NotificationsOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getFallbackUrl } from 'utils/general';
import { useSelector } from 'react-redux';

const MobileNavbar = () => {
    const [openSidebar, setOpenSidebar] = useState(false);
    const notifications = useSelector((state) => state.notifications);

    const navigate = useNavigate();

    const redirectToNotifications = () => {
        navigate(getFallbackUrl('/aluno/notificacoes'));
    };

    const redirectToHelp = () => {
        navigate(getFallbackUrl('/aluno/ajuda'));
    };

    return (
        <>
            <Container>
                <Content>
                    <aside>
                        <MenuButton aria-label="menu" onClick={() => setOpenSidebar(true)}>
                            <MenuIcon color="primary" />
                        </MenuButton>

                        <a onClick={() => navigate('/aluno')} aria-hidden="true">
                            <img src={Logo} alt="Logo do Rehagro" />
                        </a>
                    </aside>
                    <main>
                        <IconButton aria-label="menu" onClick={() => redirectToNotifications()}>
                            <MenuBadge badgeContent={notifications?.total || 0} color="primary">
                                <NotificationsOutlined color="primary" />
                            </MenuBadge>
                        </IconButton>
                        <IconButton aria-label="menu" onClick={() => redirectToHelp()}>
                            <HelpOutline color="primary" />
                        </IconButton>
                    </main>
                </Content>
            </Container>

            <MainSidebar active={openSidebar} handler={() => setOpenSidebar(false)} />
        </>
    );
};

export default MobileNavbar;
