import React from 'react';

import { Container } from './styles';
import Title from 'components/atoms/texts/Title';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { htmlClean } from 'utils/html';

const HelpHeader = ({ title, description }) => {
    return (
        <Container>
            <Title title={title} />
            {description && <p style={{ marginTop: '1.5rem' }}>{ReactHtmlParser(htmlClean(description))}</p>}
        </Container>
    );
};

HelpHeader.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default HelpHeader;
