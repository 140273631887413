import { api } from 'services/api';

const getPublicationComment = async (id) => {
    return api.get(`/publication_comment/${id}`);
};

const getPublicationComments = async (params) => {
    return api.get('/publication_comment', { params });
};

const postPublicationComment = async (data) => {
    return api.post('/publication_comment', data);
};

const putPublicationComment = async (data) => {
    return api.put('/publication_comment', data);
};

export { getPublicationComment, getPublicationComments, postPublicationComment, putPublicationComment };
