import styled from 'styled-components';

export const Container = styled.div`
    padding-top: 0.5rem;
    width: 100%;
`;

export const DesktopContainer = styled.div`
    grid-area: SH;
    padding-top: 1.6rem;
    width: 100%;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;
