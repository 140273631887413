import React from 'react';
import { DesktopContainer, ImageContainer } from './styles';
import Svg from 'components/atoms/structure/Svg';
import { getSystemImageUrl } from 'utils/assets';
import PropTypes from 'prop-types';

const LibraryListHeader = ({ title, icon, idItem }) => {
    const item = idItem;

    if (icon) {
        return (
            <DesktopContainer>
                <ImageContainer>
                    <img src={icon} alt="icon" />
                </ImageContainer>
                <h1>{title}</h1>
            </DesktopContainer>
        );
    }

    return (
        <DesktopContainer>
            <ImageContainer>
                <aside>
                    {
                        {
                            1: <Svg url={getSystemImageUrl('Video.svg')} width="42" height="42" />,
                            2: <Svg url={getSystemImageUrl('Arquivo.svg')} width="42" height="42" />,
                            3: <Svg url={getSystemImageUrl('Imagem.svg')} width="42" height="42" />,
                            4: <Svg url={getSystemImageUrl('Curso.svg')} width="42" height="42" />,
                            5: <Svg url={getSystemImageUrl('Evento.svg')} width="42" height="42" />,
                            6: <Svg url={getSystemImageUrl('Audio.svg')} width="42" height="42" />,
                            7: <Svg url={getSystemImageUrl('PDF.svg')} width="42" height="42" />,
                            8: <Svg url={getSystemImageUrl('Tabela.svg')} width="42" height="42" />,
                            9: <Svg url={getSystemImageUrl('Arquivo.svg')} width="42" height="42" />
                        }[item]
                    }
                </aside>
            </ImageContainer>
            <h1>{title}</h1>
        </DesktopContainer>
    );
};

LibraryListHeader.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes,
    idItem: PropTypes.isRequired
};

export default LibraryListHeader;
