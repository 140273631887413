import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Section } from './styles';
import CourseCard from 'components/molecules/cards/CourseCard';
import Message from 'components/molecules/alerts/Message';
import { CourseType } from 'types/data/course';
import FullWidthContainer from 'components/atoms/structure/FullWidthContainer';
import { CompetenceType } from 'types/data/competence';

const CompletedCoursesList = ({ courses, emptyMessage, competences, setSelectableCompetences }) => {
    const [coursesByCompetences, setCoursesByCompetences] = useState([]);

    const getCourseByCompetence = () => {
        const coursesByCompetences = [];

        competences.forEach((competence) => {
            coursesByCompetences[competence.description] = [];
        });

        let courseCompetence;
        let selectableCompetences = [];

        courses.forEach((course) => {
            courseCompetence = course.competences[0].description;

            if (!coursesByCompetences[courseCompetence]) {
                coursesByCompetences[courseCompetence] = [];
            }

            if (!selectableCompetences.includes(course.competences[0].id)) {
                selectableCompetences.push(course.competences[0].id);
            }

            coursesByCompetences[courseCompetence] = [...coursesByCompetences[courseCompetence], course];
        });

        setCoursesByCompetences(coursesByCompetences);
        setSelectableCompetences(selectableCompetences.sort((a, b) => a - b));
    };

    useEffect(() => {
        getCourseByCompetence();
    }, [courses]);

    if (!courses.length) {
        return <Message text={emptyMessage || 'Você ainda não concluiu nenhum curso'} />;
    }

    return (
        <FullWidthContainer background="#fff">
            <Container>
                {Object.keys(coursesByCompetences).map((competence) => {
                    if (coursesByCompetences[competence].length === 0) {
                        return null;
                    }

                    const competenceData = competences.find((comp) => comp.description === competence);

                    return (
                        <Section key={competence} id={`competence-${competenceData?.id}`}>
                            <header>
                                <img src={competenceData.icon} alt={competence} />
                                <h2>{competence}</h2>
                            </header>

                            <main>
                                {coursesByCompetences[competence].map((course) => (
                                    <CourseCard key={course.id} course={course} completed />
                                ))}
                            </main>
                        </Section>
                    );
                })}
            </Container>
        </FullWidthContainer>
    );
};

CompletedCoursesList.propTypes = {
    courses: PropTypes.arrayOf(PropTypes.shape(CourseType)).isRequired,
    emptyMessage: PropTypes.string,
    competences: PropTypes.arrayOf(PropTypes.shape(CompetenceType)).isRequired,
    setSelectableCompetences: PropTypes.func.isRequired
};

export default CompletedCoursesList;
