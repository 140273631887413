import ModalCard from 'components/organisms/layout/modals/ModalCard';
import React, { useState } from 'react';

import { Container, Input } from './styles';
import useFeedback from 'hooks/useFeedback';
import { useSelector } from 'react-redux';
import UserHeader from 'components/molecules/headers/UserHeader';
import Button from 'components/molecules/buttons/Button';
import useSnackbar from 'hooks/useSnackbar';

const SuggestionModal = () => {
    const hook = useFeedback();
    const snackbar = useSnackbar();
    const [suggestion, setSuggestion] = useState('');
    const auth = useSelector((state) => state.auth);

    const submit = () => {
        if (suggestion.length === 0) {
            snackbar('Digite uma sugestão', 'error');
            return;
        }

        snackbar('Sugestão enviada com sucesso!', 'success');
        hook.closeFeedback();
    };

    const modules = {
        toolbar: false
    };

    return (
        <ModalCard open={true} title="Compartilhar" handleClose={hook.closeFeedback} formLayout>
            <Container>
                <header>
                    <UserHeader name={auth?.user?.name} image={auth?.user?.image} />
                </header>

                <main>
                    <Input value={suggestion} onChange={setSuggestion} modules={modules} placeholder="Escreva sua sugestão" />
                </main>

                <footer>
                    <Button size="fat" type="button" onClick={() => submit()} width="auto" color="neutral">
                        Enviar
                    </Button>
                </footer>
            </Container>
        </ModalCard>
    );
};

export default SuggestionModal;
