import styled from 'styled-components';

export const Container = styled.div`
    padding-top: 1rem;
`;
export const DesktopContainer = styled.div`
    grid-area: C;
    width: 100% !important;
    margin-top: 16px;
    > .message {
        width: 100%;
        text-align: center;
        margin-top: 3rem;
    }
`;
export const ContainerSearch = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;

    > div {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 8px;
        cursor: pointer;

        .title {
            flex-grow: 1;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 130%;
            color: #464646;
        }
    }
`;
export const CoursesGrid = styled.div`
    display: grid;
    grid-template-columns: 256px 256px 256px;
    width: 100%;
    gap: 16px;
    margin-top: 40px;
`;
export const PageTitle = styled.div`
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: #033025;
`;

export const PageDescription = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;

    color: #373848;
`;
