import styled from 'styled-components';

export const Container = styled.div`
    padding-top: 0.5rem;
    padding-bottom: 1rem;

    button {
        margin-top: 1rem;
    }
`;
