import { CircularProgress } from '@mui/material';
import ListCarousel from 'components/molecules/carousels/ListCarousel';
import BorderTitle from 'components/molecules/headers/BorderTitle';
import CoursesList from 'components/organisms/lists/CoursesList';
import Meta from 'components/templates/Seo/Meta';
import useSnackbar from 'hooks/useSnackbar';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCompetences } from 'services/competences';
import { getAllCourses } from 'services/courses';
import { getFallbackUrl } from 'utils/general';

const TodosCursos = () => {
    const [courses, setCourses] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const snackbar = useSnackbar();
    const [page, setPage] = useState(0);
    const [lastPage, setLastPage] = useState(1);
    const [loadingMore, setLoadingMore] = useState(false);
    const [scrollY, setScrollY] = useState(0);
    const [competences, setCompetences] = useState([]);

    const getCompetencesData = async () => {
        await getCompetences()
            .then((response) => {
                setCompetences(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Não foi possível carregar as competências.', 'error');
            });
    };

    const getCoursesData = async () => {
        if (page >= lastPage || loadingMore) {
            return;
        }

        setLoadingMore(true);

        await getAllCourses(page + 1)
            .then((res) => {
                setCourses([...courses, ...res.data]);
                setLastPage(res.last_page);
                setPage(page + 1);
            })
            .catch(() => {
                snackbar('Não foi possível carregar os cursos.', 'error');
            })
            .finally(() => {
                setLoading(false);
                setLoadingMore(false);
            });
    };

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        setScrollY(currentScrollY);
    };

    const verifyScroll = () => {
        if (window.innerHeight === document.body.offsetHeight && page < lastPage) {
            getCoursesData();
            return;
        }

        if (scrollY !== 0 && !loadingMore) {
            if (window.innerHeight + scrollY + 10 >= document.documentElement.scrollHeight) {
                getCoursesData();
                return;
            }
        }
    };

    const redirectToCompetence = (competence) => {
        navigate(getFallbackUrl('/aluno/cursos/competencias/' + competence, 'Todos os cursos'));
    };

    useEffect(() => {
        verifyScroll();
    }, [scrollY, page]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: '/aluno/cursos',
                returnText: 'Todos os cursos',
                returnPath: location.pathname
            }
        });
    }, []);

    useEffect(() => {
        getCoursesData();
        getCompetencesData();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
            <Meta title="Todos os cursos" description="Aqui você pode ver todos os cursos" />
            <BorderTitle title="Todos os cursos" />

            <ListCarousel
                data={competences.map((competence) => ({
                    id: competence.id,
                    text: competence.description
                }))}
                selectedData={null}
                onItemClick={redirectToCompetence}
                selectableItemsAll={true}
                reference="competence"
                description="Filtre por uma competência:"
                noScroll
            />

            <CoursesList courses={courses} />
        </>
    );
};

export default TodosCursos;
