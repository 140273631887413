import React from 'react';
import { Alert, Snackbar as MUISnackBar } from '@mui/material';
import PropTypes from 'prop-types';

const SnackbarModule = ({ type, text, cleanText }) => {
    const cleanSnackbar = () => {
        cleanText();
    };

    return (
        <MUISnackBar open={text.length > 0} autoHideDuration={4000} message={text || ''} onClose={() => cleanSnackbar()}>
            <Alert onClose={() => cleanSnackbar()} severity={type || 'success'} sx={{ width: '100%' }}>
                <>{text || ''}</>
            </Alert>
        </MUISnackBar>
    );
};

SnackbarModule.propTypes = {
    type: PropTypes.string,
    text: PropTypes.string,
    cleanText: PropTypes.func
};

export default SnackbarModule;
