import React from 'react';
import PropTypes from 'prop-types';
import { ButtonInline, Container } from './styles';
import { userType } from 'types/data/user';

import { useSelector } from 'react-redux';
import ShareIdeaButton from 'components/molecules/buttons/ShareIdeaButton';
import AvatarGroup from '../AvatarGroup';

const InlineStudents = ({ users }) => {
    const auth = useSelector((state) => state.auth);
    const isEnterprise = auth?.user?.enterpriseId;

    return (
        <Container>
            {isEnterprise ? (
                <ButtonInline>
                    <ShareIdeaButton />
                </ButtonInline>
            ) : (
                <p>Pessoas aprendendo junto com você:</p>
            )}
            <AvatarGroup users={users} />
        </Container>
    );
};

InlineStudents.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape(userType)).isRequired
};

export default InlineStudents;
