import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
    background-color: #fff;
    height: var(--navbar-height);
    justify-content: center;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.03);
`;

export const Content = styled.div`
    max-width: var(--max-width);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px var(--padding-base);

    aside {
        display: flex;
        align-items: center;

        > h2 {
            font-size: 1rem;
            color: var(--color-primary);
            font-weight: 700;
            padding-left: 0.2rem;
        }
    }

    > main {
        display: flex;
        align-items: center;
        > * {
            margin-left: 8px;
        }
    }
`;

export const MenuButton = styled(IconButton)`
    margin-left: -8px;
`;
