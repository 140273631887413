import styled from 'styled-components';

export const Header = styled.div`
    grid-area: H;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-right: 30px;
    height: 9rem;
    background-color: #fff;
    padding: 26px;
    border-top-right-radius: 0.5rem;
    border-bottom: 1px solid #000;
    box-shadow: var(--box-shadow);
`;
export const Body = styled.div`
    box-shadow: var(--box-shadow);
    padding-top: 2rem;
    padding-left: 2rem;
    grid-area: B;
    width: 100%;
    padding-right: 30px;
    height: auto;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    background-color: #fff;
    align-self: start;
`;
export const ContainerTotalParticipants = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
    color: #787885;
    font-size: 18px;
`;
export const ContainerSearch = styled.div`
    display: flex;
    gap: 12px;
    width: 50%;
    align-items: center;
`;
export const ContainerAlphabet = styled.div`
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex-wrap: nowrap;
`;
export const ContainerMenu = styled.div`
    width: 100%;
`;
export const ButtonStyled = styled.button`
    border: none;
    width: 30px;
    margin-right: 1rem;
    font-size: 25px;
    background-color: #fff;
`;
export const ClearFilterButton = styled.button`
    height: 32px;
    border: none;
    background: #e2edd0;
    padding: 8px 20px;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0a432d;
    font-weight: 600;
    font-size: 16px;

    &:hover {
        filter: brightness(0.95);
    }
    &:active {
        filter: brightness(0.9);
    }
`;
export const SearchButton = styled.button`
    height: 32px;
    border: none;
    background: #87a851;
    padding: 8px 20px;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;

    &:hover {
        filter: brightness(0.95);
    }
    &:active {
        filter: brightness(0.9);
    }
`;
