import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Button = ({ type, disabled, onClick, children, size, width, color }) => {
    return (
        <Container
            size={size || 'medium'}
            variant="contained"
            type={type || 'button'}
            onClick={() => onClick()}
            disabled={disabled}
            width={width || null}
            color={color || 'primary'}
        >
            {children}
        </Container>
    );
};

Button.propTypes = {
    type: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.string,
    width: PropTypes.string,
    color: PropTypes.string,
    sx: PropTypes.objectOf()
};

export default Button;
