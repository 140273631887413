import React from 'react';
import { LevelType } from 'types/data/level';
import PropTypes from 'prop-types';
import { Container } from './styles';
import BlondeLevel from 'components/atoms/structure/BlondeLevel';
import { getTypeByLevel } from 'utils/rules/level';

const LevelIcon = ({ level, small }) => {
    return (
        <Container>
            <header>
                <span>Nível Atual</span>
            </header>

            <main>
                <BlondeLevel level={level} small={small} />
            </main>

            <footer>
                <h3>{getTypeByLevel(level.name)}</h3>
            </footer>
        </Container>
    );
};

LevelIcon.propTypes = {
    level: PropTypes.shape(LevelType),
    small: PropTypes.bool
};

export default LevelIcon;
