import { CircularProgress } from '@mui/material';
import Message from 'components/molecules/alerts/Message';
import ProfileAbout from 'components/organisms/containers/ProfileAbout';
import ProfileListAbout from 'components/organisms/lists/ProfileListAbout';
import PfaContents from 'components/organisms/tabsSections/PfaContents';
import Meta from 'components/templates/Seo/Meta';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getProfileById } from 'services/profile';
import useSnackbar from 'hooks/useSnackbar';
import { getSystemImageUrl } from 'utils/assets';
import { useParams } from 'react-router-dom';
import BigLevelCard from 'components/molecules/cards/BigLevelCard';
import ParticipantDescription from 'components/organisms/containers/ParticipantDescription';

const Participante = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState({});
    const [error, setError] = useState('');

    const snackbar = useSnackbar();
    const dispatch = useDispatch();

    const getProfile = async () => {
        const data = await getProfileById(id)
            .then((response) => response)
            .catch((err) => {
                console.error(err);
                snackbar('Erro ao carregar perfil', 'error');
            });

        if (data.status !== 200) {
            setError(data.data.error);
            setLoading(false);
        }

        if (data.status === 401) {
            navigate('/login');
            return;
        }

        setProfile(...data.data);
        setLoading(false);
    };

    const setSubNavbar = () => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: '/aluno',
                returnText: 'Participantes',
                returnPath: location.pathname
            }
        });
    };

    useEffect(() => {
        setSubNavbar();
        getProfile(id);
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    if (error || !profile?.id) {
        return <Message text={error} />;
    }

    return (
        <>
            <Meta title={profile?.name || 'Participante'} />

            <ParticipantDescription
                image={profile?.url_image}
                name={profile.name}
                city={profile.city}
                occupation={profile.occupation}
                state={profile.uf}
                background={profile?.url_background_image}
            />

            {profile?.levelExperience && <BigLevelCard level={profile?.levelExperience} />}

            {profile.description && <ProfileAbout text={profile.description} />}

            {profile.academicEducation !== undefined && profile.academicEducation.length > 0 && (
                <ProfileListAbout
                    title="Formação Acadêmica:"
                    data={profile.academicEducation}
                    icon={getSystemImageUrl('certificate.svg')}
                />
            )}

            {profile.courseAndCertificate !== undefined && profile.courseAndCertificate.length > 0 && (
                <ProfileListAbout title="Cursos e certificados:" data={profile.courseAndCertificate} icon={getSystemImageUrl('work.svg')} />
            )}

            {profile.language !== undefined && profile.language.length > 0 && (
                <ProfileListAbout title="Idiomas:" data={profile.language} icon={getSystemImageUrl('translate.svg')} />
            )}

            {profile.experience !== undefined && profile.experience.length > 0 && (
                <ProfileListAbout title="Experiências:" data={profile.experience} icon={getSystemImageUrl('briefcase.svg')} />
            )}

            <PfaContents events={profile?.participationEventInPfa} courses={profile?.participationCourseInPfa} participant />
        </>
    );
};

export default Participante;
