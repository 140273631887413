import React, { useState, useEffect } from 'react';
import FavoriteCheckbox from 'components/atoms/inputs/FavoriteCheckbox';
import Button from 'components/molecules/buttons/Button';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import { Container, DescriptionText, ItemsContainer, OpportunityTag, ParticipatingOpportunity, UserRedirect } from './styles';
import { TimelineItem } from 'types/data/timeline';
import { htmlClean } from 'utils/html';
import { Link, useNavigate } from 'react-router-dom';
import IconText from 'components/atoms/texts/IconText';
import { Business, LocationOn, Work, WbTwilight, Check } from '@mui/icons-material';
import { Avatar, Stack } from '@mui/material';
import { likePost } from 'services/timeline';
import useSnackbar from 'hooks/useSnackbar';
import { seePost } from 'services/post';
import TimelineContent from 'components/organisms/containers/TimelineContent';
import PublicationComments from 'components/molecules/containers/PublicationComments';
import PublicationCommentBtn from 'components/molecules/buttons/PublicationComment';
import { getFallbackUrl } from 'utils/general';

const OpportunityCard = ({ post }) => {
    const [liked, setLiked] = useState(post.liked);
    const [uri, setUri] = useState('');
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const [modalOpen, setModalOpen] = useState(false);

    const descriptionText = post?.shortDescription ? post.shortDescription : post.description;

    const handleLike = async (value) => {
        await likePost(post.id)
            .then(() => {
                setLiked(value);
                snackbar(value ? 'Postagem curtida com sucesso' : 'Postagem descurtida com sucesso', 'success');
            })
            .catch((err) => {
                snackbar(err.message || 'Erro ao curtir postagem', 'error');
            });

        setLiked(value);
    };

    const getUri = () => {
        const path = window?.location?.pathname;

        return `/aluno/publicacao/${post.id}${path ? `?returnUrl=${path}` : ''}`;
    };

    const redirectToEnterprise = () => {
        if (!post?.user?.id_enterprise) return;

        navigate(getFallbackUrl(`/aluno/empresas/${post?.user?.id_enterprise}`));
    };

    const redirect = async () => {
        if (!post.sawed) {
            await seePost(Number(post.id));
        }

        const uri = getUri();

        navigate(uri);
    };

    useEffect(() => {
        setUri(getUri());
    }, [post]);

    return (
        <Container>
            <header>
                <UserRedirect onClick={() => redirectToEnterprise()} pointer={!!post?.user?.id_enterprise}>
                    <Avatar src={post?.user?.image} />

                    <h3>{post?.title}</h3>
                </UserRedirect>
            </header>
            <Link to={uri}>
                <main>
                    <TimelineContent files={post.files} />
                    <Stack direction="row" spacing={1}>
                        {post.vacationInternship === 1 && (
                            <OpportunityTag icon={<WbTwilight style={{ color: '#fff' }} />} label="Estágio de Férias" size="small" />
                        )}
                        {post.isParticipatingOpportunity === 1 && (
                            <ParticipatingOpportunity icon={<Check style={{ color: '#fff' }} />} label="Já inscrito" size="small" />
                        )}
                    </Stack>

                    {(post.local || post.period || post.company) && (
                        <ItemsContainer>
                            {post.local && <IconText icon={<LocationOn />} text={post.local} label="Local" />}
                            {post.period && <IconText icon={<Work />} text={post.period} label="Período" />}
                            {post.company && <IconText icon={<Business />} text={post.company} label="Empresa" />}
                        </ItemsContainer>
                    )}

                    <DescriptionText>{ReactHtmlParser(htmlClean(descriptionText))}</DescriptionText>

                    <footer>
                        <Button size="fat" type="button" width="auto" color="neutral" onClick={redirect}>
                            Saiba mais
                        </Button>
                    </footer>
                </main>
            </Link>

            <div className="divider" />

            <PublicationComments
                comments={post.comments}
                ellipsis={true}
                redirectOnClick={false}
                clickHandle={() => setModalOpen(!modalOpen)}
            />

            <footer>
                <aside>
                    <div>
                        <FavoriteCheckbox active={liked} setActive={handleLike} noPaddingLeft />
                        <p>{post.likes + liked - post.liked}</p>
                    </div>
                    <div>
                        <PublicationCommentBtn publication={post} modalOpen={modalOpen} setModalOpen={setModalOpen} />
                        <p>{post.commentsTotal}</p>
                    </div>
                </aside>

                <main>
                    <p>{post.views} visualizações</p>
                </main>
            </footer>
        </Container>
    );
};

OpportunityCard.propTypes = {
    post: PropTypes.shape(TimelineItem)
};

export default OpportunityCard;
