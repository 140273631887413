import { useDispatch, useSelector } from 'react-redux';
import { coursesActions } from '../../redux/reducers/coursesReducer';

const useCourses = () => {
    const dispatch = useDispatch();

    const coursesReducer = useSelector((state) => state.courses);

    const setOpenCertificateModal = (payload) => dispatch({ type: coursesActions.SET_CERTIFICATE_MODAL, payload });
    const setSelectedCourse = (payload) => dispatch({ type: coursesActions.SET_SELECTED_COURSE, payload });

    return {
        coursesReducer,
        setOpenCertificateModal,
        setSelectedCourse
    };
};
export default useCourses;
