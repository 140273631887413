import styled from 'styled-components';

export const Container = styled.div`
    padding: 1rem;
    transition: var(--transition);

    b {
        font-weight: 900;
        font-size: 1rem;
    }

    .MuiStepConnector-lineVertical {
        border-left-style: dashed;
    }
`;
