import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Container, ContainerDesktop } from './styles';
import { CourseType } from 'types/data/course';
import { learningObjectType } from 'types/data/library';
import CourseCard from 'components/molecules/cards/CourseCard';
import LibraryObjectCard from 'components/molecules/cards/LibraryObjectCard';
import Message from 'components/molecules/alerts/Message';
import { CircularProgress } from '@mui/material';
import { EventType } from 'types/data/event';
import SmallEventCard from 'components/molecules/cards/SmallEventCard';
import useWindowSize from 'hooks/useWindowSize';

const LibraryList = ({ courses, learningObjects, filterSearch, marginTop, events, columns = 1 }) => {
    const [filteredCourses, setFilteredCourses] = useState(courses || []);
    const [filteredEvents, setFilteredEvents] = useState(events || []);
    const [filteredLearningObjects, setFilteredLearningObjects] = useState(learningObjects || []);
    const [loading, setLoading] = useState(true);
    const windowSize = useWindowSize();

    const verifyFilterIsBlank = () =>
        (!filterSearch?.competences || filterSearch?.competences?.length === 0) &&
        (!filterSearch?.formats || filterSearch?.formats?.length === 0);

    const filterData = () => {
        if (verifyFilterIsBlank()) {
            setFilteredCourses(courses);
            setFilteredLearningObjects(learningObjects);
            setFilteredEvents(events);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            return;
        }

        if (filterSearch?.competences?.length > 0) {
            const filterCompetencesCourses = (course) =>
                filterSearch?.competences.filter((filterCompetence) =>
                    course.competences.some((eventCompetence) => eventCompetence.desription === filterCompetence)
                ).length > 0;

            const filterCompetenceEvents = (event) =>
                filterSearch?.competences.filter((filterCompetence) =>
                    event.competences.some((eventCompetence) => eventCompetence.desription === filterCompetence)
                ).length > 0;

            const filterCompetencesLearningObjects = (learningObject) =>
                filterSearch?.competences.filter((filterCompetence) =>
                    learningObject?.library?.competence.some((competence) => competence.desription === filterCompetence)
                ).length > 0;

            setFilteredCourses(courses.filter(filterCompetencesCourses));
            setFilteredLearningObjects(learningObjects.filter(filterCompetencesLearningObjects));
            setFilteredEvents(events.filter(filterCompetenceEvents));

            return;
        }

        if (filterSearch?.formats?.length > 0) {
            if (filterSearch?.formats.includes('Cursos')) {
                setFilteredCourses(courses);
            } else {
                setFilteredCourses([]);
            }

            if (filterSearch?.formats.includes('Eventos')) {
                setFilteredEvents(events);
            } else {
                setFilteredEvents([]);
            }

            setFilteredLearningObjects(
                learningObjects.filter((learningObject) => filterSearch?.formats.includes(learningObject.typeLearningObject))
            );

            return;
        }
    };

    useEffect(() => {
        filterData();
    }, [filterSearch, courses, events, learningObjects]);

    if (loading) {
        return <CircularProgress />;
    }
    if (windowSize.mobile) {
        return (
            <Container marginTop={marginTop}>
                {filteredLearningObjects?.length > 0 || filteredCourses?.length > 0 || filteredEvents?.length > 0 ? (
                    <>
                        {filteredLearningObjects &&
                            filteredLearningObjects?.map((learningObject, index) => (
                                <LibraryObjectCard learningObject={learningObject} key={index} />
                            ))}

                        {filteredCourses &&
                            filteredCourses?.map((course) => <CourseCard key={course.id} course={course} pathName="Biblioteca" />)}

                        {filteredEvents &&
                            filteredEvents?.map((event) => (
                                <SmallEventCard key={event.id} event={event} returnUrl={window.location.pathname} />
                            ))}
                    </>
                ) : (
                    <Message text={`Nenhum material encontrado ${!verifyFilterIsBlank() ? 'com o filtro inserido' : ''}`} />
                )}
            </Container>
        );
    }
    return (
        <ContainerDesktop marginTop={marginTop} columns={columns}>
            {filteredLearningObjects?.length > 0 || filteredCourses?.length > 0 || filteredEvents?.length > 0 ? (
                <>
                    {filteredLearningObjects &&
                        filteredLearningObjects?.map((learningObject, index) => (
                            <div key={index} className={'libraryCard'}>
                                <LibraryObjectCard learningObject={learningObject} />
                            </div>
                        ))}

                    {filteredCourses &&
                        filteredCourses?.map((course) => <CourseCard key={course.id} course={course} pathName="Biblioteca" />)}

                    {filteredEvents &&
                        filteredEvents?.map((event) => (
                            <SmallEventCard key={event.id} event={event} returnUrl={window.location.pathname} />
                        ))}
                </>
            ) : (
                <Message text={`Nenhum material encontrado ${!verifyFilterIsBlank() ? 'com o filtro inserido' : ''}`} />
            )}
        </ContainerDesktop>
    );
};

LibraryList.propTypes = {
    courses: PropTypes.arrayOf(PropTypes.shape(CourseType)),
    events: PropTypes.arrayOf(PropTypes.shape(EventType)),
    learningObjects: PropTypes.arrayOf(PropTypes.shape(learningObjectType)),
    filterSearch: PropTypes.object,
    marginTop: PropTypes.bool,
    columns: PropTypes.number
};

export default LibraryList;
