import { useDispatch } from 'react-redux';

const useSnackbar = () => {
    const dispatch = useDispatch();

    const showSnackbar = (message, type) => {
        dispatch({
            type: 'SET_SNACKBAR',
            payload: {
                text: message,
                type: type
            }
        });
    };

    return showSnackbar;
};

export default useSnackbar;
