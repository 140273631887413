import React, { useEffect, useState } from 'react';

import FormMaker from 'components/organisms/layout/form/FormMaker';
import { getCities, getResourceEditData, updateProfile } from 'services/profile';
import { Validator } from 'utils/validators';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import useSnackbar from 'hooks/useSnackbar';

const EditProfileForm = ({ profile }) => {
    const [getProfile, setGetProfile] = useState(false);
    const [resource, setResource] = useState({});
    const [startValue, setStartValue] = useState({});
    const auth = useSelector((state) => state.auth);
    const snackbar = useSnackbar();

    const parseStartValue = (profile) => {
        const parsedValue = {
            name: profile?.name,
            occupation: profile?.occupation,
            idState: profile?.id_state,
            idCity: profile?.id_city,
            about: profile?.description,
            image: profile?.url_image,
            language: profile?.language?.map((language) => language?.id),
            academicEducation: profile?.academicEducation?.map((education) => {
                return {
                    endDate: education?.final_date ? new Date(education?.final_date) : null,
                    forecastDate: education?.end_forecast ? new Date(education?.end_forecast) : null,
                    institution: education.institution,
                    level: education.id_level_academic_education,
                    name: education.name,
                    startDate: education?.initial_date ? new Date(education?.initial_date) : null,
                    status: education?.id_status_academic_education
                };
            }),
            certifications: profile?.courseAndCertificate?.map((certification) => {
                return {
                    conclusionDate: certification?.conclusion_date ? new Date(certification?.conclusion_date) : null,
                    institution: certification.institution,
                    name: certification.name
                };
            }),
            profissionalExperiences: profile?.experience?.map((experience) => {
                return {
                    endDate: experience?.final_date ? new Date(experience?.final_date) : null,
                    forecastDate: experience?.end_forecast ? new Date(experience?.end_forecast) : null,
                    institution: experience.company,
                    name: experience.office,
                    startDate: experience?.initial_date ? new Date(experience?.initial_date) : null,
                    status: experience?.id_status_experience,
                    working: !experience?.final_date
                };
            })
        };

        return parsedValue;
    };

    const getData = async () => {
        await getResourceEditData()
            .then((response) => {
                setResource(response.data);
            })
            .catch((error) => {
                snackbar(error, 'error');
            });
    };

    const getUserData = async () => {
        setStartValue(parseStartValue(profile));
        setGetProfile(true);
    };

    const getCitiesData = async (stateId) => {
        if (stateId) {
            await getCities(stateId)
                .then((response) => {
                    setResource({
                        ...resource,
                        cities:
                            response.data.map((item) => {
                                return {
                                    value: item.name,
                                    id: item.id
                                };
                            }) || []
                    });
                    setGetProfile(true);
                })
                .catch((error) => {
                    snackbar(error.message, 'error');
                });
        }
    };

    const formSubmit = async (values) => {
        const formData = new FormData();

        formData.append('name', values?.name);
        formData.append('occupation', values?.occupation);
        formData.append('idState', Number(values?.idState));
        formData.append('idCity', Number(values?.idCity));
        formData.append('description', values?.about);

        if (values?.image && typeof values?.image !== 'string') {
            formData.append('image', values?.image);
        }

        if (values?.languages) {
            const languages = values?.languages.map((item) => {
                return {
                    idLanguage: item
                };
            });

            formData.append('language', JSON.stringify(languages));
        }

        const courseAndCertificateFormData = values?.certifications?.map((certification) => {
            return {
                name: certification?.name,
                conclusionDate: certification?.conclusionDate ? moment(certification?.conclusionDate).format('YYYY-MM-DD') : null,
                institution: certification?.institution
            };
        });

        if (courseAndCertificateFormData || courseAndCertificateFormData.length > 0) {
            formData.append('courseAndCertificate', JSON.stringify(courseAndCertificateFormData));
        }

        const experienceFormData = values?.profissionalExperiences?.map((profissionalExperience) => {
            return {
                office: profissionalExperience?.name,
                company: profissionalExperience?.institution,
                initialDate: profissionalExperience?.startDate ? moment(profissionalExperience?.startDate).format('YYYY-MM-DD') : null,
                finalDate: profissionalExperience?.endDate ? moment(profissionalExperience?.endDate).format('YYYY-MM-DD') : null
            };
        });

        if (experienceFormData || experienceFormData.length > 0) {
            formData.append('experience', JSON.stringify(experienceFormData));
        }

        const academicEducationFormData = values?.academicEducation?.map((education) => {
            return {
                name: education?.name,
                institution: education?.institution,
                idLevelAcademicEducation: education?.level,
                initialDate: moment(education?.startDate).format('YYYY-MM-DD'),
                finalDate: education?.endDate ? moment(education?.endDate).format('YYYY-MM-DD') : null,
                idStatusAcademicEducation: education?.status,
                endForecast: education?.forecastDate ? moment(education?.forecastDate).format('YYYY-MM-DD') : null
            };
        });

        if (academicEducationFormData || academicEducationFormData.length > 0) {
            formData.append('academicEducation', JSON.stringify(academicEducationFormData));
        }

        await updateProfile(formData, auth.user.studentId).then(() => {
            snackbar('Perfil atualizado com sucesso', 'success');
            window.location.href = '/aluno/perfil';
        });
    };

    const form = [
        [
            {
                id: 'image',
                type: 'dropfile',
                label: 'Subir imagem de perfil',
                initialValue: startValue?.image
            }
        ],
        [
            {
                id: 'name',
                label: 'Nome Completo',
                type: 'text',
                initialValue: startValue?.name,
                validation: Validator.string().max(255).required('O nome é obrigatório')
            }
        ],
        [
            {
                id: 'occupation',
                label: 'Cargo atual',
                type: 'text',
                initialValue: startValue?.occupation,
                validation: Validator.string().max(255).required('O cargo é obrigatório')
            }
        ],
        [
            {
                id: 'idState',
                label: 'Estado',
                type: 'dropdown',
                initialValue: startValue?.idState,
                onChange: getCitiesData,
                validation: Validator.number()
                    .equals([resource?.states?.map((item) => item.id)], 'Estado inválido')
                    .required('O estado é obrigatório'),
                options: resource?.states || []
            }
        ],
        [
            {
                id: 'idCity',
                label: 'Cidade',
                type: 'dropdown',
                initialValue: startValue?.idCity,
                validation: Validator.number()
                    .equals([resource?.cities?.map((item) => item.id)], 'Cidade inválida')
                    .required('O estado é obrigatório'),
                options: resource?.cities || []
            }
        ],
        [
            {
                id: 'about',
                label: 'Sobre',
                type: 'textarea',
                initialValue: startValue?.about,
                validation: Validator.string()
            }
        ],
        [
            {
                id: 'languages',
                type: 'multiSelect',
                label: 'Idiomas',
                initialValue: startValue?.language,
                validation: Validator.array(Validator.number()),
                options: resource?.languages || []
            }
        ],
        [
            {
                id: 'academicEducation',
                type: 'formList',
                label: 'Formação Acadêmica',
                initialValue: startValue?.academicEducation,
                buttonLabel: 'Adicionar nova Formação Acadêmica',
                validation: Validator.array(
                    Validator.object({
                        name: Validator.string().required('Nome é obrigatório'),
                        institution: Validator.string().required('Instituição de ensino é obrigatório'),
                        level: Validator.number()
                            .equals([resource?.levelAcademicEducation?.map((item) => item.id)], 'Nível inválido')
                            .required('O nível do curso é obrigatório'),
                        status: Validator.number()
                            .equals([resource?.statusAcademicEducation?.map((item) => item.id)], 'Status inválido')
                            .required('O status do curso é obrigatório'),
                        forecastDate: Validator.date()
                            .when('status', {
                                is: 2,
                                then: Validator.date('Data de conclusão é obrigatória').nullable()
                            })
                            .when('status', {
                                is: 1,
                                then: Validator.date('Data de conclusão é obrigatória').required('Data de término obrigatória')
                            })
                            .min(Validator.ref('startDate'), 'Previsão de término deve ser maior que a data de início'),
                        endDate: Validator.date()
                            .when('status', {
                                is: 0,
                                then: Validator.date('Data de conclusão é obrigatória')
                                    .required('Data de término obrigatória')
                                    .min(Validator.ref('startDate'), 'Data de término deve ser maior que a data de início')
                            })
                            .when('status', {
                                is: 1,
                                then: Validator.date('Data de conclusão é obrigatória').nullable()
                            })
                            .min(Validator.ref('startDate'), 'Previsão de término deve ser maior que a data de início'),
                        startDate: Validator.date().required('Data de início obrigatória')
                    })
                ),
                formData: [
                    [
                        {
                            id: 'name',
                            label: 'Nome do curso',
                            type: 'text'
                        }
                    ],
                    [
                        {
                            id: 'institution',
                            label: 'Instituição de Ensino',
                            type: 'text'
                        }
                    ],
                    [
                        {
                            id: 'level',
                            label: 'Nível do Curso',
                            type: 'dropdown',
                            options: resource?.levelAcademicEducation || []
                        }
                    ],
                    [
                        {
                            id: 'status',
                            label: 'Status do Curso',
                            type: 'dropdown',
                            options: resource?.statusAcademicEducation || []
                        }
                    ],
                    ,
                    [
                        {
                            id: 'startDate',
                            label: 'Data de início',
                            type: 'date',
                            maxDate: new Date()
                        }
                    ],
                    [
                        {
                            id: 'forecastDate',
                            label: 'Previsão de término',
                            type: 'date',
                            conditionAttr: 'status',
                            conditionValue: (status) => Number(status) === 1,
                            minDate: new Date()
                        }
                    ],
                    [
                        {
                            id: 'endDate',
                            label: 'Data de término',
                            type: 'date',
                            conditionAttr: 'status',
                            conditionValue: (status) => Number(status) !== 1,
                            maxDate: new Date()
                        }
                    ]
                ]
            }
        ],
        [
            {
                id: 'certifications',
                type: 'formList',
                label: 'Curso ou Certificado',
                initialValue: startValue?.certifications,
                buttonLabel: 'Adicionar Curso ou Certificado',
                validation: Validator.array(
                    Validator.object({
                        name: Validator.string().required('Nome é obrigatório'),
                        institution: Validator.string().required('Instituição de ensino é obrigatório'),
                        conclusionDate: Validator.date().required('Data de conclusão é obrigatória')
                    })
                ),
                formData: [
                    [
                        {
                            id: 'name',
                            label: 'Nome do curso',
                            type: 'text'
                        }
                    ],
                    [
                        {
                            id: 'institution',
                            label: 'Instituição de Ensino',
                            type: 'text'
                        }
                    ],
                    [
                        {
                            id: 'conclusionDate',
                            label: 'Data de conclusão',
                            type: 'date',
                            maxDate: new Date()
                        }
                    ]
                ]
            }
        ],
        [
            {
                id: 'profissionalExperiences',
                type: 'formList',
                label: 'Experiência Profissional',
                buttonLabel: 'Adicionar Experiência Profissional',
                initialValue: startValue?.profissionalExperiences,
                validation: Validator.array(
                    Validator.object({
                        name: Validator.string().required('Nome é obrigatório'),
                        institution: Validator.string().required('Instituição de ensino é obrigatório'),
                        startDate: Validator.date().required('Data de início é obrigatória'),
                        working: Validator.boolean(),
                        endDate: Validator.date()
                            .when('working', {
                                is: false,
                                then: Validator.date('Data de conclusão é obrigatória')
                                    .required('Data de término obrigatória')
                                    .min(Validator.ref('startDate'), 'Data de término deve ser maior que a data de início')
                            })
                            .when('working', {
                                is: true,
                                then: Validator.date('Data de conclusão deve ser uma data').nullable()
                            })
                            .min(Validator.ref('startDate'), 'Previsão de término deve ser maior que a data de início')
                    })
                ),
                formData: [
                    [
                        {
                            id: 'name',
                            label: 'Nome do cargo',
                            type: 'text'
                        }
                    ],
                    [
                        {
                            id: 'institution',
                            label: 'Empresa',
                            type: 'text'
                        }
                    ],
                    [
                        {
                            id: 'startDate',
                            label: 'Data de início',
                            type: 'date',
                            maxDate: new Date()
                        }
                    ],
                    [
                        {
                            id: 'working',
                            label: 'Trabalhando atualmente',
                            type: 'checkbox'
                        }
                    ],
                    [
                        {
                            id: 'endDate',
                            label: 'Data de término',
                            type: 'date',
                            conditionAttr: 'working',
                            conditionValue: (working) => !working
                        }
                    ]
                ]
            }
        ]
    ];

    useEffect(() => {
        getData().then(() => getUserData());
    }, []);

    if (!getProfile) {
        return <CircularProgress />;
    }

    return <FormMaker data={form} formSubmit={formSubmit} />;
};

EditProfileForm.propTypes = {
    profile: PropTypes.object
};

export default EditProfileForm;
