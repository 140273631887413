import BlondeLevel from 'components/atoms/structure/BlondeLevel';
import Svg from 'components/atoms/structure/Svg';
import ModalCard from 'components/organisms/layout/modals/ModalCard';
import React from 'react';
import { getSystemImageUrl } from 'utils/assets';
import { getTypeByLevel } from 'utils/rules/level';
import PropTypes from 'prop-types';

import { Container, GoldText, ImageContainer } from './styles';
import { FeedbackType } from 'types/components/feedback';
import useFeedback from 'hooks/useFeedback';

const LevelUpModal = ({ feedback }) => {
    const { level } = feedback;
    const hook = useFeedback();

    const getLevelImageKey = () => {
        if (level.name === '1 estrela - Prata') {
            return 'SilverMember';
        }

        if (level.name === '1 estrela - Ouro') {
            return 'GoldMember';
        }

        return 'LevelUp';
    };

    return (
        <ModalCard open={true} title="Nível conquistado" handleClose={hook.closeFeedback}>
            {
                {
                    SilverMember: (
                        <Container>
                            <h3>Parabéns!</h3>

                            <p>Você é um membro PFA estrela prata.</p>

                            <ImageContainer>
                                <Svg url={getSystemImageUrl('icone primeiro.svg')} width="108" height="108" />
                            </ImageContainer>

                            <p>
                                Continue avançando no PFA <br /> e conquiste mais estrelas!
                            </p>
                        </Container>
                    ),
                    GoldMember: (
                        <Container>
                            <h3>Parabéns!</h3>

                            <p>Você conquistou a sua primeira estrela de:</p>

                            <ImageContainer>
                                <Svg url={getSystemImageUrl('Groupmedal.svg')} width="108" height="108" />
                                <GoldText>Ouro</GoldText>
                            </ImageContainer>

                            <p>Continue avançando no PFA e conquiste mais estrelas!</p>
                        </Container>
                    ),
                    LevelUp: (
                        <Container>
                            <h3>Parabéns!</h3>

                            <p>Você evoluiu em mais um nível no seu perfil do PFA.</p>

                            <ImageContainer>
                                <BlondeLevel level={level} />
                                <footer>
                                    <h3>{getTypeByLevel(level.name)}</h3>
                                </footer>
                            </ImageContainer>

                            <p>Continue avançando no PFA e conquiste mais estrelas!</p>
                        </Container>
                    )
                }[getLevelImageKey()]
            }
        </ModalCard>
    );
};

LevelUpModal.propTypes = {
    feedback: PropTypes.shape(FeedbackType).isRequired
};

export default LevelUpModal;
