import React from 'react';
import PropTypes from 'prop-types';

import { Container, InfoContainer } from './styles';
import { LevelType } from 'types/data/level';
import Progress from 'components/atoms/inputs/Progress';
import Stars from 'components/atoms/structure/Stars';
import { InfoOutlined } from '@mui/icons-material';
import useFeedback from 'hooks/useFeedback';
import { checkInfoCard, getStarsByLevel } from 'utils/rules/level';
import BlockedLevelCard from '../BlockedLevelCard';

const LevelCard = ({ currentLevel, level }) => {
    const isCurrentLevel = currentLevel === level.id;
    const passedLevel = currentLevel > level.id;
    const componentId = `level-card-${level.id}`;
    const infoCard = checkInfoCard(level.name);

    const feedback = useFeedback();

    const getProgress = () => {
        if (level?.progress === undefined) {
            return 0;
        }

        if (typeof level?.progress === 'number') {
            return level.progress;
        }

        const listProgress = Object.values(level.progress);
        const countTrues = listProgress.filter((item) => item).length;

        return (countTrues / listProgress.length) * 100;
    };

    const viewInfoCard = () => {
        if (!infoCard) {
            return;
        }

        if (currentLevel <= level.id) {
            feedback.toLevelUp(level, currentLevel);
        }
    };

    if (level.blocked) {
        return <BlockedLevelCard level={level} />;
    }

    return (
        <Container pointer={!!infoCard} id={componentId} onClick={() => viewInfoCard()}>
            <header>
                <Stars data={getStarsByLevel(level.name, currentLevel < level.id)} />
            </header>

            <main>
                {(passedLevel || infoCard || isCurrentLevel) && (
                    <span>
                        {(passedLevel || isCurrentLevel) && <span>{isCurrentLevel ? 'Nível atual!' : 'Nível conquistado!'}</span>}
                        {infoCard && (
                            <InfoContainer edge="end" size="small">
                                <InfoOutlined fontSize="12" />
                            </InfoContainer>
                        )}
                    </span>
                )}

                <h2>{level.name}</h2>
            </main>

            <footer>
                <Progress progress={getProgress()} color="secondary" />
            </footer>
        </Container>
    );
};

LevelCard.propTypes = {
    currentLevel: PropTypes.number,
    level: PropTypes.shape(LevelType)
};

export default LevelCard;
