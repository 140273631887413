import styled from 'styled-components';
import { List, ListItemButton, ListItemText } from '@mui/material';

export const ListStyled = styled(List)`
    background-color: white;
    border-radius: 8px;
    overflow: hidden;

    margin-top: 23px;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.05));
`;
export const ListItemButtonStyled = styled(ListItemButton)`
    padding: 13.5px 16px 13.5px 16px;
    font-size: 16px;
    &.active {
        background-color: #e7f5e6;
    }

    :not(:first-of-type) {
        border-top: 1px solid #e0e0e0;
    }

    &:hover {
        filter: brightness(0.9);
    }
`;
export const ListItemButtonCompetence = styled(ListItemButton)`
    padding: 10px 12px;
    border-left: ${(props) => (props.border ? `4px solid ${props.border}` : 'none')};
    font-size: 10.5pt !important;

    &.active {
        background-color: #e7f5e6;
    }

    :not(:first-of-type) {
        border-top: 1px solid #e0e0e0;
    }

    &:hover {
        filter: brightness(0.9);
    }
`;
export const ListItemTextStyled = styled(ListItemText)`
    margin: 0;
    padding: 16px 16px 11px 16px;

    > * {
        font-weight: bold;
    }
    :not(:first-of-type) {
        border-top: 1px solid #e0e0e0;
    }
`;

export const CompetenceGroupList = styled('ul')`
    margin-top: 10px;
`;

export const CompetenceGroupItem = styled('li')`
    position: relative;
    margin-top: 5px;
    display: flex;
    align-items: center;
    &:before {
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        background-color: ${(props) => props.color};
        margin-right: 10px;
        border-radius: 50%;
    }
`;
