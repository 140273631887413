import React from 'react';
import { LevelType } from 'types/data/level';
import PropTypes from 'prop-types';

import { Container } from './styles';
import InfoLogLevel from 'components/molecules/containers/InfoLogLevel';

const Level1GoldLevelModal = ({ level, levelConquered }) => {
    const data = [
        {
            active: levelConquered,
            title: 'Avaliação Estagiário',
            url: 'https://forms.gle/X9ikvQQ9KaGS4QUC9',
            target: '_blank'
        }
    ];
    return (
        <Container>
            <p>Desafio: </p>

            <h2>Conclua a avaliação do seu estágio</h2>

            <span>Faça os testes</span>

            <InfoLogLevel data={data} />
        </Container>
    );
};

Level1GoldLevelModal.propTypes = {
    level: PropTypes.shape(LevelType),
    levelConquered: PropTypes.bool
};

export default Level1GoldLevelModal;
