import styled from 'styled-components';

export const Container = styled.div`
    background-color: #fff;
    width: 100%;
    margin: 0;
    transition: var(--transition);
    top: 0;
    overflow: auto;
    min-height: 100vh;
    left: 0;
    overflow-x: hidden;

    > main {
        margin-top: 4rem;
        padding: var(--padding-base);
        width: 100%;
        max-width: 600px;
        overflow-x: hidden;
        display: flex;
        justify-content: center;
    }
`;
