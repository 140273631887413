import React from 'react';
import Meta from 'components/templates/Seo/Meta';
import { CircularProgress } from '@mui/material';
import { removeCookie } from 'utils/cookies';
import { useEffect } from 'react';
import useQueryParam from 'hooks/useQueryParam';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { addHours } from 'utils/date';

const Admin = () => {
    const query = useQueryParam();
    const navigate = useNavigate();

    const setToken = (token, personification) => {
        removeCookie('token');

        if (!token) {
            navigate('/login');
            return;
        }

        const hours = 7 * 24;

        Cookies.set('token', token, {
            expires: addHours(new Date(), hours)
        });

        Cookies.set('personification', personification, {
            expires: addHours(new Date(), hours)
        });

        window.location.href = '/';
    };

    useEffect(() => {
        const token = query.get('token');
        const personification = query.get('personification');

        setToken(token, personification);
    }, []);

    return (
        <>
            <Meta title="Auth" description="Faça seu login" />
            <CircularProgress color="inherit" />
        </>
    );
};

export default Admin;
