import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import Svg from 'components/atoms/structure/Svg';

const CircleGreenButton = ({ onClick, iconUrl }) => {
    return (
        <Container onClick={() => onClick()}>
            <Svg url={iconUrl} width="25" height="25" />
        </Container>
    );
};

CircleGreenButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    iconUrl: PropTypes.string.isRequired
};

export default CircleGreenButton;
