import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const CarouselButton = ({ text, value, active, onClick, selectable }) => {
    const handleClick = () => {
        if (!selectable) {
            return;
        }

        onClick(value);
    };

    return (
        <Container selectable={selectable} active={active} onClick={() => handleClick()}>
            <span>{text}</span>
        </Container>
    );
};

CarouselButton.propTypes = {
    text: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    selectable: PropTypes.bool.isRequired
};

export default CarouselButton;
