import React, { useEffect } from 'react';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import NavigationScroll from './components/templates/layouts/Components/NavigationScroll';
import Routes from './routes';
import GlobalStyles from './styles/global';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/theme';
import ReactGA from 'react-ga';
import config from './config';

const App = () => {
    ReactGA.initialize(config.google_analytics);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme()}>
                <CssBaseline />
                <GlobalStyles />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
