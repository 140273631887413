import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { LevelType } from 'types/data/level';
import LevelIcon from 'components/molecules/containers/LevelIcon';

const BigLevelCard = ({ level }) => {
    if (!level?.id) {
        return null;
    }

    return (
        <Container>
            <LevelIcon level={level} />
        </Container>
    );
};

BigLevelCard.propTypes = {
    level: PropTypes.shape(LevelType)
};

export default BigLevelCard;
