import React from 'react';
import PropTypes from 'prop-types';
import { AccessTimeOutlined } from '@mui/icons-material';
import { Container } from './styles';
import moment from 'moment';

const TimerText = ({ initialDate, finalDate, big, flexEnd }) => {
    const startTime = moment(initialDate).format('HH:mm');

    const finalTime = finalDate ? moment(finalDate).format('HH:mm') : null;

    return (
        <Container large={!!finalTime} big={big} flexEnd={flexEnd}>
            <aside>
                <AccessTimeOutlined fontSize="small" color="gray" />
            </aside>
            <p>
                {startTime} {finalTime && `- ${finalTime}`}
            </p>
        </Container>
    );
};

TimerText.propTypes = {
    initialDate: PropTypes.string.isRequired,
    finalDate: PropTypes.string,
    big: PropTypes.bool,
    flexEnd: PropTypes.bool
};

export default TimerText;
