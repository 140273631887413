import styled from 'styled-components';
import ReactQuill from 'react-quill';

export const Container = styled.div`
    width: 100%;
    border-radius: var(--border-radius);

    > header {
        padding-bottom: 0.2rem;

        > span {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 130%;
            color: #464646;
        }
    }

    > span {
        color: #d32f2f;
        font-size: 12px;
        margin-left: 0.5rem;
    }
`;

export const Content = styled(ReactQuill)`
    width: 100%;
    border-radius: var(--border-radius);

    .quill {
        border-radius: var(--border-radius);
    }
`;
