import styled, { css } from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    color: var(--color-text);
    padding: 1rem 1rem 0.6rem 1rem;

    ${(props) =>
        props.active &&
        css`
            z-index: 50;
            background-color: #e7f5e6;
        `}

    &:hover {
        background-color: #e7f5e6;
    }

    > aside {
        margin-right: 0.5rem;
        width: 1.5rem;
    }

    > main {
        p {
            padding-bottom: 0rem;
            font-size: 1rem;
            font-weight: 600;
        }
    }
`;
