import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    cursor: pointer;
    position: relative;
    height: 100%;

    > header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;

        > span {
            display: flex;
            align-items: center;
            justify-content: center;
            filter: invert(16%) sepia(10%) saturate(5445%) hue-rotate(117deg) brightness(103%) contrast(92%);

            > svg {
                filter: brightness(0) saturate(100%);
            }
        }

        > h3 {
            margin-left: 8px;

            font-weight: 600;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
        }
    }

    > footer {
        width: 100%;
        height: 4px;
        position: absolute;
        bottom: 0rem;
        background: #6b9d4a;
        border-radius: 8px 8px 0px 0px;
    }
`;
