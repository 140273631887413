import { Avatar } from '@mui/material';
import styled from 'styled-components';
import { AvatarGroup } from '@mui/material';

export const Container = styled(AvatarGroup)``;

export const Image = styled(Avatar)`
    z-index: ${(props) => props.zindex || '1'};
    cursor: pointer;

    &:nth-of-type(6) {
        background-color: #375623;
    }

    &:nth-of-type(5) {
        background-color: #476633;
    }

    &:nth-of-type(4) {
        background-color: #6b9d4a;
    }

    &:nth-of-type(3) {
        background-color: #bedabd;
    }

    &:nth-of-type(2) {
        background-color: #c0e1d9;
    }

    &:first-of-type {
        background-color: #476633;
    }

    @media (min-width: 768px) {
        width: 32px;
        height: 32px;

        font-size: 0.9rem;
    }
`;
