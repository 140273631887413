import SubSection from 'components/molecules/structure/SubSection';
import React from 'react';
import PropTypes from 'prop-types';
import { CarouselContainer, Container, DesktopContainer, Footer, GridContainer } from './styles';
import FullWidthContainer from 'components/atoms/structure/FullWidthContainer';
import ParticipatingCourseCard from 'components/molecules/cards/ParticipatingCourseCard';
import FinishedCourseCard from 'components/molecules/cards/FinishedCourseCard';
import { useNavigate } from 'react-router-dom';
import Message from 'components/molecules/alerts/Message';
import useWindowSize from '../../../../hooks/useWindowSize';
import CarouselArrowButton from '../../buttons/CarouselArrowButton';
import GridCourseCard from '../../cards/GridCourseCard';

const CoursesCarousel = ({ courses, removeCompletedCourse, finishedCourses, label, errorLabel, newCourses, cardVariant }) => {
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    const redirectToAll = () => {
        const path = window.location.pathname;

        if (finishedCourses) {
            navigate(`/aluno/cursos/concluidos${path ? `?returnUrl=${path}` : ''}`);
        }

        if (newCourses) {
            navigate(`/aluno/cursos/todos${path ? `?returnUrl=${path}` : ''}`);
        }
    };
    if (windowSize.mobile) {
        return (
            <FullWidthContainer>
                <SubSection title={label} paddingLeft borderTop>
                    <Container>
                        {courses.length === 0 ? (
                            <Message html text={errorLabel} />
                        ) : (
                            <>
                                {finishedCourses
                                    ? courses.map((course, index) => <FinishedCourseCard course={course} key={index} />)
                                    : courses.map((course, index) => (
                                          <ParticipatingCourseCard
                                              course={course}
                                              key={index}
                                              removeCompletedCourse={removeCompletedCourse}
                                              newCourse={newCourses}
                                          />
                                      ))}
                            </>
                        )}
                    </Container>
                    {(finishedCourses || newCourses) && courses.length > 0 && (
                        <Footer>
                            <button onClick={() => redirectToAll()}>Ver tudo</button>
                        </Footer>
                    )}
                </SubSection>
            </FullWidthContainer>
        );
    }

    return (
        <DesktopContainer>
            {courses.length <= 3 && (
                <GridContainer>
                    {courses.map((course, index) => (
                        <GridCourseCard key={`course-${index}`} course={course} variant={cardVariant} />
                    ))}
                </GridContainer>
            )}
            {courses.length > 3 && (
                <CarouselContainer
                    cellSpacing={16}
                    dragging={false}
                    swiping={false}
                    renderCenterLeftControls={({ previousSlide }) => (
                        <CarouselArrowButton onClick={previousSlide} type="left" backgroundWhite />
                    )}
                    renderCenterRightControls={({ nextSlide }) => <CarouselArrowButton onClick={nextSlide} type="right" backgroundWhite />}
                    renderBottomCenterControls={null}
                    dragThreshold={0.1}
                    slidesToShow={3}
                    wrapAround
                    enableKeyboardControls={false}
                    scrollMode="remainder"
                >
                    {courses.map((course, index) => (
                        <GridCourseCard key={`course-${index}`} course={course} variant={cardVariant} />
                    ))}
                </CarouselContainer>
            )}
        </DesktopContainer>
    );
};

CoursesCarousel.propTypes = {
    courses: PropTypes.arrayOf(PropTypes.object).isRequired,
    removeCompletedCourse: PropTypes.func,
    finishedCourses: PropTypes.bool,
    newCourses: PropTypes.bool,
    label: PropTypes.string,
    errorLabel: PropTypes.string,
    cardVariant: PropTypes.string
};

export default CoursesCarousel;
