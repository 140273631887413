import styled from 'styled-components';

export const Frame = styled.iframe`
    ::-webkit-scrollbar {
        border-radius: 10px;
        width: 0.4rem;
        height: 0.4rem;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #bbb;
    }

    ::-webkit-scrollbar-thumb:hover {
        border-radius: 10px;
        background: #aaa;
    }

    @media screen and (max-width: 768px) {
        ::-webkit-scrollbar {
            border-radius: 10px;
            width: 0.1rem;
            height: 0.1rem;
        }
    }
`;
