import React from 'react';
import {
    AccountCircle,
    Help,
    Settings,
    Logout,
    Notifications,
    AccountCircleOutlined,
    SettingsOutlined,
    NotificationsOutlined,
    HelpOutline
} from '@mui/icons-material';
import Svg from 'components/atoms/structure/Svg';
import { getSystemImageUrl } from 'utils/assets';

export const sidebarData = [
    {
        title: 'Home',
        icon: <Svg url={getSystemImageUrl('Home=Default.svg')} image height={24} width={24} />,
        activeIcon: <Svg url={getSystemImageUrl('Home=Select.svg')} image height={24} width={24} />,
        link: '/aluno',
        disabled: false
    },
    {
        title: 'Eventos',
        icon: <Svg url={getSystemImageUrl('Eventos=Default.svg')} image height={24} width={24} />,
        activeIcon: <Svg url={getSystemImageUrl('Eventos=Select.svg')} image height={24} width={24} />,
        link: '/aluno/eventos',
        disabled: false
    },
    {
        title: 'Cursos',
        icon: <Svg url={getSystemImageUrl('Cursos=Default.svg')} image height={24} width={24} />,
        activeIcon: <Svg url={getSystemImageUrl('Cursos=Select.svg')} image height={24} width={24} />,
        link: '/aluno/cursos',
        disabled: false
    },
    {
        title: 'Favoritos',
        icon: <Svg url={getSystemImageUrl('Favoritos=Default.svg')} image height={24} width={24} />,
        activeIcon: <Svg url={getSystemImageUrl('Favoritos=Select.svg')} image height={24} width={24} />,
        link: '/aluno/favoritos',
        disabled: false
    },
    {
        title: 'Biblioteca',
        icon: <Svg url={getSystemImageUrl('Biblioteca=Default.svg')} image height={24} width={24} />,
        activeIcon: <Svg url={getSystemImageUrl('Biblioteca=Select.svg')} image height={24} width={24} />,
        link: '/aluno/biblioteca',
        disabled: false
    },
    {
        title: 'Perfil',
        icon: <AccountCircleOutlined color="primary" />,
        activeIcon: <AccountCircle color="primary" />,
        link: '/aluno/perfil',
        disabled: false
    },
    {
        title: 'Ajuda',
        icon: <HelpOutline color="primary" />,
        activeIcon: <Help color="primary" />,
        link: '/aluno/ajuda',
        disabled: false
    },
    {
        title: 'Configurações da conta',
        icon: <SettingsOutlined color="primary" />,
        activeIcon: <Settings color="primary" />,
        link: '/aluno/minha-conta',
        disabled: false
    },
    {
        title: 'Notificações',
        icon: <NotificationsOutlined color="primary" />,
        activeIcon: <Notifications color="primary" />,
        link: '/aluno/notificacoes',
        disabled: false
    },
    {
        title: 'Sair',
        icon: <Logout color="primary" />,
        activeIcon: <Logout color="primary" />,
        link: '/logout',
        disabled: false
    }
];
