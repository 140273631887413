import React from 'react';
import PropTypes from 'prop-types';
import { Container, Item, Link } from './styles';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { getFallbackUrl } from '../../../../utils/general';
import useFeedback from '../../../../hooks/useFeedback';
import { useNavigate } from 'react-router-dom';

const InfoLogLevel = ({ data }) => {
    const hook = useFeedback();
    const navigate = useNavigate();
    const goToResource = (url) => () => {
        hook.closeFeedback();
        navigate(getFallbackUrl(url, 'Perfil'));
    };
    return (
        <Container>
            {data.map((item, index) => (
                <Item key={index} active={item.active}>
                    {item.active ? (
                        <CheckCircle htmlColor="#6B9D4A" />
                    ) : (
                        <RadioButtonUnchecked
                            htmlColor="#A1A1A7
"
                        />
                    )}
                    <Link
                        href={item.target && item.target === '_blank' ? item.url : 'javascript:void(0)'}
                        target={item.target ?? '_self'}
                        onClick={!item.target && goToResource(item.url)}
                    >
                        {item.title}
                    </Link>
                </Item>
            ))}
        </Container>
    );
};

InfoLogLevel.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            active: PropTypes.bool,
            title: PropTypes.string
        })
    )
};

export default InfoLogLevel;
