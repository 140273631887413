import { IconButton, Modal } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Modal)`
    margin: auto;
    max-width: ${({ formLayout }) => (formLayout ? '900px' : '600px')};
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    padding: 1rem;
`;

export const Content = styled.div`
    position: absolute;
    width: 90vw;
    max-width: ${({ formLayout }) => (formLayout ? '600px' : '400px')};
    padding: ${({ formLayout }) => (formLayout ? '0' : '1rem')};
    background-color: #fff;
    border-radius: 10px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > header {
        position: relative;
        display: flex;
        justify-content: ${({ formLayout }) => (formLayout ? 'flex-start' : 'center')};
        align-items: center;
        width: 100%;
        color: var(--color-primary);
        padding-bottom: 0.8rem;
        border-bottom: 1px solid #c7c7c7;

        ${({ formLayout }) => formLayout && 'padding: 1rem ;'}

        > h3 {
            font-size: 1.1rem;
        }
    }

    > main {
        padding-top: 1rem;
        width: 100%;

        ${({ formLayout }) => formLayout && 'padding: 1rem;'}
    }
`;

export const CloseButton = styled(IconButton)`
    position: absolute;
    top: ${({ formLayout }) => (formLayout ? '0.3rem' : '-0.8rem')};
    right: ${({ formLayout }) => (formLayout ? '0.5rem' : '-0.5rem')};
`;
