import FormMaker from 'components/organisms/layout/form/FormMaker';
import useSnackbar from 'hooks/useSnackbar';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signUp } from 'services/auth';
import { Validator } from 'utils/validators';

import { Container } from './styles';

const FormSignUp = () => {
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const [invalidEmails, setInvalidEmails] = useState([]);

    const form = [
        [
            {
                id: 'name',
                type: 'text',
                label: 'Nome',
                validation: Validator.string().required('Nome é obrigatório')
            }
        ],
        [
            {
                id: 'email',
                label: 'E-mail',
                type: 'text',
                validation: Validator.string()
                    .email('Digite um email válido')
                    .max(255)
                    .notOneOf(invalidEmails, 'E-mail já cadastrado')
                    .required('Email Obrigatório')
            }
        ],
        [
            {
                id: 'password',
                label: 'Senha',
                type: 'password',
                validation: Validator.string()
                    .oneOf([Validator.ref('passwordRepeat'), null], 'Senhas são divergentes')
                    .min(8, 'Senha deve possuir 8 ou mais caracteres')
                    .required('Senha é obrigatória')
            }
        ],
        [
            {
                id: 'passwordRepeat',
                label: 'Confirme a senha',
                type: 'password',
                validation: Validator.string()
                    .oneOf([Validator.ref('password'), null], 'Senhas são divergentes')
                    .min(8, 'Senha deve possuir 8 ou mais caracteres')
                    .required('Confirmação de senha é obrigatória')
            }
        ],
        [
            {
                id: 'terms',
                type: 'terms',
                initialValue: false,
                validation: Validator.bool()
                    .isTrue('Você deve aceitar os termos e condições')
                    .required('Você deve aceitar os termos e condições')
            }
        ]
    ];

    const onSubmit = async (data, setErrors) => {
        await signUp(data?.name, data?.email, data?.password, data?.passwordRepeat, data?.terms)
            .then(() => {
                snackbar('Usuário cadastrado com sucesso', 'success');
                setTimeout(() => navigate('/login'), 1000);
            })
            .catch((err) => {
                if (err.message === 'Email já cadastrado') {
                    snackbar(err.message, 'error');
                    setErrors({ email: err.message });
                    setInvalidEmails([...invalidEmails, data.email]);
                    return;
                }
                snackbar(err.message, 'error');
            });
    };

    return (
        <Container>
            <header>
                <h2>Faça o seu cadastro</h2>
            </header>
            <main>
                <FormMaker data={form} formSubmit={onSubmit} fullWidthButtonSubmit submitText="Cadastrar" noConfirm />
            </main>

            <footer>
                <a onClick={() => navigate('/login')} aria-hidden="true">
                    Eu já possuo uma conta
                </a>
            </footer>
        </Container>
    );
};

export default FormSignUp;
