import React from 'react';
import PropTypes from 'prop-types';
import { Container, Title, Content, Text, Actions, Button, Line, Header, DesktopContainer, DialogContent, CloseButton } from './styles';
import useWindowSize from '../../../../hooks/useWindowSize';
import { Close as CloseIcon } from '@mui/icons-material';

const Dialog = ({ title, text, submit, handler, open, cancelText, submitText, noButton }) => {
    const windowSize = useWindowSize();
    const onSubmit = () => {
        submit();
        handler();
    };

    if (windowSize.mobile) {
        return (
            <Container open={open} onClose={handler} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <Title size="small" id="alert-dialog-title">
                    {title}
                </Title>

                {text && <Line />}

                <Content>
                    <Text>{text}</Text>
                </Content>
                {!noButton && (
                    <Actions>
                        <Button cancel="true" size="small" onClick={handler}>
                            {cancelText || 'Cancelar'}
                        </Button>
                        <Button size="small" onClick={() => onSubmit()}>
                            {submitText || 'Continuar'}
                        </Button>
                    </Actions>
                )}
            </Container>
        );
    }
    return (
        <DesktopContainer open={open} onClose={handler} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogContent>
                <Header>
                    {title}
                    <CloseButton onClick={handler}>
                        <CloseIcon />
                    </CloseButton>
                </Header>
                <Content>
                    <Text>{text}</Text>
                </Content>
                {!noButton && (
                    <Actions>
                        <Button cancel="true" size="small" onClick={handler}>
                            {cancelText || 'Cancelar'}
                        </Button>
                        <Button size="small" onClick={() => onSubmit()}>
                            {submitText || 'Continuar'}
                        </Button>
                    </Actions>
                )}
            </DialogContent>
        </DesktopContainer>
    );
};

Dialog.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    submit: PropTypes.func.isRequired,
    handler: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    cancelText: PropTypes.string,
    submitText: PropTypes.string,
    noButton: PropTypes.bool
};

export default Dialog;
