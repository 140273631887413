import { CircularProgress } from '@mui/material';
import PostCard from 'components/molecules/cards/PostCard';
import SubSection from 'components/molecules/structure/SubSection';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Container, DesktopContainer } from './styles';
import { TimelineItem } from 'types/data/timeline';
import TimelineCard from 'components/molecules/cards/TimelineCard';
import TimelineLearningObjectCard from 'components/molecules/cards/TimelineLearningObjectCard';
import OpportunityCard from 'components/molecules/cards/OpportunityCard';
import Message from 'components/molecules/alerts/Message';
import TimelineHeader from 'components/organisms/containers/TimelineHeader';
import useWindowSize from 'hooks/useWindowSize';

const Timeline = ({ posts, lastPage }) => {
    const windowSize = useWindowSize();

    const tab = useSelector((state) => state.timeline.tab);

    if (windowSize.mobile) {
        return (
            <SubSection title="Fique por dentro:" borderTop>
                {!posts ? (
                    <CircularProgress />
                ) : posts.length === 0 ? (
                    <Message text="Nenhum post encontrado" />
                ) : (
                    <Container>
                        <TimelineHeader />
                        {tab === 'all'
                            ? posts?.map((post, index) => {
                                  if (post.typeText === 'Postagem') {
                                      return <PostCard key={index} post={post} />;
                                  }

                                  if (post.typeText === 'Oportunidade') {
                                      return <OpportunityCard key={index} post={post} />;
                                  }

                                  if (post.typeText === 'Gravação do evento') {
                                      return <TimelineCard key={index} post={post} />;
                                  }

                                  if (post.typeText === 'Biblioteca') {
                                      return <TimelineLearningObjectCard key={index} post={post} />;
                                  }

                                  return <TimelineCard key={index} post={post} />;
                              })
                            : posts?.map((post, index) => {
                                  if (post.typeText === 'Oportunidade') {
                                      return <OpportunityCard key={index} post={post} />;
                                  }
                                  <Message text="Nenhuma vaga encontrada" />;
                              })}

                        {!lastPage && <footer> {tab === 'all' ? <CircularProgress /> : null}</footer>}
                    </Container>
                )}
            </SubSection>
        );
    }

    return (
        <DesktopContainer>
            <header></header>
            <main>
                {!posts ? (
                    <CircularProgress />
                ) : posts.length === 0 ? (
                    <Message text="Nenhum post encontrado" />
                ) : (
                    <Container>
                        <TimelineHeader />
                        {tab === 'all'
                            ? posts?.map((post, index) => {
                                  if (post.typeText === 'Postagem') {
                                      return <PostCard key={index} post={post} />;
                                  }

                                  if (post.typeText === 'Oportunidade') {
                                      return <OpportunityCard key={index} post={post} />;
                                  }

                                  if (post.typeText === 'Gravação do evento') {
                                      return <TimelineCard key={index} post={post} />;
                                  }

                                  if (post.typeText === 'Biblioteca') {
                                      return <TimelineLearningObjectCard key={index} post={post} />;
                                  }

                                  return <TimelineCard key={index} post={post} />;
                              })
                            : posts?.map((post, index) => {
                                  if (post.typeText === 'Oportunidade') {
                                      return <OpportunityCard key={index} post={post} />;
                                  }
                                  <Message text="Nenhuma vaga encontrada" />;
                              })}

                        {!lastPage && <footer> {tab === 'all' ? <CircularProgress /> : null}</footer>}
                    </Container>
                )}
            </main>
        </DesktopContainer>
    );
};

Timeline.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.shape(TimelineItem)),
    lastPage: PropTypes.bool
};

export default Timeline;
