import { CircularProgress } from '@mui/material';
import FullWidthContainer from 'components/atoms/structure/FullWidthContainer';
import Message from 'components/molecules/alerts/Message';
import CompetenceHeader from 'components/molecules/headers/CompetenceHeader';
import TabsHeader from 'components/molecules/headers/TabsHeader';
import CoursesList from 'components/organisms/lists/CoursesList';
import LibraryList from 'components/organisms/lists/LibraryList';
import SmallEventList from 'components/organisms/lists/SmallEventList';
import Meta from 'components/templates/Seo/Meta';
import useSnackbar from 'hooks/useSnackbar';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    getCompetenceById,
    getCompetenceCourses,
    getCompetenceEvents,
    getCompetenceLibraries,
    getStudentCompetences
} from 'services/competences';
import useWindowSize from '../../../../../hooks/useWindowSize';
import { DesktopContainer } from './styles';
import CompetenceList from '../../../../../components/molecules/lists/CompetenceList';
import CoursesCarousel from '../../../../../components/molecules/carousels/CoursesCarousel';
import CompetenceKnob from '../../../../../components/molecules/cards/CompetenceKnob';
import EventsCarousel from '../../../../../components/molecules/carousels/EventsCarousel';

const CompetenceScreen = () => {
    const [loading, setLoading] = useState(true);
    const [loadingMoreData, setLoadingMoreData] = useState(false);
    const [loadingCourses, setLoadingCourses] = useState(false);
    const [loadingEvents, setLoadingEvents] = useState(false);
    const [loadingLibrary, setLoadingLibrary] = useState(false);
    const [competence, setCompetence] = useState(null);
    const [tab, setTab] = useState('courses');
    const [scrollY, setScrollY] = useState(0);

    const snackbar = useSnackbar();
    const windowSize = useWindowSize();
    const navigate = useNavigate();

    const [courses, setCourses] = useState([]);
    const [events, setEvents] = useState([]);
    const [libraries, setLibraries] = useState([]);
    const [competences, setCompetences] = useState([]);

    const [page, setPage] = useState({
        libraries: 0,
        courses: 0,
        events: 0
    });

    const [lastPage, setLastPage] = useState({
        libraries: 1,
        courses: 1,
        events: 1
    });

    const dispatch = useDispatch();
    const { id } = useParams();

    const tabs = [
        {
            label: 'Cursos',
            value: 'courses'
        },
        {
            label: 'Eventos',
            value: 'events'
        },
        {
            label: 'Recursos',
            value: 'libraries'
        }
    ];

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        setScrollY(currentScrollY);
    };

    const getCompetence = async () => {
        await getCompetenceById(id)
            .then((res) => {
                setCompetence(res.data);
                dispatch({
                    type: 'SET_COMPETENCE',
                    payload: {
                        competence: {
                            id: res.data.id,
                            name: res.data.description
                        }
                    }
                });
            })
            .catch((error) => {
                snackbar(error, 'error');
            });
    };

    const getCourses = async () => {
        if (page.courses === lastPage.courses && windowSize.mobile) {
            return;
        }

        setLoadingMoreData(true);
        setLoadingCourses(true);
        await getCompetenceCourses(id, page.courses + 1)
            .then((res) => {
                if (windowSize.mobile) {
                    setCourses([...courses, ...res.data]);
                    setPage({ ...page, courses: page.courses + 1 });
                    setLastPage({ ...lastPage, courses: res.lastPage });
                } else {
                    setCourses(() => res.data.slice(0, 9));
                }
                setLoadingMoreData(false);
                setLoadingCourses(false);
            })
            .catch((error) => {
                snackbar(error, 'error');
            });
    };

    const getEvents = async () => {
        if (windowSize.mobile) {
            if (page.events === lastPage.events || loadingMoreData) {
                return;
            }
        }

        setLoadingMoreData(true);
        setLoadingEvents(true);
        await getCompetenceEvents(id, page.events + 1)
            .then((res) => {
                if (windowSize.mobile) {
                    setEvents([...events, ...res.data]);
                    setPage({ ...page, events: page.events + 1 });
                    setLastPage({ ...lastPage, events: res.lastPage });
                } else {
                    setEvents(() => res.data.slice(0, 9));
                }
                setLoadingMoreData(false);
                setLoadingEvents(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar(typeof error === 'string' ? error : 'Erro ao buscar pelos eventos', 'error');
            });
    };

    const getLibraries = async () => {
        if (windowSize.mobile) {
            if (page.libraries === lastPage.libraries || loadingMoreData) {
                return;
            }
        }

        setLoadingMoreData(true);
        setLoadingLibrary(true);

        await getCompetenceLibraries(id, page.libraries + 1)
            .then((res) => {
                setLoadingLibrary(false);
                const newData = res.data.map((library) => {
                    return {
                        ...library,
                        id_type: library.idTypeLearningObject,
                        library: {
                            ...library,
                            id_type: library.idTypeLearningObject,
                            competence: [competence]
                        }
                    };
                });

                if (windowSize.mobile) {
                    setLibraries([...libraries, ...newData]);
                    setPage({ ...page, libraries: page.libraries + 1 });
                    setLastPage({ ...lastPage, libraries: res.lastPage });
                } else {
                    setLibraries([...newData]);
                }
                setLoadingMoreData(false);
            })
            .catch((error) => {
                snackbar(error, 'error');
            });
    };

    const getMoreData = async () => {
        switch (tab) {
            case 'courses':
                getCourses();
                break;
            case 'events':
                getEvents();
                break;
            case 'libraries':
                getLibraries();
                break;
            default:
                break;
        }
    };

    const verifyScroll = () => {
        if (scrollY !== 0 && !loadingMoreData) {
            if (windowSize.mobile) {
                if (document.body.scrollHeight - scrollY - 30 < window.innerHeight) {
                    getMoreData();
                    return;
                }
            }
        }
    };
    const getCompetencesData = async () => {
        await getStudentCompetences()
            .then((response) => {
                setCompetences(
                    response.data.map((competence) => {
                        return {
                            ...competence,
                            key: competence.description
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .replaceAll(' ', '_')
                                .toLowerCase()
                        };
                    })
                );
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar competências', 'error');
            });
    };

    const handleClickCompetence = (competence) => {
        return navigate(`/aluno/perfil/competencias/${competence.id}`, { replace: false });
    };
    useEffect(() => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: '/aluno/perfil/competencias',
                returnText: 'Minhas competências',
                returnPath: location.pathname
            }
        });
    }, []);

    useEffect(() => {
        verifyScroll();
    }, [scrollY]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        getCompetence();
        getCompetencesData();
    }, []);

    useEffect(() => {
        if (windowSize.mobile) {
            if (tab === 'courses') {
                getCourses();
            }

            if (tab === 'events') {
                getEvents();
            }

            if (tab === 'libraries') {
                getLibraries();
            }
        }
    }, [tab]);
    useEffect(() => {
        getCompetence();
    }, [id]);
    useEffect(() => {
        if (!windowSize.mobile) {
            getCourses();
            getEvents();
            getLibraries();
        }
    }, [competence]);
    if (loading) {
        return (
            <>
                <Meta title="Minhas competências" />
                <CircularProgress />
            </>
        );
    }

    if (!competence) {
        return (
            <>
                <Meta title="Minhas competências" />
                <Message text="Competência não encontrada" />
            </>
        );
    }

    if (windowSize.mobile) {
        return (
            <>
                <Meta title="Minhas competências" />
                <CompetenceHeader competence={competence} />
                <FullWidthContainer padding=" 0 1rem 4rem 1rem">
                    <TabsHeader tabs={tabs} tab={tab} setTab={setTab} />

                    {
                        {
                            courses: <CoursesList courses={courses} loading={loading} marginTop />,
                            events: <SmallEventList events={events} loading={loading} />,
                            libraries: (
                                <LibraryList learningObjects={libraries} courses={[]} filterSearch={{}} marginTop loading={loading} />
                            )
                        }[tab]
                    }
                </FullWidthContainer>
            </>
        );
    }
    return (
        <DesktopContainer>
            <aside>
                <h1>Dicas</h1>
                <CompetenceList
                    onClickCompetence={handleClickCompetence}
                    competences={competences}
                    options={{ returnFullCompetence: true, showGroups: true }}
                />
            </aside>
            <main>
                <h1 className={'page-title'}>Saiba como evoluir em suas competencias</h1>
                <CompetenceKnob
                    competence={competences?.find((comp) => comp.id === competence.id) ?? {}}
                    courseRedirect={false}
                    fontSize={'16px'}
                />
                <section>
                    <h4 className={'subtitle'}>Cursos</h4>
                    {!loadingCourses && courses.length > 0 ? (
                        <>
                            <CoursesCarousel courses={courses} label={null} errorLabel={null} />
                            <Link className={'link-button'} to={`/aluno/cursos/competencias/${competence.id}`}>
                                Ver Todos
                            </Link>
                        </>
                    ) : (
                        <h4 className={'not-found'}>{loadingCourses ? 'Carregando..' : 'Nenhum curso encontrado para essa competência'}</h4>
                    )}
                </section>
                <section>
                    <h4 className={'subtitle'}>Eventos</h4>
                    {!loadingEvents && events.length > 0 ? (
                        <>
                            <EventsCarousel events={events} label={null} errorLabel={null} />
                            <Link className={'link-button'} to={`/aluno/eventos?competence=${competence.id}`}>
                                Ver Todos
                            </Link>
                        </>
                    ) : (
                        <h4 className={'not-found'}>{loadingEvents ? 'Carregando..' : 'Nenhum evento encontrado para essa competência'}</h4>
                    )}
                </section>
                <section>
                    <h4 className={'subtitle'}>Recursos</h4>
                    {!loadingEvents && events.length > 0 ? (
                        <>
                            <LibraryList
                                learningObjects={libraries}
                                courses={[]}
                                filterSearch={{}}
                                marginTop
                                loading={loadingLibrary}
                                columns={2}
                            />
                            {/*<Link className={'link-button'} to={`/aluno/perfil/competencias/${competence.id}`}>*/}
                            {/*    Ver Todos*/}
                            {/*</Link>*/}
                        </>
                    ) : (
                        <h4 className={'not-found'}>
                            {loadingLibrary ? 'Carregando..' : 'Nenhum recurso encontrado para essa competência'}
                        </h4>
                    )}
                </section>
            </main>
        </DesktopContainer>
    );
};

export default CompetenceScreen;
