import { CircularProgress } from '@mui/material';
import BorderTitle from 'components/molecules/headers/BorderTitle';
import FavoriteList from 'components/organisms/lists/FavoriteList';
import Meta from 'components/templates/Seo/Meta';
import useFavoriteList from 'hooks/useFavoriteList';
import React, { useEffect, useState } from 'react';

const Favoritos = () => {
    const [loading, setLoading] = useState(true);
    const favoriteList = useFavoriteList();

    useEffect(() => {
        favoriteList.getAll();
        setLoading(false);
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
            <Meta title="Favoritos" />

            <BorderTitle title="Seus Favoritos" />

            <FavoriteList />
        </>
    );
};

export default Favoritos;
