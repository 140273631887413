import { Add } from '@mui/icons-material';
import useFavoriteList from 'hooks/useFavoriteList';
import React from 'react';

import { Container } from './styles';

const CreateFavoriteListItem = () => {
    const favoriteList = useFavoriteList();

    return (
        <Container onClick={() => favoriteList.openAddModal()}>
            <aside>
                <Add />
            </aside>
            <main>
                <span>Criar nova lista de favoritos</span>
            </main>
        </Container>
    );
};

export default CreateFavoriteListItem;
