import { CircularProgress } from '@mui/material';
import CreateFavoriteListItem from 'components/molecules/listItems/CreateFavoriteListItem';
import FavoriteListItem from 'components/molecules/listItems/FavoriteListItem';
import useFavoriteList from 'hooks/useFavoriteList';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Container } from './styles';

const FavoriteList = ({ selectFavoriteList }) => {
    const favoriteReducer = useSelector((state) => state.favoriteList);
    const favoriteList = useFavoriteList();
    const selectList = favoriteReducer.selectedItem;

    useEffect(() => {
        favoriteList.getAll();
    }, []);

    return (
        <Container>
            <CreateFavoriteListItem />

            {favoriteReducer.searched ? (
                favoriteReducer.list.map((item) => (
                    <FavoriteListItem
                        active={selectList?.id === item?.id}
                        key={item.id}
                        item={item}
                        selectFavoriteList={selectFavoriteList}
                    />
                ))
            ) : (
                <CircularProgress />
            )}
        </Container>
    );
};

FavoriteList.propTypes = {
    selectFavoriteList: PropTypes.func
};

export default FavoriteList;
