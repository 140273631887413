import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: var(--padding-base);
    border-bottom: #e8e8e8 solid 1px;
`;
