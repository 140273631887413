import React, { useEffect, useState } from 'react';
import { Container, Mark } from './styles';
import TimerText from '../../../atoms/texts/TimerText';
import Button from 'components/molecules/buttons/Button';
import { useNavigate } from 'react-router-dom';
import DateVertical from 'components/atoms/texts/DateVertical';
import { Beenhere } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { EventType } from 'types/data/event';
import Chips from 'components/molecules/containers/Chips';
import NowText from 'components/atoms/texts/NowText';
import DiffHoursText from 'components/atoms/texts/DiffHoursText';
import { convertTimezone, getMinutesDiff } from 'utils/date';

const EventCard = ({ event, isWeb }) => {
    const [minutesToEvent, setMinutesToEvent] = useState(780);
    const { id, title, initialDate, finalDate, competences, registered, typeEvent } = event;
    const navigate = useNavigate();

    const initialDateConverted = convertTimezone(initialDate);
    const finalDateConverted = convertTimezone(finalDate);

    const redirectToEvent = () => {
        navigate(`/aluno/eventos/${id}`);
    };

    const getMinutesToEvent = () => {
        const eventDay = new Date(initialDateConverted);
        const now = new Date();

        return getMinutesDiff(now, eventDay);
    };

    const now = () => {
        const now = new Date();

        return now.getTime() > new Date(initialDateConverted).getTime() && now.getTime() < new Date(finalDateConverted).getTime();
    };

    useEffect(() => {
        setMinutesToEvent(getMinutesToEvent());
        setInterval(() => setMinutesToEvent(getMinutesToEvent()), 10000);
    }, []);

    return (
        <Container>
            {registered && (
                <Mark>
                    <Beenhere color="secondary" fontSize="small" />
                </Mark>
            )}
            <aside>
                <DateVertical dateString={initialDateConverted.toString()} />
            </aside>
            <main>
                <header>
                    <h2>
                        {typeEvent}: {title}
                    </h2>

                    <Chips
                        data={competences.map((competence) => {
                            return { description: competence.desription, color: competence.color };
                        })}
                    />
                </header>
                <footer>
                    {now() ? (
                        <NowText />
                    ) : minutesToEvent < 720 ? (
                        <DiffHoursText minutes={minutesToEvent} />
                    ) : (
                        <TimerText initialDate={initialDateConverted.toString()} finalDate={finalDateConverted.toString()} />
                    )}
                    <Button size="fat" type="button" onClick={() => redirectToEvent()} width="auto" color="neutral">
                        Saiba mais
                    </Button>
                </footer>
            </main>
        </Container>
    );
};

EventCard.propTypes = {
    event: PropTypes.shape(EventType).isRequired
};

export default EventCard;
