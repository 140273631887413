import Button from '@mui/material/Button';
import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import ReactHtmlParser from 'react-html-parser';
import { Container } from './styles';

const ButtonAttachment = ({ item }) => {
    const { setFieldValue } = useFormikContext();
    const { values } = useFormikContext();

    return (
        <Container>
            <p>{ReactHtmlParser(item.descriptionLabel)}</p>
            <Button
                size="small"
                color="secondary"
                variant="contained"
                component="label"
                onChange={(e) => setFieldValue(item.id, e.target.files[0])}
            >
                {item.label}
                <input hidden accept="image/*" multiple type="file" />
            </Button>
            <p>{values?.file?.name}</p>
        </Container>
    );
};

ButtonAttachment.propTypes = {
    item: PropTypes.object.isRequired
};

export default ButtonAttachment;
