import DashboardLayout from 'components/templates/layouts/Outlet/DashboardLayout';
import Home from 'pages/Aluno';
import Biblioteca from 'pages/Aluno/Biblioteca';
import LibraryContentScreen from 'pages/Aluno/Biblioteca/Conteudo';
import LibraryListScreen from 'pages/Aluno/Biblioteca/List';
import Cursos from 'pages/Aluno/Cursos';
import Watch from 'pages/Aluno/Cursos/Assistir';
import CourseCompetence from 'pages/Aluno/Cursos/Competencia';
import FinishedCourses from 'pages/Aluno/Cursos/Concluidos';
import CoursePage from 'pages/Aluno/Cursos/Curso';
import Evento from 'pages/Aluno/Eventos/Evento';
import Notificacoes from 'pages/Aluno/Notificacoes';
import Perfil from 'pages/Aluno/Perfil';
import CompetencesProfile from 'pages/Aluno/Perfil/Competencias';
import CompetenceScreen from 'pages/Aluno/Perfil/Competencias/Competence';
import EditarPerfil from 'pages/Aluno/Perfil/Editar';
import Post from 'pages/Aluno/Publicacao';
import React from 'react';
import Eventos from 'pages/Aluno/Eventos';
import BlankLayout from 'components/templates/layouts/Outlet/BlankLayout';
import MinhaConta from 'pages/Aluno/MinhaConta';
import BemVindo from 'pages/Aluno/BemVindo';
import TodosCursos from 'pages/Aluno/Cursos/Todos';
import Ajuda from 'pages/Aluno/Ajuda';
import Duvidas from 'pages/Aluno/Ajuda/Duvidas';
import Favoritos from 'pages/Aluno/Favoritos';
import FavoritosListagem from 'pages/Aluno/Favoritos/Listagem';
import Participantes from 'pages/Aluno/Participantes';
import Participante from 'pages/Aluno/Participantes/Participante';
import Empresa from 'pages/Aluno/Participantes/Empresa';
import VagaForm from 'pages/Aluno/Perfil/Vaga';
import Certificados from 'pages/Aluno/Cursos/Certificados';
import Publicar from 'pages/Aluno/Publicacao/Publicar';

const StudentRoutes = [
    {
        path: '/aluno',
        element: <DashboardLayout />,
        children: [
            {
                path: '/aluno',
                element: <Home />
            },
            {
                path: '/aluno/eventos',
                element: <Eventos />
            },
            {
                path: '/aluno/cursos',
                element: <Cursos />
            },
            {
                path: '/aluno/favoritos',
                element: <Favoritos />
            },
            {
                path: '/aluno/biblioteca',
                element: <Biblioteca />
            },
            {
                path: '/aluno/perfil',
                element: <Perfil />
            }
        ]
    },
    {
        path: '/aluno',
        element: <DashboardLayout hiddenBottomNavbar />,
        children: [
            {
                path: '/aluno/eventos/:id',
                element: <Evento />
            },
            {
                path: '/aluno/cursos/competencias/:id',
                element: <CourseCompetence />
            },
            {
                path: '/aluno/cursos/concluidos',
                element: <FinishedCourses />
            },
            {
                path: '/aluno/cursos/todos',
                element: <TodosCursos />
            },
            {
                path: '/aluno/cursos/curso/:id',
                element: <CoursePage />
            },
            {
                path: '/aluno/cursos/assistir/:id',
                element: <Watch />
            },
            {
                path: '/aluno/cursos/certificados',
                element: <Certificados />
            },
            {
                path: '/aluno/biblioteca/conteudo/:id',
                element: <LibraryContentScreen />
            },
            {
                path: '/aluno/biblioteca/:filter/:id',
                element: <LibraryListScreen />
            },
            {
                path: '/aluno/perfil/competencias',
                element: <CompetencesProfile />
            },
            {
                path: '/aluno/perfil/competencias/:id',
                element: <CompetenceScreen />
            },
            {
                path: '/aluno/notificacoes',
                element: <Notificacoes />
            },
            {
                path: '/aluno/publicacao/:id',
                element: <Post />
            },
            {
                path: '/aluno/ajuda',
                element: <Ajuda />
            },
            {
                path: '/aluno/favoritos/:id',
                element: <FavoritosListagem />
            },
            {
                path: '/aluno/participantes',
                element: <Participantes />
            },
            {
                path: '/aluno/participantes/:id',
                element: <Participante />
            },
            {
                path: '/aluno/empresas/:id',
                element: <Empresa />
            },
            {
                path: '/aluno/ajuda/duvidas',
                element: <Duvidas />
            }
        ]
    },
    {
        path: '/aluno',
        element: <BlankLayout noPadding />,
        children: [
            {
                path: '/aluno/perfil/editar',
                element: <EditarPerfil />
            },
            {
                path: '/aluno/minha-conta',
                element: <MinhaConta />
            },
            {
                path: '/aluno/bem-vindo',
                element: <BemVindo />
            },
            {
                path: '/aluno/perfil/vaga',
                element: <VagaForm />
            },
            {
                path: '/aluno/perfil/vaga/:id',
                element: <VagaForm />
            },
            {
                path: '/aluno/publicar',
                element: <Publicar />
            }
        ]
    }
];

export default StudentRoutes;
