import { combineReducers } from 'redux';
import authReducer from './authReducer';
import courseReducer from './courseReducer';
import coursesReducer from './coursesReducer';
import eventReducer from './eventReducer';
import favoriteListReducer from './favoriteListReducer';
import feedbackReducer from './feedbackReducer';
import notificationsReducer from './notificationsReducer';
import routerReducer from './routerReducer';
import snackbarReducer from './snackbarReducer';
import timelineReducer from './timelineReducer';
import alertReducer from './alertReducer';
import participantsReducer from './participantsReducer';

const reducer = combineReducers({
    auth: authReducer,
    course: courseReducer,
    courses: coursesReducer,
    favoriteList: favoriteListReducer,
    feedback: feedbackReducer,
    notifications: notificationsReducer,
    router: routerReducer,
    snackbar: snackbarReducer,
    timeline: timelineReducer,
    alert: alertReducer,
    participants: participantsReducer,
    event: eventReducer
});

export default reducer;
