import { Avatar } from '@mui/material';
import FavoriteCheckbox from 'components/atoms/inputs/FavoriteCheckbox';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import { Container, DescriptionText, UserRedirect } from './styles';
import { TimelineItem } from 'types/data/timeline';
import { htmlClean } from 'utils/html';
import { likePost } from 'services/timeline';
import useSnackbar from 'hooks/useSnackbar';
import TimelineContent from 'components/organisms/containers/TimelineContent';
import { useNavigate } from 'react-router-dom';
import { getFallbackUrl } from 'utils/general';
import PublicationComments from 'components/molecules/containers/PublicationComments';
import PublicationCommentBtn from 'components/molecules/buttons/PublicationComment';
import TimelineVimeoContent from '../../../organisms/containers/TimelineVimeoContent';

const PostCard = ({ post }) => {
    const [liked, setLiked] = useState(post.liked);
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const handleLike = async (value) => {
        await likePost(post.id)
            .then(() => {
                setLiked(value);
                snackbar(value ? 'Postagem curtida com sucesso' : 'Postagem descurtida com sucesso', 'success');
            })
            .catch((err) => {
                snackbar(err.message || 'Erro ao curtir postagem', 'error');
            });

        setLiked(value);
    };

    const redirect = () => {
        if (!post?.user?.id_enterprise) return;

        navigate(getFallbackUrl(`/aluno/empresas/${post?.user?.id_enterprise}`));
    };
    return (
        <Container>
            <header>
                <UserRedirect onClick={() => redirect()} pointer={!!post?.user?.id_enterprise}>
                    <Avatar src={post?.user?.image} />

                    <h3>{post?.user?.name}</h3>
                </UserRedirect>
            </header>

            <main>
                <TimelineVimeoContent videos={post.learningObjects} />

                <TimelineContent files={post.files} />

                <DescriptionText>{ReactHtmlParser(htmlClean(post.description))}</DescriptionText>
            </main>

            <div className="divider" />
            <PublicationComments
                comments={post.comments || []}
                ellipsis={true}
                redirectOnClick={false}
                clickHandle={() => setModalOpen(!modalOpen)}
            />

            <footer>
                <aside>
                    <div className="footer-aside__item">
                        <FavoriteCheckbox active={liked} setActive={handleLike} noPaddingLeft />
                        <p>{post.likes + liked - post.liked}</p>
                    </div>
                    <div className="footer-aside__item">
                        <PublicationCommentBtn publication={post} modalOpen={modalOpen} setModalOpen={setModalOpen} />
                        <p>{post.commentsTotal}</p>
                    </div>
                </aside>
            </footer>
        </Container>
    );
};

PostCard.propTypes = {
    post: PropTypes.shape(TimelineItem)
};

export default PostCard;
