import styled from 'styled-components';
import { Chip } from '@mui/material';

export const Container = styled.div`
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
    border-radius: 16px;

    > header {
        padding: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        > aside {
            margin-right: 1rem;
        }

        > main {
            > h3 {
                font-size: 1rem;
                color: var(--color-text);
                margin-bottom: 0.4rem;
            }
        }
    }

    > a {
        > main {
            padding: 0 1rem 1rem 1rem;
            width: 100%;

            > footer {
                margin-top: 0.5rem;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
            }
        }
    }

    .divider {
        border-top: 1px solid #e0e0e0;
    }

    > footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        padding-top: 0;

        > aside {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 27px;

            > div {
                display: flex;
                align-items: center;
            }

            p {
                font-size: 0.9rem;
                height: 100%;
                display: flex;
                align-items: center;
            }
        }

        > main {
            font-size: 0.9rem;
        }
    }
`;

export const DescriptionText = styled.div`
    p {
        margin-top: 1rem;

        &:first-child {
            margin-top: 0;
        }
    }

    font-size: 0.9rem !important;
    line-height: 1.2rem !important;
    color: var(--color-black) !important;
    background-color: transparent !important;
    font-family: 'Myriad Pro', sans-serif !important;

    span {
        font-size: 0.9rem !important;
        color: var(--color-black) !important;
        font-family: 'Myriad Pro', sans-serif !important;
        background-color: transparent !important;
        line-height: 1.2rem !important;
    }
`;

export const ItemsContainer = styled.div`
    margin-bottom: 1rem;
`;

export const OpportunityTag = styled(Chip)`
    margin-bottom: 10px;
    background-color: var(--color-primary);
    color: #ffffff;
    font-size: xx-small;
`;

export const ParticipatingOpportunity = styled(Chip)`
    margin-bottom: 10px;
    background-color: #32cd32;
    color: #ffffff;
    font-size: xx-small;
`;

export const UserRedirect = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    ${({ pointer }) => pointer && 'cursor: pointer;'}

    > h3 {
        margin-left: 1rem;
        font-size: 1rem;
        color: var(--color-text);
    }
`;
