import styled from 'styled-components';

export const ButtonMenu = styled.button`
    background-color: #fff;
    height: 60px;
    margin-right: 0.7rem;
    border-top-left-radius: 10px;
    border: none;
    font-weight: bold;
    border-top-right-radius: 10px;
    color: #616161;
    width: 200px;
    &:hover {
        background-color: #3a6647 !important;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: #fff !important;
    }
`;
