import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    color: #464646;
    margin: 1rem 0 1.5rem 0;

    > span {
        font-weight: 700;
        font-size: 1rem;
    }
`;
