import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ActionsArea, BlankImage, Container, TitleContainer, BodyCard } from './styles';
import { IconButton } from '@mui/material';
import { Bookmark, BookmarkBorder } from '@mui/icons-material';
import Button from 'components/molecules/buttons/Button';
import { Link, useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { htmlClean } from 'utils/html';
import { CourseType } from 'types/data/course';
import useFavoriteList from 'hooks/useFavoriteList';

const CourseCard = ({ course, completed, pathName }) => {
    const { id, title, description, time, speakers, isFavorite, image, finalDate } = course;

    const hours = Math.floor(time / 60);

    const [favorited, setFavorited] = useState(isFavorite);
    const favoriteList = useFavoriteList();

    const navigate = useNavigate();
    const path = window.location.pathname;
    const redirectUrl = `/aluno/cursos/curso/${id}${path ? `?returnUrl=${path}` : ''}${pathName ? `&returnName=${pathName}` : ''}`;

    const handleFavorite = async () => {
        favoriteList.openListModal(favorited, setFavorited, id, 'course');
    };

    const redirectToCourse = () => {
        navigate(redirectUrl);
    };

    const getTime = () => {
        if (hours > 0) {
            return `${hours} horas totais`;
        }

        return `${time} minutos`;
    };

    return (
        <Container>
            <header>
                <Link to={redirectUrl}>{image ? <img src={image} alt={title} /> : <BlankImage />} </Link>

                <TitleContainer>
                    <main>
                        <Link to={redirectUrl}>
                            <>
                                <h2>{title}</h2>
                                {speakers?.length > 0 && <p>Por {speakers[0].name}</p>}
                            </>
                        </Link>
                    </main>
                    <ActionsArea>
                        <IconButton aria-label="toggle password visibility" onClick={() => handleFavorite()}>
                            {favorited ? <Bookmark color="primary" /> : <BookmarkBorder />}
                        </IconButton>
                    </ActionsArea>
                </TitleContainer>
            </header>
            <Link to={redirectUrl}>
                <BodyCard>
                    <main>{ReactHtmlParser(htmlClean(description).split('</p>')[0])}</main>
                    <footer>
                        {completed && finalDate ? <p>Concluído em {finalDate}</p> : <p>{getTime()}</p>}

                        <Button type="button" size="small" width="auto" onClick={() => redirectToCourse()} color="neutral">
                            {completed ? 'Rever' : 'Saiba mais'}
                        </Button>
                    </footer>
                </BodyCard>
            </Link>
        </Container>
    );
};

CourseCard.propTypes = {
    course: PropTypes.shape(CourseType).isRequired,
    completed: PropTypes.bool,
    pathName: PropTypes.string
};
export default CourseCard;
