import { IconButton, Drawer } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Drawer)`
    .MuiDrawer-paper {
        margin: auto;
        max-width: 600px;
        border-radius: var(--border-radius) var(--border-radius) 0 0;
        padding: 1rem 1rem 0 1rem;

        > header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            color: var(--color-primary);
            padding-bottom: 1rem;
            border-bottom: 1px solid #c7c7c7;

            > h3 {
                font-size: 1.1rem;
            }
        }

        > main {
            display: flex;
            flex-direction: column;
            max-height: 80vh;
            overflow-y: auto;

            ::-webkit-scrollbar {
                border-radius: 10px;
                width: 0.4rem;
                height: 0.4rem;
            }

            ::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: #bbb;
            }

            ::-webkit-scrollbar-thumb:hover {
                border-radius: 10px;
                background: #aaa;
            }

            @media screen and (max-width: 768px) {
                ::-webkit-scrollbar {
                    border-radius: 10px;
                    width: 0.1rem;
                    height: 0.1rem;
                }
            }
        }
    }
`;

export const CloseButton = styled(IconButton)`
    position: absolute;
    top: -0.8rem;
    right: -0.5rem;
`;
