import BlankContainer from 'components/atoms/structure/BlankContainer';
import Welcome from 'components/organisms/containers/Welcome';
import Meta from 'components/templates/Seo/Meta';
import React from 'react';

const BemVindo = () => {
    return (
        <>
            <Meta title="Bem vindo" description="Bem vindo ao PFA!" />

            <BlankContainer noBackground>
                <Welcome />
            </BlankContainer>
        </>
    );
};

export default BemVindo;
