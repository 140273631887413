import styled, { css } from 'styled-components';

export const Container = styled.div`
    position: relative;
    width: 100%;

    ${(props) =>
        props.borderBottom &&
        css`
            border-bottom: #e8e8e8 solid 1px;
            padding-bottom: 1rem;
        `}

    .MuiIconButton-root {
        background-color: #fff;
        border-radius: var(--border-radius);
        position: absolute;
        top: 0;
        right: 0;
    }
`;
