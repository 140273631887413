import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    > footer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        width: 100%;
    }
`;
