import Cookies from 'js-cookie';
import { removeCookie } from 'utils/cookies';
import { addHours } from '../../utils/date';
import { api } from '../api';

const logged = async () => {
    const token = Cookies.get('token');

    if (!token) {
        return false;
    }
    const response = await api
        .post('me')
        .then((data) => {
            return data;
        })
        .catch((err) => {
            return err.request;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return response.data;
};

const authentication = async (email, password) => {
    const response = await api
        .post('login', {
            email: email,
            password: password
        })
        .then((data) => {
            removeCookie('token');

            if (data.data.user.id_role !== 1) {
                const hours = 7 * 24;
                Cookies.set('token', data.data.access_token, {
                    expires: addHours(new Date(), hours)
                });
            }

            return data;
        })
        .catch((error) => {
            return JSON.parse(error.request.response);
        });

    return response;
};

const recoverPassword = async (email) => {
    const response = await api
        .post('password/email', {
            email
        })
        .catch((error) => {
            return { message: JSON.parse(error.request.response).error };
        });

    return response;
};

const resetPassword = async (email, password, passwordConfirmation, token) => {
    const response = await api
        .post('password/reset', {
            email,
            password,
            password_confirmation: passwordConfirmation,
            token
        })
        .catch((error) => {
            return JSON.parse(error.request.response);
        });

    return response;
};

const signUp = async (name, email, password, passwordConfirmation) => {
    const response = await api
        .post('register_student', {
            name: name,
            email: email,
            password: password,
            password_confirmation: passwordConfirmation,
            acceptanceTerm: 1
        })
        .then(() => {
            return true;
        })
        .catch((error) => {
            return JSON.parse(error.request.response);
        });

    if (response !== true) {
        throw new Error(Object.values(response.errorValidate)[0]);
    }

    return response;
};

const logout = () => {
    removeCookie('token');
};

export { authentication, recoverPassword, resetPassword, logged, logout, signUp };
