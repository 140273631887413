import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    margin-top: 1rem;

    > h2 {
        font-size: 1.2rem;
        color: var(--color-text);
    }

    > * {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;
