import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    .MuiButtonBase-root {
        color: var(--color-black);
        font-weight: 700;
    }

    .MuiTabs-root {
        border-bottom: 1px #ccc solid;
    }
`;
