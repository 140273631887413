import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { Avatar } from '@mui/material';

const UserHeader = ({ image, name }) => {
    return (
        <Container>
            <aside>
                <Avatar src={image}>{name[0].toUpperCase()}</Avatar>
            </aside>
            <main>
                <span>{name}</span>
            </main>
        </Container>
    );
};

UserHeader.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string
};

export default UserHeader;
