const getImageUrl = (imageUrl) => {
    if (imageUrl.includes('https://')) {
        return imageUrl;
    }

    return `https://pfahomologacaoapi.rehagro.com.br${imageUrl}`;
};

const getSystemImageUrl = (imageUrl) => {
    return `https://pfahomologacaoapi.rehagro.com.br/storage/images_system/${imageUrl}`;
};

export { getImageUrl, getSystemImageUrl };
