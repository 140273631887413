import styled from 'styled-components';

export const Container = styled.div`
    filter: invert(19%) sepia(8%) saturate(4888%) hue-rotate(109deg) brightness(91%) contrast(92%);

    > img {
        width: ${({ width }) => width};
        height: ${({ height }) => height};
        filter: brightness(0) saturate(100%);
    }
`;
