import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    border-radius: 5px;
    background-color: #f4f4f4;

    > span {
        font-size: 0.9rem;
    }

    > button {
        margin: auto 0 !important;
    }
`;
