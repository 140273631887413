import React from 'react';
import PropTypes from 'prop-types';

import { ContainerFooter } from './styles';

import { htmlClean } from 'utils/html';
import ReactHtmlParser from 'react-html-parser';
import ProfileHeader from 'components/molecules/headers/ProfileHeader';

const EnterpriseDescription = ({ name, image, description, city, state, profile, background }) => {
    return (
        <>
            <ProfileHeader image={image} background={background} profile={profile} enterprise />

            <ContainerFooter>
                <h2>{name}</h2>

                <p>{ReactHtmlParser(htmlClean(description))}</p>

                {city && state && (
                    <p>
                        {city || ''} - {state || ''}
                    </p>
                )}
            </ContainerFooter>
        </>
    );
};

EnterpriseDescription.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    image: PropTypes.string,
    profile: PropTypes.bool,
    background: PropTypes.string
};

export default EnterpriseDescription;
