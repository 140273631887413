import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { StyledPaper } from './styles';
import LibraryList from 'components/organisms/lists/LibraryList';
import { getLibraryData } from 'services/library';
import useSnackbar from 'hooks/useSnackbar';
import PropTypes from 'prop-types';

const HistoricLibraryDrawer = ({ title, onOpen, onClose }) => {
    const [courses, setCourses] = useState([]);
    const [events, setEvents] = useState([]);
    const [learningObjects, setLearningObjects] = useState([]);
    const snackbar = useSnackbar();
    const getListData = async () => {
        await getLibraryData()
            .then((response) => {
                setLearningObjects(response.data.learningObjects);
                setCourses(response.data.courses);
                setEvents(response.data.events);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar biblioteca', 'error');
            });
    };

    useEffect(() => {
        getListData();
    }, []);

    return (
        <div>
            <Drawer anchor={'right'} PaperProps={{ component: StyledPaper }} onClose={onClose} open={onOpen}>
                <header>
                    <h1>{title}</h1>
                </header>
                <main>
                    <LibraryList courses={courses} learningObjects={learningObjects} events={events} />
                </main>
            </Drawer>
        </div>
    );
};

HistoricLibraryDrawer.propTypes = {
    title: PropTypes.string.isRequired,
    onOpen: PropTypes.bool,
    onClose: PropTypes.bool
};

export default HistoricLibraryDrawer;
