import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1rem;

    &:first-of-type {
        margin-top: 0;
    }

    > aside {
        margin-right: 0.8rem;
        width: 3rem;
        height: 3rem;

        > * {
            min-width: 3rem;
            min-height: 3rem;
        }
    }

    > main {
        > h4 {
            font-size: 1rem;
            color: #333;
        }

        > p {
            font-size: 14px;
            color: #555;
            white-space: pre-line;
        }
    }
`;
