import Button from 'components/molecules/buttons/Button';
import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const MiniModal = ({ title, body, submit }) => {
    return (
        <Container>
            <header>
                <h3>{title}</h3>
            </header>
            <main>{body}</main>
            <footer>
                <Button type="button" color="light" onClick={() => submit(false)}>
                    Recusar
                </Button>
                <Button type="button" color="secondary" onClick={() => submit(true)}>
                    Aceitar
                </Button>
            </footer>
        </Container>
    );
};

MiniModal.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.node.isRequired,
    submit: PropTypes.func.isRequired
};

export default MiniModal;
