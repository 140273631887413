import React from 'react';

import { Container } from './styles';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import { userType } from 'types/data/user';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const UserInfoHeader = ({ user }) => {
    const { name, image, level } = user;
    const path = window.location.pathname;
    const redirectUrl = `/aluno/perfil${path ? `?returnUrl=${path}` : ''}`;
    const auth = useSelector((state) => state.auth);

    if (!name) {
        return null;
    }

    return (
        <Link to={redirectUrl}>
            <Container>
                <aside>{image ? <Avatar src={image} /> : <Avatar>{name[0].toUpperCase()}</Avatar>}</aside>
                <main>
                    <h4>Olá, {name}</h4>
                    {level?.level && !auth?.user?.enterpriseId && <p>Nível atual: {level?.level || ''}</p>}
                </main>
            </Container>
        </Link>
    );
};

UserInfoHeader.propTypes = {
    user: PropTypes.shape(userType).isRequired
};

export default UserInfoHeader;
