import Button from 'components/molecules/buttons/Button';
import useFavoriteList from 'hooks/useFavoriteList';
import React from 'react';

import { Container } from './styles';

const FavoriteRemove = () => {
    const favoriteList = useFavoriteList();

    const openRemoveModal = () => {
        favoriteList.openRemoveModal();
    };

    return (
        <Container>
            <span>Deseja apagar a lista?</span>
            <Button type="button" size="small" color="light" onClick={() => openRemoveModal()} width="auto">
                Apagar lista
            </Button>
        </Container>
    );
};

export default FavoriteRemove;
