import Svg from 'components/atoms/structure/Svg';
import Chip from 'components/atoms/texts/Chip';
import ModalCard from 'components/organisms/layout/modals/ModalCard';
import React from 'react';
import { getSystemImageUrl } from 'utils/assets';
import PropTypes from 'prop-types';

import { Container, ImageContainer } from './styles';
import { FeedbackType } from 'types/components/feedback';
import useFeedback from 'hooks/useFeedback';

const CompetenceLevelUpModal = ({ feedback }) => {
    const hook = useFeedback();
    const { competences } = feedback;

    const moreThanOneCompetence = competences.length > 1;

    return (
        <ModalCard open={true} title="Nível conquistado" handleClose={hook.closeFeedback}>
            <Container>
                <h3>Parabéns!</h3>
                <p>
                    Você evoluiu em mais um nível em sua{moreThanOneCompetence && 's'} competência{moreThanOneCompetence && 's'}:
                </p>

                <ImageContainer>
                    <aside>
                        <Svg url={getSystemImageUrl('icone check.svg')} width="75" height="68" />
                    </aside>

                    <main>
                        {competences.map((competence, index) => (
                            <Chip key={index} label={competence.description} color={competence.color} />
                        ))}
                    </main>
                </ImageContainer>
                <p>Uma grande transformação inicia-se com pequenas conquistas. Continue!</p>
            </Container>
        </ModalCard>
    );
};

CompetenceLevelUpModal.propTypes = {
    feedback: PropTypes.shape(FeedbackType).isRequired
};

export default CompetenceLevelUpModal;
