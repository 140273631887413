import styled from 'styled-components';
import { List, ListItemButton, ListItemText } from '@mui/material';

export const Container = styled.div`
    grid-area: CA;
    width: 100%;
    padding-right: 30px;
    height: 30rem;
    position: sticky;
    top: calc(var(--navbar-height) + 1rem);
    align-self: start;

    > .competencesList {
        margin-top: 24px;
    }
`;

export const ListStyled = styled(List)`
    background-color: white;
    border-radius: 8px;
    overflow: hidden;

    margin-top: 23px;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.05));
`;
export const ListItemButtonStyled = styled(ListItemButton)`
    padding: 13.5px 16px 13.5px 16px;
    font-size: 16px;
    &.active {
        background-color: #e7f5e6;
    }

    :not(:first-of-type) {
        border-top: 1px solid #e0e0e0;
    }

    &:hover {
        filter: brightness(0.9);
    }
`;
