import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { Step, StepLabel, Stepper } from '@mui/material';
import { ClassType } from 'types/data/course';
import { padNumber } from 'utils/general';

const ClassesList = ({ classes, changeClass, currentClassNumber, maxClassNumber, finished, finishedCourse }) => {
    const clickHandler = (index) => {
        if (finished && index === maxClassNumber + 1) {
            changeClass(index);
            return;
        }

        if (index === currentClassNumber || index > maxClassNumber) {
            return;
        }

        changeClass(index);
    };

    const activeStep = () => {
        if (!finished && currentClassNumber === maxClassNumber) {
            return currentClassNumber - 1;
        }

        if (finished && currentClassNumber === maxClassNumber) {
            return currentClassNumber;
        }

        if (currentClassNumber === maxClassNumber) {
            return currentClassNumber;
        }

        return maxClassNumber - !finishedCourse;
    };

    return (
        <Container>
            <Stepper activeStep={activeStep()} orientation="vertical">
                {classes.map((classItem, index) => (
                    <Step
                        key={index}
                        sx={{
                            cursor: 'pointer',
                            '& .MuiStepLabel-root .Mui-completed': {
                                color: 'secondary.light' // circle color (COMPLETED)
                            },
                            '& .MuiStepLabel-label.Mui-completed': {
                                color: '#373848', // Just text label (COMPLETED)
                                fontWeight: '500'
                            },
                            '& .MuiStepLabel-root .Mui-active': {
                                color: 'secondary.main' // circle color (ACTIVE)
                            },
                            '& .MuiStepLabel-label.Mui-active': {
                                color: '#373848' // Just text label (ACTIVE)
                            },
                            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                fill: 'white' // circle's number (ACTIVE)
                            }
                        }}
                    >
                        <StepLabel onClick={() => clickHandler(Number(classItem.number))}>
                            {Number(classItem.number) === currentClassNumber ? (
                                <>
                                    <span style={{ fontWeight: '600' }}>
                                        Aula {padNumber(Number(classItem.number))}: {classItem.title}
                                    </span>
                                </>
                            ) : (
                                `Aula ${padNumber(Number(classItem.number))}: ${classItem.title}`
                            )}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Container>
    );
};

ClassesList.propTypes = {
    classes: PropTypes.arrayOf(PropTypes.shape(ClassType)).isRequired,
    changeClass: PropTypes.func.isRequired,
    currentClassNumber: PropTypes.number.isRequired,
    maxClassNumber: PropTypes.number.isRequired,
    finished: PropTypes.bool.isRequired,
    finishedCourse: PropTypes.bool.isRequired
};

export default ClassesList;
