import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import HelpAside from 'components/organisms/containers/HelpAside';

const HelpLayout = ({ children }) => {
    return (
        <Container>
            <div>
                <HelpAside />
            </div>
            <div>{children}</div>
        </Container>
    );
};

HelpLayout.propTypes = {
    children: PropTypes.node.isRequired
};

export default HelpLayout;
