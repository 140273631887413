import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { Terrain } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getFallbackUrl } from 'utils/general';

const EnterpriseOpportunityCard = ({ title, legend, footer, image, id }) => {
    const navigate = useNavigate();

    const redirect = () => {
        const uri = getFallbackUrl(`/aluno/perfil/vaga/${id}`);
        navigate(uri);
    };

    return (
        <Container onClick={() => redirect()}>
            <aside>
                {image ? (
                    <img src={image} alt={title} />
                ) : (
                    <div>
                        <Terrain fontSize="large" />
                    </div>
                )}
            </aside>
            <main>
                {title && <h3>{title}</h3>}
                {legend && <p>{legend}</p>}
                {footer && <span>{footer}</span>}
            </main>
        </Container>
    );
};

EnterpriseOpportunityCard.propTypes = {
    title: PropTypes.string,
    legend: PropTypes.string,
    footer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default EnterpriseOpportunityCard;
