import { Container } from './styles';
import PropTypes from 'prop-types';
import CompetenceKnob from '../CompetenceKnob';

const CourseCompetencesCard = ({ competences, course }) => {
    if (!(competences && course && course.competences)) return <></>;

    return (
        <Container>
            <h3>Faça esse curso e evolua em:</h3>
            {competences &&
                competences
                    .filter((competence) => course.competences.some((comp) => comp.id === competence.id))
                    .map((competence, index) => (
                        <div key={`competence-${index}`} className={'competence'}>
                            <CompetenceKnob competence={competence} />
                        </div>
                    ))}
        </Container>
    );
};
CourseCompetencesCard.propTypes = {
    competences: PropTypes.array.isRequired,
    course: PropTypes.object.isRequired
};
export default CourseCompetencesCard;
