import { CircularProgress } from '@mui/material';
import SharePost from 'components/organisms/forms/Student/SharePost';
import MenuLayout from 'components/templates/layouts/Components/MenuLayout';
import Meta from 'components/templates/Seo/Meta';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Publicar = () => {
    const [loading, setLoading] = useState(true);
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const checkEnterprise = () => {
        if (!auth?.user?.id) {
            return;
        }

        if (auth?.user?.enterpriseId) {
            return;
        }

        navigate('/aluno');
    };

    useEffect(() => {
        checkEnterprise();
    }, [auth]);

    useEffect(() => {
        setLoading(false);
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <MenuLayout title="Compartilhar" returnUrlDefault="/aluno">
            <Meta title="Compartilhar" />

            <SharePost />
        </MenuLayout>
    );
};

export default Publicar;
