import PropTypes from 'prop-types';

export const CompetenceType = {
    id: PropTypes.number,
    created_at: PropTypes.string,
    currentLevel: PropTypes.string,
    description: PropTypes.string,
    desription: PropTypes.string,
    percentNextLevel: PropTypes.number,
    updated_at: PropTypes.string,
    icon: PropTypes.string,
    type: PropTypes.string,
    color: PropTypes.string
};
