import styled from 'styled-components';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

export const Container = styled.div`
    margin-top: 20px;
    width: 100%;
    background-color: white;
    padding: 2px;
    border-radius: 10px;
    > * {
        margin-top: 1rem;

        &:first-of-type {
            margin-top: 0;
        }
    }
`;

export const ListHeader = styled.div`
    padding: 5px;
    margin: 8px;
    > h1 {
        font-size: 0.9rem;
        font-weight: 700;
        text-align: left;
    }
`;

export const AddFavorite = styled(AddOutlinedIcon)`
    color: #87a851;
`;

export const BoxIcone = styled.div`
    width: 50px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    background-color: #e9f7d2;
    padding: 5px;
    margin: 0px;
`;
