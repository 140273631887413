import FullWidthContainer from 'components/atoms/structure/FullWidthContainer';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { LevelType } from 'types/data/level';
import LevelCard from 'components/molecules/cards/LevelCard';

const LevelCarousel = ({ data, currentLevel }) => {
    const carousel = useRef(null);

    useEffect(() => {
        if (!carousel || !currentLevel) {
            return;
        }

        carousel.current.scrollTo(currentLevel * 248 - 248 - window.screenX / 2 - 32, 0);
    }, [currentLevel, carousel]);

    if (!data) return null;

    return (
        <FullWidthContainer>
            <Container ref={carousel}>
                {data.map((item, index) => (
                    <LevelCard level={item} key={index} currentLevel={currentLevel} />
                ))}
            </Container>
        </FullWidthContainer>
    );
};

LevelCarousel.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape(LevelType)).isRequired,
    currentLevel: PropTypes.number
};

export default LevelCarousel;
