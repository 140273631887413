import SaveCheckbox from 'components/atoms/inputs/SaveCheckbox';
import TimerDuration from 'components/atoms/texts/TimerDuration';
import Chips from 'components/molecules/containers/Chips';
import React, { useEffect, useState } from 'react';

import { AsideHeader, Container, Description, FormatText, Actions, Spacer } from './styles';
import Player from 'components/molecules/functionalities/Player';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { htmlClean } from 'utils/html';
import Button from 'components/molecules/buttons/Button';
import PropTypes from 'prop-types';

import { downloadFile, getFileExtension } from 'utils/general';
import { libraryType } from 'types/data/library';
import { CircularProgress } from '@mui/material';
import { likeLibrary, seeLibrary, timeStopLibrary } from 'services/library';
import { studentPassedLevel } from 'services/student';

import useSnackbar from 'hooks/useSnackbar';
import useFeedback from 'hooks/useFeedback';
import useFavoriteList from 'hooks/useFavoriteList';
import FavoriteCheckbox from 'components/atoms/inputs/FavoriteCheckbox';
import PublicationCommentBtn from 'components/molecules/buttons/PublicationComment';

const LibraryContent = ({ data }) => {
    const {
        id,
        title,
        description,
        competences,
        time,
        learningObject,
        timeStop,
        isViewed,
        isFavorite,
        publication,
        publicationCommentsTotal
    } = data;

    const [liked, setLiked] = useState(data.liked);
    const [favorited, setFavorited] = useState(isFavorite);
    const [viewed, setViewed] = useState(isViewed);
    const [modalOpen, setModalOpen] = useState(false);

    const favoriteList = useFavoriteList();
    const auth = useSelector((state) => state.auth);
    const fileFormat = getFileExtension(learningObject?.name);

    const downloadOptions = ['Imagens', 'Textos', 'PDF', 'Excel', 'Word'];

    const snackbar = useSnackbar();
    const feedback = useFeedback();

    const handleLike = async (value) => {
        await likeLibrary(auth.user.studentId, id, value)
            .then(() => {
                setLiked(value);
                snackbar(value ? 'Material curtido com sucesso' : 'Material descurtido com sucesso', 'success');
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao curtir material', 'error');
            });
    };

    const handleFavorite = async () => {
        favoriteList.openListModal(favorited, setFavorited, id, 'library');
    };

    const seeLibraryData = async () => {
        if (viewed) {
            return;
        }

        await seeLibrary(auth.user.studentId, id)
            .then(() => {
                setLiked(false);
                snackbar('Material assistido com sucesso', 'success');
                setViewed(true);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        if (learningObject?.typeLearningObject === 'Imagens') {
            seeLibraryData();
        }
    }, [learningObject]);

    const startDownload = async (fileName) => {
        if (downloadOptions.includes(learningObject?.typeLearningObject) && learningObject?.typeLearningObject !== 'Imagens') {
            seeLibraryData();
        }
        downloadFile(fileName, learningObject.url);
    };

    if (!data) {
        return <CircularProgress />;
    }

    return (
        <Container>
            <header>
                <h1>{title}</h1>
                <Chips data={competences} />

                {time && <TimerDuration minutes={time} text="Tempo estimado" unit=" h" />}

                <AsideHeader>
                    <FavoriteCheckbox active={liked} setActive={handleLike} noPaddingLeft />
                    <SaveCheckbox active={favorited} setActive={handleFavorite} />
                </AsideHeader>
            </header>

            <main>
                {learningObject && (
                    <>
                        {
                            {
                                Imagens: <img src={learningObject?.url} alt={learningObject?.name || ''} />,
                                Videos: (
                                    <Player
                                        url={learningObject?.url}
                                        setFinished={() => seeLibraryData()}
                                        watchClass={{
                                            id,
                                            myStudentTimeStop: timeStop
                                        }}
                                        idStudent={auth?.user?.studentId}
                                        stopClass={(a, b, c) => timeStopLibrary(a, b, c)}
                                    />
                                )
                            }[learningObject?.typeLearningObject]
                        }
                    </>
                )}

                <Description>{ReactHtmlParser(htmlClean(description))}</Description>

                <Actions>
                    <div>
                        {publication && (
                            <PublicationCommentBtn publication={publication} modalOpen={modalOpen} setModalOpen={setModalOpen} />
                        )}
                        {publicationCommentsTotal}
                    </div>
                    <Spacer />
                </Actions>
            </main>

            <footer>
                {learningObject && (
                    <>
                        {fileFormat && <FormatText>Arquivo Formato: {fileFormat}</FormatText>}
                        {downloadOptions.includes(learningObject.typeLearningObject) && (
                            <Button color="secondary" onClick={() => startDownload(learningObject.name)}>
                                Baixar Arquivo
                            </Button>
                        )}
                    </>
                )}
            </footer>
        </Container>
    );
};

LibraryContent.propTypes = {
    data: PropTypes.shape(libraryType)
};

export default LibraryContent;
