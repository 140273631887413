import styled from 'styled-components';

export const Container = styled.div`
    padding: 1rem 0;
`;

export const ImageContainer = styled.div`
    width: 100%;
    margin: 1rem 0rem;
`;
