import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const FormLabel = ({ item }) => {
    return (
        <Container>
            <span>{item.text}</span>
        </Container>
    );
};

FormLabel.propTypes = {
    item: PropTypes.object.isRequired
};

export default FormLabel;
