import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: #e8e8e8 solid 1px;
    padding-bottom: 1rem;
    cursor: pointer;

    > main {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        > div {
            width: 40px;
            height: 40px;
            margin-right: 0.5rem;
            border-radius: 50%;
            object-fit: cover;
            background-size: cover;
        }

        > p {
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.15px;
            color: #373848;
        }
    }
`;

export const ContainerDesktop = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: #e8e8e8 solid 1px;
    padding-bottom: 1rem;
    cursor: pointer;

    > main {
        width: 30%;
        display: flex;
        flex-direction: row;
        align-items: center;

        > div {
            width: 40px;
            height: 40px;
            margin-right: 0.5rem;
            border-radius: 50%;
            object-fit: cover;
            background-size: cover;
        }

        > section {
            display: flex;
            flex-direction: row;
            align-items: center;
            > p {
                margin-left: 0.5rem;
                font-weight: 400;
                font-size: 14px;
                letter-spacing: 0.15px;
                color: #373848;
            }
        }
    }

    > aside {
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > p {
            white-space: nowrap;
        }
    }
`;
