import styled from 'styled-components';

export const Container = styled.div`
    background-color: ${({ whitePage }) => (whitePage ? '#fff' : 'var(--color-background)')};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    padding: ${({ noPadding }) => (noPadding ? '0' : 'var(--padding-base)')};
    width: 600px;
    max-width: 100vw;

    > * {
        margin-top: 2rem;
    }
`;
