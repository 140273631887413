import React from 'react';
import PropTypes from 'prop-types';
import { Container, Label } from './styles';
import { Select, MenuItem, FormHelperText, Checkbox, ListItemText } from '@mui/material';
import { useEffect } from 'react';

const MultiSelectInput = ({ id, handleChange, value, error, handleBlur, item }) => {
    const { label, optional, options, onChange } = item;

    const getRenderText = (selected) => {
        if (selected.length > 0) {
            return selected
                .map((item) => {
                    return options.find((option) => option.id === item).value;
                })
                .join(', ');
        }

        return 'Nenhum item selecionado';
    };

    useEffect(() => {
        if (!value || !onChange) {
            return;
        }

        onChange(value);
    }, [value]);

    useEffect(() => {
        const optionsIds = options.map((option) => option.id);

        if (!optionsIds.includes(value)) {
            handleChange({
                target: {
                    value: ''
                }
            });
        }
    }, [options]);

    return (
        <Container error={error}>
            <Label>
                {label}
                {optional ? '' : '*'}
            </Label>
            <Select
                id={id}
                name={id}
                onBlur={handleBlur}
                value={value || []}
                variant="outlined"
                onChange={(e) => handleChange(e)}
                helperText={'d'}
                error={error}
                multiple
                renderValue={(selected) => getRenderText(selected)}
            >
                {options.map((option) => (
                    <MenuItem value={option.id} key={option.id}>
                        <Checkbox checked={value?.indexOf(option.id) > -1} />
                        <ListItemText primary={option.value} />
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{error}</FormHelperText>
        </Container>
    );
};

MultiSelectInput.propTypes = {
    item: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    value: PropTypes.any,
    error: PropTypes.string,
    id: PropTypes.string
};

export default MultiSelectInput;
