import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const LibraryListLayout = ({ children }) => {
    return <Container>{children}</Container>;
};

LibraryListLayout.propTypes = {
    children: PropTypes.node.isRequired
};

export default LibraryListLayout;
