import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    > * {
        margin-bottom: 0.5rem;
    }

    > h3 {
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 130%;
        color: #373848;
    }

    > p {
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        color: #373848;
    }

    > h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 130%;
        text-align: center;
        color: #373848;
    }
`;

export const ImageContainer = styled.div`
    width: 100%;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        > h3 {
            font-weight: bold;
            font-size: 1.1rem;
            text-transform: uppercase;
        }
    }
`;

export const GoldText = styled.span`
    color: #df9b34;
    margin-top: 0.5rem;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: bold;
`;
