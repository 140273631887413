import PropTypes from 'prop-types';
import { CompetenceType } from 'types/data/competence';
import { LevelType } from 'types/data/level';

export const FeedbackType = {
    type: PropTypes.oneOf(['finishCourse', 'finishEvent', 'levelUpCompetences', 'levelUp', 'toLevelUp']).isRequired,
    points: PropTypes.number,
    competences: PropTypes.arrayOf(CompetenceType),
    level: PropTypes.shape(LevelType)
};
