import Video from 'components/molecules/functionalities/Video';
import React from 'react';
import PropTypes from 'prop-types';

import { Slider, ImageContainer, ImageContainerItem, SingleItemContainer, Slides } from './styles';
import { FileType } from 'types/components/file';

const TimelineContent = ({ files }) => {
    const isVideo = (uri) => uri.includes('.mp4') || uri.includes('.mov');

    if (!files || files.length === 0) {
        return null;
    }

    if (files.length === 1) {
        const file = files[0];
        if (isVideo(file.url)) {
            return (
                <SingleItemContainer>
                    <Video url={file.url} />
                </SingleItemContainer>
            );
        }

        return (
            <SingleItemContainer>
                <ImageContainer>
                    <img src={file.url} alt="Postagem" />
                </ImageContainer>
            </SingleItemContainer>
        );
    }

    return (
        <Slider>
            <Slides>
                {files.map((file, index) => {
                    if (isVideo(file.url)) {
                        return <Video key={index} url={file.url} />;
                    }

                    return (
                        <ImageContainerItem key={index}>
                            <img src={file.url} alt="Postagem" />
                        </ImageContainerItem>
                    );
                })}
            </Slides>
        </Slider>
    );
};

TimelineContent.propTypes = {
    files: PropTypes.arrayOf(PropTypes.shape(FileType))
};

export default TimelineContent;
