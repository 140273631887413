import Svg from 'components/atoms/structure/Svg';
import React from 'react';
import { LevelType } from 'types/data/level';
import { getSystemImageUrl } from 'utils/assets';
import PropTypes from 'prop-types';

import { Container, ImageContainer } from './styles';
import { WeekModalData } from './utils';
import WeekLogAccess from 'components/molecules/containers/WeekLogAccess';

const Level3LevelModal = ({ level, levelConquered }) => {
    const getWeekAccessData = (progress) => {
        if (levelConquered) {
            return WeekModalData[4];
        }

        if (!progress) return null;

        const week = level?.progress / 20;

        return WeekModalData[week - 1];
    };

    const weekAccessData = getWeekAccessData(level?.progress);

    if (!level?.progress) {
        return (
            <Container>
                <p>Desafio: acesse o PFA por </p>

                <h2>5 Semanas direto!</h2>

                <ImageContainer>
                    <Svg url={getSystemImageUrl('Framecalendar.svg')} width="108" height="108" />
                </ImageContainer>

                <p>
                    Só aprende quem têm foco e rotina! <br />
                    Vamos evoluir pessoal! :)
                </p>
            </Container>
        );
    }

    return (
        <Container>
            <p>{weekAccessData.heading} </p>

            <h2>{weekAccessData.title}</h2>

            <ImageContainer>
                <Svg url={weekAccessData.image} width="108" height="108" />
            </ImageContainer>

            <WeekLogAccess selectedWeek={weekAccessData.week} />

            <footer>{weekAccessData.footer}</footer>
        </Container>
    );
};

Level3LevelModal.propTypes = {
    level: PropTypes.shape(LevelType),
    levelConquered: PropTypes.bool
};

export default Level3LevelModal;
