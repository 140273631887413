import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import Message from 'components/molecules/alerts/Message';
import { EventType } from 'types/data/event';
import SmallEventCard from 'components/molecules/cards/SmallEventCard';
import { CircularProgress } from '@mui/material';

const SmallEventList = ({ events, emptyMessage, loading }) => {
    const path = window.location.pathname;

    if (loading) {
        return <CircularProgress />;
    }

    if (!events.length) {
        return <Message text={emptyMessage || 'Nenhum evento encontrado'} />;
    }

    return (
        <Container>
            {events.map((event, index) => (
                <SmallEventCard competences={event.competences} key={index} event={event} returnUrl={path} />
            ))}
        </Container>
    );
};

SmallEventList.propTypes = {
    events: PropTypes.arrayOf(PropTypes.shape(EventType)),
    emptyMessage: PropTypes.string,
    loading: PropTypes.bool
};

export default SmallEventList;
