import styled from 'styled-components';

export const Container = styled.div`
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 16px;
    gap: 16px;

    width: 100%;

    background: #ffffff;

    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    border-radius: 16px;

    position: sticky;
    top: calc(var(--navbar-height) + 3rem);
    align-self: start;

    > h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;

        color: #373848;
    }
    > .competence {
        width: 100%;
        padding: 16px 0 0 0;
        &:not(:first-of-type) {
            border-top: 1px solid #c7c7c7;
        }
    }
`;
