import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import AuthNavbar from 'components/organisms/layout/navbars/AuthNavbar';

const AuthLayout = ({ children }) => {
    return (
        <Container>
            <AuthNavbar />
            <main>{children}</main>
        </Container>
    );
};

AuthLayout.propTypes = {
    children: PropTypes.node.isRequired
};

export default AuthLayout;
