export const initialState = {
    returnUrl: '',
    returnText: '',
    returnPath: ''
};

const routerReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_RETURN':
            return {
                ...state,
                returnUrl: actions.payload.returnUrl,
                returnText: actions.payload.returnText,
                returnPath: actions.payload.returnPath
            };

        default:
            return state;
    }
};

export default routerReducer;
