import { Dialog, DialogTitle, DialogContentText, DialogActions, Button as MUIButton } from '@mui/material';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

export const Container = styled(Dialog)``;

export const DesktopContainer = styled(Dialog)``;
export const DialogContent = styled.div`
    width: 328px;
    padding: 16px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 15px 0;

    border-bottom: 1px solid #c7c7c7;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #0a432d;
`;
export const Title = styled(DialogTitle)`
    font-size: 1rem;
    padding: 0.8rem;
    width: 100%;
    border-bottom: 1px;
    color: var(--color-primary);
    font-weight: 700;
    text-align: center;
`;

export const Line = styled.div`
    width: 80%;
    margin: 0 auto;
    height: 1px;
    background-color: #c7c7c7;
`;

export const Content = styled(DialogContent)`
    padding: 0.8rem;
`;

export const Text = styled(DialogContentText)`
    font-size: 16px;
    text-align: center;
`;

export const Actions = styled(DialogActions)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const Button = styled(MUIButton)`
    width: auto;
    font-size: 0.8rem;
    text-transform: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: ${({ cancel }) => (cancel ? '#E2EDD0' : 'var(--color-secondary)')};
    color: ${({ cancel }) => (cancel ? 'var(--color-primary)' : '#fff')};
    padding: 8px 22px;
    &:hover {
        background-color: var(--color-primary);
        color: #fff;
    }
`;

export const CloseButton = styled(CloseIcon)`
    position: absolute;
    right: 16px;
    top: 16px;

    cursor: pointer;
    color: var(--color-primary);
`;
