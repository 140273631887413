import ReactQuill from 'react-quill';
import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    overflow-y: visible;

    > aside {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin: 1rem 0;

        > * {
            margin-right: 0.5rem;
        }
    }

    > footer {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > button {
            max-width: 50%;
        }
    }
`;

export const Input = styled(ReactQuill)`
    width: 100%;
    border-bottom: 1px solid #e8e8e8;

    .quill {
        border-bottom: 1px solid #e8e8e8;
    }

    .ql-container.ql-snow {
        border: none;
    }
`;

export const Files = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 1rem;
`;
