import { CircularProgress } from '@mui/material';
import NotificationsList from 'components/organisms/lists/NotificationsList';
import Meta from 'components/templates/Seo/Meta';
import useSnackbar from 'hooks/useSnackbar';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getNotifications, sawNotification } from 'services/notifications';

const Notificacoes = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);
    const [loadingNotifications, setLoadingNotifications] = useState(false);
    const [scrollY, setScrollY] = useState(0);
    const [page, setPage] = useState(0);
    const [lastPage, setLastPage] = useState(true);
    const [removedNotifications, setRemovedNotifications] = useState([]);
    const snackbar = useSnackbar();

    const getNoRepeatNotifications = useCallback(
        (notifications) => {
            const noRepeatNotifications = [];
            const ids = [];

            notifications?.forEach((notification) => {
                if (!ids.includes(notification.id) && !removedNotifications.includes(notification.id)) {
                    ids.push(notification.id);
                    noRepeatNotifications.push(notification);
                }
            });

            return noRepeatNotifications;
        },
        [removedNotifications]
    );

    const setNoRepeatNotifications = (notifications) => {
        setNotifications(getNoRepeatNotifications(notifications));
    };

    const getNotificationsData = async () => {
        if (page === lastPage || loadingNotifications) {
            return;
        }

        setLoadingNotifications(true);

        await getNotifications(page + 1)
            .then((res) => {
                if (res?.data?.data?.length === 0) {
                    setLastPage(0);
                    return;
                }

                setNoRepeatNotifications([...notifications, ...res.data.data]);
                setLastPage(res.data.last_page);
            })
            .catch(() => {
                snackbar('Não foi possível carregar as notificações.', 'error');
            })
            .finally(() => {
                setLoading(false);
                setLoadingNotifications(false);
            });
    };

    const removeNotification = async (id) => {
        sawNotification(id)
            .then(() => {
                dispatch({ type: 'SAW_NOTIFICATION' });
            })
            .catch(() => {});

        setRemovedNotifications([...removedNotifications, id]);
        setNotifications(notifications.filter((notification) => notification.id !== id));

        if (notifications.length < 10 && page < lastPage) {
            getNotificationsData();
        }
    };

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        setScrollY(currentScrollY);
    };

    const verifyScroll = () => {
        if (window.innerHeight === document.documentElement.scrollHeight && page < lastPage) {
            getNotificationsData();
            return;
        }

        if (scrollY !== 0 && !loadingNotifications) {
            if (window.innerHeight + scrollY + 10 >= document.documentElement.scrollHeight) {
                getNotificationsData();
                return;
            }
        }
    };

    useEffect(() => {
        verifyScroll();
    }, [scrollY, page]);

    useEffect(() => {
        const thisPage = notifications?.length ? Math.floor(notifications?.length / 5) : 0;

        setPage(thisPage);
    }, [notifications]);

    useEffect(() => {
        if (notifications?.length > 0 || page >= lastPage) {
            return;
        }

        getNotificationsData();
    }, [notifications]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: '/aluno',
                returnText: 'Notificações',
                returnPath: location.pathname
            }
        });
    }, []);

    useEffect(() => {
        getNotificationsData();
        setNotifications();

        setLoading(false);
    }, []);

    const cleanedNotifications = getNoRepeatNotifications(notifications);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
            <Meta title="Notificações" />

            <NotificationsList notifications={cleanedNotifications} removeNotification={removeNotification} />
        </>
    );
};

export default Notificacoes;
