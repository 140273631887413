import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const IconText = ({ text, icon, label }) => {
    return (
        <Container>
            <aside> {icon}</aside>

            <span>
                <b>{label}:</b> {text}
            </span>
        </Container>
    );
};

IconText.propTypes = {
    text: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.node
};

export default IconText;
