import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: var(--color-background);
    border-radius: 1rem;
    padding: 1rem;
`;

export const Item = styled.div`
    display: flex;
    flex-direction: column;
    width: 3.2rem;
    justify-content: center;
    align-items: center;

    > span {
        font-weight: 700;
        font-size: 14px;
        line-height: 130%;
        text-align: center;
        margin-top: 0.5rem;
        color: ${({ active }) => (active ? '#6B9D4A' : '#E8E8E8')};
    }
`;
