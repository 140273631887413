import { CircularProgress } from '@mui/material';
import Message from 'components/molecules/alerts/Message';
import BorderTitle from 'components/molecules/headers/BorderTitle';
import Course from 'components/organisms/containers/Course';
import Meta from 'components/templates/Seo/Meta';
import useQueryParam from 'hooks/useQueryParam';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getCourseById, seeCourse } from 'services/courses';
import useWindowSize from '../../../../hooks/useWindowSize';
import CourseLayout from '../../../../components/templates/layouts/Pages/CourseLayout';
import CoursesAside from '../../../../components/organisms/containers/CoursesAside';
import { getStudentCompetences } from '../../../../services/competences';
import CourseCompetencesCard from '../../../../components/molecules/cards/CourseCompetencesCard';

const CoursePage = () => {
    const { id } = useParams();
    const windowSize = useWindowSize();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [course, setCourse] = useState({ id: 1 });
    const [competence, setCompetence] = useState({});
    const [competences, setCompetences] = useState([]);
    const dispatch = useDispatch();
    const location = useLocation();
    const courseSelector = useSelector((state) => state.course);
    const query = useQueryParam();
    const [gettingCourse, setGettingCourse] = useState(false);
    const { REACT_APP_BASE_URL } = process.env;

    const getCompetencesData = async () => {
        await getStudentCompetences()
            .then((response) => {
                setCompetences(
                    response.data.map((competence) => {
                        return {
                            ...competence,
                            key: competence.description
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .replaceAll(' ', '_')
                                .toLowerCase()
                        };
                    })
                );
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar competências', 'error');
            });
    };
    const getCompetenceData = () => {
        if (!courseSelector?.competence && !course?.competences) {
            return;
        }

        if (courseSelector?.competence?.id) {
            setCompetence(courseSelector.competence);
            return;
        }

        setCompetence({
            id: course?.competences?.[0]?.id,
            name: course?.competences?.[0]?.desription
        });
    };

    const getCourseData = async () => {
        if (gettingCourse) {
            return;
        }

        await getCourseById(id)
            .then((response) => {
                if (!response.data.sawed) {
                    seeCourse(Number(id));
                }

                let course = response.data;
                if (course.certificatePdf) {
                    let [, path] = course.certificatePdf.split('/storage/');
                    course.certificatePdf = `${REACT_APP_BASE_URL}/get_file/${path}`;
                }

                setCourse(course);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.data.error);

                console.error(error);
                snackbar('Não foi possível carregar o curso.', 'error');
            });
    };

    const getReturnUrl = () => {
        const returnUrl = query.get('returnUrl');

        if (returnUrl) {
            return returnUrl;
        }

        return courseSelector?.competence?.id ? `/aluno/cursos/competencias/${courseSelector?.competence.id}` : '/aluno/cursos';
    };

    const onClickType = (newType) => {
        navigate(`/aluno/cursos?list=${newType}`);
    };
    const onClickCompetence = (scroll) => {
        navigate(`/aluno/cursos?list=todos&scrollTo=${scroll}`);
    };

    useEffect(() => {
        getCompetencesData();
        getCourseData();
        setGettingCourse(true);
    }, []);

    useEffect(() => {
        getCompetenceData();
    }, [courseSelector, course]);

    useEffect(() => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: getReturnUrl(),
                returnText: 'Cursos',
                returnPath: location.pathname
            }
        });
    }, [courseSelector]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error || !course?.id) {
        return <Message text={error} />;
    }

    if (windowSize.mobile) {
        return (
            <>
                <Meta title={course?.title} description={course?.title} />

                <BorderTitle title={competence.name || ''} />

                {course && <Course course={course} />}
            </>
        );
    }
    return (
        <>
            <Meta title={course?.title} description={course?.title} />

            <CourseLayout>
                <CoursesAside competences={competences} onClickCompetence={onClickCompetence} active={''} onClickType={onClickType} />
                <Course course={course} />
                <CourseCompetencesCard competences={competences} course={course} />
            </CourseLayout>
        </>
    );
};

export default CoursePage;
