import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, DesktopContainer, DownloadSection } from './styles';
import TimerDuration from 'components/atoms/texts/TimerDuration';
import Player from 'components/molecules/functionalities/Player';
import Line from 'components/atoms/structure/Line';
import FavoriteCheckbox from 'components/atoms/inputs/FavoriteCheckbox';
import Button from 'components/molecules/buttons/Button';
import { ClassType } from 'types/data/course';
import ReactHtmlParser from 'react-html-parser';
import { padNumber } from 'utils/general';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { finishVideoClass, stopClass } from 'services/courses/watch';
import Quiz from 'components/organisms/forms/Structure/Quiz';
import useSnackbar from 'hooks/useSnackbar';
import { CircularProgress } from '@mui/material';
import useWindowSize from '../../../../hooks/useWindowSize';
import ClassesList from '../../lists/ClassesList';

const WatchClass = ({
    watchClass,
    liked,
    setLiked,
    likes,
    nextClass,
    handleFinish,
    lastLesson,
    setFinished,
    finishedCourse,
    answerQuiz,
    quizAnswers,

    classes,
    changeClass,
    activeClassNumber,
    maxClassNumber,
    finished,
    progress
}) => {
    const { learningObject } = watchClass;

    const video = learningObject.find((learningObject) => learningObject.typeLearningObject === 'Videos');
    const quiz = watchClass?.quiz.length > 0 ? watchClass?.quiz.flatMap((quiz) => (quiz?.question ? quiz?.question : [])) : null;
    const quizTime = watchClass?.quiz.reduce((acc, curr) => acc + curr.time, 0);

    const auth = useSelector((state) => state.auth);
    const snackbar = useSnackbar();
    const windowSize = useWindowSize();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [finishedQuiz, setFinishedQuiz] = useState(null);
    const [finishedVideo, setFinishedVideo] = useState(null);

    const finishVideo = async () => {
        await finishVideoClass(watchClass.id)
            .then(() => {
                setFinishedVideo(true);

                if (finishedQuiz) {
                    handleFinish();
                }
            })
            .catch((error) => {
                snackbar(error.message, 'error');
            });
    };

    const finishQuiz = () => {
        setFinishedQuiz(true);

        if (finishedVideo) {
            handleFinish();
        }
    };

    const checkFinishVideo = () => {
        if (!video || watchClass?.myStudentFinishedVideo || watchClass?.myStudentComplete) {
            return true;
        }

        return false;
    };

    const checkFinishQuiz = () => {
        if (!quiz || watchClass?.myStudentComplete) {
            return true;
        }

        return quizAnswers?.filter((answer) => answer !== null).length === quiz?.length;
    };

    const checkStatus = useCallback(() => {
        const finishVideo = checkFinishVideo();
        const finishQuiz = checkFinishQuiz();

        setFinishedVideo(finishVideo);
        setFinishedQuiz(finishQuiz);

        if (video && quiz) {
            if (finishVideo && finishQuiz) {
                handleFinish();
            }
            return;
        }
        if (video && !quiz) {
            if (finishVideo) {
                handleFinish();
            }
            return;
        }
        if (quiz && !video) {
            if (finishQuiz) {
                handleFinish();
            }
            return;
        }

        setFinished(false);
    }, [watchClass]);

    const goToNextClass = () => {
        if (lastLesson && finished) {
            navigate('/aluno/cursos');
            return;
        }
        if (!finishedQuiz) {
            snackbar('Você precisa responder todas as questões para finalizar a aula.', 'error');
            return;
        }

        if (!finishedVideo) {
            snackbar('Você precisa assistir o vídeo para finalizar a aula.', 'error');
            return;
        }

        if (!video && !quiz) {
            handleFinish(true);
            return;
        }
        nextClass();
    };
    const answerQuestion = async (questionId, answerId, index) => {
        const newAnswers = quizAnswers;
        newAnswers[index] = answerId;

        await answerQuiz(questionId, answerId, index).then(() => {
            if (checkFinishQuiz()) {
                finishQuiz();
            }
        });
    };

    useEffect(() => {
        setLoading(true);
        checkStatus();
        setLoading(false);
    }, [watchClass.id]);

    if (loading) {
        return <CircularProgress />;
    }

    if (windowSize.mobile) {
        return (
            <Container>
                <header>
                    <h3>
                        Aula {padNumber(Number(watchClass.number))}: {watchClass.title}
                    </h3>
                    <h1>{watchClass.name}</h1>

                    <TimerDuration minutes={watchClass.time + quizTime} text="Tempo estimado de" />
                </header>

                {video && (
                    <Player
                        url={video.url}
                        setFinished={() => finishVideo()}
                        watchClass={watchClass}
                        idStudent={auth?.user?.studentId}
                        stopClass={stopClass}
                    />
                )}

                <main>{ReactHtmlParser(watchClass.description)}</main>
                {learningObject &&
                    learningObject
                        ?.filter((learningObject) => learningObject.idLearningObject === 2)
                        .map((learningObject, index) => (
                            <DownloadSection key={`lo-${index}`}>
                                <Button onClick={() => window.open(learningObject.url)}>
                                    Download do arquivo {learningObject.name.split('.')[1].toUpperCase()}
                                </Button>
                            </DownloadSection>
                        ))}

                {quiz && <Quiz questions={quiz} answers={quizAnswers} answerQuestion={answerQuestion} />}

                <footer>
                    <header>
                        <h3>Gostou da aula? Nôs dê um like!</h3>

                        <aside>
                            <FavoriteCheckbox active={liked} setActive={setLiked} noPaddingLeft />
                            <p>{likes + liked}</p>
                        </aside>
                    </header>

                    <Line color="#333" />

                    {lastLesson ? (
                        <Button onClick={goToNextClass} disabled={!finishedVideo}>
                            {finishedCourse ? 'Voltar para cursos' : 'Finalizar curso'}
                        </Button>
                    ) : (
                        <Button onClick={goToNextClass} disabled={!finishedVideo}>
                            Próximo
                        </Button>
                    )}
                </footer>
            </Container>
        );
    }
    return (
        <DesktopContainer>
            <aside>
                <ClassesList
                    classes={classes}
                    changeClass={changeClass}
                    currentClassNumber={activeClassNumber}
                    maxClassNumber={maxClassNumber}
                    finished={finished}
                    finishedCourse={progress === 100}
                />
            </aside>
            <main>
                <Container>
                    <header>
                        <h3>
                            Aula {padNumber(Number(watchClass.number))}: {watchClass.title}
                        </h3>
                        <h1>{watchClass.name}</h1>

                        <TimerDuration minutes={watchClass.time + quizTime} text="Tempo estimado de" />
                    </header>

                    {video && (
                        <Player
                            url={video.url}
                            setFinished={() => finishVideo()}
                            watchClass={watchClass}
                            idStudent={auth?.user?.studentId}
                            stopClass={stopClass}
                        />
                    )}

                    <main>{ReactHtmlParser(watchClass.description)}</main>
                    {learningObject && (
                        <>
                            <h3>Objetos de Aprendizagem</h3>
                            {learningObject
                                ?.filter((learningObject) => learningObject.idLearningObject === 2)
                                .map((learningObject, index) => (
                                    <DownloadSection key={`lo-${index}`}>
                                        <Button onClick={() => window.open(learningObject.url)} color={'secondary'}>
                                            Download do arquivo {learningObject.name.split('.')[1].toUpperCase()}
                                        </Button>
                                    </DownloadSection>
                                ))}
                        </>
                    )}
                    {quiz && <Quiz questions={quiz} answers={quizAnswers} answerQuestion={answerQuestion} />}

                    <footer>
                        <header>
                            <h3>Gostou da aula? Nôs dê um like!</h3>

                            <aside>
                                <FavoriteCheckbox active={liked} setActive={setLiked} noPaddingLeft />
                                <p>{likes + liked}</p>
                            </aside>
                        </header>

                        <Line color="#333" />

                        {lastLesson ? (
                            <Button onClick={goToNextClass} disabled={!finishedVideo} color={'secondary'}>
                                {finished ? 'Voltar para cursos' : 'Finalizar curso'}
                            </Button>
                        ) : (
                            <Button onClick={goToNextClass} disabled={!finishedVideo} color={'secondary'}>
                                Próximo
                            </Button>
                        )}
                    </footer>
                </Container>
            </main>
        </DesktopContainer>
    );
};

WatchClass.propTypes = {
    watchClass: PropTypes.shape(ClassType).isRequired,
    liked: PropTypes.bool.isRequired,
    setLiked: PropTypes.func.isRequired,
    likes: PropTypes.number.isRequired,
    nextClass: PropTypes.func.isRequired,
    handleFinish: PropTypes.func.isRequired,
    lastLesson: PropTypes.bool,
    setFinished: PropTypes.func,
    finishedCourse: PropTypes.bool,
    noVideo: PropTypes.bool,
    quizAnswers: PropTypes.arrayOf(PropTypes.number),
    answerQuiz: PropTypes.func.isRequired,

    classes: PropTypes.arrayOf(PropTypes.shape(ClassType)).isRequired,
    changeClass: PropTypes.func.isRequired,
    activeClassNumber: PropTypes.number.isRequired,
    maxClassNumber: PropTypes.number.isRequired,
    finished: PropTypes.bool.isRequired,
    progress: PropTypes.number.isRequired
};

export default WatchClass;
