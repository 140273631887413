import styled from 'styled-components';

export const Container = styled.div``;

export const ModalContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    @media (min-width: 768px) {
        max-height: 80vh;
        height: 80vh;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }
`;

export const CommentModal = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    width: 600px;
    max-width: 100vw;
    height: 100vh;
    max-height: 850px;
    margin: 0 auto;
    border-radius: 0.5rem;
    overflow: hidden;

    > header {
        display: flex;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid #a1a1a7;
        background: #f8f7fd;
    }

    > main {
        flex-grow: 1;
        overflow: auto;
        background: #f8f7fd;

        ::-webkit-scrollbar {
            border-radius: 10px;
            width: 0.2rem;
            height: 0.4rem;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #bbb;
        }

        ::-webkit-scrollbar-thumb:hover {
            border-radius: 10px;
            background: #aaa;
        }
    }

    > footer {
        box-shadow: 0px -5px 0.5rem rgba(0, 0, 0, 0.03);
    }

    @media (min-width: 768px) {
        height: 80vh;
        border-radius: 0.5rem 0.5rem 0 0;
    }
`;

export const HeaderTitle = styled.div`
    flex-grow: 1;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    color: #0a432d;
`;

export const ModalPost = styled.div`
    display: flex;
    gap: 10px;
    padding: 15px;

    > footer {
        flex-grow: 1;
        overflow: auto;
    }
`;

export const ModalPostTitle = styled.div`
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 130%;
    color: #373848;
    margin-bottom: 5px;
`;

export const ModalPostText = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #373848;
`;

export const Divisor = styled.div`
    border-top: 1px solid #e8e8e8;
    margin: 10px 15px;
`;

export const MoreComments = styled.button`
    display: block;
    border: none;
    background: none;
    font-style: normal;
    font-size: 14px;
    line-height: 130%;
    margin: 0 auto 20px auto;
    text-decoration: underline;
`;

export const Emojis = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #e8e8e8;

    button {
        border: none;
        background: none;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
    }
`;

export const FormContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    height: 100%;
    padding: 0.5rem 0.5rem 0.5rem 1rem;

    > header {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
    }

    > main {
        flex-grow: 1;

        > textarea {
            resize: none;
            background: none;
            border: none;
            width: 100%;
            height: 26px;
            max-height: 72px;
            font-family: 'Myriad Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            padding: 4px;
            margin-bottom: -7px;
            max-height: 100px;
            overflow: hidden;

            &[disabled] {
                opacity: 0.5;
            }
        }
    }

    button {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 130%;
        color: #0a432d;
        text-decoration-line: underline;
        border: none;
        background: none;

        &[disabled] {
            opacity: 0.5;
        }
    }
`;
