import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
`;

export const Tabs = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    border-bottom: 1px solid #03302520;

    > * {
        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }
    }
`;

export const Tab = styled.div`
    cursor: pointer;
    border-radius: 8px 8px 0px 0px;
    padding: 0.5rem;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 0.8rem;
    text-transform: uppercase;
    text-align: center;
    color: #373848;
    transition: 0.2s ease-in-out;

    background: ${({ active }) => (active ? '#e7f5e6' : '#fff')};

    border-bottom: 2px solid ${({ active }) => (active ? '#87A851' : '#fff')};
`;
