import React from 'react';
import PropTypes from 'prop-types';
import { ContainerWrapper, Container } from './styles';

const HomeLayout = ({ children, title, marginTop }) => {
    return (
        <ContainerWrapper marginTop={marginTop}>
            {title && <div className="title">{title}</div>}
            <Container>{children}</Container>
        </ContainerWrapper>
    );
};

HomeLayout.propTypes = {
    title: PropTypes.string,
    marginTop: PropTypes.string,
    children: PropTypes.node.isRequired
};

HomeLayout.defaultProps = {
    marginTop: '0'
};

export default HomeLayout;
