import { Avatar } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
    grid-area: UA;
    width: 100%;
    position: sticky;
    top: calc(var(--navbar-height) + 1rem);
    align-self: start;
`;

export const UserContent = styled.div`
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 68%, rgba(220, 248, 255, 0.8491771708683473) 100%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    width: 100%;
    padding: 1rem;
    border: 2px #fff solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > * {
        margin-bottom: 1rem;
    }

    > header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > p {
            font-weight: 400;
            font-size: 12px;
            line-height: 130%;
            color: var(--color-dark);
        }

        > h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 130%;
            color: var(--color-dark);
            margin-top: 0.2rem;
            text-align: center;
        }
    }

    > main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    > footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const UserImage = styled(Avatar)`
    width: 72px;
    height: 72px;
    margin-bottom: 1rem;
    border: 3px solid #ffffff;
`;

export const ParticipantsContent = styled.div`
    background-color: #e7f5e6;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > * {
        margin-bottom: 0.8rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    > h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 130%;
        color: var(--color-dark);
    }
`;

export const PostForm = styled.a`
    width: 100%;
    position: relative;
    cursor: pointer;

    > header {
        margin-top: 2rem;

        > textarea {
            width: 100%;
            border: none;
            background-color: transparent;
            border-bottom: 2px solid #e8e8e8;
            height: 5rem;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            text-align: left;
            font-family: 'Myriad Pro';
            resize: none;
            font-size: 0.9rem;
            cursor: pointer;
        }
    }

    > footer {
        display: flex;
        flex-direction: row;
        margin-top: 0.5rem;

        > * {
            margin-right: 0.5rem;
        }
    }
`;

export const Line = styled.div`
    width: 216px;
    margin-left: -1rem;
    height: 2px;
    background: #e8e8e8;
    position: absolute;
`;
