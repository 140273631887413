import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const BlankContainer = ({ children, noBackground }) => {
    return <Container noBackground={noBackground}>{children}</Container>;
};

BlankContainer.propTypes = {
    children: PropTypes.node,
    noBackground: PropTypes.bool
};

export default BlankContainer;
