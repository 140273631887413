import SubSection from 'components/molecules/structure/SubSection';
import React from 'react';
import PropTypes from 'prop-types';
import { CarouselCard, CarouselContainer, Container, DesktopContainer } from './styles';
import FullWidthContainer from 'components/atoms/structure/FullWidthContainer';
import CompetenceKnob from 'components/molecules/cards/CompetenceKnob';
import { CompetenceType } from 'types/data/competence';
import useWindowSize from 'hooks/useWindowSize';
import CarouselArrowButton from 'components/molecules/buttons/CarouselArrowButton';

const MyCompetencesCarousel = ({ competences, title, legend, courseRedirect }) => {
    const windowSize = useWindowSize();

    competences.sort((a, b) => {
        const currentLevelData = Number(b.currentLevel) - Number(a.currentLevel);

        if (currentLevelData === 0) {
            return Number(b.percentNextLevel) - Number(a.percentNextLevel);
        }

        return currentLevelData;
    });

    if (!competences || competences.length === 0) {
        return null;
    }

    if (windowSize.mobile) {
        return (
            <FullWidthContainer>
                <SubSection title={title || 'Suas Competências:'} legend={legend} paddingLeft>
                    <Container>
                        {competences.map((item, index) => (
                            <CompetenceKnob key={index} competence={item} courseRedirect={courseRedirect} />
                        ))}
                    </Container>
                </SubSection>
            </FullWidthContainer>
        );
    }

    return (
        <DesktopContainer>
            {title && (
                <header>
                    <h3>{title}</h3>
                </header>
            )}

            <main>
                <CarouselContainer
                    cellSpacing={40}
                    dragging
                    swiping
                    renderCenterLeftControls={({ previousSlide }) => (
                        <CarouselArrowButton onClick={previousSlide} type="left" backgroundWhite />
                    )}
                    renderCenterRightControls={({ nextSlide }) => <CarouselArrowButton onClick={nextSlide} type="right" backgroundWhite />}
                    renderBottomCenterControls={null}
                    dragThreshold={0.1}
                    slidesToShow={7}
                    wrapAround
                    enableKeyboardControls={false}
                    scrollMode="remainder"
                >
                    {competences.map((item, index) => (
                        <CarouselCard key={index}>
                            <CompetenceKnob competence={item} courseRedirect={courseRedirect} />
                        </CarouselCard>
                    ))}
                </CarouselContainer>
            </main>
        </DesktopContainer>
    );
};

MyCompetencesCarousel.propTypes = {
    competences: PropTypes.arrayOf(PropTypes.shape(CompetenceType)).isRequired,
    title: PropTypes.string,
    legend: PropTypes.string,
    courseRedirect: PropTypes.bool
};

export default MyCompetencesCarousel;
