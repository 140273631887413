import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import React from 'react';

import { Container } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { navigationData } from 'constants/components/navigation';

const BottomNavbar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const redirect = (path) => {
        navigate(path);
    };

    return (
        <Container>
            <BottomNavigation
                showLabels
                value={location.pathname}
                onChange={(event, newValue) => {
                    redirect(newValue);
                }}
            >
                {navigationData.map((item, index) => (
                    <BottomNavigationAction
                        key={index}
                        disabled={item.disabled}
                        label={item.title}
                        value={item.link}
                        icon={location.pathname === item.link ? item.iconSelected : item.icon}
                    />
                ))}
            </BottomNavigation>
        </Container>
    );
};

export default BottomNavbar;
