import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    width: 100%;

    .Mui-expanded {
        margin: 0;
    }
`;

export const Item = styled(Accordion)`
    width: 100%;
    margin: 0;
    box-shadow: none;
    border: none;
    border-top: 1px #eee solid;

    > .Mui-expanded {
        margin: 0;
        border: none;
        border-top: none;
    }
`;

export const Summary = styled(AccordionSummary)`
    border: none;
    padding: 0.5rem 1rem;
    margin: 0;

    h3 {
        font-weight: 600;
        font-size: 1rem;
        color: var(--color-primary);
    }

    .Mui-expanded {
        margin: 0;
    }
`;

export const Details = styled(AccordionDetails)`
    background-color: #e8e8e8;
    border: none;
    margin: 0;

    p {
        font-size: 0.9rem;
        margin-top: 0.5rem;
        white-space: pre-line;
    }
`;
