import React, { useEffect, useState } from 'react';
import { Container, Image } from './styles';
import { Add } from '@mui/icons-material';
import useWindowSize from 'hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';
import { getFallbackUrl } from 'utils/general';
import { userType } from 'types/data/user';
import PropTypes from 'prop-types';

const AvatarGroup = ({ users }) => {
    const size = useWindowSize();
    const [max, setMax] = useState(4);
    const navigate = useNavigate();

    const redirect = (user) => {
        const uri = user?.id_enterprise ? `/aluno/empresas/${user?.id_enterprise}` : `/aluno/participantes/${user?.id}`;
        navigate(getFallbackUrl(uri, 'Home'));
    };

    useEffect(() => {
        if (size.width <= 330) {
            setMax(2);
            return;
        }

        if (size.width <= 370) {
            setMax(3);
            return;
        }

        if (size.width <= 700) {
            setMax(4);
            return;
        }

        setMax(6);
    }, [size.width]);

    return (
        <Container max={max}>
            {users.slice(0, max - 1).map((user, index) => (
                <Image key={index} src={user.url_image || ''} zindex={index + 1} onClick={() => redirect(user)}>
                    {user.name[0].toUpperCase()}
                </Image>
            ))}
            <Image zindex={9} onClick={() => navigate(getFallbackUrl('/aluno/participantes', 'Home'))}>
                <Add />
            </Image>
        </Container>
    );
};

AvatarGroup.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape(userType)).isRequired
};

export default AvatarGroup;
