import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const DescriptionText = ({ children }) => {
    return <Container>{children}</Container>;
};

DescriptionText.propTypes = {
    children: PropTypes.node.isRequired
};

export default DescriptionText;
