import styled from 'styled-components';

export const ContainerFooter = styled.div`
    width: 100%;
    padding-top: 0.8rem;

    > h2 {
        color: var(--color-black);
        font-size: 24px;
        font-weight: 700;
    }

    > h3 {
        padding-top: 0.2rem;
        font-weight: 400;
        font-size: 16px;
        color: var(--color-black);
    }

    > p {
        padding-top: 0.4rem;
        font-weight: 400;
        font-size: 12px;
        color: var(--color-black);
    }
`;
