import ModalFormTemplate from 'components/molecules/structure/ModalFormTemplate';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const TextareaModalFormInput = ({ item, onSubmit }) => {
    const [value, setValue] = useState('');

    const handleSubmit = () => {
        onSubmit(value);
    };

    return (
        <ModalFormTemplate onSubmit={handleSubmit}>
            <Container>
                <h2>
                    {item.label}
                    {item?.optional && <span>(opcional)</span>}
                </h2>

                <main>
                    <textarea onChange={(e) => setValue(e.target.value)} />
                </main>
            </Container>
        </ModalFormTemplate>
    );
};

TextareaModalFormInput.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        validation: PropTypes.any,
        submit: PropTypes.func,
        optional: PropTypes.bool
    }),
    onSubmit: PropTypes.func
};

export default TextareaModalFormInput;
