import PropTypes from 'prop-types';
import React from 'react';
import useWindowSize from 'hooks/useWindowSize';

import { Container, ContainerDesktop } from './styles';

const Title = ({ heading, title }) => {
    const windowSize = useWindowSize();

    if (windowSize.mobile) {
        return (
            <Container>
                {heading && <p>{heading}</p>}
                <h1>{title}</h1>
            </Container>
        );
    }

    return (
        <ContainerDesktop>
            {heading && <p>{heading}</p>}
            <h1>{title}</h1>
        </ContainerDesktop>
    );
};

Title.propTypes = {
    heading: PropTypes.string,
    title: PropTypes.string.isRequired
};

export default Title;
