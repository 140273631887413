import styled from 'styled-components';
import certificadoImg from 'assets/images/certificado.png';

export const DesktopContainer = styled.div`
    width: 100%;
    max-width: 1170px;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin: 0 auto;
    > :first-child {
        max-width: 50%;
        white-space: nowrap;
        > h3 {
            color: #033025;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 700;
            font-size: 18px;
            line-height: 130%;
        }
        > .competences {
            display: flex;
            gap: 5px;
        }
    }
    > :last-child {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: end;
        align-items: baseline;
        gap: 10px;

        font-weight: 600;
        font-size: 14px;
        white-space: nowrap;
        > .progress {
            width: 100%;
            min-width: 408px;
        }
        > .certificado {
            min-width: 76px;
            max-width: 76px;
            height: 54px;
            background-image: url('${certificadoImg}');
            background-size: cover;
            background-repeat: no-repeat;
            border: 0.5px solid #c7c7c7;
        }
    }
`;
export const Container = styled.div`
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;

    > header {
        width: 100%;
        margin-bottom: 1rem;

        > h3 {
            font-size: 1.2rem;
            color: var(--color-text);
        }
    }

    > footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
`;
