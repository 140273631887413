import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > header {
        > span {
            font-weight: 600;
            font-size: 0.8rem;
            color: #6b9d4a;
        }
    }

    > main {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    > footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        > h3 {
            font-weight: bold;
            font-size: 1.1rem;
            text-transform: uppercase;
        }
    }
`;
