import Svg from 'components/atoms/structure/Svg';
import useFeedback from 'hooks/useFeedback';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getSystemImageUrl } from 'utils/assets';
import { getFallbackUrl } from 'utils/general';

import { Container, ImageContainer } from './styles';

const Level1LevelModal = () => {
    const hook = useFeedback();
    const navigate = useNavigate();

    const goToMagnaClass = () => {
        hook.closeFeedback();
        navigate(getFallbackUrl('/aluno/biblioteca/conteudo/155', 'Perfil'));
    };

    return (
        <Container>
            <p>Desafio: Assista ao vídeo do evento:</p>

            <ImageContainer>
                <Svg url={getSystemImageUrl('Iconelevelup1.svg')} width="60" height="60" />
            </ImageContainer>

            <a aria-hidden="true" onClick={() => goToMagnaClass()}>
                <h4>Aula Magna - Comunidade Profissional do Futuro do Agronegócio</h4>
            </a>

            <a aria-hidden="true" onClick={() => goToMagnaClass()}>
                <p> Dica: vá em Biblioteca</p>
            </a>
        </Container>
    );
};

export default Level1LevelModal;
