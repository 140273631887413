import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { Close, Check } from '@mui/icons-material';

const QuestionFeedback = ({ correct, message }) => {
    return (
        <Container correct={correct}>
            <header>
                {correct ? (
                    <>
                        <Check htmlColor="#87D04D" />
                        <span>Resposta correta!</span>
                    </>
                ) : (
                    <>
                        <Close htmlColor="#DB5252" />
                        <span>Resposta errada!</span>
                    </>
                )}
            </header>
            <main>{message !== null && <p>{message}</p>}</main>
        </Container>
    );
};

QuestionFeedback.propTypes = {
    correct: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    message: PropTypes.string.isRequired
};

export default QuestionFeedback;
