import ModalFormTemplate from 'components/molecules/structure/ModalFormTemplate';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, EvaluationContainer, EvaluationButton } from './styles';

const EvaluationModalFormInput = ({ item, onSubmit }) => {
    const [value, setValue] = useState();
    const grades = new Array(10).fill(0).map((_, index) => index + 1);

    const handleSubmit = () => {
        onSubmit(value);
    };

    return (
        <ModalFormTemplate onSubmit={handleSubmit}>
            <Container>
                <h2>{item.label}</h2>

                <legend>
                    <span>1 = {item.minLegend}</span>

                    <span>10 = {item.maxLegend}</span>
                </legend>

                <main>
                    <EvaluationContainer>
                        {grades.slice(0, 5).map((grade) => (
                            <EvaluationButton key={grade} onClick={() => setValue(grade)} active={grade === value}>
                                <span>{grade}</span>
                            </EvaluationButton>
                        ))}
                    </EvaluationContainer>
                    <EvaluationContainer>
                        {grades.slice(5, 10).map((grade) => (
                            <EvaluationButton key={grade} onClick={() => setValue(grade)} active={grade === value}>
                                <span>{grade}</span>
                            </EvaluationButton>
                        ))}
                    </EvaluationContainer>
                </main>
            </Container>
        </ModalFormTemplate>
    );
};

EvaluationModalFormInput.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        validation: PropTypes.any,
        submit: PropTypes.func,
        minLegend: PropTypes.string,
        maxLegend: PropTypes.string
    }),
    onSubmit: PropTypes.func.isRequired
};

export default EvaluationModalFormInput;
