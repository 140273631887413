import React, { useState } from 'react';
import TabsHeader from 'components/molecules/headers/TabsHeader';
import useWindowSize from 'hooks/useWindowSize';
import { useDispatch } from 'react-redux';
import TabsSectionHeader from 'components/molecules/headers/TabsSectionHeader';

const TimelineHeader = () => {
    const [tab, setTab] = useState('all');
    const windowSize = useWindowSize();
    const dispatch = useDispatch();

    const handleTab = (newValue) => {
        setTab(newValue);

        dispatch({
            type: 'CHANGE_TAB',
            payload: newValue
        });
    };

    const tabs = [
        {
            label: 'Geral',
            value: 'all'
        },
        {
            label: 'Vagas',
            value: 'opportunities'
        }
    ];

    if (windowSize.mobile) {
        return <TabsHeader tab={tab} setTab={handleTab} tabs={tabs} />;
    }

    return <TabsSectionHeader tab={tab} setTab={handleTab} tabs={tabs} />;
};

export default TimelineHeader;
