import styled from 'styled-components';

export const Container = styled.div`
    padding-top: 0.5rem;
    padding-bottom: 1rem;

    > header {
        display: flex;
        margin-top: 0.5rem;

        > p {
            text-align: center;
        }
    }

    > footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
    }
`;
