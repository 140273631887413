import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    > aside {
        margin-right: 1rem;
        height: 48px;
        width: 48px;

        > * {
            height: 48px;
            width: 48px;
        }
    }

    > main {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 130%;
        color: #373848;
    }
`;
