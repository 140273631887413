import React from 'react';
import PropTypes from 'prop-types';
import { Container, Status } from './styles';
import { Link } from 'react-router-dom';

const PfaListCard = ({ label, title, legend, href, status }) => {
    return (
        <Link to={href}>
            <Container>
                <h3>{label}</h3>
                <p>{title}</p>
                <span>{legend}</span>

                {status && (
                    <Status finished={status === 'Concluído'}>
                        <span>{status}</span>
                    </Status>
                )}
            </Container>
        </Link>
    );
};

PfaListCard.propTypes = {
    label: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    legend: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    status: PropTypes.string
};

export default PfaListCard;
