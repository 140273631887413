import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import Progress from '../../../atoms/inputs/Progress';
import { useNavigate } from 'react-router-dom';
import PdfViewer from '../../../atoms/structure/PdfViewer';
import { BookmarkBorder, ZoomIn } from '@mui/icons-material';
import useCourses from '../../../../hooks/useCourses';
import useFavoriteList from '../../../../hooks/useFavoriteList';
import FavoriteCheckbox from '../../../atoms/inputs/FavoriteCheckbox';
import SaveCheckbox from '../../../atoms/inputs/SaveCheckbox';

const GridCourseCard = ({ course, variant }) => {
    const navigate = useNavigate();
    const favoriteList = useFavoriteList();
    const { REACT_APP_BASE_URL } = process.env;

    const [isFavorite, setIsFavorite] = useState(false);

    const { setSelectedCourse, setOpenCertificateModal } = useCourses();
    const formatDescription = (description) => {
        description = description.replace(/<\/?[^>]+(>|$)/g, '');

        return description;
    };

    const redirectToCourse = (courseId) => {
        navigate(`/aluno/cursos/curso/${courseId}`);
    };

    const handleFavorite = async () => {
        favoriteList.openListModal(isFavorite, setIsFavorite, course.id, 'course');
    };
    useEffect(() => {
        setIsFavorite(course.isFavorite);
        if (course.certificatePdf && !course.formatted) {
            let [, path] = course.certificatePdf.split('/storage/');
            course.certificatePdf = `${REACT_APP_BASE_URL}/get_file/${path}`;
            course.formatted = true;
        }
    }, [course]);

    const handleClickCertificate = (course) => {
        setSelectedCourse(course);
        setOpenCertificateModal(true);
    };

    switch (variant) {
        case 'participating':
            return (
                <Container>
                    <div className={'image'}>
                        <img src={course.image} alt={course.title} />
                    </div>
                    <div className={'title'}>
                        <h4>{course.title}</h4>
                        <SaveCheckbox active={isFavorite} setActive={handleFavorite} />
                    </div>
                    <div className={'progress'}>
                        <Progress progress={course.progress} height={'10.67px'} />
                    </div>
                    <h6>{course.time} horas no total</h6>
                    <button
                        onClick={() => {
                            redirectToCourse(course.id);
                        }}
                    >
                        Continuar Curso
                    </button>
                </Container>
            );
        case 'concluded':
            return (
                <Container>
                    <div className={'pdfViewer'}>
                        {course.certificatePdf && (
                            <div
                                aria-hidden="true"
                                className={'pdf'}
                                onClick={() => {
                                    handleClickCertificate(course);
                                }}
                            >
                                <PdfViewer src={course.certificatePdf} />
                                <div className={'hover'}>
                                    <ZoomIn />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={'title'}>
                        <h4>{course.title}</h4>
                        <SaveCheckbox active={isFavorite} setActive={handleFavorite} />
                    </div>
                    <h6>Concluído em {course.finalDate}</h6>
                    <button
                        onClick={() => {
                            redirectToCourse(course.id);
                        }}
                    >
                        Rever
                    </button>
                </Container>
            );
        default:
            return (
                <Container>
                    <div className={'image'}>
                        <img src={course.image} alt={course.title} />
                    </div>
                    <div className={'title'}>
                        <h4>{course.title}</h4>
                        <SaveCheckbox active={isFavorite} setActive={handleFavorite} />
                    </div>
                    <h6>Por {course.speaker}</h6>
                    <div className={'description'}>
                        <p>{formatDescription(course.description)}</p>
                    </div>
                    <h6>{course.time} horas no total</h6>
                    <button
                        onClick={() => {
                            redirectToCourse(course.id);
                        }}
                    >
                        Saiba Mais
                    </button>
                </Container>
            );
    }
};
GridCourseCard.propTypes = {
    course: PropTypes.any.isRequired,
    variant: PropTypes.string
};
export default GridCourseCard;
