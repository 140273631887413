import { api } from 'services/api';
import { parsedCourses } from 'services/courses';
import { parsedEvents } from 'services/events';
import { postLogError } from '../logErrors/index';

const getCompetenceById = async (competenceId) => {
    const response = await api
        .get(`/competence/${competenceId}`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const getCompetences = async () => {
    const response = await api
        .get(`/competence`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: parseCompetences(response.data),
        status: response.status
    };
};

const getCompetenceCourses = async (idCompetence, page, qtdPerPage) => {
    const response = await api
        .get(`/get_course_competence`, {
            params: {
                idCompetence,
                page,
                qtdPerPage
            }
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: parsedCourses(response.data.data),
        status: response.status,
        lastPage: response.data.last_page
    };
};

const getCompetenceLibraries = async (idCompetence, page) => {
    const response = await api
        .get(`/get_library_competence`, {
            params: {
                idCompetence,
                page
            }
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data.data,
        status: response.status,
        lastPage: response.data.last_page
    };
};

const getCompetenceEvents = async (idCompetence, page) => {
    const response = await api
        .get(`/get_event_competence`, {
            params: {
                idCompetence,
                page
            }
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: parsedEvents(response.data.data),
        status: response.status,
        lastPage: response.data.last_page
    };
};

const getStudentCompetences = async () => {
    const response = await api
        .get(`/competence_student`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: parseCompetences(response.data),
        status: response.status
    };
};

const parseCompetences = (competences) => {
    return competences
        .map((item) => {
            return {
                ...item,
                description: item?.desription || item?.description
            };
        })
        .sort((a, b) => {
            return a.id - b.id;
        });
};

export { getCompetenceById, getCompetences, getStudentCompetences, getCompetenceCourses, getCompetenceLibraries, getCompetenceEvents };
