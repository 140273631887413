import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import Svg from '../Svg';
import { getSystemImageUrl } from 'utils/assets';

const Stars = ({ data }) => {
    const { stars, url } = data;

    return (
        <Container stars={stars}>
            {Array(stars)
                .fill(0)
                .map((_, index) => (
                    <Svg url={getSystemImageUrl(url)} key={index} width="42" height="42" />
                ))}
        </Container>
    );
};

Stars.propTypes = {
    data: PropTypes.shape({
        stars: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired
    }).isRequired
};

export default Stars;
