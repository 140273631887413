import { CircularProgress } from '@mui/material';
import BorderTitle from 'components/molecules/headers/BorderTitle';
import TabsHeader from 'components/molecules/headers/TabsHeader';
import CompetencesList from 'components/organisms/lists/CompetencesList';
import Meta from 'components/templates/Seo/Meta';
import useSnackbar from 'hooks/useSnackbar';
import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getStudentCompetences } from 'services/competences';

const CompetencesProfile = () => {
    const [activeTab, setActiveTab] = useState('individuals');
    const [competences, setCompetences] = useState([]);
    const dispatch = useDispatch();
    const location = useLocation();
    const [loading, setLoading] = useState(true);

    const snackbar = useSnackbar();

    const socialsCompetences = useMemo(() => competences.filter((competence) => competence.competenceGroup === 'Social'), [competences]);

    const individualsCompetences = useMemo(
        () => competences.filter((competence) => competence.competenceGroup === 'Individual'),
        [competences]
    );

    const tabs = [
        {
            label: 'Individuais',
            value: 'individuals'
        },
        {
            label: 'Sociais',
            value: 'socials'
        }
    ];

    const getCompetencesData = async () => {
        await getStudentCompetences()
            .then((response) => {
                setCompetences(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Não foi possível carregar as competências.', 'error');
            });
    };

    useEffect(() => {
        getCompetencesData();
    }, []);

    useEffect(() => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: '/aluno/perfil',
                returnText: 'Perfil',
                returnPath: location.pathname
            }
        });
    }, []);

    if (loading) {
        return (
            <>
                <Meta title="Minhas competências" />
                <CircularProgress />
            </>
        );
    }

    return (
        <>
            <Meta title="Minhas competências" />

            <BorderTitle title="Suas Competências" />

            <TabsHeader tabs={tabs} setTab={setActiveTab} tab={activeTab} />

            {
                {
                    individuals: <CompetencesList competences={individualsCompetences} />,
                    socials: <CompetencesList competences={socialsCompetences} />
                }[activeTab]
            }
        </>
    );
};

export default CompetencesProfile;
