import * as React from 'react';
import { ListItemButton, ListItemText } from '@mui/material';
import { Container, ListStyled } from './styles';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const FilterAside = ({ formats }) => {
    const navigate = useNavigate();

    const redirectToItem = (id) => {
        id ? (window.location.href = `/aluno/biblioteca/formato/${id}`) : navigate('/aluno/biblioteca');
    };
    return (
        <Container>
            <ListStyled component="nav" disablePadding>
                <ListItemButton key={0}>
                    <ListItemText primary="Todos os arquivos" onClick={() => redirectToItem()} />
                </ListItemButton>
                {formats?.map((option, key) => (
                    <ListItemButton key={key}>
                        <ListItemText primary={option.description} onClick={() => redirectToItem(option.id)} />
                    </ListItemButton>
                ))}
            </ListStyled>
        </Container>
    );
};
FilterAside.propTypes = {
    formats: PropTypes.array.isRequired
};

export default FilterAside;
