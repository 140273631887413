import { api } from 'services/api';
import { postLogError } from '../logErrors/index';

const getParticipants = async (page, name, exactName, idRole) => {
    const response = await api
        .get(`/student`, {
            params: {
                page,
                name,
                exactName,
                idRole
            }
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: parseParticipants(response.data?.data) || [],
        lastPage: response.data.last_page,
        total: response.data.total
    };
};

const parseParticipants = (participants) => {
    if (!participants) return [];

    let parsedData = [];

    participants.forEach((course) => {
        parsedData.push(parseParticipant(course));
    });

    return parsedData;
};

const parseParticipant = (participant) => {
    return {
        id: participant?.id_enterprise || participant?.id_student,
        name: participant?.name,
        image: participant?.url_image,
        roleId: participant?.id_role,
        city: participant?.city ? participant?.city : participant?.city_enterprise,
        uf: participant?.uf ? participant?.uf : participant?.uf_enterprise,
        type: participant?.id_enterprise ? 'enterprise' : 'student'
    };
};

export { getParticipants };
