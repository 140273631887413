import React from 'react';
import PropTypes from 'prop-types';
import { Container, Content } from './styles';
import { Search } from '@mui/icons-material';

const SearchInput = ({ value, onChange, footer }) => {
    return (
        <Container>
            <Content>
                <button>
                    <Search htmlColor="#A1A1A7" />
                </button>
                <input type="text" value={value} onChange={(e) => onChange(e.target.value)} placeholder="Digite o que está procurando" />
                <button>
                    <Search htmlColor="#A1A1A7" />
                </button>
            </Content>
            {footer && <footer>{footer}</footer>}
        </Container>
    );
};

SearchInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    footer: PropTypes.string
};

export default SearchInput;
