import React from 'react';
import PropTypes from 'prop-types';
import { Container, ContainerDesktop } from './styles';
import LibrarySearchCard from 'components/molecules/cards/LibrarySearchCard';
import Message from 'components/molecules/alerts/Message';
import useWindowSize from 'hooks/useWindowSize';

const GridLibrary = ({ data, type, errorText }) => {
    const windowSize = useWindowSize();

    if (!data || data.length === 0) {
        return <Message text={errorText} />;
    }

    if (windowSize.mobile) {
        return (
            <Container>
                {data.map((item, index) => (
                    <LibrarySearchCard key={index} item={item} type={type} />
                ))}
            </Container>
        );
    }
    return (
        <ContainerDesktop>
            {data.map((item, index) => (
                <LibrarySearchCard key={index} item={item} type={type} />
            ))}
        </ContainerDesktop>
    );
};

GridLibrary.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            value: PropTypes.string
        })
    ).isRequired,
    type: PropTypes.string.isRequired,
    errorText: PropTypes.string
};

export default GridLibrary;
