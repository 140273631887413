import styled from 'styled-components';
import { Avatar } from '@mui/material';

export const Container = styled.div`
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > p {
        font-size: 0.7rem;
    }

    .MuiAvatarGroup-root {
        > * {
            min-width: 40px;
            min-height: 40px;
            max-width: 40px;
            max-height: 40px;
        }
    }
`;

export const Image = styled(Avatar)`
    z-index: ${(props) => props.zindex || '1'};
    cursor: pointer;

    &:nth-of-type(4) {
        background-color: #6b9d4a;
    }

    &:nth-of-type(3) {
        background-color: #bedabd;
    }

    &:nth-of-type(2) {
        background-color: #c0e1d9;
    }

    &:first-of-type {
        background-color: #476633;
    }
`;

export const ButtonInline = styled.div`
    margin-right: 0.5rem;
`;
