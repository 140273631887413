import styled from 'styled-components';

export const Container = styled.div`
    padding-top: 0.5rem;
    width: 100%;
`;

export const DesktopContainer = styled.div`
    grid-area: SH;
    padding-top: 1.6rem;
    width: 100%;
    height: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;

    > h1 {
        font-size: 1.2rem;
        color: #333;
        margin-top: 5px;
    }
`;

export const ImageContainer = styled.div`
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #fff;
    box-shadow: var(--box-shadow);
    color: red;

    > img {
        width: 40px;
        height: 40px;
    }
`;
