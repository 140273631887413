import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const CompetenceIcon = ({ icon, filter }) => {
    return (
        <Container filter={filter}>
            <img
                src={icon ? icon : 'https://icons.iconarchive.com/icons/icons8/windows-8/512/Files-Png-icon.png'}
                alt="Ícone da competência"
            />
        </Container>
    );
};

CompetenceIcon.propTypes = {
    filter: PropTypes.string,
    icon: PropTypes.string.isRequired
};

export default CompetenceIcon;
