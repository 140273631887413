import styled from 'styled-components';

export const Container = styled.div`
    > div {
        margin-right: 0.5rem;

        &:last-child {
            margin-right: 0;
        }
    }
`;
