import FavoriteRemove from 'components/molecules/containers/FavoriteRemove';
import FormMaker from 'components/organisms/layout/form/FormMaker';
import Drawer from 'components/organisms/layout/modals/Drawer';
import useFavoriteList from 'hooks/useFavoriteList';
import React from 'react';
import { useSelector } from 'react-redux';
import { Validator } from 'utils/validators';

import { Container } from './styles';

const EditFavoriteDrawer = () => {
    const favoriteList = useSelector((state) => state.favoriteList);
    const hook = useFavoriteList();

    const selectedItem = favoriteList?.selectedItem;

    const submit = async (data) => {
        await hook
            .edit({ ...selectedItem, ...data })
            .then(() => hook.closeEditModal())
            .catch(() => {});
    };

    const form = [
        [
            {
                text: 'Renomear Lista',
                type: 'label'
            }
        ],
        [
            {
                id: 'description',
                type: 'text',
                label: 'Nome da lista',
                initialValue: selectedItem?.description,
                message: 'Máximo 50 caracteres',
                validation: Validator.string()
                    .required('Nome da lista é obrigatório')
                    .max(50, 'Nome da lista deve ter no máximo 50 caracteres')
            }
        ]
    ];

    return (
        <Drawer title="Editar lista" active={favoriteList.editModal} close={() => hook.closeEditModal()} open={() => hook.openEditModal()}>
            <Container>
                <FavoriteRemove />
                <FormMaker data={form} formSubmit={submit} submitText="Salvar" noConfirm />
            </Container>
        </Drawer>
    );
};

export default EditFavoriteDrawer;
