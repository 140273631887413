import ModalFormMaker from 'components/organisms/layout/form/ModalFormMaker';
import React from 'react';
import { Validator } from 'utils/validators';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { QuizDataType } from 'types/data/quiz';
import { answerSatisfactionQuiz } from 'services/quizzes';

const QuizModalForm = ({ quiz }) => {
    const submit = async (id, values) => {
        await answerSatisfactionQuiz(id, values);
    };

    const buildQuiz = () => {
        const form = quiz.map((question) => {
            if (question.type === 'radio') {
                return {
                    id: question.id,
                    label: question.description,
                    type: 'radio',
                    options: [
                        {
                            id: 'Sim',
                            label: 'Sim',
                            value: true
                        },
                        {
                            id: 'Não',
                            label: 'Não',
                            value: false
                        }
                    ],
                    validation: Validator.bool('Deve selecionar uma das opções').required('Deve selecionar uma das opções'),
                    submit: submit
                };
            }

            if (question.type === 'evaluation') {
                return {
                    id: question.id,
                    label: question.description,
                    type: 'evaluation',

                    validation: Validator.number('A avaliação é obrigatória')
                        .required('A avaliação é obrigatória')
                        .min(1, 'Nota mínima deve ser 1')
                        .max(10, 'Nota máxima deve ser 10'),
                    minLegend: 'Pouco provável',
                    maxLegend: 'Muito provável',
                    submit: submit
                };
            }

            if (question.type === 'textarea') {
                return {
                    id: question.id,
                    label: question.description,
                    type: 'textarea',
                    validation: question?.required
                        ? Validator.string('Deve preencher o campo').required('Deve preencher o campo')
                        : Validator.string('Deve preencher o campo').nullable(),
                    submit: submit,
                    optional: !question?.required
                };
            }

            if (question.type === 'acknowledgment') {
                return {
                    id: 'acknowledgment',
                    type: 'acknowledgment'
                };
            }
        });

        return form;
    };

    const form = buildQuiz();

    return (
        <Container>
            <ModalFormMaker data={form} />
        </Container>
    );
};

QuizModalForm.propTypes = {
    quiz: PropTypes.arrayOf(PropTypes.shape(QuizDataType))
};

export default QuizModalForm;
