import PropTypes from 'prop-types';

export const FileType = {
    created_at: PropTypes.string,
    id: PropTypes.number,
    id_post: PropTypes.number,
    name: PropTypes.string,
    size: PropTypes.string,
    updated_at: PropTypes.string,
    url: PropTypes.string
};
