import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Svg = ({ url, height, width, color, image }) => {
    if (image) {
        return (
            <Container color={color}>
                <img src={url} alt="ícone" width={width || '20'} height={height || '20'} />
            </Container>
        );
    }

    return (
        <svg width={width || '20'} height={height || '20'}>
            <image xlinkHref={url} src={url} width={width || '20'} height={height || '20'} />
        </svg>
    );
};

export default Svg;

Svg.propTypes = {
    url: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    image: PropTypes.bool
};
