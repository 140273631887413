import { CircularProgress } from '@mui/material';
import Message from 'components/molecules/alerts/Message';
import Post from 'components/organisms/containers/Post';
import Meta from 'components/templates/Seo/Meta';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getPostById } from 'services/post';

const Publicacao = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [post, setPost] = useState({ id: 1 });
    const dispatch = useDispatch();
    const location = useLocation();

    const getPostData = async () => {
        await getPostById(id)
            .then((response) => {
                setPost(response.data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.data.error);
                console.error(error);
                snackbar('Não foi possível carregar os cursos.', 'error');
            });
    };

    useEffect(() => {
        getPostData();
    }, []);

    useEffect(() => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: '/aluno',
                returnText: 'Oportunidade',
                returnPath: location.pathname
            }
        });
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    if (error || !post?.id) {
        return <Message text={error} />;
    }

    return (
        <>
            <Meta title={post?.title} description="Descrição do Curso" />

            {post && <Post post={post} idPublication={id} />}
        </>
    );
};

export default Publicacao;
