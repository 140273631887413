import styled from 'styled-components';

// CA - Courses Aside
// C - Courses

export const Container = styled.div`
    display: grid;
    grid-template-columns: 250px minmax(auto, 560px) 320px;
    gap: 1rem;
    width: 100%;
    height: 100%;
    align-items: start;

    grid-template-areas:
        'CA C C'
        'CA C C'
        'CA C C';
`;
