import Meta from 'components/templates/Seo/Meta';
import React from 'react';
import MenuLayout from 'components/templates/layouts/Components/MenuLayout';
import AccountConfig from 'components/organisms/forms/Others/AccountConfig';

const MinhaConta = () => {
    return (
        <MenuLayout title="Configurações da conta" returnUrlDefault="/aluno">
            <Meta title="Configurações da conta" />

            <AccountConfig />
        </MenuLayout>
    );
};

export default MinhaConta;
