import styled from 'styled-components';

export const Container = styled.a`
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    text-decoration: underline;
    cursor: pointer;
`;
