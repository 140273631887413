import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const HeaderTitle = styled.div`
    margin-bottom: 16px;
    font-family: 'Myriad Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #373848;
`;

export const EventColumn = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    > header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start
        width: 100%;
        padding-bottom: 0.5rem;

        > h2 {
            font-size: 1.1rem;
        }
    }

    > main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        > * {
            margin-bottom: 1rem;
        }
    }

    &:last-child {
        border-bottom: none;
    }
`;
