import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SubSection from 'components/molecules/structure/SubSection';
import useWindowSize from 'hooks/useWindowSize';
import { FormatType } from 'types/data/format';
import { CompetenceType } from 'types/data/competence';
import { useEffect } from 'react';
import { cleanAccents } from 'utils/general';
import { Container, DesktopContainer, ContainerSearch } from './styles';
import GridLibrary from 'components/molecules/containers/GridLibrary';
import Message from 'components/molecules/alerts/Message';
import LibraryObjectCard from 'components/molecules/cards/LibraryObjectCard';
import CourseCard from 'components/molecules/cards/CourseCard';
import EventCard from 'components/molecules/cards/EventCard';

const Library = ({ search, formats, competences, loading, learningObjects, courses, events }) => {
    const [filteredFormats, setFilteredFormats] = useState(formats || []);
    const [filteredCompetences, setFilteredCompetences] = useState(competences || []);
    const windowSize = useWindowSize();

    const pipe = (array, attr) => {
        if (!array) return [];

        return array.map((item) => {
            return {
                id: item.id,
                value: item[attr],
                icon: item?.icon,
                color: item?.color
            };
        });
    };

    useEffect(() => {
        if (search) {
            setFilteredCompetences(
                pipe(
                    competences.filter((competence) => {
                        return cleanAccents(competence.description.toLowerCase()).includes(cleanAccents(search.toLowerCase()));
                    }),
                    'description'
                )
            );

            return;
        }

        setFilteredCompetences(pipe(competences, 'description'));
    }, [search, competences]);

    useEffect(() => {
        if (search) {
            setFilteredFormats(
                pipe(
                    formats.filter((format) => {
                        return cleanAccents(format.description.toLowerCase()).includes(cleanAccents(search.toLowerCase()));
                    }),
                    'description'
                )
            );

            return;
        }
        setFilteredFormats(pipe(formats, 'description'));
    }, [search, formats]);

    if (windowSize.mobile) {
        return (
            <>
                {learningObjects.length > 0 && (
                    <SubSection title="Resultados da busca:" marginTop>
                        <ContainerSearch>
                            {learningObjects.map((item, index) => (
                                <LibraryObjectCard learningObject={item} key={index} />
                            ))}
                            {courses.map((item, index) => (
                                <CourseCard course={item} key={index} />
                            ))}
                            {events.map((item, index) => (
                                <EventCard event={item} key={index} />
                            ))}
                        </ContainerSearch>
                    </SubSection>
                )}

                {loading && <Message html text="Carregando..." />}

                {loading === false && learningObjects.length == 0 && (
                    <SubSection title="Pesquisar por Competências:" marginTop>
                        <Container>
                            <GridLibrary data={filteredCompetences} type="competence" errorText="Nenhuma competência encontrada" />
                        </Container>
                    </SubSection>
                )}

                {loading === false && learningObjects.length == 0 && (
                    <SubSection title="Pesquisar por tipo de arquivo:" marginTop>
                        <Container>
                            <GridLibrary data={filteredFormats} type="format" errorText="Nenhum tipo de arquivo encontrado" />
                        </Container>
                    </SubSection>
                )}
            </>
        );
    }
    return (
        <DesktopContainer>
            {learningObjects.length > 0 && (
                <SubSection title="Resultados da busca:" marginTop>
                    <ContainerSearch>
                        {learningObjects.map((item, index) => (
                            <LibraryObjectCard learningObject={item} key={index} />
                        ))}
                        {courses.map((item, index) => (
                            <CourseCard course={item} key={index} />
                        ))}
                        {events.map((item, index) => (
                            <EventCard event={item} key={index} />
                        ))}
                    </ContainerSearch>
                </SubSection>
            )}

            {loading && <Message html text="Carregando..." />}

            {loading === false && learningObjects.length == 0 && (
                <SubSection title="Pesquisar por Competências:" marginTop>
                    <Container>
                        <GridLibrary data={filteredCompetences} type="competence" errorText="Nenhuma competência encontrada" />
                    </Container>
                </SubSection>
            )}

            {loading === false && learningObjects.length == 0 && (
                <SubSection title="Pesquisar por tipo de arquivo:" marginTop>
                    <Container>
                        <GridLibrary data={filteredFormats} type="format" errorText="Nenhum tipo de arquivo encontrado" />
                    </Container>
                </SubSection>
            )}
        </DesktopContainer>
    );
};

Library.propTypes = {
    search: PropTypes.string.isRequired,
    formats: PropTypes.arrayOf(PropTypes.shape(FormatType)).isRequired,
    competences: PropTypes.arrayOf(PropTypes.shape(CompetenceType)).isRequired,
    loading: PropTypes.bool,
    learningObjects: PropTypes.array,
    courses: PropTypes.array,
    events: PropTypes.array
};

Library.defaultProps = {
    learningObjects: [],
    courses: [],
    events: []
};

export default Library;
