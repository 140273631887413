export const initialState = {
    list: [],
    searched: false,
    addModal: false,
    editModal: false,
    listModal: false,
    removeModal: false,
    selectedItem: null,
    content: null,
    callback: null
};

const favoriteListReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'GET_FAVORITE_LIST':
            return {
                ...state,
                groups: actions.payload.list
            };

        case 'SET_FAVORITE_LIST':
            return {
                ...state,
                list: actions.payload.list,
                searched: true
            };

        case 'SELECT_FAVORITE_LIST':
            return {
                ...state,
                selectedItem: actions.payload.item
            };

        case 'ADD_FAVORITE_LIST':
            return {
                ...state,
                list: [actions.payload.item, ...state.list]
            };

        case 'EDIT_FAVORITE_LIST':
            return {
                ...state,
                list: state.list.map((item) => {
                    if (item.id === Number(actions.payload.item.id)) {
                        return actions.payload.item;
                    }

                    return item;
                }),
                selectedItem: actions.payload.item
            };

        case 'DELETE_FAVORITE_LIST':
            return {
                ...state,
                list: state.list.filter((item) => item.id !== Number(actions.payload.id)),
                removeModal: false,
                editModal: false,
                selectedItem: null
            };

        case 'OPEN_ADD_MODAL':
            return {
                ...state,
                addModal: true
            };

        case 'CLOSE_ADD_MODAL':
            return {
                ...state,
                addModal: false
            };

        case 'OPEN_EDIT_MODAL':
            return {
                ...state,
                editModal: true,
                selectedItem: actions.payload.item
            };

        case 'CLOSE_EDIT_MODAL':
            return {
                ...state,
                editModal: false
            };

        case 'OPEN_LIST_MODAL':
            return {
                ...state,
                listModal: true,
                selectedItem: actions.payload.item,
                callback: actions.payload.callback,
                content: actions.payload.content
            };

        case 'CLOSE_LIST_MODAL':
            return {
                ...state,
                listModal: false,
                selectedItem: null,
                callback: null,
                content: null
            };

        case 'OPEN_REMOVE_MODAL':
            return {
                ...state,
                removeModal: true
            };

        case 'CLOSE_REMOVE_MODAL':
            return {
                ...state,
                removeModal: false
            };

        default:
            return state;
    }
};

export default favoriteListReducer;
