import Svg from 'components/atoms/structure/Svg';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getSystemImageUrl } from 'utils/assets';
import { getFallbackUrl } from 'utils/general';
import { Container } from './styles';

const ShareIdeaButton = () => {
    const navigate = useNavigate();

    const redirect = () => {
        navigate(getFallbackUrl('/aluno/publicar', 'Compartilhar'));
    };

    return (
        <Container onClick={() => redirect()}>
            <main>
                <span>Compartilhe suas ideias</span>
            </main>
            <aside>
                <Svg url={getSystemImageUrl('ChatTeardropDots.svg')} width="20" height="20" />
            </aside>
        </Container>
    );
};

export default ShareIdeaButton;
