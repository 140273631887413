import SubSection from 'components/molecules/structure/SubSection';
import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import EnterpriseOpportunityCard from 'components/molecules/cards/EnterpriseOpportunityCard';
import Button from 'components/molecules/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { getFallbackUrl } from 'utils/general';
import { getSystemImageUrl } from 'utils/assets';

const OpportunityEnterpriseList = ({ data }) => {
    const navigate = useNavigate();

    const redirectToCreateOpportunity = () => {
        navigate(getFallbackUrl('/aluno/perfil/vaga'));
    };

    return (
        <SubSection title="Vagas" borderTop>
            <Container>
                {data !== undefined &&
                    data.map((item, index) => (
                        <EnterpriseOpportunityCard
                            key={index}
                            title={item.title}
                            legend={item.legend}
                            footer={item.footer}
                            image={getSystemImageUrl('brief.svg')}
                            id={item?.id}
                        />
                    ))}

                <footer>
                    <Button type="button" color="secondary" onClick={() => redirectToCreateOpportunity()}>
                        Adicionar vaga
                    </Button>
                </footer>
            </Container>
        </SubSection>
    );
};

OpportunityEnterpriseList.propTypes = {
    title: PropTypes.string,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            legend: PropTypes.string,
            footer: PropTypes.string,
            image: PropTypes.string,
            uri: PropTypes.string
        })
    ).isRequired,
    icon: PropTypes.string
};

export default OpportunityEnterpriseList;
