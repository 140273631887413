import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
`;

export const Content = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;

    width: 100%;
    max-width: 700px;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 10px;

    > video {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: var(--color-primary);
    }

    > footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        position: absolute;
        bottom: 1rem;
        padding: 1rem;
        width: 100%;
        max-width: calc(100% - 2rem);
        flex-wrap: wrap;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        transform: translateY(150%);
        transition: all 0.3s ease-in-out;

        input[type='range'] {
            -webkit-appearance: none !important;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 20px;
            height: 4px;
            width: calc(100% - 6rem);
        }

        input[type='range']::-webkit-slider-thumb {
            -webkit-appearance: none !important;
            cursor: pointer;
            height: 0.8rem;
            width: 0.8rem;
            background-color: #fff;
            border-radius: 50%;
        }

        input[type='range']::-moz-range-progress {
            background: white;
        }

        button {
            -webkit-tap-highlight-color: transparent;

            width: 2rem;
            background: none;
            border: none;
            outline: none;
            cursor: pointer;
            color: white;
            font-size: 20px;

            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &:hover footer {
        transform: translateY(0%);
    }
`;
