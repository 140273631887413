import { createTheme } from '@mui/material/styles';
import { ptBR } from '@mui/material/locale';

export const theme = () => {
    const themeOptions = {
        typography: {
            fontFamily: 'Myriad Pro'
        },
        palette: {
            type: 'light',
            primary: {
                main: '#0A432D'
            },
            secondary: {
                main: '#6B9D4A'
            },
            neutral: {
                main: '#87A851'
            },
            light: {
                main: '#E2EDD0',
                primary: '#0A432D'
            },
            gray: {
                main: '#A1A1A7'
            },
            lightGray: {
                main: '#E8E8E8'
            },
            orange: {
                main: '#DF9B34'
            },
            purple: {
                main: '#6967C1'
            }
        },
        shape: {
            borderRadius: 8
        },
        ptBR
    };

    const themes = createTheme(themeOptions);

    return themes;
};

export default theme;
