import { CircularProgress } from '@mui/material';
import Meta from 'components/templates/Seo/Meta';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logged } from '../services/auth';

const Index = () => {
    const navigate = useNavigate();

    const redirect = (path) => {
        try {
            navigate(path);
        } catch (error) {
            window.location.href = path;
        }
    };

    const authVerify = async () => {
        const isLogged = await logged();

        if (!isLogged) {
            redirect('/login');
            return;
        }

        redirect('/aluno');
    };

    useEffect(() => {
        setTimeout(() => {
            authVerify();
        }, 500);
    }, [navigate]);

    return (
        <>
            <Meta />
            <CircularProgress color="inherit" />
        </>
    );
};

export default Index;
