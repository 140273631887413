import Svg from 'components/atoms/structure/Svg';
import React from 'react';
import { getSystemImageUrl } from 'utils/assets';

import { Container, ImageContainer } from './styles';

const Level2LevelModal = () => {
    return (
        <Container>
            <p>Desafio: Evolua no mínimo </p>

            <h2>6 níveis</h2>

            <ImageContainer>
                <Svg url={getSystemImageUrl('icone avanco.svg')} width="108" height="108" />
            </ImageContainer>

            <p>Dica: distribua os níveis entre as competências do jeito que você quiser :)</p>
        </Container>
    );
};

export default Level2LevelModal;
