import React from 'react';
import PropTypes from 'prop-types';
import { TextArea, Container } from './styles';
import { FormHelperText } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';

const TextareaInput = ({ id, item, handleChange, value, handleBlur, error }) => {
    const { label, optional } = item;

    return (
        <Container error={error}>
            <p>{ReactHtmlParser(item.descriptionLabel)}</p>
            <TextArea
                label={`${label}${optional ? '' : '*'}`}
                value={value || ''}
                variant="outlined"
                onChange={(e) => handleChange(e)}
                onBlur={handleBlur}
                id={id}
                name={id}
                multiline
                rows={4}
            />
            <FormHelperText>{error}</FormHelperText>
        </Container>
    );
};

TextareaInput.propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    value: PropTypes.any,
    error: PropTypes.string,
    id: PropTypes.string,
    item: PropTypes.object.isRequired
};

export default TextareaInput;
