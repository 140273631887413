import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import Progress from 'components/atoms/inputs/Progress';
import { useNavigate } from 'react-router-dom';
import { CourseType } from 'types/data/course';
import Chips from 'components/molecules/containers/Chips';

const SmallCourseCard = ({ course, path }) => {
    const { id, title, progress, image, competences } = course;
    const navigate = useNavigate();

    const redirectToWatch = () => {
        navigate(`/aluno/cursos/assistir/${id}${path ? `?returnUrl=${path}` : ''}`);
    };

    return (
        <Container onClick={() => redirectToWatch()}>
            <aside>{image ? <img src={image} alt={title} /> : <img src={'https://via.placeholder.com/126'} alt={title} />}</aside>
            <main>
                <header>
                    <h3>{title}</h3>
                </header>
                <footer>
                    <Chips data={competences || []} />
                    <Progress progress={progress} />
                </footer>
            </main>
        </Container>
    );
};

SmallCourseCard.propTypes = {
    course: PropTypes.shape(CourseType).isRequired,
    path: PropTypes.string
};

export default SmallCourseCard;
