import { CircularProgress } from '@mui/material';
import Message from 'components/molecules/alerts/Message';
import Meta from 'components/templates/Seo/Meta';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import useSnackbar from 'hooks/useSnackbar';
import { useParams } from 'react-router-dom';
import { getEnterpriseById } from 'services/enterprise';
import EnterpriseDescription from 'components/organisms/containers/EnterpriseDescription';
import EnterpriseContent from 'components/organisms/containers/EnterpriseContent';
import ProfileListAbout from 'components/organisms/lists/ProfileListAbout';
import { getSystemImageUrl } from 'utils/assets';

const Empresa = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState({});
    const [error, setError] = useState('');

    const snackbar = useSnackbar();
    const dispatch = useDispatch();

    const getProfile = async () => {
        const data = await getEnterpriseById(id)
            .then((response) => response)
            .catch((err) => {
                console.error(err);
                snackbar('Erro ao carregar perfil', 'error');
            });

        if (data.status !== 200) {
            setError(data.data.error);
            setLoading(false);
        }

        if (data.status === 401) {
            navigate('/login');
            return;
        }

        const enterprise = data.data[0];

        if (enterprise?.opportunityEnterprise?.length === 0) {
            enterprise.opportunityEnterprise = [
                {
                    title: 'No momento estamos sem vagas.',
                    legend: 'Mas não desanime! :)',
                    footer: '',
                    image: ''
                }
            ];
        }

        setProfile(enterprise);
        setLoading(false);
    };

    const setSubNavbar = () => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: '/aluno',
                returnText: 'Empresas',
                returnPath: location.pathname
            }
        });
    };

    useEffect(() => {
        setSubNavbar();
        getProfile(id);
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    if (error || !profile?.id) {
        return <Message text={error} />;
    }

    return (
        <>
            <Meta title={profile?.name || 'Empresa'} />

            <EnterpriseDescription
                image={profile?.url_image}
                name={profile.name}
                city={profile.city}
                description={profile.description}
                state={profile.uf}
                background={profile?.url_background_image}
            />

            <EnterpriseContent overview={profile.overview} age={profile.age} site={profile.site} size={profile.size} />

            {profile.sector && <ProfileListAbout title="Atuação" data={[profile.sector]} icon={getSystemImageUrl('brief.svg')} border />}

            {profile.opportunityEnterprise && (
                <ProfileListAbout title="Vagas" data={profile.opportunityEnterprise} icon={getSystemImageUrl('brief.svg')} border />
            )}
        </>
    );
};

export default Empresa;
