import styled from 'styled-components';

// HA - Help Aside
// HH - Help Header
// HB - Help Body

export const Container = styled.div`
    display: grid;
    grid-template-columns: 250px auto auto;
    gap: 1rem;
    width: 100%;
    align-items: start;

    grid-template-areas:
        'HA HH HH'
        'HA HB HB'
        'HA HB HB';
`;
