import styled from 'styled-components';

export const DesktopContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 29px;

    > aside {
        > h1 {
            font-weight: 700;
            font-size: 22px;

            color: #033025;
            margin-top: 20px;
            margin-bottom: 0 !important;
        }
        width: 100%;
        max-width: 224px;
        align-self: flex-start;
        position: sticky;
        top: calc(var(--navbar-height) + 1rem);
    }

    > main {
        width: 100%;
        max-width: 888px;

        > h1.page-title {
            font-weight: 700;
            font-size: 24px;

            margin: 1rem 0;
            text-align: center;
            color: #033025;
        }

        > section {
            padding: 10px 0;

            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            :not(:last-of-type) {
                border-bottom: 1px solid #c7c7c7;
            }
            > * {
                margin: 0;
            }

            > h4.subtitle {
                font-size: 16px;
                color: #373848;
                text-align: center;
            }

            > h4.not-found {
                font-size: 14px;
                color: #464646;
                text-align: center;
                font-weight: normal;
            }

            > .link-button {
                padding: 4px 20px;

                background: #e2edd0;
                border-radius: 4px;
                border: none;

                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 130%;
                color: #015641;
            }
        }
    }
`;
