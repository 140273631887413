import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { Checkbox } from '@mui/material';
import { useFormikContext } from 'formik';

const CheckboxInput = ({ id, item, value, handleBlur }) => {
    const { label, optional } = item;
    const { setFieldValue } = useFormikContext();

    return (
        <Container
            onBlur={handleBlur}
            control={
                <Checkbox
                    checked={value === true}
                    onChange={() => {
                        setFieldValue(id, !value);
                    }}
                />
            }
            label={`${label}${optional ? '' : '*'}`}
            name={id}
        />
    );
};

CheckboxInput.propTypes = {
    item: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    value: PropTypes.any,
    id: PropTypes.string
};

export default CheckboxInput;
