import { getSystemImageUrl } from 'utils/assets';

const WeekModalData = [
    {
        week: 1,
        image: getSystemImageUrl(`week1.svg`),
        heading: 'Desafio: Acesse o PFA por',
        title: '5 semanas',
        footer: (
            <p>
                Seu esforço será recompensado,
                <br /> Vai na fé!
            </p>
        )
    },
    {
        week: 2,
        image: getSystemImageUrl(`week2.svg`),
        heading: 'Desafio: Ofensiva de ',
        title: '5 semanas de uso!',
        footer: (
            <p>
                Já está dando um grauzinho...
                <br />
                Não desista!
            </p>
        )
    },
    {
        week: 3,
        image: getSystemImageUrl(`week3.svg`),
        heading: 'Desafio: Ofensiva de ',
        title: '5 semanas de uso!',
        footer: (
            <p>
                Já chegou na metade,
                <br />
                Agora vai!
            </p>
        )
    },
    {
        week: 4,
        image: getSystemImageUrl(`week4.svg`),
        heading: 'Desafio: Ofensiva de ',
        title: '5 semanas de uso!',
        footer: (
            <p>
                Está quase lá :) <br />
                Agora é tudo ou nada!
            </p>
        )
    },
    {
        week: 5,
        image: getSystemImageUrl(`week5.svg`),
        heading: 'Desafio: Ofensiva de ',
        title: '5 semanas de uso!',
        footer: (
            <p>
                <b>Parabéns! Você concluiu o Desafio!</b> <br />
                Estamos impressionados com sua garra!
            </p>
        )
    }
];

export { WeekModalData };
