import React from 'react';
import Svg from 'components/atoms/structure/Svg';
import { getSystemImageUrl } from 'utils/assets';

export const navigationData = [
    {
        title: 'Home',
        icon: <Svg url={getSystemImageUrl('Home=Default.svg')} />,
        iconSelected: <Svg url={getSystemImageUrl('Home=Select.svg')} />,
        link: '/aluno',
        disabled: false
    },
    {
        title: 'Eventos',
        icon: <Svg url={getSystemImageUrl('Eventos=Default.svg')} />,
        iconSelected: <Svg url={getSystemImageUrl('Eventos=Select.svg')} />,
        link: '/aluno/eventos',
        disabled: false
    },
    {
        title: 'Cursos',
        icon: <Svg url={getSystemImageUrl('Cursos=Default.svg')} />,
        iconSelected: <Svg url={getSystemImageUrl('Cursos=Select.svg')} />,
        link: '/aluno/cursos',
        disabled: false
    },
    {
        title: 'Favoritos',
        icon: <Svg url={getSystemImageUrl('Favoritos=Default.svg')} />,
        iconSelected: <Svg url={getSystemImageUrl('Favoritos=Select.svg')} />,
        link: '/aluno/favoritos',
        disabled: false
    },
    {
        title: 'Biblioteca',
        icon: <Svg url={getSystemImageUrl('Biblioteca=Default.svg')} />,
        iconSelected: <Svg url={getSystemImageUrl('Biblioteca=Select.svg')} />,
        link: '/aluno/biblioteca',
        disabled: false
    }
];

export const desktopNavigationData = [
    {
        title: 'Home',
        icon: <Svg url={getSystemImageUrl('Home=Default.svg')} height={18} width={18} />,
        link: '/aluno',
        disabled: false
    },
    {
        title: 'Eventos',
        icon: <Svg url={getSystemImageUrl('Eventos=Default.svg')} height={18} width={18} />,
        link: '/aluno/eventos',
        disabled: false
    },
    {
        title: 'Cursos',
        icon: <Svg url={getSystemImageUrl('Cursos=Default.svg')} height={18} width={18} />,
        link: '/aluno/cursos',
        disabled: false
    },
    // {
    //     title: 'Vagas',
    //     icon: <Svg url={getSystemImageUrl('Briefcase-nav.svg')} height={18} width={18} />,
    //     link: '/aluno/favoritos',
    //     disabled: true
    // },
    {
        title: 'Biblioteca',
        icon: <Svg url={getSystemImageUrl('Biblioteca=Default.svg')} height={18} width={18} />,
        link: '/aluno/biblioteca',
        disabled: false
    },
    {
        title: 'Comunidade',
        icon: <Svg url={getSystemImageUrl('Leaf.svg')} height={15} width={15} />,
        link: '/aluno/participantes',
        disabled: false
    }
];
