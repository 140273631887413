import styled from 'styled-components';

export const Container = styled.div`
    overflow: visible;
    box-shadow: var(--box-shadow);
    padding: 1rem;
    border-radius: var(--border-radius);
    background: #ffffff;
    min-width: 15.5rem;
    max-width: 15.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

    > * {
        margin-bottom: 0.5rem;
    }

    > header {
        > span {
            font-weight: 600;
            font-size: 0.8rem;
            color: #6b9d4a;
        }
    }

    > main {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    > footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        > h3 {
            font-weight: bold;
            font-size: 1.1rem;
            text-transform: uppercase;
        }
    }
`;
