import { CircularProgress } from '@mui/material';
import Message from 'components/molecules/alerts/Message';
import Meta from 'components/templates/Seo/Meta';
import React, { useEffect, useState } from 'react';
import MenuLayout from 'components/templates/layouts/Components/MenuLayout';
import { useParams } from 'react-router-dom';
import EnterpriseOpportunityForm from 'components/organisms/forms/Others/EnterpriseOpportunityForm';
import { getEnterpriseOpportunityById } from 'services/enterprise/opportunityEnterprise';

const VagaForm = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [opportunity, setOpportunity] = useState({});
    const [error, setError] = useState('');

    const getEnterpriseOpportunityData = async () => {
        if (!id) {
            setOpportunity({
                id: null,
                title: null,
                final_date: null,
                shortDescription: null,
                description: null,
                requirement: null,
                url: null,
                period: null,
                local: null,
                salary: null
            });

            setLoading(false);
            return;
        }

        await getEnterpriseOpportunityById(id)
            .then((response) => {
                setOpportunity({
                    id: response.data.id,
                    title: response.data.office,
                    finalDate: response.data.finalDate,
                    shortDescription: response.data.shortDescription,
                    description: response.data.description,
                    requirement: response.data.requirement,
                    url: response.data.url,
                    period: response.data.id_period_opportunity,
                    local: response.data.local,
                    salary: response.data.salary
                });
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getEnterpriseOpportunityData();
    }, []);

    return (
        <MenuLayout title={id ? 'Editar Vaga' : 'Criar vaga'} returnUrlDefault="/aluno/perfil">
            <Meta title={id ? 'Editar Vaga' : 'Criar vaga'} />

            {loading ? (
                <CircularProgress />
            ) : error ? (
                <Message text={error} />
            ) : (
                <EnterpriseOpportunityForm opportunity={opportunity} id={id} />
            )}
        </MenuLayout>
    );
};

export default VagaForm;
