import styled from 'styled-components';

export const Container = styled.div`
    background: #ffffff;
    box-shadow: var(--box-shadow);
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
        width: 2rem;
        height: 2rem;
        filter: ${({ filter }) =>
            filter ? filter : `invert(20%) sepia(19%) saturate(683%) hue-rotate(198deg) brightness(87%) contrast(86%)`};
    }
`;
