import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 5px 6px 5px 10px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 100px;
    width: 100%;
    max-width: 250px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    > main {
        > span {
            color: #373848;
            font-size: 14px;
        }
    }

    > aside {
        margin-left: 1rem;
        background: #e7f5e6;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width: 340px) {
        > main {
            > span {
                color: #373848;
                font-size: 12px;
            }
        }
    }
`;
