import { CircularProgress } from '@mui/material';
import BorderTitle from 'components/molecules/headers/BorderTitle';
import CoursesCarousel from 'components/molecules/carousels/CoursesCarousel';
import Meta from 'components/templates/Seo/Meta';
import React, { useEffect, useState } from 'react';
import { getAllCourses, getCompletedCourses, getParticipatingCourses, removeCourseList } from 'services/courses';
import MyCompetencesCarousel from 'components/molecules/carousels/MyCompetencesCarousel';
import { getStudentCompetences } from 'services/competences';
import useSnackbar from 'hooks/useSnackbar';
import { useSelector } from 'react-redux';
import useWindowSize from '../../../hooks/useWindowSize';
import CoursesLayout from '../../../components/templates/layouts/Pages/CoursesLayout';
import CoursesAside from '../../../components/organisms/containers/CoursesAside';
import Courses from '../../../components/organisms/containers/Courses';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

const Cursos = () => {
    const windowSize = useWindowSize();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const [participatingCourses, setParticipatingCourses] = useState([]);
    const [competences, setCompetences] = useState([]);
    const [concludedCourses, setConcludedCourses] = useState([]);
    const [allCourses, setAllCourses] = useState([]);
    const [allCoursesWeb, setAllCoursesWeb] = useState({});
    const [currentList, setCurrentList] = useState('todos');

    const [loading, setLoading] = useState(true);
    const snackbar = useSnackbar();
    const auth = useSelector((state) => state.auth);

    const getAllCoursesData = async () => {
        await getAllCourses()
            .then((response) => {
                setAllCourses(response.data);
                setAllCoursesWeb(response.rawData);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar cursos', 'error');
            });
    };

    const getParticipatingCoursesData = async () => {
        await getParticipatingCourses(true)
            .then((response) => {
                setParticipatingCourses(response.data);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar cursos', 'error');
            });
    };

    const getConcludedCoursesData = async () => {
        await getCompletedCourses()
            .then((response) => {
                setConcludedCourses(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Não foi possível carregar os cursos.', 'error');
            });
    };

    const getCompetencesData = async () => {
        await getStudentCompetences()
            .then((response) => {
                setCompetences(
                    response.data.map((competence) => {
                        return {
                            ...competence,
                            key: competence.description
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .replaceAll(' ', '_')
                                .toLowerCase()
                        };
                    })
                );
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar competências', 'error');
            });
    };

    const removeCompletedCourse = (courseId) => {
        removeCourseList(courseId);
        const newParticipatingCourses = participatingCourses.filter((course) => course.id !== courseId);
        setParticipatingCourses(newParticipatingCourses);
    };

    const onClickType = (newType) => {
        navigate(`/aluno/cursos?list=${newType}`);
    };

    const onClickCompetence = (scroll) => {
        if (scroll) {
            const component = document.querySelector(`#competence-${scroll}`);

            if (!component) {
                return;
            }

            const y = component.getBoundingClientRect().top + window.scrollY - 150;
            window.scrollTo(0, y);
        }
    };
    useEffect(() => {
        if (searchParams.get('list')) {
            setCurrentList(searchParams.get('list'));
        }
    }, [location]);

    useEffect(() => {
        getParticipatingCoursesData();
        getCompetencesData();
        getConcludedCoursesData();
        getAllCoursesData();
    }, []);
    if (loading) {
        return <CircularProgress />;
    }

    if (windowSize.mobile) {
        return (
            <>
                <Meta title="Cursos" description="Visualize todos os cursos da nossa plataforma" />

                <BorderTitle title="Cursos" />

                {competences?.length > 0 && !auth?.user?.enterpriseId && (
                    <MyCompetencesCarousel
                        competences={competences}
                        title="Cursos para o seu desenvolvimento:"
                        legend="Explore as Competências:"
                        courseRedirect
                    />
                )}

                <CoursesCarousel
                    removeCompletedCourse={removeCompletedCourse}
                    courses={allCourses}
                    label="Todos os cursos:"
                    errorLabel="Não há novos cursos disponíveis"
                    newCourses
                />

                <CoursesCarousel
                    label="Cursos que você está participando:"
                    errorLabel="Você não está participando de nenhum curso. Explore as competências acima."
                    courses={participatingCourses}
                    removeCompletedCourse={removeCompletedCourse}
                />

                <CoursesCarousel
                    courses={concludedCourses}
                    removeCompletedCourse={removeCompletedCourse}
                    finishedCourses
                    label="Cursos que você já concluiu:"
                    errorLabel="Você não concluiu nenhum curso."
                />
            </>
        );
    }
    return (
        <>
            <Meta title="Cursos" description="Visualize todos os cursos da nossa plataforma" />
            <CoursesLayout>
                <CoursesAside
                    active={currentList}
                    competences={competences}
                    onClickType={onClickType}
                    onClickCompetence={onClickCompetence}
                />
                <Courses
                    currentList={currentList}
                    competences={competences}
                    courses={allCoursesWeb}
                    participatingCourses={participatingCourses}
                    concludedCourses={concludedCourses}
                />
            </CoursesLayout>
        </>
    );
};

export default Cursos;
