export const getTypeColor = (color) => {
    if (color === '#DF9B34') {
        return 'orange';
    }

    if (color === '#6967C1') {
        return 'purple';
    }

    return 'primary';
};
