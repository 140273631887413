import React from 'react';
import PropTypes from 'prop-types';
import { Container, Button } from './styles';
import { CompetenceType } from 'types/data/competence';
import Progress from 'components/atoms/inputs/Progress';
import CompetenceIcon from 'components/molecules/containers/CompetenceIcon';
import { useNavigate } from 'react-router-dom';
import { getTypeColor } from 'utils/rules/competence';

const CompetenceItem = ({ data }) => {
    const { icon, description, currentLevel, percentNextLevel, color, id } = data;

    const competenceColorType = getTypeColor(color);

    const navigate = useNavigate();

    const redirect = () => {
        const path = window.location.pathname;
        const url = `/aluno/perfil/competencias/${id}${path ? `?returnUrl=${path}&returnName=${'Suas Competências'}` : ''}`;
        navigate(url);
    };

    return (
        <Container onClick={() => redirect()}>
            <CompetenceIcon icon={icon} />

            <header>
                <h2>{description}</h2>
                <p>Nível {currentLevel}</p>
            </header>

            <main>
                <Progress progress={percentNextLevel} color={competenceColorType} />
            </main>

            <footer>
                <Button color="secondary" variant="contained">
                    Dicas
                </Button>
            </footer>
        </Container>
    );
};

CompetenceItem.propTypes = {
    data: PropTypes.shape(CompetenceType).isRequired
};

export default CompetenceItem;
