export const initialState = {
    competence: {}
};

const courseReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case 'SET_COMPETENCE':
            return {
                ...state,
                competence: actions.payload.competence
            };

        default:
            return state;
    }
};

export default courseReducer;
