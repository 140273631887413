import { api } from 'services/api';
import { postLogError } from '../logErrors/index';

const getPostById = async (id) => {
    const response = await api
        .get(`/publication/${id}`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: parsePost(response.data),
        status: response.status
    };
};

const seePost = async (idPublication) => {
    const response = await api
        .post(`/saw_publication`, {
            idPublication
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    return {
        data: response.data,
        status: response.status
    };
};

const viewPost = async (idPublication) => {
    const response = await api
        .post(`/view_publication`, {
            idPublication
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });
    return {
        data: response.data,
        status: response.status
    };
};

const studentOpportunity = async (id) => {
    const response = await api
        .get(`/student_opportunity/${id}`)
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });
    return {
        data: response.data,
        status: response.status
    };
};

const parsePost = (data) => {
    return {
        id: data?.id,
        title: `Oportunidade: ${data?.office}`,
        company: data?.company,
        liked: data?.liked,
        likes: data?.likes,
        views: data?.saw,
        viewed: data?.viewed,
        sawed: data?.sawed,
        salary: data?.salary,
        period: data?.period_opportunity,
        local: data?.local,
        description: data?.description,
        shortDescription: data?.shortDescription,
        files: data?.file,
        url: data?.url,
        vacationInternship: data?.vacationInternship,
        isParticipatingOpportunity: data?.isParticipatingOpportunity,
        requirement: data?.requirement,
        finalDate: data?.finalDate,
        publication: data?.publication,
        publicationCommentsTotal: data?.publicationCommentsTotal || 0
    };
};

export { getPostById, seePost, viewPost, studentOpportunity };
