import { api } from 'services/api';
import { getImageUrl } from 'utils/assets';
import { postLogError } from '../logErrors/index';

const getTimeline = async (page) => {
    const response = await api
        .get(`/timeline`, {
            params: {
                page: page
            }
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: {
            data: parseTimeline(response.data?.data || []),
            lastPage: response.data.last_page
        },
        status: response.status
    };
};

const likePost = async (idPublication) => {
    const response = await api
        .post(`/like_publication`, {
            idPublication: idPublication
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const parseTimeline = (timelineArray) => {
    let data = [];

    timelineArray.map((item) => {
        if (item.type_publication === 'Postagem') {
            data.push({
                id: item.id_publication,
                liked: item.publication.liked,
                likes: item.publication.likes,
                views: item.publication.saw,
                viewed: false,
                type: item.type_publication,
                typeText: 'Postagem',
                typeAdvertisement: 'Postagem',
                sawed: item.publication?.sawed,
                title: '',
                description: item.publication.description,
                shortDescription: item.publication?.shortDescription,
                competences: item.publication.competence,
                skills: item.publication.skill,
                files: item?.publication?.file,
                learningObjects: item?.publication?.learning_objects,
                comments: item?.comments,
                commentsTotal: item?.commentsTotal,
                user: {
                    image: item?.publication?.url_image || 'https://pfaapi.rehagro.com.br/storage/images_system/rehagro.png',
                    name: item?.publication?.name_created_by,
                    id_enterprise: item?.publication?.id_enterprise
                }
            });
        }

        if (item.type_publication === 'Oportunidade') {
            data.push({
                id: item.id_publication,
                liked: item.publication.liked,
                likes: item.publication.likes,
                views: item.publication.saw,
                viewed: false,
                type: 1,
                typeText: 'Oportunidade',
                typeAdvertisement: 'Oportunidade',
                title: `Oportunidade: ${item.publication.office || 'Título da Oportunidade'}`,
                description: item.publication.description,
                shortDescription: item.publication?.shortDescription,
                competences: [],
                sawed: item.publication?.sawed,
                company: item.publication.company,
                salary: item.publication?.salary,
                period: item.publication?.period_opportunity,
                local: item.publication?.local,
                skills: item.publication?.skill,
                files: item?.publication?.file,
                comments: item?.comments,
                commentsTotal: item?.commentsTotal,
                vacationInternship: item?.publication?.vacationInternship,
                isParticipatingOpportunity: item?.publication?.isParticipatingOpportunity,
                user: {
                    image: item?.publication?.url_image || 'https://pfaapi.rehagro.com.br/storage/images_system/rehagro.png',
                    name: item?.publication?.name_created_by,
                    id_enterprise: item?.publication?.id_enterprise
                }
            });
        }

        if (item.type_publication === 'Anúncio') {
            if (item.publication.typeAdvertisement === 'Curso') {
                data.push({
                    id: item.id_publication,
                    itemId: item?.publication?.course?.id,
                    liked: item.publication.liked,
                    likes: item.publication.likes,
                    views: item.publication.saw,
                    viewed: false,
                    type: item.type_publication,
                    typeText: 'Anúncio',
                    typeAdvertisement: 'Curso',
                    sawed: item.publication?.sawed,
                    title: `Curso: ${item.publication.course.name}`,
                    description: item.publication.course.description,
                    competences: item.publication.course.competence,
                    skills: [],
                    comments: item?.comments,
                    commentsTotal: item?.commentsTotal,
                    image: item?.publication?.course?.file
                        ? item?.publication?.course?.file[0]?.url
                            ? getImageUrl(item?.publication.course?.file[0].url)
                            : ''
                        : '',
                    user: {
                        image: item?.publication?.url_image || 'https://pfaapi.rehagro.com.br/storage/images_system/rehagro.png',
                        name: item?.publication?.name_created_by,
                        id_enterprise: item?.publication?.id_enterprise
                    }
                });
            }

            if (item.publication.typeAdvertisement === 'Evento') {
                data.push({
                    id: item.id_publication,
                    itemId: item?.publication?.event?.id,
                    liked: item.publication.liked,
                    likes: item.publication.likes,
                    views: item.publication.saw,
                    viewed: false,
                    type: item.type_publication,
                    typeText: 'Anúncio',
                    typeAdvertisement: 'Evento',
                    sawed: item.publication?.sawed,
                    title: `Evento: ${item.publication.event.title}`,
                    description: item.publication.event.description,
                    competences: item.publication.event.competence,
                    skills: [],
                    comments: item?.comments,
                    commentsTotal: item?.commentsTotal,
                    image: item?.publication?.event?.file
                        ? item?.publication?.event?.file[0]?.url
                            ? getImageUrl(item?.publication.event?.file[0].url)
                            : ''
                        : '',
                    user: {
                        image: item?.publication?.url_image || 'https://pfaapi.rehagro.com.br/storage/images_system/rehagro.png',
                        name: item?.publication?.name_created_by,
                        id_enterprise: item?.publication?.id_enterprise
                    }
                });
            }

            if (item.publication.typeAdvertisement === 'Gravação do Evento') {
                if (item.publication.library?.event) {
                    const event = item.publication.library.event;

                    data.push({
                        id: item.id_publication,
                        itemId: item.publication.library.id,
                        liked: item.publication.liked,
                        likes: item.publication.likes,
                        views: item.publication.saw,
                        viewed: false,
                        type: item.type_publication,
                        typeText: 'Gravação do evento',
                        typeAdvertisement: 'Gravação do evento',
                        sawed: item.publication?.sawed,
                        title: `Gravação do evento: ${event.title}`,
                        description: item.publication.library.description,
                        shortDescription: item.publication?.library?.shortDescription,
                        competences: event.competence,
                        skills: [],
                        comments: item?.comments,
                        commentsTotal: item?.commentsTotal,
                        image: event.file ? (event.file[0]?.url ? getImageUrl(event?.file[0].url) : '') : '',
                        user: {
                            image: item?.publication?.url_image || 'https://pfaapi.rehagro.com.br/storage/images_system/rehagro.png',
                            name: item?.publication?.name_created_by,
                            id_enterprise: item?.publication?.id_enterprise
                        }
                    });
                }
            }

            if (item.publication.typeAdvertisement === 'Biblioteca') {
                if (
                    item.publication.library.learningObject[0].typeLearningObject === 'PDF' ||
                    item.publication.library.learningObject[0].typeLearningObject === 'Excel'
                ) {
                    data.push({
                        id: item.id_publication,
                        itemId: item.publication.library.id,
                        liked: item.publication.liked,
                        likes: item.publication.likes,
                        views: item.publication.saw,
                        viewed: false,
                        type: item.type_publication,
                        typeText: 'Biblioteca',
                        typeAdvertisement: 'Biblioteca',
                        sawed: item.publication?.sawed,
                        title: `Biblioteca: ${item.publication.library.title}`,
                        description: item.publication.descriptionAdvertisement,
                        competences: item.publication.library.competence,
                        comments: item?.comments,
                        commentsTotal: item?.commentsTotal,
                        skills: [],
                        user: {
                            image: item?.publication?.url_image || 'https://pfaapi.rehagro.com.br/storage/images_system/rehagro.png',
                            name: item?.publication?.name_created_by,
                            id_enterprise: item?.publication?.id_enterprise
                        }
                    });
                }
                if (item.publication.library.learningObject[0].typeLearningObject === 'Imagens') {
                    const learningObject = item.publication.library.learningObject[0];
                    data.push({
                        id: item.id_publication,
                        itemId: item.publication.library.id,
                        liked: item.publication.liked,
                        likes: item.publication.likes,
                        views: item.publication.saw,
                        viewed: false,
                        type: item.type_publication,
                        typeText: 'Biblioteca',
                        typeAdvertisement: 'Biblioteca',
                        sawed: item.publication?.sawed,
                        title: `Biblioteca: ${item.publication.library.title}`,
                        description: item.publication.descriptionAdvertisement,
                        competences: item.publication.library.competence,
                        image: learningObject ? (learningObject?.url ? getImageUrl(learningObject.url) : '') : '',
                        comments: item?.comments,
                        commentsTotal: item?.commentsTotal,
                        skills: [],
                        user: {
                            image: item?.publication?.url_image || 'https://pfaapi.rehagro.com.br/storage/images_system/rehagro.png',
                            name: item?.publication?.name_created_by,
                            id_enterprise: item?.publication?.id_enterprise
                        }
                    });
                }
                if (item.publication.library.learningObject[0].typeLearningObject === 'Videos') {
                    const learningObject = item.publication.library.learningObject[0];
                    data.push({
                        id: item.id_publication,
                        itemId: item.publication.library.id,
                        liked: item.publication.liked,
                        likes: item.publication.likes,
                        views: item.publication.saw,
                        viewed: false,
                        type: item.type_publication,
                        typeText: 'Biblioteca',
                        typeAdvertisement: 'Biblioteca',
                        sawed: item.publication?.sawed,
                        title: `Biblioteca: ${item.publication.library.title}`,
                        description: item.publication.descriptionAdvertisement,
                        competences: item.publication.library.competence,
                        comments: item?.comments,
                        commentsTotal: item?.commentsTotal,
                        video: learningObject ? (learningObject?.url ? getImageUrl(learningObject.url) : '') : '',
                        skills: [],
                        user: {
                            image: item?.publication?.url_image || 'https://pfaapi.rehagro.com.br/storage/images_system/rehagro.png',
                            name: item?.publication?.name_created_by,
                            id_enterprise: item?.publication?.id_enterprise
                        }
                    });
                }
            }
        }
    });

    return data;
};

export { getTimeline, likePost };
