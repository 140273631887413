import { ExpandMore } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Details, Item, Container, Summary } from './styles';
import { AccordionItem } from 'types/components/accordion';

const Accordion = ({ data }) => {
    const [expanded, setExpanded] = useState(0);

    const handleChange = (isExpanded, panel) => {
        setExpanded(isExpanded ? panel : false);
    };

    if (!data) return null;

    return (
        <Container>
            {data?.map((item, index) => (
                <Item key={index} expanded={expanded === index} onChange={(event, isExpanded) => handleChange(isExpanded, index)}>
                    <Summary expandIcon={<ExpandMore color="primary" />} aria-controls="panel1a-content" id="panel1a-header">
                        <h3>{item.question}</h3>
                    </Summary>
                    <Details>
                        <Typography>{item.answer}</Typography>
                    </Details>
                </Item>
            ))}
        </Container>
    );
};

Accordion.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape(AccordionItem)).isRequired
};

export default Accordion;
