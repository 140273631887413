import React from 'react';

import Title from 'components/atoms/texts/Title';
import { Container } from './styles';
import FavoriteMenu from 'components/organisms/menus/FavoriteMenu';
import FilterAside from 'components/atoms/structure/FilterAside';
import PropTypes from 'prop-types';

const LibraryAside = ({ formats }) => {
    return (
        <Container>
            <Title title="Biblioteca" />
            <FilterAside formats={formats}></FilterAside>
            <FavoriteMenu />
        </Container>
    );
};

LibraryAside.propTypes = {
    formats: PropTypes.array.isRequired
};

export default LibraryAside;
