const getModalData = (itemName, type) => {
    const data = {
        update: {
            title: 'Confirmar modificação',
            text: 'Deseja salvar todas as modificações realizadas?'
        },
        create: {
            title: 'Confirmar criação',
            text: `Deseja realmente criar ${itemName}?`
        }
    };

    return data[type];
};

export { getModalData };
