import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    > * {
        margin-bottom: 0.5rem;
    }

    > h3 {
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 130%;
        color: #373848;
    }

    > p {
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        color: #373848;
    }

    > h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 130%;
        text-align: center;
        color: #373848;
    }

    > Svg {
        width: 75px;
        height: 68px;
        filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
    }
`;

export const ImageContainer = styled.div`
    width: 100%;
    margin-top: 0.5rem;
`;

export const IconSvg = styled.div`
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
`;
