import React, { useEffect, useState } from 'react';

import FormMaker from 'components/organisms/layout/form/FormMaker';
import { Validator } from 'utils/validators';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import useSnackbar from 'hooks/useSnackbar';
import moment from 'moment';
import { postLogError } from 'services/logErrors';
import {
    createEnterpriseOpportunity,
    deleteEnterpriseOpportunity,
    updateEnterpriseOpportunity
} from 'services/enterprise/opportunityEnterprise';
import { getResourcesPublication } from 'services/publication';
import { useSelector } from 'react-redux';
import { getUTCDate } from 'utils/date';

const EnterpriseOpportunityForm = ({ opportunity, id }) => {
    const [getProfile, setOpportunity] = useState(false);
    const [startValue, setStartValue] = useState({});
    const [periods, setPeriods] = useState([]);
    const snackbar = useSnackbar();
    const today = new Date();
    const auth = useSelector((state) => state.auth);
    today.setHours(0, 0, 0, 0);

    const parseStartValue = (opportunityData) => {
        const parsedValue = {
            title: opportunityData?.title,
            finalDate: opportunityData?.finalDate ? new Date(getUTCDate(opportunityData?.finalDate)) : null,
            shortDescription: opportunityData?.shortDescription,
            description: opportunityData?.description,
            requirement: opportunityData?.requirement,
            url: opportunityData?.url,
            period: opportunityData?.period,
            local: opportunityData?.local,
            salary: opportunityData?.salary
        };

        return parsedValue;
    };

    const getResourcesPublicationData = async () => {
        await getResourcesPublication()
            .then((response) => {
                const periodsData = response.periodOpportunity.map((period) => ({ id: period.id, value: period.description }));
                setPeriods(periodsData);
            })
            .catch((error) => {
                console.error(error);
                postLogError(error);
                snackbar('Não foi possível carregar os dados do formulário', 'error');
            });
    };

    const getOpportunityData = async () => {
        getResourcesPublicationData();
        setStartValue(parseStartValue(opportunity));
        setOpportunity(true);
    };

    const formSubmit = async (values) => {
        const cashToFloat = (value) => {
            if (typeof value !== 'string') return value;

            return parseFloat(value);
        };

        const data = {
            office: values.title,
            finalDate: moment(values?.finalDate).format('YYYY-MM-DD'),
            shortDescription: values.shortDescription,
            description: values.description,
            requirement: values.requirement,
            url: values.url,
            idPeriodOpportunity: values.period,
            local: values.local,
            salary: values.salary ? cashToFloat(values.salary) : null,
            company: auth?.user?.name
        };

        if (id) {
            await updateEnterpriseOpportunity(data, id)
                .then(() => {
                    snackbar('Oportunidade atualizada com sucesso', 'success');
                    window.location.href = '/aluno/perfil';
                })
                .catch((err) => {
                    snackbar('Erro ao salvar dados', 'error');
                    postLogError(err);
                });

            return;
        }

        await createEnterpriseOpportunity(data)
            .then(() => {
                snackbar('Oportunidade criada com sucesso', 'success');
                window.location.href = '/aluno/perfil';
            })
            .catch((err) => {
                snackbar('Erro ao salvar dados', 'error');
                postLogError(err);
            });
    };

    const deleteOpportunity = async () => {
        if (!id) {
            return;
        }

        await deleteEnterpriseOpportunity(id)
            .then(() => {
                snackbar('Oportunidade excluída com sucesso', 'success');
                window.location.href = '/aluno/perfil';
            })
            .catch((err) => {
                snackbar('Erro ao salvar dados', 'error');
                postLogError(err);
            });
    };

    const form = [
        [
            {
                id: 'title',
                label: 'Vaga',
                type: 'text',
                initialValue: startValue?.title,
                validation: Validator.string().max(255).required('A vaga é obrigatória')
            }
        ],
        [
            {
                id: 'shortDescription',
                label: 'Descrição Resumida',
                type: 'editor',
                initialValue: startValue?.shortDescription,
                validation: Validator.string().required('A descrição resumida é obrigatória')
            }
        ],
        [
            {
                id: 'description',
                label: 'Descrição',
                type: 'editor',
                initialValue: startValue?.description,
                validation: Validator.string().required('A descrição é obrigatória')
            }
        ],
        [
            {
                id: 'requirement',
                label: 'Requisitos',
                type: 'editor',
                initialValue: startValue?.requirement,
                validation: Validator.string().required('Os requisitos são obrigatórios')
            }
        ],
        [
            {
                id: 'local',
                label: 'Local',
                type: 'text',
                initialValue: startValue?.local,
                validation: Validator.string().max(255, 'O local deve ter no máximo 255 caracteres').required('O local é obrigatório')
            }
        ],
        [
            {
                id: 'period',
                label: 'Período',
                type: 'dropdown',
                initialValue: startValue?.period,
                validation: Validator.number()
                    .equals([periods?.map((item) => item.id)], 'Opção inválida')
                    .required('O período é obrigatório'),
                options: periods || []
            }
        ],
        [
            {
                id: 'salary',
                label: 'Salário (opcional)',
                type: 'cash',
                initialValue: startValue?.salary
            }
        ],
        [
            {
                id: 'finalDate',
                label: 'Data de encerramento',
                type: 'date',
                minDate: new Date(),
                initialValue: startValue?.finalDate,
                validation: Validator.date('Insira uma data válida')
                    .required('A data de encerramento é obrigatória')
                    .min(today, 'A data de encerramento deve ser maior que a data atual')
            }
        ],
        [
            {
                id: 'url',
                label: 'Link de redirecionamento',
                type: 'text',
                initialValue: startValue?.url,
                validation: Validator.string()
                    .matches(
                        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                        'Insira a url completa. Ex: https://pfa.rehagro.com.br'
                    )
                    .required('O site é obrigatório')
            }
        ]
    ];

    useEffect(() => {
        getOpportunityData();
    }, []);

    if (!getProfile) {
        return <CircularProgress />;
    }

    return (
        <FormMaker
            data={form}
            deleteItem={opportunity?.id ? deleteOpportunity : null}
            itemName="a oportunidade"
            formSubmit={formSubmit}
            create={!id}
        />
    );
};

EnterpriseOpportunityForm.propTypes = {
    opportunity: PropTypes.object,
    id: PropTypes.number
};

export default EnterpriseOpportunityForm;
