import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    > footer {
        margin-top: 0.5rem;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: #787885;
    }
`;

export const Content = styled.div`
    width: 100%;
    background-color: #fff;
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
`;

export const ContainerDesktop = styled.div`
    width: 100%;
    display: flex;
    margin-top: 1rem;
    align-items: center;
    flex-direction: row;
    > footer {
        margin-top: 0.5rem;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: #787885;
    }
`;

export const ContentInput = styled.div`
    background-color: white;
    width: 55%;
`;

export const ContentButton = styled.div`
    width: 23%;
    display: flex;
    justify-content: left;

    > button {
        display: flex;
        padding: 8px 22px;
        margin-left: 10px;
        height: 42px;
        font-size: 16px;
    }
`;

export const ContentButtonHistoric = styled.div`
    width: 23%;
    display: flex;
    justify-content: right;

    > button {
        display: flex;
        padding: 8px 22px;
        margin-left: 10px;
        height: 42px;
        font-size: 16px;
    }
`;
