import styled from 'styled-components';

export const Container = styled.div`
    padding-top: 1rem;
`;
export const DesktopContainer = styled.div`
    grid-area: L;
    height: 40rem;
    width: 100%;
`;
export const ContainerSearch = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;

    > div {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 8px;
        cursor: pointer;

        .title {
            flex-grow: 1;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 130%;
            color: #464646;
        }
    }
`;
