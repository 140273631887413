import { CircularProgress } from '@mui/material';
import LibraryContent from 'components/organisms/containers/LibraryContent';

import Meta from 'components/templates/Seo/Meta';
import useSnackbar from 'hooks/useSnackbar';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLibraryById } from 'services/library';

const LibraryContentScreen = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [libraryData, setLibraryData] = useState(null);
    const snackbar = useSnackbar();
    const [loading, setLoading] = useState(true);

    const getLibraryData = async () => {
        setLoading(false);

        await getLibraryById(id)
            .then((response) => {
                setLibraryData(response.data);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Não foi possível carregar o material.', 'error');
            });
    };

    useEffect(() => {
        getLibraryData();
    }, []);

    useEffect(() => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: '/aluno/biblioteca',
                returnText: 'Biblioteca',
                returnPath: location.pathname
            }
        });
    }, [id]);

    if (loading || !libraryData) {
        return <CircularProgress />;
    }

    return (
        <>
            <Meta title={`${libraryData?.title || ''} - Biblioteca `} />
            <LibraryContent data={libraryData} />
        </>
    );
};

export default LibraryContentScreen;
