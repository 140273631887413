import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    ${({ marginTop }) => marginTop && `margin-top: 1rem;`}

    > * {
        margin-top: 1rem;

        &:first-of-type {
            margin-top: 0;
        }
    }
`;
export const ContainerDesktop = styled.div`
    grid-area: L;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    ${({ marginTop }) => marginTop && `margin-top: 1rem;`}
    row-gap: 15px;
    column-gap: ${(props) => (props.columns > 1 ? '60px' : '20px')};

    > .libraryCard {
        width: ${(props) => `calc(100% / ${props.columns} - 80px)`};
    }

    > * {
        margin-top: 1rem;

        &:first-of-type {
            margin-top: ${(props) => (props.columns === 1 ? 0 : '1rem')};
        }
    }
`;
