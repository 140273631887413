import { FormControl, InputLabel } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(FormControl)`
    width: 100%;
`;

export const Label = styled(InputLabel)`
    background-color: #fff;
    padding: 0 0.4rem;
`;
