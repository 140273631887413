import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
	
    :root {
        // fonts
        --font-size-heading: 1.5rem;
        --font-size-body: 1rem;

        // structure
        --padding-base: 1rem;
        --padding-small: 0.5rem;
        --border-radius: 8px;
        --max-width: 600px;
        --navbar-height: 56px;
        --transition: 0.5s ease-in-out;
        --box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);

        // colors
        --color-background: #f8f8fd;
        --color-primary: #0A432D;
        --color-secondary: #6B9D4A;
        --color-tertiary: #87A851;
        --color-text: #464646;
        --color-gray: #A1A1A7;
        --color-black: #373848;

        --filter-black: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%);

        @media screen and (min-width: 767px) {
            --max-width: 1200px;
        }
    }

    * {
		margin: 0;
		padding: 0;
		outline: 0;
		box-sizing: border-box;
	}

	*:focus {
		outline: 0;
	}

	a {
		text-decoration: none;
        cursor: pointer;
	}

    input, textarea, select, a, div {
        -webkit-tap-highlight-color: transparent;
    }


	ul {
		list-style: none;
	}

	body, input, button {
		font: 14px 'Myriad Pro', sans-serif;
        font-family: "Myriad Pro", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;
	}

	html, body, #root {
        margin: 0;
        background-color: #f8f8fd;
		height: 100%;  
        scroll-behavior: smooth;
	}

	button {
		cursor: pointer;
	}
`;
