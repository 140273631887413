import Title from 'components/atoms/texts/Title';
import { Container, ListItemButtonStyled, ListStyled } from './styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const HelpAside = () => {
    const navigate = useNavigate();
    const path = new URL(window.location.href);

    const onClickOption = (option) => {
        navigate(`/aluno/${option}`);
    };

    return (
        <Container>
            <Title title="Ajuda" />
            <ListStyled component="nav" disablePadding>
                <ListItemButtonStyled className={path.pathname === '/aluno/ajuda' && 'active'} onClick={() => onClickOption('ajuda')}>
                    Perguntas frequentes
                </ListItemButtonStyled>
                <ListItemButtonStyled
                    className={path.pathname === '/aluno/ajuda/duvidas' && 'active'}
                    onClick={() => onClickOption('ajuda/duvidas')}
                >
                    Fale conosco
                </ListItemButtonStyled>
            </ListStyled>
        </Container>
    );
};

export default HelpAside;
