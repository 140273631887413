import styled from 'styled-components';

export const Slider = styled.div`
    width: 100%;
    max-width: 600px;
    text-align: center;
    overflow: hidden;
`;

export const Slides = styled.div`
    display: flex;

    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    ::-webkit-scrollbar {
        border-radius: 10px;
        width: 0.4rem;
        height: 0.4rem;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #bbb;
    }

    ::-webkit-scrollbar-thumb:hover {
        border-radius: 10px;
        background: #aaa;
    }

    > * {
        scroll-snap-align: start;
        flex-shrink: 0;
        width: 100%;
        margin-right: 2rem;
        border-radius: 10px;
        transform-origin: center center;
        transform: scale(1);
        transition: transform 0.5s;
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 100px;
    }
`;

export const SingleItemContainer = styled.div`
    width: 100%;
    height: auto;
`;

export const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 1rem;

    > img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-position: center;
        background-size: cover;
        object-fit: cover;
        background-color: #eee;
        top: 0;
        left: 0;
    }
`;

export const ImageContainerItem = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 1rem;
    padding-bottom: 56.25%;
    background-color: #eee;

    > img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-position: center;
        background-size: cover;
        object-fit: cover;
        background-color: #eee;
        position: absolute;
        top: 0;
        left: 0;
    }
`;
