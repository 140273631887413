import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import Svg from '../Svg';
import { getSystemImageUrl } from 'utils/assets';

const StarsSquare = ({ data, small }) => {
    const { stars, url } = data;

    const size = small ? 35 : 50;

    return (
        <Container stars={stars} size={size}>
            {Array(stars)
                .fill(0)
                .map((_, index) => (
                    <Svg url={getSystemImageUrl(url)} key={index} width={size} height={size} />
                ))}
        </Container>
    );
};

StarsSquare.propTypes = {
    data: PropTypes.shape({
        stars: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired
    }).isRequired,
    small: PropTypes.bool
};

export default StarsSquare;
