import styled from 'styled-components';
import Cropper from 'react-easy-crop';

export const Container = styled.div`
    position: absolute;
    width: 90vw;
    max-width: 600px;
    padding: 1rem;
    background-color: #fff;
    border-radius: 10px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > header {
        width: 100%;
        padding: 0.5rem 0;
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: center;
        align-items: center;

        > h3 {
            font-size: 1.1rem;
            color: var(--color-primary);
        }
    }

    > main {
        margin-top: 1rem;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    > footer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
    }
`;

export const CropperContainer = styled.div`
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
    width: 100%;
    height: 12rem;
    border-radius: 10px;
    overflow: hidden;
`;

export const Controls = styled.div`
    bottom: 20px;
    left: 50%;
    width: 50%;
    height: 40px;
    display: flex;
    align-items: center;

    .slider {
        padding: 22px 0px;
    }

    .zoom-range {
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 2px;

        background: var(--color-primary);
        width: 100%;
    }

    .zoom-range::-moz-range-thumb {
        -webkit-appearance: none;
        -moz-appearance: none;
        border: 1px solid var(--color-primary);
        background: var(--color-primary);
        border-radius: 50%;
        width: 12px;
        height: 12px;
        transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .zoom-range::-webkit-slider-thumb {
        -webkit-appearance: none;
        -moz-appearance: none;
        border: 1px solid var(--color-primary);
        background: var(--color-primary);
        border-radius: 50%;
        width: 12px;
        height: 12px;
        transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    &:hover input[type='range']::-webkit-slider-thumb {
        box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
        border-radius: 50%;
    }

    &:hover input[type='range']::-moz-range-thumb {
        box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    }
`;

export const Crop = styled(Cropper)`
    min-width: 5rem;
    min-height: 5rem;
    border: 1px #ddd dashed;
    border-radius: var(--border-radius);

    > div {
        border-radius: var(--border-radius);
    }
`;
