const getNumberStarsByLevel = (levelName) => {
    if (levelName.includes('1')) {
        return 1;
    }

    if (levelName.includes('2')) {
        return 2;
    }

    if (levelName.includes('3')) {
        return 3;
    }

    if (levelName.includes('4')) {
        return 4;
    }

    if (levelName.includes('5')) {
        return 5;
    }
};

const getImageByLevelName = (levelName) => {
    if (levelName.includes('Ouro')) {
        return 'Status=Ouro.svg';
    }

    if (levelName.includes('Prata')) {
        return 'Status=Prata.svg';
    }

    return 'Status=Inativo.svg';
};

const getTypeByLevel = (levelName) => {
    if (levelName.includes('Ouro')) {
        return 'Ouro';
    }

    if (levelName.includes('Prata')) {
        return 'Prata';
    }

    return 'Inativo';
};

const getStarsByLevel = (levelName, higherLevel) => {
    const stars = getNumberStarsByLevel(levelName);
    const url = higherLevel ? 'Status=Inativo.svg' : getImageByLevelName(levelName);

    return {
        stars,
        url
    };
};

const checkInfoCard = (levelName) => {
    return [
        '1 estrela - Prata',
        '2 estrelas - Prata',
        '3 estrelas - Prata',
        '4 estrelas - Prata',
        '5 estrelas - Prata',
        '1 estrela - Ouro',
        '2 estrelas - Ouro'
    ].includes(levelName);
};

export { getStarsByLevel, getTypeByLevel, checkInfoCard };
