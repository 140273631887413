import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    > footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 2.2rem;
        padding-top: 0.4rem;

        > p {
            font-size: 0.8rem;
        }

        > h3 {
            font-size: ${(props) => props.fontSize || '0.8rem'};
            text-align: center;
        }
    }
`;
export const IconContainer = styled.div`
    position: relative;
    min-width: 92px;
    max-width: 92px;
`;
export const Knob = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 92px;
    min-height: 92px;
    background-color: #e8e8e8;
    position: relative;
    border-radius: 50%;

    .MuiCircularProgress-root {
        position: absolute;
        transform: rotate(-315deg) !important;
        width: 20px;
        border-radius: 40px;
    }

    .MuiCircularProgress-circle,
    .MuiCircularProgress-circleDeterminate {
        stroke-linecap: round !important;
    }

    > main {
        position: absolute;
        width: 75px;
        height: 75px;
        background-color: var(--color-background);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const ImageContainer = styled.div`
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #fff;
    box-shadow: var(--box-shadow);
    color: red;

    > img {
        width: 40px;
        height: 40px;
    }
`;

export const Level = styled.div`
    position: absolute;
    right: 0.2rem;
    top: 4rem;
    background-color: ${(props) => darken(0.08, props.color)};
    border-radius: 50%;
    width: 1.8rem;
    height: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    border: 3px #fff solid;

    > span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        line-height: 0.8rem;
        font-size: 0.8rem;
    }
`;

export const IconOnlyContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
