export const initialState = {
    openCertificateModal: false,
    selectedCourse: null
};

export const coursesActions = {
    SET_CERTIFICATE_MODAL: 'SET_CERTIFICATE_MODAL',
    SET_SELECTED_COURSE: 'SET_SELECTED_COURSE'
};

const coursesReducer = (state = initialState, actions) => {
    const { type, payload } = actions;
    switch (type) {
        case coursesActions.SET_CERTIFICATE_MODAL:
            return {
                ...state,
                openCertificateModal: payload
            };
        case coursesActions.SET_SELECTED_COURSE:
            return {
                ...state,
                selectedCourse: payload
            };
        default:
            return state;
    }
};

export default coursesReducer;
