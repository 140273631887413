import ModalFormFinish from 'components/organisms/containers/ModalFormFinish';
import useSnackbar from 'hooks/useSnackbar';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import RadioModalFormInput from 'components/atoms/modalFormInputs/RadioModalFormInput';
import EvaluationModalFormInput from 'components/atoms/modalFormInputs/EvaluationModalFormInput';
import TextareaModalFormInput from 'components/atoms/modalFormInputs/TextareaModalFormInput';
import { Validator } from 'utils/validators';
import { QuizDataType } from 'types/data/quiz';

const ModalFormMaker = ({ data }) => {
    const [activeFormIndex, setActiveFormIndex] = useState(0);
    const snackbar = useSnackbar();

    const activeForm = data[activeFormIndex];

    const submitForm = async (value) => {
        if (activeForm?.validation) {
            const schema = Validator.object().shape({
                value: activeForm.validation
            });

            schema
                .validate({ value })
                .then(async () => {
                    await activeForm
                        .submit(activeForm?.id, value)
                        .then(() => {
                            setActiveFormIndex(activeFormIndex + 1);
                        })
                        .catch((err) => {
                            console.error(err);
                            snackbar('Erro ao enviar formulário', 'error');
                        });
                })
                .catch((err) => {
                    snackbar(err.errors[0], 'error');
                    return;
                });

            return;
        }

        await activeForm
            .submit(value)
            .then(() => {
                setActiveFormIndex(activeFormIndex + 1);
            })
            .catch(() => {
                snackbar('Erro ao enviar formulário', 'error');
            });
    };

    useEffect(() => setActiveFormIndex(0), [data]);

    return (
        <Container>
            {
                {
                    radio: <RadioModalFormInput item={activeForm} onSubmit={submitForm} />,
                    textarea: <TextareaModalFormInput item={activeForm} onSubmit={submitForm} />,
                    evaluation: <EvaluationModalFormInput item={activeForm} onSubmit={submitForm} />,
                    acknowledgment: <ModalFormFinish />
                }[activeForm.type]
            }
        </Container>
    );
};

ModalFormMaker.propTypes = {
    data: PropTypes.arrayOf(PropTypes.arrayOf(QuizDataType))
};

export default ModalFormMaker;
