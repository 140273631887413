import { api } from 'services/api';
import { postLogError } from '../logErrors/index';

const updatePassword = async (data) => {
    const response = await api
        .post(`/update_password`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => res)
        .catch((err) => {
            postLogError(err);
            return err.response;
        });
    if (response.status === 401) {
        throw new Error(response.data.error);
    }

    return {
        data: response,
        status: response.status
    };
};

export { updatePassword };
