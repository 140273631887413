import { api } from 'services/api';
import { postLogError } from 'services/logErrors';

const getHashtags = async (param) => {
    const response = await api
        .get('/hashtag', {
            params: {
                description: param
            }
        })
        .catch((err) => {
            postLogError(err);
            return err.response;
        });

    return parseHashtags(response.data);
};

const parseHashtags = (array) => {
    if (!array) return [];

    const hashtags = array.map((item) => {
        return {
            id: item.id,
            value: item.description
        };
    });

    return hashtags;
};

export { getHashtags };
