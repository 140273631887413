import React from 'react';
import PropTypes from 'prop-types';
import { Container, Content } from './styles';

import 'react-quill/dist/quill.snow.css';
import { useFormikContext } from 'formik';

const EditorInput = ({ item, value, error }) => {
    const { id, label, optional } = item;
    const { setFieldValue } = useFormikContext();

    const handleChange = (text) => {
        setFieldValue(id, text);
    };

    return (
        <Container>
            <header>
                <span>{`${label}${optional ? '' : '*'}`}</span>
            </header>
            <Content theme="snow" value={value} onChange={handleChange} />

            {error && <span>{error}</span>}
        </Container>
    );
};

EditorInput.propTypes = {
    value: PropTypes.any,
    error: PropTypes.string,
    item: PropTypes.object.isRequired
};

export default EditorInput;
