import React from 'react';
import PropTypes from 'prop-types';
import { Container, Content } from './styles';
import { Outlet, useLocation } from 'react-router-dom';
import Snackbar from 'components/molecules/alerts/Snackbar';
import { whitePages } from 'constants/components/rules';

const BlankLayout = ({ noPadding }) => {
    const location = useLocation();
    const whitePage = whitePages.includes(location.pathname);

    return (
        <Container whitePage={whitePage}>
            <Content noPadding={noPadding}>
                <Outlet />
                <Snackbar />
            </Content>
        </Container>
    );
};

BlankLayout.propTypes = {
    noPadding: PropTypes.bool
};

export default BlankLayout;
