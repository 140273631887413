import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    &:first-child {
        margin-top: 0.5rem;
    }

    > h2 {
        font-size: 1.2rem;
        color: var(--color-text);
    }

    > * {
        margin-bottom: 1rem;
    }
`;
