import { CircularProgress } from '@mui/material';
import SearchInput from 'components/atoms/inputs/SearchInput';
import BorderTitleStart from 'components/molecules/headers/BorderTitleStart';
import BorderTitle from 'components/molecules/headers/BorderTitle';
import ButtonGroupHeader from 'components/molecules/headers/ButtonHeaderCommunity';
import ParticipantsList from 'components/organisms/lists/ParticipantsList';
import Meta from 'components/templates/Seo/Meta';
import useSnackbar from 'hooks/useSnackbar';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getParticipants } from 'services/participants';
import useWindowSize from 'hooks/useWindowSize';
import CommunityLayout from 'components/templates/layouts/Pages/CommunityLayout';
import { alphabet } from 'constants/alphabet';
import {
    Header,
    Body,
    ContainerTotalParticipants,
    ContainerSearch,
    ButtonStyled,
    ContainerAlphabet,
    ContainerMenu,
    ClearFilterButton,
    SearchButton
} from './styles';
import SearchInputIconStart from 'components/atoms/inputs/SearchInputIconStart';
import { Clear } from '@mui/icons-material';

const Participantes = () => {
    const dispatch = useDispatch();
    const searchInput = useRef(null);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [finish, setFinish] = useState(false);
    const [total, setTotal] = useState(0);
    const [scrollY, setScrollY] = useState(0);
    const [search, setSearch] = useState('');
    const [searchPage, setSearchPage] = useState(0);
    const idRole = useSelector((state) => state.participants.filterIdRole);
    const page = useSelector((state) => state.participants.page);
    const [debounced, setDebounced] = useState('');
    const windowSize = useWindowSize();

    const addParticipants = async (newParticipants) => {
        const newParticipantsOrderedByName = (newParticipants) => {
            return newParticipants.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }

                if (a.name > b.name) {
                    return 1;
                }

                return 0;
            });
        };

        const participantsData = participants;

        newParticipantsOrderedByName(newParticipants).forEach((participant) => {
            const firstLetter = participant.name.charAt(0).toLowerCase();

            if (!participantsData[firstLetter]) {
                participantsData[firstLetter] = [];
            }

            if (!participantsData[firstLetter].find((p) => p.id === participant.id)) {
                participantsData[firstLetter].push(participant);
            }
        });

        setParticipants(participantsData);
    };

    const snackbar = useSnackbar();

    const setSubNavbar = () => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: '/aluno',
                returnText: 'Home',
                returnPath: location.pathname
            }
        });
    };

    const getParticipantsData = async () => {
        setLoadingMore(true);

        const pageToSearch = debounced ? searchPage + 1 : page + 1;

        const exactName = debounced;

        await getParticipants(pageToSearch, debounced, exactName, idRole)
            .then((res) => {
                addParticipants(res.data);

                if (pageToSearch === res.lastPage) setFinish(true);

                setLoadingMore(false);
                setLoading(false);

                if (debounced !== '') {
                    setSearchPage(pageToSearch);
                    return;
                }

                dispatch({ type: 'PAGE', payload: pageToSearch });
                setTotal(res.total);
            })
            .catch((error) => {
                snackbar(error.message, 'error');
            })
            .finally(() => setLoadingMore(false));
    };

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        setScrollY(currentScrollY);
    };

    const filterByLetter = (value) => {
        setParticipants(() => []);
        setScrollY(() => 0);
        setSearch(() => value);
    };

    const verifyScroll = () => {
        if (finish || loading) {
            return;
        }

        if (document.body.scrollHeight <= window.innerHeight) {
            getParticipantsData();
            return;
        }

        if (scrollY !== 0 && !loading) {
            if (window.innerHeight + scrollY + 10 >= document.documentElement.scrollHeight) {
                getParticipantsData();
                return;
            }
        }
    };

    const createScrollListener = () => {
        handleScroll();
        window.addEventListener('scroll', handleScroll);
    };

    useEffect(() => {
        verifyScroll();
    }, [scrollY, page]);

    useEffect(() => {
        getParticipantsData();
        createScrollListener();
        setSubNavbar();
    }, []);

    const handleSearch = () => {
        dispatch({ type: 'PAGE', payload: 0 });
        setSearchPage(0);
        setLoadingMore(true);
        setParticipants([]);
        getParticipantsData();
    };
    const handleClear = () => {
        setParticipants([]);
        setSearch('');
        setTimeout(() => {
            getParticipantsData();
        }, 100);
    };
    useEffect(() => {
        const timeout = setTimeout(() => {
            setFinish(finish && search.length > debounced.length);
            setSearchPage(0);
            setDebounced(search);
        }, 500);

        return () => clearTimeout(timeout);
    }, [search]);

    useEffect(() => {
        getParticipantsData();
    }, [debounced]);

    useEffect(() => {
        dispatch({ type: 'PAGE', payload: 0 });
        getParticipantsData();
    }, [idRole]);

    if (loading) {
        return <CircularProgress />;
    }

    if (windowSize.mobile) {
        return (
            <>
                <Meta title="Participantes do PFA" />

                <BorderTitle title="Participantes do PFA" />

                <SearchInput value={search} onChange={setSearch} footer={`${total} membros`} />

                <ParticipantsList participants={participants} search={search} loadingMore={loadingMore} />
            </>
        );
    }
    return (
        <>
            <Meta title="Comunidade PFA" />

            <BorderTitleStart title="Comunidade PFA" />

            <CommunityLayout>
                <ContainerMenu>
                    <ButtonGroupHeader />
                </ContainerMenu>
                <Header>
                    <ContainerSearch>
                        <SearchInputIconStart ref={searchInput} value={search} onChange={setSearch} placeholder="Pesquise Membros no PFA" />
                        <SearchButton onClick={handleSearch}>Buscar</SearchButton>
                        {search && (
                            <ClearFilterButton onClick={handleClear}>
                                <Clear />
                                Limpar
                            </ClearFilterButton>
                        )}
                    </ContainerSearch>

                    <ContainerTotalParticipants>
                        <div>{total} membros</div>
                    </ContainerTotalParticipants>
                    <ContainerAlphabet>
                        {alphabet?.map((value, key) => (
                            <ButtonStyled key={key} onClick={() => filterByLetter(value)}>
                                {value}
                            </ButtonStyled>
                        ))}
                    </ContainerAlphabet>
                </Header>
                <Body>
                    <ParticipantsList participants={participants} search={search} loadingMore={loadingMore} />
                </Body>
            </CommunityLayout>
        </>
    );
};

export default Participantes;
