import React from 'react';
import PropType from 'prop-types';

import { Container } from './styles';

const Carousel = ({ children, slide, draggable, cellSpacing }) => {
    return (
        <Container
            cellSpacing={cellSpacing || 8}
            dragging={draggable !== false}
            swiping={draggable !== false}
            renderCenterLeftControls={() => null}
            renderCenterRightControls={() => null}
            dragThreshold={0.1}
            slideIndex={slide}
            defaultControlsConfig={{
                pagingDotsStyle: {
                    margin: '0 0.2rem'
                }
            }}
        >
            {children}
        </Container>
    );
};

Carousel.propTypes = {
    children: PropType.node.isRequired,
    slide: PropType.number,
    draggable: PropType.bool,
    cellSpacing: PropType.number
};

export default Carousel;
