import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';

import SubSection from 'components/molecules/structure/SubSection';
import PdfViewer from 'components/atoms/structure/PdfViewer';
import { getCompletedCourses } from 'services/courses';

import Button from 'components/molecules/buttons/Button';

import { Container, ProfileContainer } from './styles';

const ProfileLastCertificate = () => {
    const [course, setCourse] = useState(false);
    const navigate = useNavigate();
    const { REACT_APP_BASE_URL } = process.env;
    const getConcludedCoursesData = async () => {
        await getCompletedCourses()
            .then((response) => {
                const courses = response.data.filter((course) => course.certificatePdf);
                let course = courses.length > 0 ? courses.pop() : false;
                const [, path] = course?.certificatePdf?.split('storage/');
                const certificatePdf = path ? `${REACT_APP_BASE_URL}/get_file/${path}` : false;
                setCourse({ ...course, certificatePdf });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const redirectToCourse = () => {
        navigate('/aluno/cursos/certificados');
    };

    useEffect(() => {
        getConcludedCoursesData();
    }, []);

    if (course === false) {
        return null;
    }

    return (
        <SubSection title="Certificados:" borderTop>
            <Container>
                <ProfileContainer>
                    <h3>Conquista mais recente:</h3>
                    <PdfViewer src={course.certificatePdf} />
                    <h3>{course.title}</h3>
                </ProfileContainer>
                <br />
                <Button type="button" size="small" width="auto" onClick={() => redirectToCourse()} color="neutral">
                    Veja todos os certificados
                </Button>
            </Container>
        </SubSection>
    );
};

ProfileLastCertificate.propTypes = {
    data: PropTypes.array
};

export default ProfileLastCertificate;
