import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container, Options, OptionsItem, BlankItems } from './styles';
import OptionsForm from 'components/organisms/forms/Structure/OptionsForm';
import { Checkbox, FormControlLabel } from '@mui/material';

const EventFilter = ({ filterStructure, filterValue, groupCompetences, setFilter, tab, tabs, setTab, redirectToEvent }) => {
    useEffect(() => {}, []);

    const changeFilterValue = useCallback(
        (value, id) => {
            setFilter((old) => {
                let newData = { ...old };
                newData[id] = newData[id] || [];
                let index = newData[id].indexOf(value);
                if (index >= 0) {
                    newData[id].splice(index, 1);
                } else {
                    newData[id].push(value);
                }
                return newData;
            });
        },
        [filterValue]
    );

    return (
        <Container>
            <Options>
                {tabs.map((item, i) => (
                    <OptionsItem
                        key={i}
                        className={tab == item.value ? 'selected' : ''}
                        onClick={() => {
                            setTab({ type: 'SET_FILTERTAB', payload: item.value });
                            redirectToEvent();
                        }}
                    >
                        {item.label}
                    </OptionsItem>
                ))}
            </Options>

            {filterStructure.map((item, index) => (
                <Options key={index}>
                    <OptionsItem className="title">{item.label}</OptionsItem>
                    {item.options.map((option, ii) => (
                        <OptionsItem key={ii} borderLeft={option.color || ''}>
                            <FormControlLabel
                                label={option.description}
                                control={
                                    <Checkbox
                                        color="secondary"
                                        checked={(filterValue[item.id] || []).includes(option.description)}
                                        onChange={() => changeFilterValue(option.description, item.id)}
                                    />
                                }
                            />
                        </OptionsItem>
                    ))}
                </Options>
            ))}

            <BlankItems>
                {groupCompetences.map((item, index) => (
                    <div key={index}>
                        <div className="bullet" style={{ background: item.color }}></div>
                        <div>{item.description}</div>
                    </div>
                ))}
            </BlankItems>
        </Container>
    );
};

EventFilter.propTypes = {
    filterStructure: PropTypes.array,
    filterValue: PropTypes.object,
    groupCompetences: PropTypes.array,
    setFilter: PropTypes.func,
    tab: PropTypes.string,
    tabs: PropTypes.array,
    setTab: PropTypes.func
};

EventFilter.defaultProps = {
    filterStructure: [],
    filterValue: {},
    groupCompetences: []
};

export default EventFilter;
