import React, { useEffect, useState } from 'react';

import FormMaker from 'components/organisms/layout/form/FormMaker';
import { getCities, getResourceEditData } from 'services/profile';
import { Validator } from 'utils/validators';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import useSnackbar from 'hooks/useSnackbar';
import { updateEnterprise } from 'services/enterprise';
import moment from 'moment';
import { postLogError } from 'services/logErrors';

const EditEnterpriseForm = ({ profile }) => {
    const [getProfile, setGetProfile] = useState(false);
    const [resource, setResource] = useState({});
    const [startValue, setStartValue] = useState({});
    const auth = useSelector((state) => state.auth);
    const snackbar = useSnackbar();

    const parseStartValue = (profile) => {
        const parsedValue = {
            name: profile?.name,
            idState: profile?.id_state,
            idCity: profile?.id_city,
            about: profile?.description,
            image: profile?.url_image,
            overview: profile?.overview,
            sector: profile?.sector?.legend,
            size: profile?.size,
            site: profile?.site,
            age: profile?.age ? new Date(profile?.age) : null
        };

        return parsedValue;
    };

    const getData = async () => {
        await getResourceEditData()
            .then((response) => {
                setResource(response.data);
            })
            .catch((error) => {
                snackbar(error, 'error');
            });
    };

    const getUserData = async () => {
        setStartValue(parseStartValue(profile));
        setGetProfile(true);
    };

    const getCitiesData = async (stateId) => {
        if (stateId) {
            await getCities(stateId)
                .then((response) => {
                    setResource({
                        ...resource,
                        cities:
                            response.data.map((item) => {
                                return {
                                    value: item.name,
                                    id: item.id
                                };
                            }) || []
                    });
                    setGetProfile(true);
                })
                .catch((error) => {
                    snackbar(error.message, 'error');
                });
        }
    };

    const formSubmit = async (values) => {
        const formData = new FormData();

        formData.append('name', values?.name);
        formData.append('overview', values?.overview);
        formData.append('idState', Number(values?.idState));
        formData.append('idCity', Number(values?.idCity));
        formData.append('description', values?.about);
        formData.append('sector', values?.sector);
        formData.append('sizeCompany', values?.size);
        formData.append('age', moment(values?.age).format('YYYY-MM-DD'));
        formData.append('site', values?.site);

        if (values?.image && typeof values?.image !== 'string') {
            formData.append('image', values?.image);
        }

        await updateEnterprise(formData, auth.user.enterpriseId)
            .then(() => {
                snackbar('Perfil atualizado com sucesso', 'success');
                window.location.href = '/aluno/perfil';
            })
            .catch((err) => {
                snackbar('Erro ao salvar dados', 'error');
                postLogError(err);
            });
    };

    const form = [
        [
            {
                id: 'image',
                type: 'dropfile',
                label: 'Subir imagem de perfil',
                initialValue: startValue?.image
            }
        ],
        [
            {
                id: 'name',
                label: 'Nome Completo',
                type: 'text',
                initialValue: startValue?.name,
                validation: Validator.string().max(255).required('O nome é obrigatório')
            }
        ],
        [
            {
                id: 'idState',
                label: 'Estado',
                type: 'dropdown',
                initialValue: startValue?.idState,
                onChange: getCitiesData,
                validation: Validator.number()
                    .equals([resource?.states?.map((item) => item.id)], 'Estado inválido')
                    .required('O estado é obrigatório'),
                options: resource?.states || []
            }
        ],
        [
            {
                id: 'idCity',
                label: 'Cidade',
                type: 'dropdown',
                initialValue: startValue?.idCity,
                validation: Validator.number()
                    .equals([resource?.cities?.map((item) => item.id)], 'Cidade inválida')
                    .required('O estado é obrigatório'),
                options: resource?.cities || []
            }
        ],
        [
            {
                id: 'about',
                label: 'Descrição',
                type: 'textarea',
                initialValue: startValue?.about,
                validation: Validator.string()
            }
        ],
        [
            {
                id: 'overview',
                label: 'Visão Geral',
                type: 'textarea',
                initialValue: startValue?.overview,
                validation: Validator.string()
            }
        ],
        [
            {
                id: 'age',
                label: 'Fundação',
                type: 'date',
                initialValue: startValue?.age,
                maxDate: new Date(),
                validation: Validator.date().required('A data de fundação é obrigatória')
            }
        ],
        [
            {
                id: 'sector',
                label: 'Setor',
                type: 'text',
                initialValue: startValue?.sector,
                validation: Validator.string().max(255, 'O máximo de caracteres é 255').required('O setor é obrigatório')
            }
        ],
        [
            {
                id: 'size',
                label: 'Número de funcionários',
                type: 'text',
                typeInput: 'number',
                initialValue: startValue?.size,
                validation: Validator.number().required('O número de funcionários é obrigatório')
            }
        ],
        [
            {
                id: 'site',
                label: 'Site',
                type: 'text',
                initialValue: startValue?.site,
                validation: Validator.string()
                    .matches(
                        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                        'Insira a url completa. Ex: https://pfa.rehagro.com.br'
                    )
                    .required('O site é obrigatório')
            }
        ]
    ];

    useEffect(() => {
        getData().then(() => getUserData());
    }, []);

    if (!getProfile) {
        return <CircularProgress />;
    }

    return <FormMaker data={form} formSubmit={formSubmit} />;
};

EditEnterpriseForm.propTypes = {
    profile: PropTypes.object
};

export default EditEnterpriseForm;
