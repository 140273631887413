import styled, { css } from 'styled-components';

export const Container = styled.div`
    > header {
        padding: 1rem 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > h2 {
            font-size: 1rem;
            font-weight: bold;
        }
    }

    > main {
        ${({ showForm }) =>
            !showForm &&
            css`
                height: 0;
                max-height: 0;
                min-height: 0;
                overflow: hidden;
            `}
    }
`;

export const Item = styled.div`
    margin-bottom: 1rem;
    border-top: 1px solid #eee;
    padding-top: 1rem;

    &:first-child {
        border-top: none;
        padding-top: 0rem;
    }
`;

export const InputItem = styled.div`
    margin-bottom: 1rem;
`;
