import { useDispatch, useSelector } from 'react-redux';
import { createFavoriteList, editFavoriteList, getFavoriteList, removeFavoriteList } from 'services/favorite';

const useFavoriteList = () => {
    const dispatch = useDispatch();
    const favoriteList = useSelector((state) => state.favoriteList);

    const getAll = async () => {
        if (favoriteList.searched === false) {
            const list = await getFavoriteList()
                .then((res) => {
                    dispatch({
                        type: 'SET_FAVORITE_LIST',
                        payload: {
                            list: res.data
                        }
                    });

                    return res;
                })
                .catch(() => {
                    return [];
                });

            return list;
        }

        return favoriteList.list;
    };

    const add = async (item) => {
        await createFavoriteList(item)
            .then((res) => {
                dispatch({
                    type: 'ADD_FAVORITE_LIST',
                    payload: {
                        item: {
                            ...item,
                            id: res.data.id
                        }
                    }
                });
                location.reload();
            })
            .catch(() => {});
    };

    const edit = async (item) => {
        await editFavoriteList(item)
            .then(() => {
                dispatch({
                    type: 'EDIT_FAVORITE_LIST',
                    payload: {
                        item: {
                            id: Number(item.id),
                            description: item.description,
                            description: item.idIcon
                        }
                    }
                });
                location.reload();
            })
            .catch(() => {});
    };

    const remove = async (id) => {
        await removeFavoriteList(id)
            .then(() => {
                dispatch({
                    type: 'DELETE_FAVORITE_LIST',
                    payload: {
                        id
                    }
                });
            })
            .catch(() => {});
    };

    const selectItem = (item) => {
        dispatch({
            type: 'SELECT_FAVORITE_LIST',
            payload: {
                item
            }
        });
    };

    const openAddModal = () => {
        dispatch({
            type: 'OPEN_ADD_MODAL'
        });
    };

    const closeAddModal = () => {
        dispatch({
            type: 'CLOSE_ADD_MODAL'
        });
    };

    const openEditModal = (item) => {
        dispatch({
            type: 'OPEN_EDIT_MODAL',
            payload: {
                item
            }
        });
    };

    const closeEditModal = () => {
        dispatch({
            type: 'CLOSE_EDIT_MODAL'
        });
    };

    const openListModal = (selectedItem, setIsFavorite, idContent, type) => {
        dispatch({
            type: 'OPEN_LIST_MODAL',
            payload: {
                item: selectedItem,
                callback: setIsFavorite,
                content: {
                    id: idContent,
                    type
                }
            }
        });
    };

    const closeListModal = () => {
        dispatch({
            type: 'CLOSE_LIST_MODAL'
        });
    };

    const openRemoveModal = () => {
        dispatch({
            type: 'OPEN_REMOVE_MODAL'
        });
    };

    const closeRemoveModal = () => {
        dispatch({
            type: 'CLOSE_REMOVE_MODAL'
        });
    };

    return {
        getAll,
        add,
        edit,
        remove,
        openAddModal,
        closeAddModal,
        openEditModal,
        closeEditModal,
        openListModal,
        closeListModal,
        selectItem,
        openRemoveModal,
        closeRemoveModal
    };
};

export default useFavoriteList;
