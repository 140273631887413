import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { Container } from './styles';
import Button from '../../../../molecules/buttons/Button';
import { useNavigate } from 'react-router-dom';
import InputPassword from 'components/atoms/formInputs/InputPassword';
import useSnackbar from 'hooks/useSnackbar';
import { resetPassword } from 'services/auth';

const FormRecoverPassword = () => {
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const snackbar = useSnackbar();

    const navigate = useNavigate();

    const recoverPassword = async (values, { setSubmitting }) => {
        const { newPassword, repeatNewPassword } = values;

        if (newPassword !== repeatNewPassword) {
            snackbar('As senhas não conferem', 'error');
            setSubmitting(false);
            return;
        }

        const response = await resetPassword(email, newPassword, repeatNewPassword, token);

        if (response.status !== 200) {
            snackbar(response.error, 'error');
            setSubmitting(false);
            return response;
        }

        snackbar('Senha alterada com sucesso. Você está sendo redirecionado para a página de login.', 'success');

        setTimeout(() => {
            navigate('/login');
        }, 2000);

        return response;
    };

    const getData = () => {
        const params = new URLSearchParams(window.location.search);
        const email = params.get('email');
        const token = params.get('token');

        setEmail(email);
        setToken(token);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <Formik
            initialValues={{ newPassword: '', repeatNewPassword: '' }}
            validationSchema={Yup.object().shape({
                newPassword: Yup.string().max(255).required('Nova Senha Obrigatório'),
                repeatNewPassword: Yup.string().max(255).required('Repetir Senha Obrigatório')
            })}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true);
                    await recoverPassword(values, { setSubmitting });
                } catch (error) {
                    console.error(error);
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <Container noValidate onSubmit={handleSubmit}>
                    <InputPassword
                        id="newPassword"
                        label="Nova Senha"
                        value={values.newPassword}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        error={Boolean(touched.newPassword && errors.newPassword)}
                        errorText={errors.newPassword}
                    />

                    <InputPassword
                        id="repeatNewPassword"
                        label="Repita a nova senha"
                        value={values.repeatNewPassword}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        error={Boolean(touched.repeatNewPassword && errors.repeatNewPassword)}
                        errorText={errors.repeatNewPassword}
                    />

                    <Button type="submit" onClick={() => handleSubmit()} disabled={isSubmitting}>
                        Cadastrar nova senha
                    </Button>
                </Container>
            )}
        </Formik>
    );
};

export default FormRecoverPassword;
