import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px;

    .comment__item {
        display: flex;
        padding: 8px;
        gap: 8px;
        background: #f8f8fd;
        border-radius: 8px;
    }

    .comment__iteml {
        .MuiAvatar-root {
            cursor: pointer;
        }
    }

    .comment__itemr {
        flex-grow: 1;
        overflow: auto;
    }

    .comment__item-user {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 130%;
        margin-bottom: 4px;
        color: #373848;
    }

    .comment__item-comment {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: #373848;

        ${({ ellipsis }) =>
            ellipsis &&
            `
            max-height: 36px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
                    line-clamp: 2; 
            -webkit-box-orient: vertical;
        `}

        ${({ ellipsis }) => !ellipsis && `overflow: auto;`}
    }
`;
