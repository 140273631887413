import styled from 'styled-components';

export const ContainerWrapper = styled.div`
    margin-top: ${(props) => props.marginTop};

    > .title {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 130%;
        color: #033025;
        margin-bottom: 23px;
    }
`;

// UA - User Aside
// CC - Competence Carousel
// T - Timeline
// HA - Home Aside

export const Container = styled.div`
    display: grid;
    grid-template-columns: 220px 560px 320px;
    grid-template-rows: auto 1fr;
    gap: 2rem;
    width: 100%;
    max-width: 1200px;
    align-items: start;
    align-content: start;

    grid-template-areas:
        'UA CC CC'
        'UA T HA';
`;
