import styled from 'styled-components';
import { Chip } from '@mui/material';

export const Container = styled.div`
    width: 100%;
    display: flex;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: var(--box-shadow);
    border-radius: 8px;

    flex-direction: column;
    padding: var(--padding-base);

    > header {
        display: flex;
        justify-content: space-between;

        > main {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            align-items: flex-end;

            > p {
                font-size: 0.7rem;
            }
        }
    }

    > main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > * {
            margin-bottom: var(--padding-base);
        }

        > header {
            display: flex;
            flex-direction: column;
            width: 100%;

            > img {
                width: 100%;
                border-radius: var(--border-radius);
                background-color: #eee;
            }
        }
    }
`;

export const Title = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    > h1 {
        font-size: 1.3rem;
        color: var(--color-text);
    }

    > aside {
        display: flex;
        flex-direction: row;
    }
`;

export const Description = styled.div`
    > div {
        > h3 {
            font-size: 0.9rem;
            color: #979797;
            font-weight: 500;
            margin-top: 0;
            padding-bottom: 0.8rem;
        }

        > p {
            font-size: 0.8rem;
            color: #111;
        }
    }
`;

export const DescriptionText = styled.div`
    font-size: 0.9rem !important;
    line-height: 1.2rem !important;

    p {
        margin-top: 0.8rem;
    }

    > h2 {
        margin-top: 1rem;
        font-size: 1.1rem;
    }

    font-size: 0.8rem !important;
    line-height: 1.2rem !important;
    color: #333 !important;
    background-color: transparent !important;
    font-family: 'Myriad Pro', sans-serif !important;

    span {
        font-size: 0.8rem !important;
        color: #333 !important;
        font-family: 'Myriad Pro', sans-serif !important;
        background-color: transparent !important;
        line-height: 1.2rem !important;
    }
`;

export const ItemsContainer = styled.div``;

export const OpportunityTag = styled(Chip)`
    margin-bottom: 10px;
    background-color: var(--color-primary);
    color: #ffffff;
    font-size: xx-small;
`;

export const ParticipatingOpportunity = styled(Chip)`
    margin-bottom: 10px;
    background-color: #32cd32;
    color: #ffffff;
    font-size: xx-small;
`;

export const Actions = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 24px;

    > div {
        display: flex;
        align-items: center;
        font-size: 14px;
    }
`;

export const Spacer = styled.div`
    flex-grow: 1;
`;
