import styled from 'styled-components';

export const Container = styled.form`
    width: 100%;
    padding: 1rem 0;

    > * {
        width: 100%;
        margin-bottom: 1rem;
    }

    > *:last-child {
        margin-bottom: 0;
    }
`;
