import React from 'react';
import PropTypes from 'prop-types';
import { Container, Lines } from './styles';
import { useNavigate } from 'react-router-dom';

const ProfileRedirect = ({ imageUrl, title, description, href }) => {
    const navigate = useNavigate();

    const redirect = () => {
        navigate(href);
    };

    return (
        <Container onClick={() => redirect()}>
            <aside>{imageUrl ? <img src={imageUrl} alt={title} /> : <img src={'https://via.placeholder.com/126'} alt={title} />}</aside>
            <main>
                <h2>{title}</h2>
                <p>{description}</p>
            </main>
            <Lines src="https://pfahomologacaoapi.rehagro.com.br/storage/images_competences/Group_347.png" alt="Linhas" />
        </Container>
    );
};

ProfileRedirect.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired
};

export default ProfileRedirect;
