import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const InputPassword = ({ id, error, value, handleBlur, handleChange, label, errorText }) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <Container fullWidth error={error}>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <OutlinedInput
                id={id}
                type={showPassword ? 'text' : 'password'}
                value={value}
                name={id}
                onBlur={handleBlur}
                onChange={handleChange}
                label={label}
                inputProps={{}}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                            size="large"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {error && (
                <FormHelperText error id={`${id}-error`}>
                    {errorText}
                </FormHelperText>
            )}
        </Container>
    );
};

InputPassword.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    error: PropTypes.bool,
    errorText: PropTypes.string
};

export default InputPassword;
