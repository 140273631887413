import styled from 'styled-components';

export const Container = styled.div`
    overflow: visible;
    box-shadow: var(--box-shadow);
    padding: 1rem;
    border-radius: var(--border-radius);
    background: #ffffff;
    min-width: 15.5rem;
    max-width: 15.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    opacity: 0.5;

    > * {
        margin-bottom: 0.5rem;
    }

    > header {
        width: 100%;
        height: 2rem;
        margin-top: -1.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: visible;
    }

    > main {
        overflow: visible;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 21px;
            line-height: 24px;
            color: var(--color-dark);
        }
    }

    > footer {
        width: 100%;
    }

    ${(props) => props.pointer && `cursor:pointer;`}
`;
