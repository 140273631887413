import PropTypes from 'prop-types';

const libraryType = {
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    point: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    competence: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            desription: PropTypes.string
        })
    ),
    post: PropTypes.number,
    time: PropTypes.number,
    learningObject: PropTypes.shape({
        id: PropTypes.number,
        idLearningObject: PropTypes.number,
        idLibrary: PropTypes.number,
        name: PropTypes.string,
        typeLearningObject: PropTypes.string,
        url: PropTypes.string
    }),
    isViewed: PropTypes.bool
};

const learningObjectType = {
    id: PropTypes.number,
    name: PropTypes.string,
    id_type: PropTypes.number,
    size: PropTypes.string,
    url: PropTypes.string,
    id_library: PropTypes.number,
    typeLearningObject: PropTypes.string,
    library: PropTypes.shape(libraryType)
};

export { learningObjectType, libraryType };
