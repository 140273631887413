import { Avatar } from '@mui/material';
import FavoriteCheckbox from 'components/atoms/inputs/FavoriteCheckbox';
import Button from 'components/molecules/buttons/Button';
import Chips from 'components/molecules/containers/Chips';
import Player from 'components/molecules/functionalities/Player';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import { months } from 'constants/date';

import { Container, ImageContainer, DescriptionText, VideoContainer, HrContainer, HeaderTitle } from './styles';
import { TimelineItem } from 'types/data/timeline';
import { htmlClean } from 'utils/html';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { likePost } from 'services/timeline';
import { seeLibrary, timeStopLibrary } from 'services/library';
import useSnackbar from 'hooks/useSnackbar';
import { seePost } from 'services/post';
import { getFallbackUrl } from 'utils/general';
import PublicationCommentBtn from 'components/molecules/buttons/PublicationComment';
import { EventColumn } from 'components/organisms/lists/EventsList/styles';
import { likeEvent } from 'services/events';

const TimelineCardEvent = ({ post, headerTitle }) => {
    const [liked, setLiked] = useState(post.like);
    const [uri, setUri] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [eventsByMonth, setEventsByMonth] = useState([]);

    const snackbar = useSnackbar();
    const navigate = useNavigate();

    const auth = useSelector((state) => state.auth);
    const postIsEvent = ['Palestra', 'Webinar'].includes(post.typeEvent || false);

    // const getEventsByMonth = () => {
    //     const eventsByMonth = [];
    //     let eventMonth;
    //     let eventYear;
    //     let key;

    //     post.forEach((event) => {
    //         eventMonth = new Date(event.initialDate).getMonth();
    //         eventYear = new Date(event.initialDate).getFullYear();
    //         key = `${eventMonth}-${eventYear}`;
    //         if (!eventsByMonth[key]) {
    //             eventsByMonth[key] = [];
    //         }

    //         eventsByMonth[key] = [...eventsByMonth[key], event];
    //     });
    //     console.log(eventsByMonth);
    //     setEventsByMonth(eventsByMonth);
    // };

    // useEffect(() => {
    //     getEventsByMonth();
    // }, [post]);

    const handleLike = async (value) => {
        await likeEvent(post.id, auth?.user?.studentId, !liked)
            .then(() => {
                setLiked(!liked);
                snackbar(value ? 'Evento curtida com sucesso' : 'Evento descurtida com sucesso', 'success');
            })
            .catch((err) => {
                snackbar(err.message || 'Erro ao curtir evento', 'error');
            });

        setLiked(value);
    };

    const { typeAdvertisement } = post;

    const getUri = () => {
        if (postIsEvent) {
            return getFallbackUrl(`/aluno/eventos/${post.id}`, 'Evento');
        }

        if (typeAdvertisement === 'Biblioteca') {
            return getFallbackUrl(`/aluno/biblioteca/conteudo/${post.itemId}`, 'Home');
        }

        return '';
    };

    const redirectToEvent = (id) => {
        return navigate(`/aluno/eventos/${id}`);
    };

    const redirect = async () => {
        if (!post.sawed && !postIsEvent) {
            try {
                await seePost(Number(post.id));
            } catch (err) {}
        }

        navigate(getUri());
    };

    const seeLibraryData = async () => {
        if (post.viewed) {
            return;
        }
        await seeLibrary(auth.user.studentId, post.itemId)
            .then(() => {
                setLiked(false);
                snackbar('Material assistido com sucesso', 'success');
                setViewed(true);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const buttonText = typeAdvertisement === 'Gravação do evento' ? 'Assistir evento' : 'Saiba mais';

    useEffect(() => {
        setUri(getUri());
    }, [post]);

    return (
        <>
            {/* <HeaderTitle>{headerTitle}</HeaderTitle> */}
            <Container key={post.id} data-initial-month={post.initialDate.split(/[^0-9]/g)[1]}>
                <Link to={uri}>
                    <header>
                        <aside>
                            <Avatar src="https://pfahomologacaoapi.rehagro.com.br/storage/images_system/rehagro.png" />
                        </aside>
                        <main>
                            <h3>{post.title}</h3>
                            <Chips
                                data={post.competences.map((item) => {
                                    return { description: item.desription, color: item.color };
                                })}
                            />
                        </main>
                    </header>
                </Link>
                <Link to={uri}>
                    <main>
                        {post.image && (
                            <ImageContainer>
                                <img src={post.image} alt={post.title} />
                            </ImageContainer>
                        )}
                        {post.video && (
                            <VideoContainer>
                                <Player
                                    url={post.video}
                                    setFinished={() => seeLibraryData()}
                                    watchClass={{
                                        id: post.itemId,
                                        myStudentTimeStop: null
                                    }}
                                    idStudent={auth?.user?.studentId}
                                    stopClass={(a, b, c) => timeStopLibrary(a, b, c)}
                                />
                            </VideoContainer>
                        )}
                        <DescriptionText>{ReactHtmlParser(htmlClean(post.description).split('</p>')[0])}</DescriptionText>
                        <footer>
                            <Link to={`/aluno/eventos/${post.id}`}>
                                <Button size="fat" width="auto" color="neutral">
                                    Saiba mais
                                </Button>
                            </Link>
                        </footer>
                    </main>
                </Link>
                <footer>
                    <aside>
                        <div className="footer-aside__item">
                            <FavoriteCheckbox active={liked} setActive={handleLike} noPaddingLeft />
                            <p>{post.likes + liked - post.like || 0}</p>
                        </div>
                        {/* <div className="footer-aside__item">
                            <PublicationCommentBtn publication={post} modalOpen={modalOpen} setModalOpen={setModalOpen} />
                            <p>{post.commentsTotal || 0}</p>
                        </div> */}
                        <div className="spacer" />
                        <div className="footer-aside__item">
                            <p>{post.saw} visualizações</p>
                        </div>
                    </aside>
                </footer>
            </Container>
            {/* <HrContainer>
                <hr></hr>
            </HrContainer> */}
        </>
    );
};

TimelineCardEvent.propTypes = {
    post: PropTypes.shape(TimelineItem)
};

export default TimelineCardEvent;
